import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  onboardActivityReset,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import CallingCode from "../../services/CallingCode";
import { fetchCityAndState } from "../../services/commonServices";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../services/Constants";
import { getOrganization } from "../../redux/action/getOrganization";
import {
  activityDuration,
  campaignType,
  restrictedCountry,
  BloodGroup,
  Gender,
  status,
  educationList,
  preferWorkingDays,
  hoursEveryWeek,
  interestAreaDict,
  skillSets
} from "../../utils/dict";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { getCity } from "../../redux/action/cityAction";
import { states, MenuProps } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link } from "react-router-dom";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import styled from 'styled-components';

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'block',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  ...commonFontStyles,
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

const StyledStateCityInput = styled('input')(({ theme }) => ({
  ...commonFontStyles,
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: '#949494',
  // color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    ...commonFontStyles,
    width: '100%',
    height: '56px',
    color: '#949494',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  ...commonFontStyles,
  width: '100%',
  height: '56px',
  color: '#949494',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',

}));

const commonFontStyles = {
  fontFamily: 'Arial, sans-serif', // Replace with your desired font family
  fontSize: '16px', // Replace with your desired font size
};

function VolunteerDeleteScreen(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const [orgRole, setORGRole] = React.useState([]);
  const [org, setORG] = React.useState([]);
  const locationPath = useLocation();
  // const [type, setType] = useState(null);
  // const [id, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  // const [deleteMessage, setDeleteMessage] = useState(null);

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const [volunteerDetails, setvolunteerDetails] = useState({
    // fullname: "",
    firstname: "",
    lastname: "",
    dob: new Date(),
    correspondenceAddress: "",
    emailId: "",
    gender: "",
    phoneNumber: "",
    city: "",
    state: "",
    area: "",
    organization: "",
    highLevelEdu: "",
    preferDays: "",
    interestArea: "",
    hoursPerWeek: "",
    orgRole: "",
    bloodGroup: "",
    skill: "",
  });

  const getORGRole = async () => {
    try {
      const res = await axios.get(`/orgrole/${localStorage.getItem("userid")}`);
      setORGRole(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const roleDisplayNames = {
    admin: "Organization Admin",
    volunteer: "Changemaker",
    campadmin: "Campaign Admin",
    cyaagadmin: "Platform Admin"
  };

  const handleDeleteUser = async () => {
    // setDisplayConfirmationModal(true);
    const payload = {
      // campid: locationPath?.state?.campid,
      // createdate: locationPath?.state?.createdate,
      // reviewstatus: reviewstatus,
      // reviewcomments: reviewComments,
      // phoneno: localStorage.getItem("userid")
      phoneno: locationPath?.state.phoneno,

    };
    try {
      const res = await axios.put(
        // `/user/delete/${localStorage.getItem("userid")}`,
        `/user/delete`,
        payload
      );
      // navigate("/reviewcampaign");
      // setuserdeletestat(res?.data);
      setDisplayConfirmationModal(false);
      navigate("/reviewvolunteer");
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getORG = async () => {
    try {
      const res = await axios.get(`/organization/${localStorage.getItem("userid")}`);
      setORG(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const [response, setResponse] = useState({});
  const { isSuccess: profileSuccess, response: VolunteerResponse } = useSelector(
    (state) => state.profile
  );

  const handleFieldChange = (field, e) => {
    setResponse({});
    setvolunteerDetails({
      ...volunteerDetails,
      [field]: field === "dob" ? e : e.target.value,
    });
  };
  
  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
  }


  useEffect(() => {
    if (locationPath?.state) {
      setvolunteerDetails({
        // fullname: locationPath?.state.fullname,
        // dob: locationPath?.state.dob,
        firstname: locationPath?.state.firstname,
        lastname: locationPath?.state.lastname,
        dob: moment(locationPath?.state.dob, "MM-DD-YYYY"),
        correspondenceAddress: locationPath?.state.correspondenceaddress,
        emailId: locationPath?.state.emailid,
        gender: locationPath?.state.gender,
        phoneNumber: locationPath?.state.phoneno,
        city: locationPath?.state.city,
        state: locationPath?.state.state,
        area: locationPath?.state.area,
        organization: locationPath?.state.organization,
        orgRole: locationPath?.state.orgrole,
        highLevelEdu: locationPath?.state.highlvledu,
        preferDays: locationPath?.state.preferday,
        interestArea: locationPath?.state.interestarea,
        hoursPerWeek: locationPath?.state.hoursperweek,
        bloodGroup: locationPath?.state.bloodgroup,
        skill: locationPath?.state.skill,
      });
    }
  }, [locationPath?.state]);

  console.log(locationPath?.state)

  const handleSave = async () => {
    try {
      const res = await axios.put(`/user/profile`, {
        // fullname: volunteerDetails?.fullname,
        firstname: volunteerDetails?.firstname,
        lastname: volunteerDetails?.lastname,
        dob: moment(volunteerDetails?.dob).format("DD-MM-yyyy"),
        phoneno: volunteerDetails?.phoneNumber,
        correspondenceaddress: volunteerDetails?.correspondenceAddress,
        emailid: volunteerDetails?.emailId,
        gender: volunteerDetails?.gender,
        city: volunteerDetails?.city,
        state: volunteerDetails?.state,
        organization: volunteerDetails?.organization,
        highlvledu: volunteerDetails?.highLevelEdu,
        preferday: volunteerDetails?.preferDays,
        hoursperweek: volunteerDetails?.hoursPerWeek,
        interestarea: volunteerDetails?.interestArea,
        orgrole: volunteerDetails?.orgRole,
        bloodgroup: volunteerDetails?.bloodGroup,
        skill: volunteerDetails.skill,
      });
      setResponse(res?.data);
      return res;

    } catch (e) {
      // console.log("error", e);
    }
  };


  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );


  React.useEffect(() => {
    dispatch(onboardBulkReset());
    dispatch(onboardIndividualReset());
    dispatch(onboardActivityReset());
    dispatch(onboardCampaignReset());
    dispatch(onboardOrganizationReset());
  }, []);

  useEffect(() => {
    // console.log(locationPath?.state.city)
    if (locationPath?.state.city) {
      dispatch(getCity(locationPath?.state.state));
    }
    getORGRole();
    getORG();
  }, [])

  //handle key press event
  const handleKeypress = (e) => {
    e.preventDefault();
    return false
  }
  const isMobile = useMediaQuery('(max-width:600px)');

  console.log("volunteerDetails = ", volunteerDetails)
  return(
  <div className="wrapper">
    <ResponsiveDrawer {...props} />
    <section className="containers">
      <TopHeaderScreen {...props} />
      <div class="content content-wrap">
        <div class="content-mrg">
        <Button
              onClick={() => navigate(-1)}
              className="backWrapper"
              style={{ justifyContent: "left" }}
            >
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
          <div>
          <span style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5, marginLeft: "5px" }}>
            Delete User Profile
          </span>
          </div>
          <form autoComplete="off">
            <div style={{ border: "1px solid #dadce0", borderRadius: 8, padding: "13px 30px 40px 30px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <FormGroup >
                  <StyledLabel>First Name</StyledLabel>
                  <StyledInput
                    disabled
                    // value={volunteerDetails?.fullname}
                    value={volunteerDetails?.firstname}
                    type="text"
                    // placeholder="Your answer"
                    onChange={(e) => {
                      handleFieldChange("firstname", e);
                    }}
                  />
                </FormGroup>
                <FormGroup >
                  <StyledLabel>Last Name</StyledLabel>
                  <StyledInput
                    disabled
                    value={volunteerDetails?.lastname}
                    type="text"
                    placeholder="Your answer"
                    onChange={(e) => {
                      handleFieldChange("lastname", e);
                    }}
                  />
                </FormGroup>
                <FormGroup >
                  <StyledLabel>Date of Birth</StyledLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={volunteerDetails?.dob}
                      onChange={(newValue) => {
                        handleFieldChange("dob", newValue);
                      }}
                      // maxDate={new Date()}
                      maxDate={subtractYears(5)}
                      disabled
                      renderInput={(params) => (
                        <StyledTextField
                          type="text"
                          onKeyDown={(e) => { handleKeypress(e) }}
                          style={{
                            width: "100%",
                            // height: 56,
                            // color: "#949494",
                            // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>

                </FormGroup>
                <FormGroup >
                  <StyledLabel>Gender</StyledLabel>
                  <StyledSelect
                    // style={{
                    //   width: "100%",
                    //   fontSize: 14,
                    //   height: 56,

                    //   boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    // }}
                    value={volunteerDetails?.gender}
                    disabled
                    onChange={(e) => handleFieldChange("gender", e)}
                  >
                    {Gender.map((item, index) => (
                      <MenuItem key={item.value} value={item?.value}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormGroup>

                <FormGroup >
                  <StyledLabel>Blood Group</StyledLabel>
                  <StyledSelect
                    style={{
                      width: "100%",
                      // fontSize: 14,
                      // height: 56,

                      // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                    disabled
                    value={volunteerDetails?.bloodGroup}
                    onChange={(e) => handleFieldChange("bloodGroup", e)}
                  >
                    {BloodGroup.map((item, index) => (
                      <MenuItem key={item.value} value={item?.value}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormGroup>

                <FormGroup>
                  <StyledLabel>Email ID</StyledLabel>
                  <StyledInput
                    disabled
                    value={volunteerDetails?.emailId}
                    type="text"
                    style={{
                      width: "100%",
                      // border: "none",
                      // padding: 10,
                      // display: "block",
                      // borderRadius: 6,
                      // height: 54,
                      // boxShadow: "0px 3px 6px #00000029",
                      // color: "#949494",
                      // fontSize: 15,
                    }}
                    onChange={(e) => handleFieldChange("emailId", e)}
                  />
                </FormGroup>
                <FormGroup  >
                  <StyledLabel>
                    Phone no
                  </StyledLabel>
                  <StyledInput
                    disabled
                    value={volunteerDetails?.phoneNumber}
                    type="text"
                    onChange={(e) => handleFieldChange("phoneNumber", e)}
                  />
                </FormGroup>
                <FormGroup  >
                  <StyledLabel>
                    Correspondence Address
                  </StyledLabel>
                  <StyledInput
                    disabled
                    value={volunteerDetails?.correspondenceAddress}
                    type="text"
                    accept="image/*"
                    id="create-yaad--title"
                    onChange={(e) => handleFieldChange("correspondenceAddress", e)}
                  />
                </FormGroup>


                {location.map((item, index) => (
                  <FormGroup  >
                    <StyledLabel>
                      State
                    </StyledLabel>
                    {/* HI {JSON.stringify(values)} */}
                    <StyledStateCityInput
                    value={volunteerDetails?.state}
                      />

                    {/* <StyledSelect
                      style={{
                        width: "100%",
                        // fontSize: 14,
                        // height: 56,
                        // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                      }}
                      // value={volunteerDetails?.state}
                      value={volunteerDetails?.state}
                      disabled
                      onChange={(e) => {
                        // handleChangeLocation(item, e, "state", index);
                        handleFieldChange("state", e)
                        // handleChangeLocation("state", e);
                        // dispatch(getCity(item.state));
                        dispatch(getCity(e.target.value));
                      }}

                      onBlur={(e) => {
                        // dispatch(getCity(item.state));
                      }}
                    >
                      {states.map((item, index) => (
                        <MenuItem style={{ fontSize: 16 }} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </StyledSelect> */}
                  </FormGroup>
                ))}

                {location.map((item, index) => (
                  <FormGroup  >
                    <StyledLabel>
                      City/Town
                    </StyledLabel>
                    <StyledStateCityInput
                    value={volunteerDetails?.city}
                      />

                    {/* <StyledSelect
                      style={{ width: "100%", 
                        // fontSize: 14, height: 56
                       }}
                      disabled
                      value={volunteerDetails?.city}
                      onChange={(e) => {
                        handleFieldChange("city", e);
                      }}
                    >
                      {citiesSuccess &&
                        citiesResponse.map((item, index) => (
                          <MenuItem style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </StyledSelect> */}
                  </FormGroup>
                ))}

                <FormGroup  >
                  <StyledLabel>
                    Highest Level of Education
                  </StyledLabel>
                  <StyledSelect
                    style={{
                      width: "100%",
                      // fontSize: 14,
                      // height: 56,

                      // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                    value={volunteerDetails?.highLevelEdu}
                    disabled
                    onChange={(e) => handleFieldChange("highLevelEdu", e)}
                  >
                    {educationList.map((item, index) => (
                      <MenuItem key={item.value} value={item?.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormGroup>

                <FormGroup  >
                  <StyledLabel>Hours Per Week</StyledLabel>
                  <StyledSelect
                    style={{
                      width: "100%",
                      // fontSize: 14,
                      // height: 56,

                      // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                    value={volunteerDetails?.hoursPerWeek}
                    disabled
                    onChange={(e) => handleFieldChange("hoursPerWeek", e)}
                  >
                    {hoursEveryWeek.map((item, index) => (
                      <MenuItem key={item.value} value={item?.value}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormGroup>

                <FormGroup  >
                  <StyledLabel>
                    Prefer Day
                  </StyledLabel>
                  <StyledSelect
                    style={{
                      width: "100%",
                      // fontSize: 14,
                      // height: 56,
                      // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                    value={volunteerDetails?.preferDays}
                    disabled
                    onChange={(e) => handleFieldChange("preferDays", e)}
                  >
                    {preferWorkingDays.map((item, index) => (
                      <MenuItem key={item.value} value={item?.value}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormGroup>

                <FormGroup  >
                  <StyledLabel> Name of Organisation</StyledLabel>
                  <StyledSelect
                    style={{
                      width: "100%",
                      // fontSize: 14,
                      // height: 56,
                      // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                    value={volunteerDetails?.organization}
                    disabled
                    onChange={(e) => {
                      handleFieldChange("organization", e);
                    }}
                  >
                    {org?.map((item, index) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormGroup>

                <FormGroup  >
                  <StyledLabel>Organisation Role</StyledLabel>
                  <StyledSelect
                    style={{
                      width: "100%",
                      // fontSize: 14,
                      // height: 56,
                      // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                    value={volunteerDetails?.orgRole.toLowerCase()}
                    disabled
                    onChange={(e) => {
                      handleFieldChange("orgRole", e);
                    }}
                  >
                    {orgRole?.map((item, index) => (
                      <MenuItem key={item.role} value={item.role}>
                        {/* {item.role} */}
                        {roleDisplayNames[item.role]}
                      </MenuItem>
                    ))}
                  </StyledSelect>

                </FormGroup>

              </div>
            </div>
          </form>
          {/* <div style={{display: "inline-flex", flexDirection: "row", justifyContent: "center" }}> */}
          <div style={{display: "inline-flex", justifyContent: "center", width: '100%'}}>
            <Button
              onClick={() => setDisplayConfirmationModal(true)}
              style={{
                marginTop: 8,
                // marginLeft: isMobile? 70: 300,
                marginRight: isMobile ? 10 : 20,
                  width: isMobile ? "100px" : "200px",
                // background: "#E88A00",
                background: "red",
                borderRadius: 20,
                color: "#fff",
                fontFamily: "Poppins !important",
                alignSelf: "center",
              }}
            >
              <span>Delete</span>
            </Button>

            <Button
              onClick={() => navigate(-1)}
              style={{
                marginTop: 8,
                // marginLeft: 600,
                width: isMobile ? "100px" : "200px",
                background: "#e9ecef",
                borderRadius: 20,
                color: "#3B3F5C",
                fontFamily: "Poppins !important",
                alignSelf: "center",
              }}
            >
              <span>Cancel</span>
            </Button>
          </div>
          {profileSuccess &&
            <span style={{ color: "green", textAlign: "center", display: "block" }}>{response?.message}</span>}
          {!profileSuccess && (
            // <span style={{ color: "red" }}>Error occured</span>
            <span style={{ color: "red", textAlign: "center", display: "block" }}>{response?.message}</span>
          )}
          <CopyRight />
          <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDeleteUser} hideModal={hideConfirmationModal} message={"Are you sure you want to delete this user?"} />
        </div>
      </div>
    </section>
  </div>
    );
}
export default VolunteerDeleteScreen;
