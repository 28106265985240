import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { socialLogin } from "../redux/action/loginAction";
import { userProfile } from "../redux/action/UserProfileActions";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { firebaseConfig } from "../services/FirebaseConfig1";
import { Button } from "@mui/material";
import { useLocation } from "react-router";
import OTPInput from '../components/OTPInput';
import jwt from 'jwt-decode';
import cYAAGlogo from "../assets/sdp.jpeg";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { CopyRight } from "../components/CopyRight";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

if (process.env.NODE_ENV === 'development') {
  auth.settings.appVerificationDisabledForTesting = true;
  auth.appVerificationDisabledForTesting = true;
}

function Otp(props) {
  const [otp, setOtp] = React.useState("");
  const checking = "false";
  const navigate = useNavigate();
  const userid = localStorage.getItem("userid");
  const [otpAccepted, setOtpAccepted] = React.useState(false);
  const [loginAuthentication, setLoginSuccess] = React.useState(false);
  const [otpError, setOtpError] = React.useState(false);
  const location = useLocation();

  const { isSuccess: loginSuccess, response: login } = useSelector(
    (state) => state?.login
  );
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state?.profile
  );
  const { isSuccess: registerSuccess, response: register } = useSelector(
    (state) => state.register
  );

  const dispatch = useDispatch();
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  useEffect(() => {
    if (userid) {
      dispatch(userProfile(userid));
    }
    localStorage.setItem("profileurl", profileResponse?.profileurl)
  }, []);
  useEffect(() => {
    if (userid) {
      setLoginSuccess(true);
    }
  }, [userid]);

  useEffect(() => {
    if (userid) {
      localStorage.setItem("profileurl", profileResponse?.profileurl)
    }

  }, [userid]);

  var replacedLoginPhoneNumber = localStorage.getItem(
    "replacedLoginPhoneNumber"
  );
  console.log("replacedLoginPhoneNumber = ", replacedLoginPhoneNumber);

  const replacedRegisteredPhoneNumber = localStorage.getItem("registerNumber");
  const registerNumberRemovedPlus = replacedRegisteredPhoneNumber.replace(
    /\+/g,
    ""
  );

  var loginNumber = localStorage.getItem("loginNumber");

  let vcode = "";
  useEffect(() => {
    vcode = otp[0] + otp[1] + otp[2] + otp[3] + otp[4] + otp[5];
  }, [otp]);


  useEffect(() => {
    const initializeRecaptcha = async () => {
      console.log("inside initializeRecaptcha");
      console.log("!window.recaptchaVerifier = ", !window.recaptchaVerifier)
      // if (!window.recaptchaVerifier) {
        try {
          const recaptchaContainer = document.getElementById('recaptcha-container111');
          if (recaptchaContainer) {
            console.log('Recaptcha container found. Initializing RecaptchaVerifier...');
            console.log('Auth object:', auth);
            if (auth) {
              if (auth.app && auth.config && auth.currentUser) {
                console.log('Auth is fully initialized');
              }
              console.log('Auth object keys:', Object.keys(auth));
              console.log('Auth object values:', Object.values(auth));
              if (auth.appVerificationDisabledForTesting) {
                console.log('appVerificationDisabledForTesting is defined');
              } else {
                console.log('appVerificationDisabledForTesting is not defined');
              }

              console.log('About to initialize RecaptchaVerifier...');
              window.recaptchaVerifier = new RecaptchaVerifier(auth,
                'recaptcha-container111',
                {
                  size: 'invisible',
                  callback: (response) => {
                    console.log('reCAPTCHA solved', response);
                  },
                  'expired-callback': () => {
                    console.log('reCAPTCHA expired');
                  },
                },

              );
              console.log('RecaptchaVerifier initialized:', window.recaptchaVerifier);
              await window.recaptchaVerifier.render();
              console.log('RecaptchaVerifier successfully initialized:', window.recaptchaVerifier);
            } else {
              console.error('Auth object is not defined.');
            }
          } else {
            console.error('Recaptcha container element not found.');
          }
        } catch (error) {
          console.error('Error initializing RecaptchaVerifier:', error);
        }
      // }
    };
    initializeRecaptcha();
  }, []);


  const handleSendCode = () => {
    // Request to send OTP
    // setOtp(new Array(6).fill(""));
    console.log("inside handleSendCode")
    // initializeRecaptcha();

    // var applicationVerifier = new firebaseConfig.auth.RecaptchaVerifier(
    //   "recaptcha-container111"
    // );
    console.log("auth = ", auth);
    // var applicationVerifier = new RecaptchaVerifier(auth, "recaptcha-container111");
    if (!auth) {
      console.error('Firebase Auth is not initialized.');
      return;
    }
    const appVerifier = window.recaptchaVerifier;
    if (!appVerifier) {
      console.error('RecaptchaVerifier is not initialized.');
      return;
    }
    console.log("appVerifier = ", appVerifier)
    // firebaseConfig
    //   .auth().
      signInWithPhoneNumber(
        auth,
        loginNumber.length > 3 ? loginNumber : location?.state?.userid,
        appVerifier
      )
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log('SMS sent. Confirmation result:', confirmationResult);
        // NotificationManager.success(
        //   "A new Otp has been send to your phone number",
        //   "Successful!",
        //   2000
        // );
      })
      .catch((error) => {
        console.log("handleSendCode ERROR = ", error);
      });
  };
  const handleVerify = () => {
    
    if (!window.confirmationResult) {
      console.error('Confirmation result not found');
      return;
    }
    console.log("OTP : Confirmation result found");
    window.confirmationResult
      .confirm(vcode)
      .then((result) => {
        localStorage.setItem(
          "userid",
          replacedLoginPhoneNumber.length > 3
            ? replacedLoginPhoneNumber
            : replacedRegisteredPhoneNumber
        );
        setOtpAccepted(true);
        setLoginSuccess(true);
        if (profileSuccess) {
          localStorage.setItem("token", JSON.stringify(jwt(profileResponse?.token)))
          // axios.defaults.headers.common['Authorization'] = `Bearer ${profileResponse?.token}`;

        }
        if (localStorage.getItem('referralid') !== null && localStorage.getItem('referedcampid') !== null && localStorage.getItem('reflevel') !== null &&
          localStorage.getItem('referralid') !== "" && localStorage.getItem('referedcampid') !== "" && localStorage.getItem('reflevel') !== "" &&
          localStorage.getItem('referralid') !== "null" && localStorage.getItem('referedcampid') !== "null" && localStorage.getItem('reflevel') !== "null") {
          navigate(`/participate/campaign/${localStorage.getItem('referedcampid')}/${profileResponse?.phoneno}`)
        }
        else if (localStorage.getItem('title') !== null && localStorage.getItem('title') !== "" && localStorage.getItem('title') !== "null") {
          navigate(`/participate/camp/${localStorage.getItem('title')}`)
        }
        else if (profileResponse?.orgrole?.toLowerCase() === "investor") {
          navigate("/create_yaag");
        } else if (profileResponse?.orgrole?.toLowerCase() === "national") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "volunteer") {
          // navigate("/volunteer");
          navigate("/home");
        } else if (profileResponse?.orgrole?.toLowerCase() === "cyaagadmin") {
          navigate("/home");
        } else if (profileResponse?.orgrole?.toLowerCase() === "sponsoradmin") {
          navigate("/home");
        } else if (profileResponse?.orgrole?.toLowerCase() === "admin") {
          navigate("/home");
        } else if (profileResponse?.orgrole?.toLowerCase() === "campadmin") {
          navigate("/home");
        }
      })
      .catch((error) => {
        console.log("otp error", error);
        setOtpError(true);
      });
    dispatch(
      userProfile(
        replacedLoginPhoneNumber.length > 3
          ? replacedLoginPhoneNumber
          : replacedRegisteredPhoneNumber
      )
    );
    dispatch(
      socialLogin(
        replacedLoginPhoneNumber.length > 3
          ? replacedLoginPhoneNumber
          : replacedRegisteredPhoneNumber
      )
    );
  };
  return (
    <div class="landingPageWrapper">
      <div class="desktop-nav header navbar" style={{ marginBottom: "0px", padding: "7px 0px 0px 0px" }}>
        <div class="container" style={{ justifyContent: "center" }}>
          <div class="nav-logo-land-direct">
            {" "}
            <img src={cYAAGlogo} class="img-fluid" alt="logo" style={{ maxHeight: "45px" }} />
            <figcaption >Powered by cYAAG</figcaption>
          </div>
        </div>
      </div>

      <div
                style={{
                  display: "flex",
                  // width: "75%",
                  justifyContent: "flex-end",
                  marginRight: 5,
                  marginBottom: 20,
                }}
              >
                
                <marquee behavior="scroll" direction="left" style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', color: 'red', marginTop:'20px' }}>
                Please do not click the back button or refresh this page, as this may interrupt the verification process and require you to start over.
          </marquee>
              </div>

              
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          verticalAlign: "center",
          marginTop: "5%",
        }}
      >
        

        <div style={{
          padding: "30px 0 0 0",
          marginTop: "70px",
        }}>
          <div style={{
            textAlign: "center",
            fontSize: "25px",
            fontWeight: "900",
            color: "#4a4b4d",
            fontFamily: "Raleway, Arial",
          }}>
            An OTP is sent to your phone number
          </div>
          <div style={{
            marginTop: "15px",
            color: "#c6c6c9",
            textAlign: "center",
            fontSize: "15px",
            fontWeight: "700",
            marginBottom: '10px'
          }}>
            Please check your phone number +
            {replacedLoginPhoneNumber.length > 3
              ? replacedLoginPhoneNumber
              : replacedRegisteredPhoneNumber}
            <br></br>
            to continue
          </div>
          {otpError && (
            <div style={{ color: "red", textAlign: "center" }}>
              You have entered incorrect OTP
              <br />
              <Button
                style={{
                  color: "#e1a23b",
                  paddingLeft: "3px",
                  textAlign: "center",
                }}
                onClick={handleSendCode}
              >
                Click Here
              </Button>
              <span style={{ color: "#000000" }}>to resend the OTP</span>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              verticalAlign: "center",
            }}
          >
           
            
            <OTPInput
              inputStyle={{
                fontSize: "30px",
                textAlign: "center",
                fontWeight: "bold",
                marginTop: "50px",
                border: "1px solid white",
                margin: "0px 2px 2px 2px",
                borderRadius: "15px",
                width: "50px !important",
                height: "45px !important",
                background: "#f2f2f2",
                display: "flex",
                padding: "10px 0 0 0",
              }}
              value={otp}
              onChange={(e) => setOtp(e)}
              length={6}
              separator={<span> </span>}
              placeholder="*"
              isInputNum
              onKeyPress={(event) => {
                if (event.key === "Enter") {
              
                  dispatch(handleVerify);
                }
              }}
            />
          </div>
          <Typography
            onClick={() => navigate("/")}
            variant="body1"
            component="p"
            style={{ marginBottom: 8, margin: 12, cursor: "pointer" }}
          >
            {registerSuccess &&
              register.message === "cannot insert existing users"
              ? `You have been registered already, 
            please Sign in!`
              : ""}
          </Typography>
          {/* {loginSuccess && ( */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                verticalAlign: "center",
              }}
              id="recaptcha-container111"
            />
          {/* )} */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              verticalAlign: "center",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              style={{
                borderRadius: 30,
                fontSize: 14,
                textTransform: "uppercase",
                padding: 8,
                width: "60%",
                background: "#e1a23b",
                boxShadow: "none",
                fontWeight: "bold",
                marginTop: "40px",
              }}
              onClick={handleVerify}
            >
              Verify
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              verticalAlign: "center",
            }}
          >
            <div
              style={{ fontSize: "18px", marginTop: "15px", display: "flex" }}
            >
              <Button style={{ color: "#c6c6c9" }}>Didn't Receive OTP? </Button>
              <Button
                style={{ color: "#e1a23b", paddingLeft: "3px" }}
                onClick={handleSendCode}
              >
                Resend OTP
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div class="nofooterinmob">
      <CopyRight />
      </div>
    </div>
  );
}

export default Otp;
