import React, { useState, useEffect , useRef} from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, IconButton, Menu, MenuItem, Chip, Alert, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, Tabs, Tab, useTheme, Badge, Popover, Typography } from '@mui/material';
import { styled } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import TopHeaderScreen from "../../components/TopHeader1";
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CryptoJS from 'crypto-js';
import moment from "moment";
import ShareButtons from '../Teams/ShareButtons';
import CustomShieldIcon from '../../components/CustomShieldIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const CustomTabs = styled(Tabs)(({ theme }) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1976d2',
  },
}));

// Styled component for CustomTab
const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: 'uppercase',
  minWidth: 72,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(4),
  color: '#888da8',
  fontWeight: 600,
  '&:hover': {
    color: '#115293',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#1976d2',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&:focus': {
    color: '#1976d2',
  },
}));

const secretKey = 'wq4r35';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

const encryptTeamId = (teamId) => {
    // Encrypt the teamId
    const encrypted = CryptoJS.AES.encrypt(teamId, secretKey).toString();

    // Convert to Base64 and replace URL-unsafe characters
    const base64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encrypted));
    const urlSafeBase64 = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

    return urlSafeBase64;
};

const HeaderTableRow = styled(TableRow)({
    backgroundColor: '#808080', // Add the background color here
});

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: '16px',
    '@media (min-width: 600px)': {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
});

const Item = styled(Box)({
    flex: '1 1 48%',
    '@media (max-width: 600px)': {
        flex: '1 1 100%',
    },
});

const FullWidthItem = styled(Box)({
    flex: '1 1 100%',
});

const JoinTeam = (props) => {
    const navigate = useNavigate();
    const [myTeamData, setMyTeamData] = useState([]);
    const [mySpecificTeamData, setMySpecificTeamData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [myTeamLBData, setMyTeamLBData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [addTeamMemberResponse, setAddTeamMemberResponse] = useState('');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [showShareButtons, setShowShareButtons] = useState(false);
    const [shareButtonPosition, setShareButtonPosition] = useState({ top: 0, left: 0 });
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [teamID, setTeamID] = useState(null);
    const [myTeamJoiningRequests, setMyTeamJoiningRequests] = useState([]);
    const [memberUniqueuserid, setMemberUniqueuserid] = useState(null);
    const [actionStatus, setActionStatus] = useState(null);
    const [approveRejectDialogOpen, setApproveRejectDialogOpen] = useState(false);
    const [myTeamJoiningRequestStatus, setMyTeamJoiningRequestStatus] = useState([]);
    const [unreadMessages, setUnreadMessages] = useState([]);
    const [anchorE2, setAnchorE2] = useState(null);

    const topRef = useRef(null);

    useEffect(() => {
        if (topRef.current) {
        topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);
    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
        (state) => state.profile
    );




    const handlePopupClick = (event) => {
        setAnchorE2(event.currentTarget);
    };

    const handlePopupClose = () => {
        setAnchorE2(null);
    };

    const open = Boolean(anchorE2);
    const id = open ? 'simple-popover' : undefined;


    const getTeamJoiningRequests = async () => {
        try {
            const response = await axios.get(`/teams/join/request/owner/review/${profileResponse?.phoneno}`);
            setMyTeamJoiningRequests(response?.data);
        } catch (error) {
            console.error('Error fetching my team', error);
        }
    };

    const fetchMyTeam = async () => {
        try {
            const response = await axios.get(`/teams/list/team/${profileResponse?.phoneno}`);
            setMyTeamData(response?.data)
        } catch (error) {
            console.error('Error fetching my team', error);
        }
    };

    const fetchMyTeamSpecificPage = async (teamidfrommenu) => {
        try {
            const response = await axios.get(`/teams/my/team/${teamidfrommenu}/${profileResponse?.phoneno}`);
            setMySpecificTeamData(response.data);

        } catch (error) {
            console.error('Error fetching my team', error);
        }
    };



    const fetchMyTeamLB = async () => {
        try {
            const response = await axios.get(`/teams/overall/lb/${profileResponse?.phoneno}`);
            setMyTeamLBData(response?.data)
        } catch (error) {
            console.error('Error fetching my team', error);
        }
    };

    const fetchMyTeamJoiningRequestStatus = async () => {
        try {
            const response = await axios.get(`/teams/join/request/review/status/${profileResponse?.phoneno}`);
            setMyTeamJoiningRequestStatus(response?.data)
        } catch (error) {
            console.error('Error fetching my team joining request status', error);
        }
    };

    console.log(`/teams/notification/count/${profileResponse?.phoneno}`)
    const getNotificationCount = async () => {
        try {
            console.log("executing getNotificationCount()");
            const response = await axios.get(`/teams/notification/count/${profileResponse?.phoneno}`);
            // console.log('callGetNotificationCountApi API Response:', response.data);
            setUnreadMessages(response.data);
        } catch (error) {
            console.error('Error updating notification data:', error);
            // Handle error scenarios
        }
    };

    const updateNotificationCount = async () => {
        try {
            await getNotificationCount();
            // Replace with your actual Axios API call
            const response = await axios.post(`/teams/update/notification/${profileResponse?.phoneno}`);
            // console.log('callNotificationUpdateApi API Response:', response.data);
            // Handle the response data as needed
        } catch (error) {
            console.error('Error updating notification data:', error);
            // Handle error scenarios
        }
    };

    // console.log("myTeamData = ", myTeamData);
    // console.log("myTeamJoiningRequests = ", myTeamJoiningRequests);

    useEffect(() => {
        console.log("inside useeffect");
        fetchMyTeam();
        fetchMyTeamLB();
        getTeamJoiningRequests();
        fetchMyTeamJoiningRequestStatus();
        getNotificationCount();
    }, [profileResponse]);

    const handleMenuClick = async (event, teamId) => {
        setAnchorEl(event.currentTarget);
        setSelectedTeam(teamId);
        setShowShareButtons(false);
        await fetchMyTeamSpecificPage(teamId);
        // setSelectedUserId(userId);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedTeam(null);
        // setSelectedUserId(null)
    };


    const handleJoinTeam = async () => {
        try {
            // setSnackbarOpen(true);
            setAddTeamMemberResponse('');
            const response = await axios.post(`/teams/join/request/${profileResponse?.phoneno}`, {
                teamid: teamID,
                member: profileResponse?.phoneno,
            });
            if (response.data.status === "success") {
                // console.log(response.data.message);
                // setSnackbarMessage(response.data.message);
                setAddTeamMemberResponse(response.data);

            } else {
                console.error(response.data.message);
                // setSnackbarMessage(response.data.message);
                setAddTeamMemberResponse(response.data);

            }
            await fetchMyTeam();
        } catch (error) {
            console.error('There was an error for the member joining the team!', error);

        } finally {
            setSnackbarOpen(true);
            handleMenuClose();
        }
    };

    // console.log("teamID = ", teamID)
    // console.log("memberUniqueuserid = ", memberUniqueuserid)
    // console.log("actionStatus = ", actionStatus)
    const handleAcceptReject = async () => {
        try {
            // setSnackbarOpen(true);
            setAddTeamMemberResponse('');
            const response = await axios.post(`/teams/join/request/action/${profileResponse?.phoneno}`, {
                teamid: teamID,
                member: memberUniqueuserid,
                actionstatus: actionStatus,

            });
            if (response.data.status === "success") {
                // console.log(response.data.message);
                // setSnackbarMessage(response.data.message);
                setAddTeamMemberResponse(response.data);

            } else {
                console.error(response.data.message);
                // setSnackbarMessage(response.data.message);
                setAddTeamMemberResponse(response.data);

            }
            // setMyTeamJoiningRequests(prevRequests => prevRequests.filter(req => req.uniqueuserid !== action.uniqueuserid));

            await fetchMyTeam();
            await getTeamJoiningRequests();
        } catch (error) {
            console.error('There was an error accepting/rejecting the member!', error);

        } finally {
            setSnackbarOpen(true);
            handleMenuClose();
        }
    };

    // console.log("myTeamJoiningRequests = ", myTeamJoiningRequests);
    // console.log("myTeamJoiningRequestslength = ", myTeamJoiningRequests.length);

    const handleOpenConfirmDialog = (teamid) => {
        setTeamID(teamid);
        setConfirmDialogOpen(true);
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const handleConfirmJoin = async () => {
        await handleJoinTeam();
        setConfirmDialogOpen(false);
    };

    const handleOpenApproveRejectDialog = (action, actionstatus) => {
        setTeamID(action?.teamid);
        setMemberUniqueuserid(action?.uniqueuserid);
        setActionStatus(actionstatus);
        setApproveRejectDialogOpen(true);
    };

    const handleApproveRejectDialogClose = () => {
        setApproveRejectDialogOpen(false);
    };

    const handleConfirmApproveReject = async () => {
        await handleAcceptReject();
        setApproveRejectDialogOpen(false);
    };


    const handleShareClick = (event) => {
        const buttonRect = event.currentTarget.getBoundingClientRect();
        setShareButtonPosition({
            top: buttonRect.bottom + window.scrollY,
            left: buttonRect.left + window.scrollX
        });
        setShowShareButtons(!showShareButtons);
        handleMenuClose();
    };

    const handleShareClose = () => {
        setShowShareButtons(false);
    };


    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        if (newValue === 2) { // Assuming Notification tab index is 2
            // Call your Axios API here
            updateNotificationCount();
        }
    };

    const handleTeamNameClick = (visibility) => {
        if (visibility === 'private') {
            const message = { "message": "This is a private group hence restricted", "status": "success" }
            setAddTeamMemberResponse(message);
            setSnackbarOpen(true);
        }
    };

    console.log("unreadMessages = ", unreadMessages);

    return (
        <div className="wrapper" ref={topRef}>
            <ResponsiveDrawer {...props} />
            <section className="containers">
                <TopHeaderScreen {...props} />
                <div className="content content-wrap">
                    <div className="content-mrg createTeamPage">
                        <Button style={{ padding: "10px", color: "#1976d2" }} onClick={() => navigate(-1)}>
                            <i className="fa fa-caret-left" />
                            <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
                        </Button>
                        <div
                            className="content-title"
                            style={{
                                fontSize: 25,
                                fontWeight: "600",
                                marginBottom: 15,
                                marginTop: 5,
                            }}

                        >
                            Join Team
                        </div>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            {/* <Tabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                centered={!isMobile}
                                variant={isMobile ? 'scrollable' : 'standard'}
                                scrollButtons={isMobile ? 'auto' : 'off'}
                                allowScrollButtonsMobile
                            > */}
                            <CustomTabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                centered={!isMobile}
                                variant={isMobile ? 'scrollable' : 'standard'}
                                scrollButtons={isMobile ? 'auto' : 'off'}
                                allowScrollButtonsMobile
                            >
                                {/* <Tab label="Join Team" /> */}
                                <CustomTab label="Join Team" />
                                {/* <Tab
                                    label={
                                        myTeamJoiningRequests && myTeamJoiningRequests.length > 0 ? (
                                            <Badge 
                                                badgeContent={myTeamJoiningRequests.length} 
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        backgroundColor: '#D32F2F',
                                                        color: '#FFF',
                                                    },
                                                }}
                                                color="secondary">
                                                Requests
                                            </Badge>
                                        ) : (
                                            "Requests"
                                        )
                                    }
                                /> */}
                                <CustomTab
                                    label={
                                        myTeamJoiningRequests && myTeamJoiningRequests.length > 0 ? (
                                            <Badge 
                                                badgeContent={myTeamJoiningRequests.length} 
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        backgroundColor: '#D32F2F',
                                                        color: '#FFF',
                                                    },
                                                }}
                                                color="secondary">
                                                Requests
                                            </Badge>
                                        ) : (
                                            "Requests"
                                        )
                                    }
                                />
                                {/* <Tab
                                    label={
                                        unreadMessages && unreadMessages.length > 0 && unreadMessages[0]["notificationcount"] > 0 ? (
                                            <Badge 
                                                badgeContent={unreadMessages[0]["notificationcount"]} 
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        backgroundColor: '#D32F2F',
                                                        color: '#FFF',
                                                    },
                                                }}
                                                color="secondary">
                                                Notifications
                                            </Badge>
                                        ) : (
                                            "Notifications"
                                        )
                                    }
                                /> */}
                                <CustomTab
                                    label={
                                        unreadMessages && unreadMessages.length > 0 && unreadMessages[0]["notificationcount"] > 0 ? (
                                            <Badge 
                                                badgeContent={unreadMessages[0]["notificationcount"]} 
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        backgroundColor: '#D32F2F',
                                                        color: '#FFF',
                                                    },
                                                }}
                                                color="secondary">
                                                Notifications
                                            </Badge>
                                        ) : (
                                            "Notifications"
                                        )
                                    }
                                />
                            {/* </Tabs> */}
                            </CustomTabs>
                            {selectedTab === 0 && (
                                <TabPanel className="fullWidthTableContainer">
                                    <TableContainer style={{ marginBottom: "50px", maxHeight: "500px", overflowY: "auto" }}>
                                        <Table stickyHeader style={{ borderCollapse: 'collapse' }}>
                                            <TableHead>
                                                <TableRow style={{ borderTop: "none", borderBottom: "none" }}>
                                                    <TableCell sx={{ width: { xs: '30%', sm: '20%', md: '20%' }, color: 'white', backgroundColor: '#808080', textAlign: 'center' }}>Team Name</TableCell>
                                                    <TableCell sx={{ width: { xs: '15%', sm: '15%', md: '15%' }, color: 'white', backgroundColor: '#808080', textAlign: 'center' }}>
                                                        Join?
                                                        <IconButton aria-describedby={id} onClick={handlePopupClick} size="small" style={{ marginLeft: 5 }}>
                                                            <InfoIcon fontSize="small" style={{ color: 'white' }} />
                                                        </IconButton>
                                                        <Popover
                                                            id={id}
                                                            open={open}
                                                            anchorEl={anchorE2}
                                                            onClose={handlePopupClose}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                        >
                                                            <Typography sx={{ p: 2 }}>
                                                                PND = Pending<br />
                                                                NFY = Not For You
                                                            </Typography>
                                                        </Popover>
                                                    </TableCell>
                                                    <TableCell sx={{ width: { xs: '10%', sm: '10%', md: '10%' }, color: 'white', backgroundColor: '#808080', textAlign: 'center' }}>Availability</TableCell>
                                                    <TableCell sx={{ width: { xs: '10%', sm: '10%', md: '10%' }, color: 'white', backgroundColor: '#808080', textAlign: 'center' }}>Actions</TableCell>
                                                    {/* <TableCell sx={{ width: { xs: '20%', sm: '20%', md: '20%' }, color: 'white', backgroundColor: '#808080' }}>Team Goal</TableCell> */}
                                                    <TableCell sx={{ width: { xs: '30%', sm: '30%', md: '30%' }, color: 'white', backgroundColor: '#808080' }}>Team Members</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody >
                                                {
                                                    myTeamData.length === 0 ? (
                                                        <TableRow>
                                                            <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                                                <div className="message-container">
                                                                    <p style={{ marginBottom: "0px" }}>No teams found. Create your team or join a team to get started.</p>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        myTeamData.map((team, index) => (
                                                            <React.Fragment key={team._id}>
                                                                <TableRow key={team._id} style={{ minHeight: "200px", marginBottom: '20px', borderTop: "none", borderBottom: "2px solid #000" }}>


                                                                    <TableCell style={{ display: "inline-flex", alignItems: "center", width: '245px', minHeight: "150px", textAlign: 'center' }}>
                                                                        <div style={{
                                                                            background: "linear-gradient(135deg, #ff7e5f, #feb47b)",
                                                                            borderRadius: "5px",
                                                                            width: "100%",
                                                                            display: "flex",
                                                                            padding: "8px",
                                                                            alignItems: "center",
                                                                            minHeight: "56px",


                                                                        }}
                                                                        >
                                                                            <Avatar src={team.logo} alt={team.name} />
                                                                            <div style={{
                                                                                marginLeft: "8px", display: "flex", flexDirection: "column", textAlign: "left", flex: 1,
                                                                                overflow: "hidden"
                                                                            }}>

                                                                                {
                                                                                    team.visibility === "private" ?
                                                                                        <div
                                                                                            className="clickable-link"
                                                                                            onClick={() => handleTeamNameClick(team.visibility)}
                                                                                            style={{
                                                                                                cursor: 'pointer', color: 'inherit', marginLeft: "8px", wordBreak: "break-all", whiteSpace: "nowrap",
                                                                                                overflow: "hidden",
                                                                                                textOverflow: "ellipsis"
                                                                                            }}
                                                                                        >
                                                                                            {team.name}
                                                                                        </div>
                                                                                        :
                                                                                        <Link
                                                                                            to={`/team/home/${(team.teamid)}`}
                                                                                            className="clickable-link"
                                                                                            style={{
                                                                                                wordBreak: "break-all", whiteSpace: "nowrap",
                                                                                                overflow: "hidden",
                                                                                                textOverflow: "ellipsis"
                                                                                            }}
                                                                                        >
                                                                                            {team.name}
                                                                                        </Link>
                                                                                }
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Chip label={team.isowner.charAt(0).toUpperCase() + team.isowner.slice(1)}
                                                                                    style={{
                                                                                        marginTop: '3px', padding: '0 3px', fontSize: '0.75rem', height: '20px', width: "80px", whiteSpace: "nowrap",
                                                                                        overflow: "hidden",
                                                                                        textOverflow: "ellipsis"
                                                                                    }}
                                                                                />
                                                                                {
                                                                                    team.visibility === "private" &&
                                                                                    // <div style={{marginTop: "2px" }}>
                                                                                        <LockIcon style={{ fontSize: 16, color: 'red', marginLeft: '4px' }} />
                                                                                    // </div>
                                                                                }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '100px', minHeight: "150px", textAlign: 'center' }}>
                                                                        {
                                                                            team.availability > 0 ?

                                                                                team?.alreadyrequested === "yes" ?
                                                                                    <Button disabled variant="contained" color="primary" style={{ minWidth: "94px", backgroundColor: '#FFDB58' }}>
                                                                                        PND
                                                                                    </Button>
                                                                                    : team?.commoncampid === "yes" ?
                                                                                        <Button disabled variant="contained" color="primary" style={{ minWidth: "94px", fontWeight: 600 }}>
                                                                                            NFY
                                                                                        </Button>
                                                                                        :
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            onClick={() => handleOpenConfirmDialog(team.teamid)}
                                                                                            style={{ minWidth: "94px" }}
                                                                                        >
                                                                                            Join
                                                                                        </Button>


                                                                                :
                                                                                <Button disabled variant="contained" color="primary" style={{ minWidth: "94px" }}>
                                                                                    Join
                                                                                </Button>
                                                                        }
                                                                        {

                                                                        }
                                                                    </TableCell>
                                                                    <TableCell style={{ width: '100px', minHeight: "150px", textAlign: 'center', fontFamily: 'Playfair Display, serif', fontSize: 20, fontWeight: 700 }}>
                                                                        {team.availability}
                                                                    </TableCell>
                                                                    <TableCell style={{ minHeight: "150px", textAlign: "center" }}>
                                                                        <IconButton onClick={(event) => handleMenuClick(event, team.teamid)}>
                                                                            <MoreVertIcon />
                                                                        </IconButton>
                                                                        <Menu
                                                                            anchorEl={anchorEl}
                                                                            open={Boolean(anchorEl)}
                                                                            onClose={handleMenuClose}
                                                                        >

                                                                            {
                                                                                mySpecificTeamData &&
                                                                                <MenuItem onClick={handleMenuClose}>
                                                                                    <InfoIcon style={{ marginRight: 8, color: '#ADD8E6' }} />
                                                                                    <Link to={`/team/home/${selectedTeam}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                        About team
                                                                                    </Link>
                                                                                </MenuItem>

                                                                            }
                                                                            <MenuItem onClick={handleShareClick}>
                                                                                <ShareIcon style={{ marginRight: 8, color: 'orange' }} />
                                                                                Share team
                                                                            </MenuItem>
                                                                        </Menu>
                                                                        {showShareButtons && (
                                                                            <div

                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    bottom: '106px',
                                                                                    right: '0',
                                                                                    // right: isMobile ? '10%' : 'auto',
                                                                                    transform: 'none',
                                                                                    zIndex: 1000,
                                                                                    width: isMobile ? 'auto' : 'auto',
                                                                                    maxWidth: isMobile ? '300px' : 'none',
                                                                                    backgroundColor: 'white',
                                                                                    border: '1px solid #ccc',
                                                                                    padding: '10px',
                                                                                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                                                                                }}


                                                                            >
                                                                                <ShareButtons
                                                                                    url={
                                                                                        "Hey! We're working on exciting social campaigns and I think you'd be a great addition to our team - " +
                                                                                        mySpecificTeamData?.[0]?.name +
                                                                                        "! Join us in making a difference and lets together win reward points. Click on this link https://app.cyaag.com now to join the team - " +
                                                                                        mySpecificTeamData?.[0]?.name +
                                                                                        " and get started."
                                                                                    }
                                                                                    onClose={handleShareClose} />
                                                                            </div>
                                                                        )}
                                                                        <Dialog
                                                                            open={confirmDialogOpen}
                                                                            onClose={handleConfirmDialogClose}
                                                                            // PaperProps={{
                                                                            //     sx: {
                                                                            //         backgroundColor: 'transparent', // Transparent background for dialog content
                                                                            //         boxShadow: 'none', // No shadow
                                                                            //     }
                                                                            // }}
                                                                            sx={{
                                                                                '& .MuiBackdrop-root': {
                                                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Slightly dark backdrop with 30% opacity
                                                                                },
                                                                            }}
                                                                        >
                                                                            <DialogTitle>{"Confirm Joining Team"}</DialogTitle>
                                                                            <DialogContent>
                                                                                <DialogContentText>
                                                                                    Are you sure you want to join this team? If Yes, then your request will
                                                                                    be sent to the team owner.
                                                                                </DialogContentText>
                                                                            </DialogContent>
                                                                            <DialogActions>
                                                                                <Button onClick={handleConfirmDialogClose} color="primary">
                                                                                    No
                                                                                </Button>
                                                                                <Button onClick={handleConfirmJoin} color="primary" autoFocus>
                                                                                    Yes
                                                                                </Button>
                                                                            </DialogActions>
                                                                        </Dialog>

                                                                        <style>
                                                                {`
                                                                    /* WebKit browsers */
                                                                    div::-webkit-scrollbar {
                                                                        width: 3px; /* Adjust the width as needed */
                                                                        height: 3px;
                                                                    }
                                                                    
                                                                    div::-webkit-scrollbar-thumb {
                                                                        background-color: darkgrey;
                                                                        border-radius: 10px;
                                                                    }

                                                                    div::-webkit-scrollbar-track {
                                                                        background-color: #f1f1f1;
                                                                        border-radius: 10px;
                                                                    }
                                                                `}
                                                            </style>

                                                                    </TableCell>
                                                                    <TableCell style={{ display: "inline-flex", flexWrap: "wrap", minHeight: "150px" }}>
                                                                        <div style={{ overflowX: 'auto', maxWidth: "500px", display: "inline-flex" }}>
                                                                            {team.memberdetails.map((member, index) => (
                                                                                <Box
                                                                                    key={member.firstname}
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    style={{ marginRight: index < team.memberdetails.length - 1 ? '8px' : '0' }}
                                                                                >
                                                                                    <Avatar src={member.profilephoto} alt={member.firstname} />
                                                                                    <div style={{ marginLeft: '4px' }}>{member.firstname} {member.lastname}</div>
                                                                                </Box>
                                                                            ))}
                                                                        </div>
                                                                    </TableCell>

                                                                    {/* ))} */}
                                                                </TableRow>

                                                                {index < myTeamData.length - 1 && (
                                                                    <TableRow>
                                                                        <TableCell colSpan={4} style={{ padding: '0' }} />
                                                                    </TableRow>
                                                                )}
                                                            </React.Fragment>
                                                        )))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <div class="cyagg-sec-space">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div>
                                                        <h4 class="site-title">Team Leaderboard</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    myTeamLBData?.[0]?.rank === undefined ?
                                                        <div className="message-container">
                                                            <p style={{ marginBottom: "0px" }}>Create your team or join a team and get the Leaderboard running!</p>
                                                        </div>
                                                        : ""
                                                }
                                                <div class="row">
                                                    {/* <div
                                                        class="col-md-4 hide-on-mobile"
                                                        style={{ paddingRight: "0px" }}
                                                    >
                                                        <div class="leaderboard-jointeam">
                                                            <div class="text-center">
                                                                <h5>Top Team</h5>
                                                                {
                                                                    myTeamLBData?.[0]?.logo != "" && myTeamLBData?.[0]?.logo !== undefined ? (
                                                                        <img
                                                                            src={myTeamLBData?.[0]?.logo}
                                                                        ></img>
                                                                    ) : (
                                                                        <span>
                                                                            <AccountCircleIcon style={{ minWidth: "200px", minHeight: "200px" }} fontSize="large" />
                                                                            
                                                                        </span>
                                                                    )
                                                                }
                                                                <span style={{
                                                                    fontSize: 36, padding: 24, wordBreak: "break-all", whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis"
                                                                }}>
                                                                    {myTeamLBData &&
                                                                        myTeamLBData?.[0]?.name}

                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    {/* <div class="col-md-8 col-12 fullWidthTableContainer" style={{ paddingLeft: "0px" }}> */}
                                                    <div class="col-md-12 col-12 fullWidthTableContainer" style={{ paddingLeft: "0px" }}>
                                                    
                                                        <div class="fixedHeader" style={{ height: "465px" }}>
                                                            <table stickyHeader class="leader-b-table" style={{ marginLeft: "2px" }}>
                                                                {/* <thead> */}
                                                                <thead style={{ top: isMobile ? "-2px" : "-1px", position: "sticky", zIndex: 10}}>
                                                                    <tr>
                                                                        <th scope="col" style={{ textAlign: "center" }}>
                                                                            {" "}
                                                                            Rank
                                                                        </th>
                                                                        <th scope="col" style={{ textAlign: "center" }}>
                                                                            Top Team
                                                                        </th>
                                                                        <th scope="col" style={{ textAlign: "center" }}>
                                                                            Total Points
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {myTeamLBData &&
                                                                        myTeamLBData?.map((row) => (
                                                                            <tr >
                                                                                {
                                                                                    row?.rank === 1 || row?.rank === 2 || row?.rank === 3 ?
                                                                                        <td
                                                                                            data-label="Account"
                                                                                            style={{ textAlign: "center", backgroundColor: "#74C3FC" }}
                                                                                        >
                                                                                            <div style={{ position: "relative", display: "inline-block" }}>
                                                                                                {
                                                                                                    row?.rank === 1 ?
                                                                                                        <CustomShieldIcon color="gold" />
                                                                                                        : row?.rank === 2 ?
                                                                                                            <CustomShieldIcon color="whitesmoke" />
                                                                                                            : row?.rank === 3 ?
                                                                                                                <CustomShieldIcon color="#CD7F32" />
                                                                                                                :
                                                                                                                <CustomShieldIcon color="silver" />
                                                                                                }
                                                                                                <span style={{
                                                                                                    position: "absolute",
                                                                                                    top: "50%",
                                                                                                    left: "50%",
                                                                                                    transform: "translate(-50%, -50%)",
                                                                                                    fontSize: "16px",
                                                                                                    color: "black",
                                                                                                    fontWeight: "bold"
                                                                                                }}>
                                                                                                    {row.rank}
                                                                                                </span>
                                                                                            </div>
                                                                                        </td>

                                                                                        :
                                                                                        <td
                                                                                            data-label="Account"
                                                                                            style={{ textAlign: "center", }}
                                                                                        >
                                                                                            <div style={{ position: "relative", display: "inline-block" }}>

                                                                                                <CustomShieldIcon color="silver" />
                                                                                                <span style={{
                                                                                                    position: "absolute",
                                                                                                    top: "50%",
                                                                                                    left: "50%",
                                                                                                    transform: "translate(-50%, -50%)",
                                                                                                    fontSize: "16px",
                                                                                                    color: "black",
                                                                                                    fontWeight: "bold"
                                                                                                }}>
                                                                                                    {row.rank}
                                                                                                </span>
                                                                                            </div>
                                                                                        </td>
                                                                                }
                                                                                {
                                                                                    row?.rank === 1 || row?.rank === 2 || row?.rank === 3 ?
                                                                                        <td data-label="Due Date" style={{ backgroundColor: "#74C3FC" }}>
                                                                                            <div class="top-player-box" >

                                                                                                <div class="top-player-box logoWrap" style={{ textAlign: "center", }}>
                                                                                                    {row?.logo != "" ? (
                                                                                                        <img src={row?.logo}></img>
                                                                                                    ) : (
                                                                                                        <Avatar style={{ display: "inline-block", verticalAlign: "middle" }} src="/broken-image.jpg" />
                                                                                                    )}
                                                                                                    <span className='memberWrapper'><span className="memberCount">{row.totalmembers}</span>
                                                                                                        <PeopleSharpIcon className="peopleIcon" />
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div class="top-player-box" style={{ textAlign: "center" }}>
                                                                                                    {"   "}
                                                                                                    <span className="teamName"
                                                                                                        style={{
                                                                                                            // color: "orange",
                                                                                                            fontWeight: "bold",
                                                                                                        }}
                                                                                                    >
                                                                                                        {row?.name}
                                                                                                    </span>


                                                                                                </div>
                                                                                            </div>
                                                                                        </td>

                                                                                        :
                                                                                        <td data-label="Due Date" >
                                                                                            <div class="top-player-box" >

                                                                                                <div class="top-player-box logoWrap" style={{ textAlign: "center", }}>
                                                                                                    {row?.logo != "" ? (
                                                                                                        <img src={row?.logo}></img>
                                                                                                    ) : (
                                                                                                        <Avatar style={{ display: "inline-block", verticalAlign: "middle" }} src="/broken-image.jpg" />
                                                                                                    )}
                                                                                                    <span className='memberWrapper'><span className="memberCount">{row.totalmembers}</span>
                                                                                                        <PeopleSharpIcon className="peopleIcon" />
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div class="top-player-box" style={{ textAlign: "center" }}>
                                                                                                    {"   "}
                                                                                                    <span
                                                                                                        style={{
                                                                                                            // color: "orange",
                                                                                                            fontWeight: "bold",
                                                                                                        }}
                                                                                                    >
                                                                                                        {row?.name}
                                                                                                    </span>


                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                }
                                                                                {
                                                                                    row?.rank === 1 || row?.rank === 2 || row?.rank === 3 ?
                                                                                        <td
                                                                                            data-label="Amount"
                                                                                            style={{ textAlign: "center", backgroundColor: "#74C3FC", fontWeight: 600, fontSize: "18px" }}
                                                                                        >
                                                                                            {row?.totalpoints?.toFixed(1)}
                                                                                        </td>

                                                                                        :
                                                                                        <td
                                                                                            data-label="Amount"
                                                                                            style={{ textAlign: "center", fontWeight: 600, fontSize: "18px" }}
                                                                                        >
                                                                                            {row?.totalpoints?.toFixed(1)}
                                                                                        </td>
                                                                                }

                                                                            </tr>
                                                                        ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <style>
                                                            {`
                                                                
                                                                .fixedHeader {
                                                                    overflow-y: auto;
                                                                }
                                                                /* WebKit browsers */
                                                                .fixedHeader::-webkit-scrollbar {
                                                                    height: 4px; /* Adjust the height as needed */
                                                                    width: 4px;
                                                                }

                                                                .fixedHeader::-webkit-scrollbar-thumb {
                                                                    background-color: darkgrey;
                                                                    border-radius: 10px;
                                                                }

                                                                .fixedHeader::-webkit-scrollbar-track {
                                                                    background-color: #f1f1f1;
                                                                    border-radius: 10px;
                                                                }
                                                            `}
                                                        </style>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            )}

                            {selectedTab === 1 && (
                                <TabPanel>
                                    {
                                        myTeamJoiningRequests && myTeamJoiningRequests.length > 0 ?
                                            myTeamJoiningRequests?.map((req, index) => (

                                                <div className="jointeamcontainer">
                                                    <div className="jointeamcolumn">{req.firstname} {" "} {req.lastname} from {" "} {req.organization} {" "} wants to join your team {" "} {req.name}</div>
                                                    <div className="jointeamcolumn jointeamtimestamp">
                                                        {/* <div>{req.reqdate}</div> */}
                                                        <div
                                                            style={{
                                                                color: "#000",
                                                                display:
                                                                    "inline-block",
                                                                fontSize: 14,
                                                                color: "grey",
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            {moment(req?.reqdate).fromNow()}
                                                        </div>
                                                    </div>
                                                    <div className="jointeamcolumn">
                                                        <button className="jointeamaccept-button" onClick={() => handleOpenApproveRejectDialog(req, "accept")}>ACCEPT</button>
                                                    </div>
                                                    <div className="jointeamcolumn">
                                                        <button className="jointeamreject-button" onClick={() => handleOpenApproveRejectDialog(req, "reject")}>REJECT</button>
                                                    </div>
                                                    <Dialog
                                                        open={approveRejectDialogOpen}
                                                        onClose={handleApproveRejectDialogClose}
                                                    >
                                                        <DialogTitle>{"Confirm Your Action"}</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText>
                                                                This Action is irreversible. Are you sure you want to {actionStatus}?
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={handleApproveRejectDialogClose} color="primary">
                                                                No
                                                            </Button>
                                                            <Button onClick={handleConfirmApproveReject} color="primary" autoFocus>
                                                                Yes
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </div>

                                            ))
                                            :
                                            <div>You have no pending requests</div>
                                    }
                                </TabPanel>
                            )}

                            {selectedTab === 2 && (
                                <TabPanel>
                                    {
                                        myTeamJoiningRequestStatus && myTeamJoiningRequestStatus.length > 0 ?
                                            myTeamJoiningRequestStatus?.map((req, index) => {
                                                const highlight = unreadMessages && unreadMessages.length > 0 && unreadMessages[0]["notificationcount"] > 0 && index < unreadMessages[0]["notificationcount"];
                                                return (
                                                    <div className="jointeamcontainer" key={index} style={{ backgroundColor: highlight ? '#D3D3D3' : 'transparent' }}>
                                                        {
                                                            req.status === "accept" ?
                                                                <div className="jointeamcolumn">Request to join <b>{req.name}</b> team, status is <span style={{ color: "green" }}>{req.status.charAt(0).toUpperCase() + req.status.slice(1)}ed</span></div>
                                                                : req.status === "reject" ?
                                                                    <div className="jointeamcolumn">Request to join <b>{req.name}</b> team, status is <span style={{ color: "red" }}>{req.status.charAt(0).toUpperCase() + req.status.slice(1)}ed</span></div>
                                                                    :
                                                                    <div className="jointeamcolumn">Request to join <b>{req.name}</b> team, status is <span style={{ color: "orange" }}>{req.status.charAt(0).toUpperCase() + req.status.slice(1)}</span></div>
                                                        }
                                                        <div className="jointeamcolumn jointeamtimestamp">
                                                            <div
                                                                style={{
                                                                    color: "#000",
                                                                    display: "inline-block",
                                                                    fontSize: 14,
                                                                    color: "grey",
                                                                    display: "block",
                                                                }}
                                                            >
                                                                {moment(req?.reqdate).fromNow()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            :
                                            <div>You have no notifications</div>
                                    }
                                </TabPanel>
                            )}


                            {/* {selectedTab === 2 && (
                                <TabPanel>
                                    {
                                        myTeamJoiningRequestStatus && myTeamJoiningRequestStatus.length > 0 ?
                                            myTeamJoiningRequestStatus?.map((req, index) => (
                                                // const highlight = unreadMessages && unreadMessages.length > 0 && unreadMessages[0]["notificationcount"] > 0 && index < unreadMessages[0]["notificationcount"];
                                                <div className="jointeamcontainer">
                                                    {
                                                        req.status === "accept" ?
                                                            <div className="jointeamcolumn">Request to join {" "} <b>{req.name}</b> {" "} team, status is <span style={{ color: "green" }}>{req.status.charAt(0).toUpperCase() + req.status.slice(1)}ed</span></div>
                                                            : req.status === "reject" ?
                                                                <div className="jointeamcolumn">Request to join {" "} <b>{req.name}</b> {" "} team, status is <span style={{ color: "red" }}>{req.status.charAt(0).toUpperCase() + req.status.slice(1)}ed</span></div>
                                                                :
                                                                <div className="jointeamcolumn">Request to join {" "} <b>{req.name}</b> {" "} team, status is <span style={{ color: "orange" }}>{req.status.charAt(0).toUpperCase() + req.status.slice(1)}</span></div>

                                                    }
                                                    <div className="jointeamcolumn jointeamtimestamp">

                                                        <div
                                                            style={{
                                                                color: "#000",
                                                                display:
                                                                    "inline-block",
                                                                fontSize: 14,
                                                                color: "grey",
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            {moment(req?.reqdate).fromNow()}
                                                        </div>
                                                    </div>


                                                </div>

                                            ))
                                            :
                                            <div>You have no pending requests</div>
                                    }
                                </TabPanel>
                            )} */}
                        </Box>
                    </div>
                </div>
                <CopyRight />
                <FixedFooterIcon />
            </section>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        top: '10%',
                        // left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }
                }}
                // sx={{
                //     '& .MuiSnackbarContent-root': {
                //       display: 'flex',
                //       alignItems: 'center',
                //       justifyContent: 'center',
                //       position: 'fixed',
                //       bottom: isMobile ? '10px' : '16px', 
                //       right: isMobile ? '10px' : '16px', 
                //       left: isMobile ? '10px' : '20px', 
                //       transform: isMobile ? 'none' : 'translateX(0)',
                //       width: isMobile ? 'calc(100% - 20px)' : 'auto', 
                //     }
                //   }}
            >
                {
                    addTeamMemberResponse &&
                        addTeamMemberResponse?.status === "success" ?
                        <Alert 
                            onClose={() => setSnackbarOpen(false)} 
                            severity="success" 
                            // sx={{ width: '100%', backgroundColor: '#28A745', color: 'white', }}
                            sx={{ 
                                width: '100%', 
                                backgroundColor: '#28A745', 
                                color: 'white',
                                '& .MuiAlert-icon': {
                                    color: 'white',
                                }
                            }}
                            >

                            {addTeamMemberResponse?.message}

                        </Alert>
                        :
                        <Alert onClose={() => setSnackbarOpen(false)} severity="warning" 
                        sx={{ 
                                width: '100%', 
                                backgroundColor: '#B9544B', 
                                color: 'white',
                                '& .MuiAlert-icon': {
                                        color: 'white',
                                }
                             }}>

                            {addTeamMemberResponse?.message}
                        </Alert>
                }

            </Snackbar>
        </div>
    );
};

export default JoinTeam;
