import React, { useState, useEffect } from 'react';
import {
  Button,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import _debounce from 'lodash.debounce';
import "react-datepicker/dist/react-datepicker.css";
import { onboardSelf, onboardSelfReset } from "../../redux/action/OnboardAction";
import { CopyRight } from "../../components/CopyRight";
import { getCity } from "../../redux/action/cityAction";
import {
  Gender,
  restrictedCountry,
} from "../../utils/dict";
import axios from "axios";
import RedStar from "../../components/RedStar";
import BasicPopover from "../../components/InformationPopup";
import ErrorMessage from "../../components/ErrorMessage";
import { useNavigate } from "react-router-dom";
import _, { set } from "lodash";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import LoginLogo from "../../assets/sdp.jpeg";
import PhoneIcon from "@mui/icons-material/Phone";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import styled from 'styled-components';

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroupFull
const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const form = [
  { id: 1, formQuestion: "First Name" },
  { id: 2, formQuestion: "Middle Name" },
  { id: 3, formQuestion: "Last Name" },
  { id: 4, formQuestion: "Father Name" },
  { id: 5, formQuestion: "Date of Birth" },
  { id: 6, formQuestion: "Adhaar No" },
  { id: 7, formQuestion: "Category" },
  { id: 8, formQuestion: "Gender" },
  { id: 9, formQuestion: "Blood Group" },
  { id: 10, formQuestion: "Present Address" },
  { id: 11, formQuestion: "Permanent Address" },
  { id: 12, formQuestion: "Email Address" },
  {
    id: 13,
    formQuestion: "Session",
  },
  { id: 14, formQuestion: "Roll Number" },
  { id: 15, formQuestion: "Mobile Number" },
  {
    id: 16,
    formQuestion: "Institute Name",
  },
  { id: 17, formQuestion: "University Name" },
  {
    id: 19,
    formQuestion: "State",
  },
  { id: 18, formQuestion: "City/Town" },
  { id: 20, formQuestion: "Organization" },
  { id: 21, formQuestion: "Organization Role" },
  { id: 22, formQuestion: "Organization Unit" },
];


// const useOutlinedInputStyles = makeStyles((theme) => ({
//   root: {
//     "&:hover $notchedOutline": {
//       borderColor: "gray",
//     },
//     "&$focused $notchedOutline": {
//       borderColor: "gray",
//     },
//     "&.Mui-error .MuiOutlinedInput-notchedOutline": {
//       borderColor: "gray"
//     },
//     '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       borderColor: "gray"
//     },

//   },

//   error: {
//     "&$notchedOutline": {
//       borderColor: "black",
//     },
//     "&:hover $notchedOutline": {
//       borderColor: "black",
//     },
//     "&$focused $notchedOutline": {
//       borderColor: "black",
//     }

//   },
//   focused: {},
//   notchedOutline: {},
// }));

const suggestionActiveStyle = {
  padding: "10px",
  cursor: "pointer",
  backgroundColor: "#007BFF",
  color: "#fff",
};

const suggestionStyle = {
  padding: "10px",
  cursor: "pointer",
  backgroundColor: "#fff",
  color: "#000",
  "&:hover": {
    backgroundColor: "#f0f0f0",
    color: "#000",
  },
};


function RegisterScreen(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [registerCallingCode, setRegisterCallingCode] = React.useState("");
  const [value1, setValue1] = React.useState("");
  const [value1Error, setValue1Error] = React.useState(false);
  const [lastname, setLastname] = React.useState("");
  const [lastnameError, setLastnameError] = React.useState(false);
  const [value2, setValue2] = React.useState("");
  const [value3, setValue3] = React.useState("");
  const [value4, setValue4] = React.useState("");
  const [value5, setValue5] = React.useState(
    new Date().setFullYear(new Date().getFullYear() - 5)
  );
  const [gender, setGender] = React.useState("");
  const [genderError, setGenderError] = React.useState(false);
  const [value8, setValue8] = React.useState("");
  const [value9, setValue9] = React.useState("");
  const [value10, setValue10] = React.useState("");
  const [value11, setValue11] = React.useState("");
  const [value12, setValue12] = React.useState("");
  const [value12Error, setValue12Error] = React.useState(false);
  const [value12DupError, setValue12DupError] = React.useState(false);
  const [emailTextError, setEmailTextError] = React.useState("");
  const [value13, setValue13] = React.useState("");
  const [value14, setValue14] = React.useState("");
  const [value15, setValue15] = React.useState("");
  const [value15Error, setValue15Error] = React.useState(false);
  const [value16, setValue16] = React.useState("");
  const [countryError, setCountryError] = React.useState(false);
  const [value18, setValue18] = React.useState("");
  const [value18Error, setValue18Error] = React.useState(false);
  const [value19, setValue19] = React.useState("");
  const [value19Error, setValue19Error] = React.useState(false);
  const [areaError, setAreaError] = React.useState(false);
  const [value20, setValue20] = React.useState("");
  const [value21, setValue21] = React.useState("");
  const [value22, setValue22] = React.useState("");
  const [highLevelEdu, setHighLevelEdu] = React.useState("");
  const [noOfHours, setNoOfHours] = React.useState("");
  const [preferDays, setPreferDays] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [correspondenceaddress, setcorrespendeceaddress] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [area, setArea] = React.useState("");
  const [added, setAdded] = React.useState("no");
  const [isAddressComplete, setIsAddressComplete] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  // const outlinedInputClasses = useOutlinedInputStyles();
  const [selected, setSelected] = React.useState([]);
  const [selectedSkills, setSelectedSkills] = React.useState([]);
  const [emailExistCheck, setEmailExistCheck] = React.useState(false);
  const [showGetOTPButton, setShowGetOTPButton] = useState(false);
  const [confirmAddressClicked, setConfirmAddressClicked] = useState(false);

  const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    selfOnboardResponse,
    isSelfOnboardSuccess,
    isSelfOnboardError,
    isSelfOnboardFetching,
  } = useSelector((state) => state.onBoard);

  useEffect(() => {
    const addressParts = address.split(",");
    const complete = addressParts.length >= 4 && addressParts.slice(-4).every(part => part.trim() !== "");
    setIsAddressComplete(complete);
    setIsSearching(true);
  }, [address]);


  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    setAddress(results[0]["formatted_address"]);
  };


  React.useEffect(() => {
    dispatch(onboardSelfReset());
  }, []);


  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );


  const checkDupEmail = _debounce(async (emailVal) => {

    try {

      const res = await axios.get(`/a/b/c/d/e/${emailVal}`);
      setEmailExistCheck(res?.data);
      // console.log("res?.data?.emailexist = ", res?.data?.[0]?.emailexist)
      if (res?.data?.[0]?.emailexist === true) {
        setValue12DupError(true);
        // setEmailTextError("Please Enter a unique Email");
      }
      return res;
    } catch (err) {
      throw err;
    }
  }, 300);

  const handleChangeRegisterCallingCode = (event) => {
    setRegisterCallingCode(event.target.value);
    validatePhoneNumber(value15, event.target.value);
  };

  const validatePhoneNumber = (phone, code) => {
    const phoneNumber = parsePhoneNumberFromString(phone, code);
    if (!phoneNumber || !phoneNumber.isValid()) {
      setValue15Error(true);
    } else {
      setValue15Error(false);
    }
  };

  const replacedPhoneNumber =
    (registerCallingCode === ""
      ? "91"
      : registerCallingCode.replace(/\+/g, "")) + value15;

  const handleOnboardIndividual = async () => {
    // console.log("value12DupError = ", value12DupError)
    const scrollToElement = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };
    if (!value1) {
      setValue1Error(true);
      scrollToElement('fName');

    } else if (!lastname) {
      setLastnameError(true);
      scrollToElement('lName');
    }
    else if (!gender) {
      setGenderError(true);
      scrollToElement('genderId');

    }
    else if (!value12) {
      setValue12Error(true);
      scrollToElement('emailId');

    } else if (emailTextError) {
      setEmailTextError(true);
      scrollToElement('emailId');
    } else if (!value15) {
      setValue15Error(true);
      scrollToElement('phoneId');
    }
    else if (!country) {
      setCountryError(true);
      scrollToElement('locationId');
      // const element = document.getElementById('locationId');
      // if (element) {
      //   element.scrollIntoView({ behavior: 'smooth' });
      // }
    } else if (!value18) {
      setValue18Error(true);
      window.scrollTo(0, 0);
    } else if (!value19) {
      setValue19Error(true);
      window.scrollTo(0, 0);
    } else if (!area) {
      setAreaError(true);
      window.scrollTo(0, 0);
    }
    else {
      localStorage.setItem("userid", replacedPhoneNumber);
      let formData = new FormData();
      formData.append("firstname", value1);
      formData.append("lastname", lastname);
      formData.append("dob", "01-01-2000")
      formData.append("bloodgroup", "A +ve");
      formData.append("gender", gender);
      formData.append("correspondenceaddress", "Home");
      formData.append("emailid", value12);
      formData.append("phoneno", replacedPhoneNumber);
      formData.append("country", country);
      formData.append("state", value18);
      formData.append("city", value19);
      formData.append("area", area);
      formData.append("orgrole", "volunteer");
      formData.append("highlvledu", "Graduate");
      formData.append("hoursperweek", "1-2 hours");
      formData.append("preferday", "Weekends (Sat-Sun)");
      formData.append("interestarea", JSON.stringify([{ "interest": "No Poverty" }]));
      formData.append("skill", JSON.stringify([{ "skill": "Networking" }]));

      dispatch(onboardSelf(formData));
      const res = await axios.post(`/verifyemail`, {
        phoneno: replacedPhoneNumber,
        email: value12,
      });
    }
  };

  React.useEffect(() => {
    if (isSelfOnboardSuccess && selfOnboardResponse?.status === "success") {
      navigate(`/emailverify/${replacedPhoneNumber}/${value12}`);
    }
  }, [isSelfOnboardSuccess]);


  React.useEffect(() => {
    if (value18) {
      dispatch(getCity(value18));
    }
  }, [value18]);


  useEffect(() => {
    // console.log("value12DupError = ", value12DupError)
    if (value1 && lastname && gender && value12 && !value12Error && value15 && isAddressComplete && !value12DupError && !emailTextError && !value15Error) {
      setShowGetOTPButton(true);
    } else {
      setShowGetOTPButton(false);
    }
  }, [value1, lastname, gender, value12, value12Error, value15, isAddressComplete, value12DupError, emailTextError, value15Error]);

  React.useEffect(() => {
    if (isSelfOnboardSuccess && selfOnboardResponse?.status === "success") {
      setValue1("");
      setLastname("");
      setValue2("");
      setValue3("");
      setValue4("");
      setValue5("");
      setValue8("");
      setValue9("");
      setValue10("");
      setValue11("");
      setValue12("");
      setValue13("");
      setValue14("");
      setValue15("");
      setValue16("");
      setValue18("");
      setValue19("");
      setValue20("");
      setValue21("");
      setValue22("");
      setGender("");
      setNoOfHours("");
      setHighLevelEdu("");
      setPreferDays("");
      setSelected([]);
      setSelectedSkills([]);
      setCountry("");
      setArea("");
      setAdded("");
      setAddress("");
    }
  }, [isSelfOnboardSuccess, isSelfOnboardError]);

  const updatelocation = (address) => {
    if (isAddressComplete) {
      setAdded("yes");
      setIsSearching(false);
      setConfirmAddressClicked(true);
      setCountry(
        String(address.split(",")[address.split(",").length - 1]).trim(" ")
      );
      setValue18(
        String(address.split(",")[address.split(",").length - 2]).trim(" ")
      );
      setValue19(
        String(address.split(",")[address.split(",").length - 3]).trim(" ")
      );
      setArea(
        String(address.split(",")[address.split(",").length - 4]).trim(" ")
      );
    }
  };

  const checkEmail = _debounce(async (emailValue) => {
    try {
      // const res = await axios.get(`/email/val/${value20}/${emailValue}`);
      console.log("emailValue = ", emailValue !== "");
      if (emailValue !== "" && emailValue !== undefined) {
        const res = await axios.get(`/email/val/${emailValue}`);
        console.log("checkEmail:res?.data?.message = ", res?.data?.message);
        console.log("pattern.test(emailValue)= ", pattern.test(emailValue))

        if (!emailValue) {
          setEmailTextError(false);
          setValue12Error(true);
        } else if (!pattern.test(emailValue) && res?.data?.message === "Invalid") {
          setValue12Error(false);
          setEmailTextError(true);
        } else if (!pattern.test(emailValue) && res?.data?.message === "Valid") {
          setValue12Error(false);
          setEmailTextError(true);
        }
        else if (pattern.test(emailValue) && res?.data?.message === "Valid") {
          setValue12Error(false);
          setEmailTextError(false);
          setValue12DupError(false);
        }
        else if (pattern.test(emailValue) && res?.data?.message === "Exist") {
          setValue12Error(false);
          setEmailTextError(false);
          setValue12DupError(true);
        }
      } else {
        console.log("else emailValue = ", emailValue !== "");
        console.log("else emailValue = ", emailValue !== undefined);
        setValue12Error(true);
        setEmailTextError(false);
      }
    } catch (err) {
      setEmailTextError(false);
      setValue12Error(true);
      throw err;
    }
  }, 300);

  const numberOfFields = 1;
  const [fieldVisibility, setFieldVisibility] = React.useState(
    Array(numberOfFields).fill(false)
  );

  const toggleField = (index) => {
    const updatedFieldVisibility = [...fieldVisibility];
    updatedFieldVisibility[index] = !updatedFieldVisibility[index];
    setFieldVisibility(updatedFieldVisibility);
  };

  const isDesktop = useMediaQuery('(min-width:600px)');
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);
  return (
    <div className="wrapper footerFullWidth">
      <div class="content content-wrap">
        <LoginPgLogo style={{ textAlign: "center", marginTop: "10px" }}>
          <img
            src={LoginLogo}
            style={{
              height: 56,
            }}
          />
          <figcaption>Powered by cYAAG</figcaption>
        </LoginPgLogo>
        <div class="content-mrg" style={{ background: "#fff" }}>
          <div style={{ maxWidth: "1100px", margin: "0 auto" }}>
            <div style={{ fontFamily: "monospace", fontSize: 32 }}>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginRight: 5,
                }}
              >
                <span class="selfregistertitle" style={{ color: "black" }}>
                  {" "}
                  Self Registration Form
                </span>
              </div>
            </div>
            <form autoComplete="off" style={{ minHeight: isMobile? '120vh' : '200vh' }}>
              <div
                style={{
                  border: "1px solid #dadce0",
                  borderRadius: 8,
                  backgroundColor: "oldlace",
                  padding: "10px"
                }}
              >
                <span class="selfregister-infoText">After registration, you can log in using either your Mobile Number or Email, provided below</span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 customFieldWidth" id="fName">
                    <StyledLabel>
                      First Name <RedStar />
                    </StyledLabel>
                    <StyledInput
                      value={value1}
                      autoFocus="true"
                      type="text"
                      id="first-name"
                      placeholder="Enter your first name"
                      onChange={(e) => {
                        setValue1(e.target.value);
                      }}
                      onBlur={() => {
                        if (!value1) {
                          setValue1Error(true);
                        } else {
                          setValue1Error(false);
                        }
                      }}
                    />
                    {value1Error && (
                      <ErrorMessage message="Please enter first name" />
                    )}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 customFieldWidth" id="lName">
                    <StyledLabel>
                      Last Name <RedStar />
                    </StyledLabel>
                    <StyledInput
                      value={lastname}
                      type="text"
                      id="last-name"
                      placeholder="Enter your last name"
                      onChange={(e) => {
                        setLastname(e.target.value);
                      }}
                      onBlur={() => {
                        if (!lastname) {
                          setLastnameError(true);
                        } else {
                          setLastnameError(false);
                        }
                      }}
                    />
                    {lastnameError && (
                      <ErrorMessage message="Please enter last name" />
                    )}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 customFieldWidth menuDropDownSelfRegister" id="genderId">
                    <StyledLabel>
                      Gender
                      <RedStar />
                      <BasicPopover content="Your Gender" />
                    </StyledLabel>

                    <Select
                    placeholder='Select Gender'
                      // className={classes.root}
                      style={{
                        width: "100%",
                        fontSize: 14,
                        height: 56,
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        backgroundColor: "white",
                      }}
                      onBlur={() => {
                        if (!gender) {
                          setGenderError(true);
                        } else {
                          setGenderError(false);
                        }
                      }}
                      value={gender}
                      onChange={(event) => setGender(event.target.value)}
                    >
                      {Gender.map((item, index) => (
                        <MenuItem style={{ fontSize: 16 }} value={item.value}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                    {genderError && (
                      <ErrorMessage message="Please select Gender" />
                    )}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 customFieldWidth" id="emailId">
                    <StyledLabel>
                      {form[11]?.formQuestion}

                      <RedStar />
                      <span style={{ fontSize: '10px', marginTop: '4px' }}> (You will receive OTP on this email)</span>
                    </StyledLabel>
                    <StyledInput
                      value={value12}
                      type="text"
                      id="create-yaad--title"
                      placeholder={"Enter your organization or personal email"}
                      onChange={(e) => {
                        setValue12(e.target.value);
                        checkEmail(e.target.value);
                        // checkDupEmail(e.target.value);
                      }}
                      onBlur={() => {
                        console.log("value12 = ", value12)
                        if (!value12) {
                          setValue12Error(true);
                        } else {
                          setValue12Error(false);
                        }
                      }}
                    />
                    {value12Error && !emailTextError && (
                      <ErrorMessage message="Please enter email address" />
                    )}
                    {!value12Error && emailTextError && (
                      <ErrorMessage message="Please enter valid email address" />
                    )}
                    {!value12Error && value12DupError && (
                      <ErrorMessage message="Please enter unique email address" />
                    )}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 customFieldWidth" id="phoneId">
                    <StyledLabel>
                      {form[14]?.formQuestion}
                      <RedStar />

                    </StyledLabel>

                    <div style={{
                      "& .MuiSelect-select": {
                        padding: "0px 0px 0px 5px",
                      },
                    }}>
                      <Select
                        // className={classes.root}
                        style={{
                          width: 116,
                          fontSize: 14,
                          padding: "0px 0px 0px 0px",
                          marginRight: 11,
                          height: 56,
                          boxShadow: '0px 3px 6px #00000029',
                          backgroundColor: 'white'
                        }}
                        value={
                          registerCallingCode === "" ? "+91" : registerCallingCode
                        }
                        onChange={handleChangeRegisterCallingCode}
                      >
                        {restrictedCountry.map((item, index) => (
                          <MenuItem value={item.dial_code}>
                            {item.name + "\t" + item.dial_code}
                          </MenuItem>
                        ))}
                      </Select>
                      <OutlinedInput
                        // classes={outlinedInputClasses}
                        onBlur={() => {
                          if (!value15) {
                            setValue15Error(true);
                          } else {
                            setValue15Error(false);
                          }
                        }}
                        value={value15}
                        style={{
                          height: 56,
                          width: "calc(100% - 128px)",
                          boxShadow: "0px 3px 6px #00000029",
                          // fontFamily: "Poppins",
                          backgroundColor: 'white'
                        }}
                        required
                        id="login-phone-number"
                        startAdornment={
                          <InputAdornment position="start" >
                            <PhoneIcon color="#2C7973" />
                          </InputAdornment>
                        }
                        placeholder="Enter your phone number"
                        // onChange={(e) => {
                        //   setValue15(e.target.value);
                        // }}
                        onChange={(e) => {
                          setValue15(e.target.value);
                          validatePhoneNumber(e.target.value, registerCallingCode);
                        }}
                        inputProps={{
                          style: {
                            fontSize: isDesktop ? 14 : 12,
                            fontFamily: 'Roboto, sans-serif',
                            color: 'black',
                          }
                        }}
                      />
                      {value15Error && (
                        <ErrorMessage message="Please enter valid mobile number" />
                      )}
                    </div>
                  </div>
                </div>
                <FormGroup2 id="locationId" style={{ backgroundColor: "oldlace", }}>
                  <StyledLabel
                    htmlFor="create-yaad--title" >Enter your Correspondence Address <RedStar />
                    <i
                      class="fa fa-question-circle"
                      style={{
                        color: "#DF7F13",
                        cursor: "pointer",
                        marginLeft: "5px",
                      }}
                      onClick={() => toggleField(1)}
                    ></i>
                  </StyledLabel>
                  {fieldVisibility[1] && (
                    <div
                      className="bottom-div"
                      style={{
                        top: "-18px",
                        position: "relative",
                        fontSize: "12px",
                        marginLeft: "10px",
                        backgroundColor: 'cyan',
                        width: isDesktop ? '42%' : '92%',
                      }}
                    >
                      Search for address, select and click on CONFIRM ADDRESS button below.
                    </div>
                  )}

                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div >
                        <TextField
                          value={correspondenceaddress}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                            style: { backgroundColor: 'white' },
                          }}
                          onBlur={() => {
                            if (!country) {
                              setCountryError(true);
                            } else {
                              setCountryError(false);
                            }
                          }}
                          {...getInputProps({
                            placeholder: "Search for address..",
                            autoFocus: false,
                          })}
                        />
                        <div style={{ marginTop: "10px", }}> {loading ? <div>...loading...</div> : null}</div>

                        {suggestions.map((suggestion, index) => {
                          const isActive = suggestion.active;
                          const style = isActive
                            ? suggestionActiveStyle
                            : {
                              ...suggestionStyle,
                              ...(hoveredIndex === index ? { backgroundColor: "#f0f0f0", color: "#000" } : {}),
                            };

                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, { style })}
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={handleMouseLeave}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </PlacesAutocomplete>
                  {countryError && (
                    <ErrorMessage message="Please enter your address" />
                  )}
              </FormGroup2>
              <div style={{display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",}}>
                {[
                  { label: "Country", value: address.split(",").pop() },
                  { label: form[17]?.formQuestion, value: address.split(",").slice(-2, -1)[0] },
                  { label: form[18]?.formQuestion, value: address.split(",").slice(-3, -2)[0] },
                  { label: "Area", value: address.split(",").slice(-4, -3)[0] },
                ].map((field, index) => (
                  <div key={index} style={{flex: "1 1 50%",
                    padding: "10px",}}>
                    <StyledLabel htmlFor={`field-${index}`} >
                      {field.label}
                      <RedStar />
                    </StyledLabel>
                    <div style={{padding: "8px",
    borderRadius: "6px",
    backgroundColor: "#f5f5f5",
    border: "1px solid #ddd",
    fontSize: "14px",
    color: "#333",
    marginTop: "4px",
    fontFamily: "Poppins",
    lineHeight: "1.5",}}>{field.value}</div>
                  </div>
                ))}
              </div>
              <div style={{textAlign: "center",
    paddingTop: "20px",}}>
                <Button
                  variant="contained"
                  onClick={() => updatelocation(address)}
                  disableElevation
                  disabled={!isAddressComplete}
                  
                  style={{ marginLeft: "10px", width: "170px",
                    height: "35px",
                    background: "#f5eceb",
                    color: "green",
                    fontSize: "14px",
                    fontFamily: "Poppins !important", }}
                >
                  Confirm Address
                </Button>
                {isAddressComplete && !isSearching && added === "yes" && <DoneIcon style={{color: "green",
    fontSize: "30px",
    marginLeft: "10px",}} />}
              </div>
              <b></b>
              {showGetOTPButton && confirmAddressClicked &&
                <div className="selfRegisterGetOTpopUpContainer">
                  <Button
                    style={{ marginTop: '53px' }}
                    variant="contained"
                    className="btn save-btn"
                    disableElevation
                    onClick={handleOnboardIndividual}
                    disabled={isSelfOnboardFetching}
                  >
                    <span>Get OTP</span>
                  </Button>
                  <br />
                </div>
              }
          </div>
        </form>
      </div>
    </div>
      </div >
    <CopyRight />
    </div >
  );
}
export default RegisterScreen;
