import React, { useEffect, useRef, useState } from "react";
import _, { isArray, round } from "lodash";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { useDispatch } from "react-redux";
import { EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PauseIcon from "@mui/icons-material/Pause";
// import SearchBar from "material-ui-search-bar";
import SearchBar from "./SearchBar";
import ReactPlayer from "react-player";
import DownloadIcon from "@mui/icons-material/Download";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { styled } from '@mui/material/styles';
import { useSocket } from './WebSocketContext';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';

const StyledTableBodyTextCell = styled(TableCell)(({ theme }) => ({
  height: 80,
  width: 120,
  textAlign: 'center',
  color: '#565656',
  fontSize: 15,
  fontWeight: 400,
  '@media (max-width: 400px)': {
    width: '300px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));

const StyledTableHeadTextCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 700,
  textAlign: 'center',
  fontSize: 16,
  padding: '0px 5px 0px 5px',
  backgroundColor: '#808080',
  width: 120,
  color: 'white',
  top: '-16px'
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    color: '#000',
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
  },
}));

// Styled TableRow component
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: '1px solid green !important',
  '&:last-child': {
    borderBottom: 'none',
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#f7dbb3',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#e9e7e8',
  },
}));

export const TableComponent = (props) => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setSearched] = useState("");
  const [user, setUser] = React.useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchedRows = (filteredOptions) => {
    setUser(filteredOptions);
  };

  const requestSearch = (searchVal) => {
    if (typeof searchVal === 'string') {
      const filteredOptions = props.rows.filter((name) =>
        name.firstname.toLowerCase().includes(searchVal.toLowerCase())
      );
      handleSearchedRows(filteredOptions);
    } else {
      console.error('searchVal is not a string:', searchVal);
      handleSearchedRows(props.rows); // or any appropriate fallback
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  function createData(
    id,
    firstname,
    lastname,
    emailid,
    phoneno,
    organization,
    orgrole,
    state,
    city
  ) {
    return {
      id,
      firstname,
      lastname,
      emailid,
      phoneno,
      organization,
      orgrole,
      state,
      city,
    };
  }

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < props.rows.length; i += 1) {
      // console.log(props.rows[i]);
      const formattedArray = [];
      formattedArray.push(
        props.rows[i].firstname,
        props.rows[i].lastname,
        props.rows[i].emailid,
        props.rows[i].phoneno,
        props.rows[i].organization,
        props.rows[i].orgrole,
        props.rows[i].state,
        props.rows[i].city
      );

      temp.push(createData(i, ...formattedArray));
      // console.log(temp);
    }
    setUser(temp);
  }, [props.rows]);



  return (
    <Paper>
      <SearchBar
        value={searched}
        // onChange={(searchVal) => requestSearch(searchVal)}
        onChange={(event) => {
          const newValue = event.target.value; // Extract the string value from the event
          setSearched(newValue);
          requestSearch(newValue);
        }}

        onCancelSearch={() => cancelSearch()}
        placeholder="Search with first name"
        style={{
          width: "320px",
          border: "1px solid #ccc", // Adjust the color and width as needed
          borderRadius: "4px" // Optional: to match typical input borders
        }}

      />
      <TableContainer
        ref={tableRef}
        component={Paper}
        style={{
          borderRadius: 8,
          borderRadius: 6,
          background: "#ffffff",
          marginTop: 16,
          minWidth: "80vw",
          maxHeight: "80vh",
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1', // Track color
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888', // Scrollbar color
            borderRadius: '10px', // Rounded corners
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555', // Scrollbar color on hover
          },

        }}
        className='manageUser'
      >
        {!_.isEmpty(user) ? (
          <Table stickyHeader aria-label="customized table" style={{
            borderCollapse: 'collapse', width: "100%",
            borderRadius: "10px",
          }}>
            <TableHead>
              <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
                <TableCell style={{ padding: '8px 16px', fontWeight: "700", textAlign: "center", fontSize: 16, padding: "0px 5px 0px 5px", backgroundColor: "#808080", width: 120, color: 'white', }}>
                  <span>First Name</span>
                </TableCell>
                <TableCell style={{ padding: '8px 16px', fontWeight: "700", textAlign: "center", fontSize: 16, padding: "0px 5px 0px 5px", backgroundColor: "#808080", width: 120, color: 'white', }}>
                  <span>Last Name</span>
                </TableCell>
                <TableCell style={{ padding: '8px 16px', fontWeight: "700", textAlign: "center", fontSize: 16, padding: "0px 5px 0px 5px", backgroundColor: "#808080", width: 120, color: 'white', }}>
                  <span>Email Id</span>
                </TableCell>
                <TableCell style={{ padding: '8px 16px', fontWeight: "700", textAlign: "center", fontSize: 16, padding: "0px 5px 0px 5px", backgroundColor: "#808080", width: 120, color: 'white', }}>
                  <span>Phone Number</span>
                </TableCell>
                <TableCell style={{ padding: '8px 16px', fontWeight: "700", textAlign: "center", fontSize: 16, padding: "0px 5px 0px 5px", backgroundColor: "#808080", width: 120, color: 'white', }}>
                  <span>Role</span>
                </TableCell>
                <TableCell style={{ padding: '8px 16px', fontWeight: "700", textAlign: "center", fontSize: 16, padding: "0px 5px 0px 5px", backgroundColor: "#808080", width: 120, color: 'white', }}>
                  <span>State</span>
                </TableCell>
                <TableCell style={{ padding: '8px 16px', fontWeight: "700", textAlign: "center", fontSize: 16, padding: "0px 5px 0px 5px", backgroundColor: "#808080", width: 120, color: 'white', }}>
                  <span>City</span>
                </TableCell>
                <TableCell style={{ padding: '8px 16px', fontWeight: "700", textAlign: "center", fontSize: 16, padding: "0px 5px 0px 5px", backgroundColor: "#808080", width: 120, color: 'white', }}>
                  <span>Edit</span>
                </TableCell>
                <TableCell style={{ padding: '8px 16px', fontWeight: "700", textAlign: "center", fontSize: 16, padding: "0px 5px 0px 5px", backgroundColor: "#808080", width: 120, color: 'white', }}>
                  <span>Delete</span>
                </TableCell>
              </TableRow>
            </TableHead>
            {props.isFetching ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <TableBody>
                {user
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <TableRow style={{
                      borderBottom: "1px solid #d3d3d3",
                      "&:last-child": {
                        borderBottom: "none",
                      }
                    }}>
                      <StyledTableBodyTextCell>
                        <span> {item?.firstname}</span>
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        <span> {item?.lastname}</span>
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        <span>{item.emailid}</span>
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        <span>{item.phoneno}</span>
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        <span>{item.orgrole}</span>
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        <span>{item.state}</span>
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        <span>{item.city}</span>
                      </StyledTableBodyTextCell>
                      {String(item.id) === "undefined" ? (
                        <StyledTableBodyTextCell
                          onClick={() =>
                            //alert("CLICKED")
                            navigate(`/editvolunteer/${item?.phoneno}`, {
                              state: item,
                            })
                          }
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          <EditIcon
                            style={{ textAlign: "center", cursor: "pointer" }}
                          />
                        </StyledTableBodyTextCell>
                      ) : (
                        <StyledTableBodyTextCell
                          onClick={() =>
                            //alert("CLICKED")
                            navigate(
                              `/editvolunteer/${props?.rows[item.id]?.phoneno}`,
                              { state: props?.rows[item.id] }
                            )
                          }
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          <EditIcon
                            style={{ textAlign: "center", cursor: "pointer" }}
                          />
                        </StyledTableBodyTextCell>
                      )}

                      {String(item.id) === "undefined" ? (
                        <StyledTableBodyTextCell
                          // onClick={handleDeleteUser(item?.phoneno)}
                          onClick={() =>
                            navigate(`/deletevolunteer/${item?.phoneno}`, {
                              state: item,
                            })
                          }
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          <DeleteForeverIcon
                            style={{
                              textAlign: "center",
                              cursor: "pointer",
                              color: "gray",
                            }}
                          />
                        </StyledTableBodyTextCell>
                      ) : (
                        <StyledTableBodyTextCell
                          // onClick={handleDeleteUser(item?.phoneno)}
                          onClick={() =>
                            navigate(
                              `/deletevolunteer/${props?.rows[item.id]?.phoneno
                              }`,
                              { state: props?.rows[item.id] }
                            )
                          }
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          <DeleteForeverIcon
                            style={{
                              textAlign: "center",
                              cursor: "pointer",
                              color: "gray",
                            }}
                          />
                        </StyledTableBodyTextCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        ) : (
          <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
            No data found
          </Typography>
        )}
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[10, 20, 40]}
            component="div"
            count={user?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginLeft: "28px",
              },
              "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
                marginBottom: "13px",
              },
              "& .MuiTablePagination-actions": {
                marginBottom: "13px",
              },
            }}
          />
        </TableFooter>
      </TableContainer>
    </Paper>
  );
};

export const CampaignTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "80vw",
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888', // Scrollbar color
          borderRadius: '10px', // Rounded corners
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}

      className='manageUser'
    >
      {!_.isEmpty(props.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{
          width: "100%",
          borderRadius: "10px",
        }}>
          <TableHead>
            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>Title</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Organization Name</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Phone No</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Campaign Name</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>Start Date</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>State</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>City</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Area</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>Impact</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Social Media Message</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Logo</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Video</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {props.rows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .filter((item, index) => item.reviewstatus === "Approved")
                .map((item) => (
                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    <StyledTableBodyTextCell>
                      <span>{item?.title}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.organizationname}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item.phoneno}</span>
                    </StyledTableBodyTextCell>

                    <StyledTableBodyTextCell>
                      <span>{item?.type}</span>
                    </StyledTableBodyTextCell>

                    <StyledTableBodyTextCell>
                      <span>{item.startdate}</span>
                    </StyledTableBodyTextCell>

                    <StyledTableBodyTextCell>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.state === "" ? (
                              <span>All States</span>
                            ) : (
                              <span>{item?.state}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.city === "" ? (
                              <span>All Cities</span>
                            ) : (
                              <span>{item?.city}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.area === "" ? (
                              <span>All Areas</span>
                            ) : (
                              <span>{item?.area}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item.impact}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item.socialmediamsg}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <Avatar
                        src={item?.logo}
                        style={{ width: 80, height: 80 }}
                      />
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <video
                        controls
                        width="100%"
                        src={item?.video}
                        style={{ width: 200, height: 100 }}
                      />
                    </StyledTableBodyTextCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
              marginBottom: "13px",
            },
            "& .MuiTablePagination-actions": {
              marginBottom: "13px",
            },
          }}
        />
      </TableFooter>
    </TableContainer>
  );
};


export const ReviewCampaignTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [searched, setsearched] = useState("");
  const [user, setUser] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchedRows = (filteredOptions) => {
    setUser(filteredOptions);
  };

  const requestSearch = (searchVal, callback) => {
    const filteredOptions = props.rows.filter((name) =>
      name.title.toLowerCase().includes(searchVal.toLowerCase())
    );
    handleSearchedRows(filteredOptions);
  };

  const cancelSearch = () => {
    setsearched("");
    requestSearch(searched);
  };

  function createData(
    id,
    title,
    organization,
    goal,
    startdate,
    enddate,
    visibility,
    location,
    reviewstatus,
    paused,
    campid,
    active
  ) {
    return {
      id,
      title,
      organization,
      goal,
      startdate,
      enddate,
      visibility,
      location,
      reviewstatus,
      paused,
      campid,
      active,
    };
  }

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < props.rows.length; i += 1) {
      const formattedArray = [];
      formattedArray.push(
        props.rows[i].title,
        props.rows[i].organization,
        props.rows[i].goal,
        props.rows[i].startdate,
        props.rows[i].enddate,
        props.rows[i].visibility,
        props.rows[i].location,
        props.rows[i].reviewstatus,
        props.rows[i].paused,
        props.rows[i].campid,
        props.rows[i].active
      );
      temp.push(createData(i, ...formattedArray));
    }
    setUser(temp);
  }, [props.rows]);

  return (
    <Paper>
      <SearchBar
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        onCancelSearch={() => cancelSearch()}
        placeholder="Search with Campaign"
        style={{ width: "350px", border: "1px solid #ccc", borderRadius: "4px" }}
      />

      <TableContainer ref={tableRef} component={Paper}
        style={{
          maxHeight: "80vh",
          overflowY: 'auto',
          marginTop: '10px',
          '&::-webkit-scrollbar': {
            width: '6px',  
            height: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1', // Track color
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888', // Scrollbar color
            borderRadius: '10px', // Rounded corners
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555', // Scrollbar color on hover
          },
        }}

        className='manageUser'
      >
        {!_.isEmpty(props?.rows) ? (
          <Table
            stickyHeader

            aria-label="customized table"
            style={{
              marginBottom: 50, width: "100%",
              borderRadius: "10px",
            }}
          >
            <TableHead>
              <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
                <StyledTableHeadTextCell>
                  <span>S.no</span>
                </StyledTableHeadTextCell>
                <StyledTableHeadTextCell>
                  <span> Name </span>
                </StyledTableHeadTextCell>
                <StyledTableHeadTextCell>
                  <span> Organization </span>
                </StyledTableHeadTextCell>
                <StyledTableHeadTextCell>
                  <span> Goal </span>
                </StyledTableHeadTextCell>
                <StyledTableHeadTextCell>
                  <span>Start Date</span>
                </StyledTableHeadTextCell>
                <StyledTableHeadTextCell>
                  <span>End Date</span>
                </StyledTableHeadTextCell>
                <StyledTableHeadTextCell>
                  <span>Status</span>
                </StyledTableHeadTextCell>
                <StyledTableHeadTextCell>
                  <span>State</span>
                </StyledTableHeadTextCell>
                <StyledTableHeadTextCell>
                  <span>City</span>
                </StyledTableHeadTextCell>
                <StyledTableHeadTextCell>
                  <span>Review Status</span>
                </StyledTableHeadTextCell>
                <StyledTableHeadTextCell>
                  <span>Pause Status (Yes/No)</span>
                </StyledTableHeadTextCell>
                <StyledTableHeadTextCell>
                  <span>View & Review</span>
                </StyledTableHeadTextCell>
                <StyledTableHeadTextCell>
                  <span>Edit</span>
                </StyledTableHeadTextCell>
                <StyledTableHeadTextCell>
                  <span>Pause</span>
                </StyledTableHeadTextCell>
                <StyledTableHeadTextCell>
                  <span>Resume</span>
                </StyledTableHeadTextCell>
              </TableRow>
            </TableHead>
            {props.isFetching ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <TableBody>
                {
                  user
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow style={{
                        borderBottom: "1px solid #d3d3d3",
                        "&:last-child": {
                          borderBottom: "none",
                        }
                      }}>
                        <StyledTableBodyTextCell>
                          <span>
                            {page === 0
                              ? index + 1
                              : page * rowsPerPage + index + 1}
                          </span>
                        </StyledTableBodyTextCell>
                        <StyledTableBodyTextCell>
                          <span>{item?.title}</span>
                        </StyledTableBodyTextCell>
                        <StyledTableBodyTextCell>
                          <span>{item?.organization}</span>
                        </StyledTableBodyTextCell>
                        <StyledTableBodyTextCell style={{ minWidth: 200 }}>
                          <div style={{
                            '&::-webkit-scrollbar': {
                              width: '6px',  
                              height: '6px',
                            },
                            '&::-webkit-scrollbar-track': {
                              background: '#f1f1f1', // Track color
                            },
                            '&::-webkit-scrollbar-thumb': {
                              background: '#888', // Scrollbar color
                              borderRadius: '10px', // Rounded corners
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                              background: '#555', // Scrollbar color on hover
                            },
                            width: '100%',
                            height: '100%',
                            overflow: 'auto',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                            <span>{item?.goal}</span>
                          </div>
                        </StyledTableBodyTextCell>
                        <StyledTableBodyTextCell style={{ minWidth: 150 }}>
                          <span>{item?.startdate}</span>
                        </StyledTableBodyTextCell>
                        <StyledTableBodyTextCell style={{ minWidth: 150 }}>
                          <span>{item?.enddate}</span>
                        </StyledTableBodyTextCell>
                        <StyledTableBodyTextCell>
                          <span>{item?.active}</span>
                        </StyledTableBodyTextCell>
                        <StyledTableBodyTextCell>
                          {_.isArray(item?.location) &&
                            item?.location.map((item, index) => (
                              <>
                                {item?.state === "" ? (
                                  <span>All States</span>
                                ) : (
                                  <span>{item?.state}</span>
                                )}
                                <br />
                              </>
                            ))}
                        </StyledTableBodyTextCell>
                        <StyledTableBodyTextCell>
                          {_.isArray(item?.location) &&
                            item?.location.map((item, index) => (
                              <>
                                {item?.city === "" ? (
                                  <span>All Cities</span>
                                ) : (
                                  <span>{item?.city}</span>
                                )}
                                <br />
                              </>
                            ))}
                        </StyledTableBodyTextCell>
                        <StyledTableBodyTextCell style={{ minWidth: 150 }}>
                          <span
                            style={{
                              border:
                                item?.reviewstatus === "Approved"
                                  ? "1px solid green"
                                  : item?.reviewstatus === "In Review"
                                    ? "1px solid #ffcc00"
                                    : "1px solid red",
                              color:
                                item?.reviewstatus === "Approved"
                                  ? "green"
                                  : item?.reviewstatus === "In Review"
                                    ? "#ffcc00"
                                    : "red",
                              padding: 4,
                              width: "200px",
                              borderRadius: 4,
                            }}
                          >
                            {item?.reviewstatus}
                          </span>
                        </StyledTableBodyTextCell>
                        <StyledTableBodyTextCell>
                          <span
                            style={{
                              border:
                                item?.paused === "No"
                                  ? "1px solid green"
                                  : item?.paused === "Yes"
                                    ? "1px solid red"
                                    : "1px solid red",
                              color:
                                item?.paused === "No"
                                  ? "green"
                                  : item?.paused === "Yes"
                                    ? "red"
                                    : "red",
                              padding: 4,
                              width: "200px",
                              borderRadius: 4,
                            }}
                          >
                            {item?.paused}
                          </span>
                        </StyledTableBodyTextCell>
                        {String(item.id) === "undefined" ? (
                          <StyledTableBodyTextCell
                            onClick={() =>
                              navigate(`/viewcampaign/${item?.campid}`, {
                                state: item,
                              })
                            }
                          >
                            <RemoveRedEyeOutlined
                              style={{ textAlign: "center", cursor: "pointer" }}
                            />
                          </StyledTableBodyTextCell>
                        ) : (
                          <StyledTableBodyTextCell
                            onClick={() =>
                              navigate(
                                `/viewcampaign/${props?.rows[item.id]?.campid}`,
                                { state: props?.rows[item.id] }
                              )
                            }
                          >
                            <RemoveRedEyeOutlined
                              style={{ textAlign: "center", cursor: "pointer" }}
                            />
                          </StyledTableBodyTextCell>
                        )}
                        {String(item.id) === "undefined" ? (
                          <StyledTableBodyTextCell
                            onClick={() =>
                              navigate(`/editcampaign/${item?.campid}`, {
                                state: item,
                              })
                            }
                          >
                            <EditIcon
                              style={{ textAlign: "center", cursor: "pointer" }}
                            />
                          </StyledTableBodyTextCell>
                        ) : (
                          <StyledTableBodyTextCell
                            onClick={() =>
                              navigate(
                                `/editcampaign/${props?.rows[item.id]?.campid}`,
                                { state: props.rows[item.id] }
                              )
                            }
                          >
                            <EditIcon
                              style={{ textAlign: "center", cursor: "pointer" }}
                            />
                          </StyledTableBodyTextCell>
                        )}
                        {!(item?.reviewstatus === "Approved") ||
                          !(item?.active === "Active") ? (
                          <StyledTableBodyTextCell>
                            <PauseIcon
                              style={{
                                textAlign: "center",
                                border: "1px solid gray",
                                color: "gray",
                              }}
                            />
                          </StyledTableBodyTextCell>
                        ) : String(item.id) === "undefined" ? (
                          <StyledTableBodyTextCell
                            onClick={() =>
                              navigate(`/pausecampaign/${item?.campid}`, {
                                state: item,
                              })
                            }
                          >
                            <PauseIcon
                              style={{
                                textAlign: "center",
                                cursor: "pointer",
                                color: "red",
                              }}
                            />
                          </StyledTableBodyTextCell>
                        ) : (
                          <StyledTableBodyTextCell
                            onClick={() =>
                              navigate(
                                `/pausecampaign/${props?.rows[item.id]?.campid}`,
                                { state: props.rows[item.id] }
                              )
                            }
                          >
                            <PauseIcon
                              style={{
                                textAlign: "center",
                                cursor: "pointer",
                                color: "red",
                              }}
                            />
                          </StyledTableBodyTextCell>
                        )}
                        {!(item?.reviewstatus === "Approved") ||
                          !(item?.active === "Active") ? (
                          <StyledTableBodyTextCell
                          >
                            <RestartAltIcon
                              style={{
                                textAlign: "center",
                                border: "1px solid gray",
                                color: "gray",
                              }}
                            />
                          </StyledTableBodyTextCell>
                        ) : String(item.id) === "undefined" ? (
                          <StyledTableBodyTextCell
                            onClick={() =>
                              navigate(`/resumecampaign/${item?.campid}`, {
                                state: item,
                              })
                            }
                          >
                            <RestartAltIcon
                              style={{
                                textAlign: "center",
                                cursor: "pointer",
                                color: "green",
                              }}
                            />
                          </StyledTableBodyTextCell>
                        ) : (
                          <StyledTableBodyTextCell
                            onClick={() =>
                              navigate(
                                `/resumecampaign/${props?.rows[item.id]?.campid}`,
                                { state: props.rows[item.id] }
                              )
                            }
                          >
                            <RestartAltIcon
                              style={{
                                textAlign: "center",
                                cursor: "pointer",
                                color: "green",
                              }}
                            />
                          </StyledTableBodyTextCell>
                        )}
                      </TableRow>
                    ))}
              </TableBody>
            )}
          </Table>
        ) : (
          <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
            No data found
          </Typography>
        )}
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[15, 30, 45, 60, 75, 90]}
            component="div"
            count={props?.rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
                marginBottom: "13px",
              },
              "& .MuiTablePagination-actions": {
                marginBottom: "13px",
              },
            }}
          />
        </TableFooter>
      </TableContainer>
    </Paper>
  );
};

export const CampaignTableComponentForSOAdmin = (props) => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer ref={tableRef} component={Paper} style={{
      maxHeight: "80vh",
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    }}

      className='manageUser'
    >
      {!_.isEmpty(props.rows) ? (
        <Table stickyHeader className='customizedOrgTable' aria-label="customized table" style={{
          width: "100%",
          borderRadius: "10px",
        }}>
          <TableHead>
            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell style={{ width: '70px' }}>
                <span>S.no</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell style={{ width: '200px', textAlign: 'left' }}>
                <span> Name </span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell style={{ width: '200px', textAlign: 'left' }}>
                <span>Goal</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell style={{ textAlign: 'left' }}>
                <span>Create Date</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell style={{ textAlign: 'left' }}>
                <span>Start Date</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell style={{ textAlign: 'left' }}>
                <span>End Date</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell style={{ textAlign: 'left' }}>
                <span>Sponsors</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell style={{ textAlign: 'left' }}>
                <span>Visibility</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell style={{ textAlign: 'left' }}>
                <span>State</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell style={{ textAlign: 'left' }}>
                <span>City</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell style={{ textAlign: 'left' }}>
                <span>Status</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell style={{ textAlign: 'left' }}>
                <span>Review Comments</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {props.rows &&
                props.rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow style={{

                      borderBottom: "1px solid #d3d3d3",
                      "&:last-child": {
                        borderBottom: "none",
                      }
                    }}>
                      <StyledTableBodyTextCell style={{ textAlign: "center" }}>
                        <span style={{ textAlign: "center" }}>{index + 1 + (page * rowsPerPage)}</span>
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell style={{ width: '200px' }}>
                        <span>{item?.title}</span>
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell style={{ maxWidth: '200px' }}>
                        {/* <span class="truncateTwoLine" title={item?.goal}>{item?.goal}</span> */}
                        <span style={{ maxWidth: '200px' }}>{item?.goal}</span>
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        <span>{item?.createdate}</span>
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        <span>{item?.startdate}</span>
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        <span>{item?.enddate}</span>
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        {_.isArray(item?.sponsor) &&
                          item?.sponsor.map((item1, index) => (
                            <>
                              <span style={{ fontFamily: "inherit" }}>{item1?.sponsor}{((index == (item?.sponsor?.length - 1)) ? '' : ', ')}</span>
                            </>
                          ))}
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        <span>{item?.visibility}</span>
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        {_.isArray(item?.location) &&
                          item?.location.map((item, index) => (
                            <>
                              {item?.state === "" ? (
                                <span>All States</span>
                              ) : (
                                <span>{item?.state}</span>
                              )}
                              <br />
                            </>
                          ))}
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        {_.isArray(item?.location) &&
                          item?.location.map((item, index) => (
                            <>
                              {item?.city === "" ? (
                                <span>All Cities</span>
                              ) : (
                                <span>{item?.city}</span>
                              )}
                              <br />
                            </>
                          ))}
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        <span
                          style={{
                            border:
                              item?.reviewstatus === "Approved"
                                ? "1px solid green"
                                : item?.reviewstatus === "In Review"
                                  ? "1px solid #ffcc00"
                                  : "1px solid red",
                            color:
                              item?.reviewstatus === "Approved"
                                ? "green"
                                : item?.reviewstatus === "In Review"
                                  ? "#ffcc00"
                                  : "red",
                            padding: 4,
                            width: "auto",
                            borderRadius: 4,
                          }}
                        >
                          {item?.reviewstatus}
                        </span>
                      </StyledTableBodyTextCell>
                      <StyledTableBodyTextCell>
                        <span>{item?.reviewcomments}</span>
                      </StyledTableBodyTextCell>

                    </TableRow>
                  ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              marginLeft: "28px",
            },
            "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
              marginBottom: "-1px",
            },
            "& .MuiTablePagination-actions": {
              marginBottom: "-1px",
            },
          }}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const CampaignRegistrationTable = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleButtonClick = (imageUrl) => {
    const popupWindow = window.open('', '_blank', 'width=400, height=300');
    // Create a simple HTML document with the image
    const htmlContent = `<html><body style="margin: 0; display: flex; justify-content: center; align-items: center; height: 100%;"><img src="${imageUrl}" alt="Opened Image" style="max-width: 100%; max-height: 100%;"></body></html>`;
    // Set the HTML content of the popup window
    popupWindow.document.write(htmlContent);
  };


  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        // padding: 16,
        borderRadius: 8,
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "80vw",
        backgroundColor: "inherit",
        marginBottom: "20px",
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888', // Scrollbar color
          borderRadius: '10px', // Rounded corners
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}

      className='manageUser'
    >
      {!_.isEmpty(props.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>S.no</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span> Campaign Name </span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Participation Status</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Review Comments</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span> Registered Date </span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Committed Date(s) for Participation</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Review Date</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>QR Code</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Points Awarded</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    <StyledTableBodyTextCell>
                      <span>{index + 1 + (page * rowsPerPage)}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.formcampaignname}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span
                        style={{
                          border:
                            item?.registrationstatus === "Approved" ||
                              item?.registrationstatus === "approved"
                              ? "1px solid green"
                              : item?.registrationstatus === "Submitted" ||
                                item?.registrationstatus === "submitted"
                                ? "1px solid #ffcc00"
                                : "1px solid red",
                          color:
                            (item?.registrationstatus === "Approved") |
                              (item?.registrationstatus === "approved")
                              ? "green"
                              : item?.registrationstatus === "Submitted" ||
                                item?.registrationstatus === "submitted"
                                ? "#ffcc00"
                                : "red",
                          padding: 4,
                          width: "200px",
                          borderRadius: 4,
                        }}
                      >
                        {String(item?.registrationstatus).toLocaleUpperCase()}
                      </span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      {item?.reviewcomments ? (
                        <span>{item?.reviewcomments}</span>
                      ) : (
                        <span>No review comments</span>
                      )}
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.registerdate}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.participatedays}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.rewarddate}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell
                      onClick={() =>
                        handleButtonClick(item?.qrcodeurl)
                      }
                    >
                      <QrCodeIcon style={{ cursor: "pointer" }} />
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{parseInt(item?.rewardpoints)}</span>
                    </StyledTableBodyTextCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              marginLeft: "28px",
            },
            "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
              marginBottom: "-1px",
            },
            "& .MuiTablePagination-actions": {
              marginBottom: "-1px",
            },
          }}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const ReviewOrgainzationTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888', // Scrollbar color
          borderRadius: '10px', // Rounded corners
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}

      className='manageUser'
    >
      {!_.isEmpty(props.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>S.no</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Organization</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>No of volunteers</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>State (Operational Area)</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>City (Operational Area)</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>Status</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>View & Review</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Edit</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    <StyledTableBodyTextCell>
                      <span>{index + 1}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.organization}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.noofvol}</span>
                    </StyledTableBodyTextCell>
                    {/* <StyledTableBodyTextCell>
                    <span>{item?.orghq}</span>
                  </StyledTableBodyTextCell> */}
                    {/* <StyledTableBodyTextCell>
                    <span>{item?.chieffunctionary}</span>
                  </StyledTableBodyTextCell> */}
                    {/* <StyledTableBodyTextCell>
                    {_.isArray(item?.location) &&
                      item?.location.map((item, index) => (
                        <>
                          <span>{item?.country}</span>
                          <br />
                        </>
                      ))}
                  </StyledTableBodyTextCell> */}
                    <StyledTableBodyTextCell>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.state === "" ? (
                              <span>All States</span>
                            ) : (
                              <span>{item?.state}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.city === "" ? (
                              <span>All Cities</span>
                            ) : (
                              <span>{item?.city}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span
                        style={{
                          border:
                            item?.reviewstatus === "Approved"
                              ? "1px solid green"
                              : item?.reviewstatus === "In Review"
                                ? "1px solid #ffcc00"
                                : "1px solid red",
                          color:
                            item?.reviewstatus === "Approved"
                              ? "green"
                              : item?.reviewstatus === "In Review"
                                ? "#ffcc00"
                                : "red",
                          padding: 4,
                          width: "200px",
                          borderRadius: 4,
                        }}
                      >
                        {item?.reviewstatus}
                      </span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell
                      onClick={() =>
                        navigate(`/orgreview/${item?.clientid}`, {
                          state: item,
                        })
                      }
                    >
                      <RemoveRedEyeOutlined style={{ cursor: "pointer" }} />
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell
                      onClick={() =>
                        navigate(`/editorg/${item?.clientid}`, { state: item })
                      }
                    >
                      <EditIcon
                        style={{ textAlign: "center", cursor: "pointer" }}
                      />
                    </StyledTableBodyTextCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              marginLeft: "28px",
            },
            "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
              marginBottom: "-1px",
            },
            "& .MuiTablePagination-actions": {
              marginBottom: "-1px",
            },
          }}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const OrganizationTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "80vw",
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888', // Scrollbar color
          borderRadius: '10px', // Rounded corners
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}

      className='manageUser'
    >
      {!_.isEmpty(props.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>Organization</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Organization Description</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>No of employees</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Phone No</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>No of Volunteer</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>City (Operation Area)</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Country</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>State (Operation Area)</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Area</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>Logo</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    <StyledTableBodyTextCell>
                      <span>{item?.organization}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.organizationdesc}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.noofemployees}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item.contactnumber}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item.noofvol}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.city === "" ? (
                              <span>All Cities</span>
                            ) : (
                              <span>{item?.city}</span>
                            )}
                            <br />
                          </>
                        ))}
                      {/* {item.location} */}
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            <span>{item.country}</span>
                            <br />
                          </>
                        ))}
                    </StyledTableBodyTextCell>

                    <StyledTableBodyTextCell>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.state === "" ? (
                              <span>All States</span>
                            ) : (
                              <span>{item?.state}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.area === "" ? (
                              <span>All Areas</span>
                            ) : (
                              <span>{item?.area}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </StyledTableBodyTextCell>

                    <StyledTableBodyTextCell>
                      <Avatar
                        src={item?.organizationlogo}
                        style={{ width: 80, height: 80 }}
                      />
                    </StyledTableBodyTextCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              marginLeft: "28px",
            },
            "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
              marginBottom: "-1px",
            },
            "& .MuiTablePagination-actions": {
              marginBottom: "-1px",
            },
          }}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const ReviewSponsorTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888', // Scrollbar color
          borderRadius: '10px', // Rounded corners
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}

      className='manageUser'
    >
      {!_.isEmpty(props.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>S.no</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Sponsor</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>No of employees</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>State (Operational Area)</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>City (Operational Area)</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>Status</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Edit</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    <StyledTableBodyTextCell>
                      <span>{index + 1}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.organization}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.noofemployees}</span>
                    </StyledTableBodyTextCell>
                    {/* <StyledTableBodyTextCell>
                    <span>{item?.noofvol}</span>
                  </StyledTableBodyTextCell> */}
                    {/* <StyledTableBodyTextCell>
                    <span>{item?.orghq}</span>
                  </StyledTableBodyTextCell> */}
                    {/* <StyledTableBodyTextCell>
                    <span>{item?.chieffunctionary}</span>
                  </StyledTableBodyTextCell> */}
                    {/* <StyledTableBodyTextCell>
                    {_.isArray(item?.location) &&
                      item?.location.map((item, index) => (
                        <>
                          <span>{item?.country}</span>
                          <br />
                        </>
                      ))}
                  </StyledTableBodyTextCell> */}
                    <StyledTableBodyTextCell>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.state === "" ? (
                              <span>All States</span>
                            ) : (
                              <span>{item?.state}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.city === "" ? (
                              <span>All Cities</span>
                            ) : (
                              <span>{item?.city}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span
                        style={{
                          border:
                            item?.reviewstatus === "Approved"
                              ? "1px solid green"
                              : item?.reviewstatus === "In Review"
                                ? "1px solid #ffcc00"
                                : "1px solid red",
                          color:
                            item?.reviewstatus === "Approved"
                              ? "green"
                              : item?.reviewstatus === "In Review"
                                ? "#ffcc00"
                                : "red",
                          padding: 4,
                          width: "200px",
                          borderRadius: 4,
                        }}
                      >
                        {item?.reviewstatus}
                      </span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell
                      onClick={() =>
                        navigate(`/editsponsor/${item?.clientid}`, {
                          state: item,
                        })
                      }
                    >
                      <EditIcon
                        style={{ textAlign: "center", cursor: "pointer" }}
                      />
                    </StyledTableBodyTextCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              marginLeft: "28px",
            },
            "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
              marginBottom: "-1px",
            },
            "& .MuiTablePagination-actions": {
              marginBottom: "-1px",
            },
          }}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const ManageRewardsTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // console.log(props.rows?.[0].sponsor.length);
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888', // Scrollbar color
          borderRadius: '10px', // Rounded corners
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}

      className='manageUser'
    >
      {!_.isEmpty(props.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead className="stickyPosition">
            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>S.no</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Award Name</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Award Ownership</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Award Title</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>Award Subtitle</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Points</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Delete</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    <StyledTableBodyTextCell>
                      <span>{index + 1 + (page * rowsPerPage)}</span>
                    </StyledTableBodyTextCell>

                    <StyledTableBodyTextCell>
                      <span>{item?.rewardtype}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.ownership}</span>
                    </StyledTableBodyTextCell>

                    <StyledTableBodyTextCell>
                      <span>{item?.title}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.subtitle}</span>
                    </StyledTableBodyTextCell>

                    {/* <StyledTableBodyTextCell>
                      {isArray(item?.sponsor) ? (
                        item?.sponsor?.map((item1, index) => (
                          <span style={{ padding: "5px" }}>
                            {item1?.sponsor}{" "}
                            {index == item?.sponsor?.length - 1 ? "" : ","}
                          </span>
                        ))
                      ) : (
                        <span>
                          {item?.sponsor}{" "}
                          {index == item?.sponsor?.length - 1 ? "" : ","}{" "}
                        </span>
                      )}
                    </StyledTableBodyTextCell> */}

                    <StyledTableBodyTextCell>
                      <span>{item?.points}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell
                      // onClick={handleDeleteUser(item?.phoneno)}
                      onClick={() =>
                        navigate(`/deletereward/${item?.phoneno}`, {
                          state: item,
                        })
                      }
                      style={{ textAlign: "center", cursor: "pointer" }}
                    >
                      <DeleteForeverIcon
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                          color: "gray",
                        }}
                      />
                    </StyledTableBodyTextCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter className="stickyPosition">
        <TablePagination className="footerPagination"
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              marginLeft: "28px",
            },
            "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
              marginBottom: "-1px",
            },
            "& .MuiTablePagination-actions": {
              marginBottom: "-1px",
            },
          }}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const AcitvityTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        width: "max-content",
        boxShadow: "1px 1px 1px 5px #f0f0f0",
        borderRadius: 8,
        marginTop: 16,
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888', // Scrollbar color
          borderRadius: '10px', // Rounded corners
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}

      className='manageUser'
    >
      {!_.isEmpty(props.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: "center" }}>Full Name</TableCell>
              <TableCell style={{ textAlign: "center" }}>Phone No</TableCell>
              <TableCell style={{ textAlign: "center" }}>City</TableCell>
              <TableCell style={{ textAlign: "center" }}>Campaign Name</TableCell>
              <TableCell style={{ textAlign: "center" }}>Activity Date</TableCell>
            </TableRow>
          </TableHead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {props.rows.map((item) => (
                <StyledTableRow style={{ borderBottom: "1px solid red" }}>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {item?.fullname}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {item.phoneno}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {item.city}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {item.campaignname}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {item.activitydate}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
    </TableContainer>
  );
};

export const VideoDetailsTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setsearched] = useState("");
  const [user, setUser] = React.useState();


  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        // padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "40vw",
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888', // Scrollbar color
          borderRadius: '10px', // Rounded corners
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}

      className='manageUser'
    >
      {!_.isEmpty(props?.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>Contributed By</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Contributed When</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Contributor's Organization</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              <TableRow style={{
                borderBottom: "1px solid #d3d3d3",
                "&:last-child": {
                  borderBottom: "none",
                }
              }}>
                <StyledTableBodyTextCell>
                  <span> {props?.rows?.username}</span>
                </StyledTableBodyTextCell>
                <StyledTableBodyTextCell>
                  <span> {props?.rows?.timestamp}</span>
                </StyledTableBodyTextCell>
                <StyledTableBodyTextCell>
                  <span>{props?.rows?.userorg}</span>
                </StyledTableBodyTextCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
    </TableContainer>
  );
};

export const TextContainerComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setsearched] = useState("");
  const [user, setUser] = React.useState();


  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        // padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        // minWidth: "40vw",
        maxWidth: "100vw",
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888', // Scrollbar color
          borderRadius: '10px', // Rounded corners
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}

      className='manageUser'
    >
      {!_.isEmpty(props?.users) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>SI #</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Members in the room</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {props.users.map((item, index) => (
                <TableRow style={{
                  borderBottom: "1px solid #d3d3d3",
                  "&:last-child": {
                    borderBottom: "none",
                  }
                }}>
                  <StyledTableBodyTextCell>
                    <span>{index + 1}</span>
                  </StyledTableBodyTextCell>
                  <StyledTableBodyTextCell>
                    <span> {item.name}</span>
                  </StyledTableBodyTextCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
    </TableContainer>
  );
};

export const VolStatsFileListTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setsearched] = useState("");
  const [user, setUser] = React.useState();


  const downloadFileAtURL = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "20vw",
        // maxWidth: "50vw",
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888', // Scrollbar color
          borderRadius: '10px', // Rounded corners
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}

      className='manageUser'
    >
      {!_.isEmpty(props?.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>Asset Type</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Asset URL</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Asset Uploaded Date</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Download</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {props?.rows?.data?.map((item, index) =>
                item.files?.map((item1) => (
                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    <StyledTableBodyTextCell>
                      <span> {item1?.type}</span>
                    </StyledTableBodyTextCell>
                    {/* <TableCell className={classes.tbodyTextvolstat}> */}
                    <StyledTableBodyTextCell>
                      {item1?.type === "video" ? (
                        <ReactPlayer
                          url={item1?.fileurl}
                          className="react-player-volstate"
                          controls
                        />
                      ) : (
                        <StyledAvatar
                          src={item1?.fileurl}
                        />
                      )}
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item1?.timestamp}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell
                      onClick={() => {
                        downloadFileAtURL(item1?.fileurl);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {/* <span>{item1?.timestamp}</span> */}

                      <DownloadIcon />
                      {/* <Button
                  onClick={() => {
                    downloadFileAtURL(item1?.fileurl);
                  }
                  }
                  >Save</Button> */}
                    </StyledTableBodyTextCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
    </TableContainer>
  );
};

export const RedeemRewardTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const downloadFileAtURL = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        // padding: '16px 1px 16px 1px',
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "80vw",
        // maxWidth: "50vw",
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}

      className='manageUser'
    >
      {!_.isEmpty(props?.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead className="stickyPosition">
            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>Reward Type</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Title</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Sub Title</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Nature of Reward</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Redeem Date</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Download</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Fulfilment Status</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Fulfilment Date</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {/* {props.rows?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => 
                item.reward?.map((item1) => ( */}
              {/* props?.rows?.data?.map((item) => item.reward?.map((item1) => item1?.length)) */}
              {props.rows?.data?.map(item => item.reward?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item1, index) => (
                  // item.reward?.map((item1) => (
                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    <StyledTableBodyTextCell>
                      <span> {item1?.rewardtype}</span>
                    </StyledTableBodyTextCell>

                    {/* <TableCell className={classes.tbodyTextRedeemReport}> */}
                    <StyledTableBodyTextCell>
                      <span class="truncateTwoLine" title={item1?.title}>{item1?.title}</span>
                    </StyledTableBodyTextCell>
                    {/* <TableCell className={classes.tbodyTextRedeemReport}> */}
                    <StyledTableBodyTextCell>
                      <span>{item1?.subtitle}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item1?.rewardnature}</span>
                    </StyledTableBodyTextCell>
                    {/* <TableCell className={classes.tbodyTextRedeemReport}> */}
                    <StyledTableBodyTextCell>
                      <span>{item1?.redeemdate}</span>
                    </StyledTableBodyTextCell>
                    {/* <TableCell className={classes.tbodyTextRedeemReport}> */}
                    <StyledTableBodyTextCell>
                      {item1?.rewardurl ? (
                        <span><CardMembershipIcon onClick={() => {
                          downloadFileAtURL(item1?.rewardurl);
                        }}
                          style={{ cursor: "pointer" }} /></span>
                      ) : (
                        <span>Not Available</span>
                      )
                      }
                    </StyledTableBodyTextCell>

                    <StyledTableBodyTextCell>
                      <span
                        style={{
                          border:
                            item1?.status === "Close" ||
                              item1?.status === "close"
                              ? "1px solid green"
                              : item1?.status === "Scheduled" ||
                                item1?.status === "scheduled"
                                ? "1px solid #ffcc00"
                                : "1px solid red",
                          color:
                            (item1?.status === "Close") |
                              (item1?.status === "close")
                              ? "green"
                              : item1?.status === "Scheduled" ||
                                item1?.status === "scheduled"
                                ? "#ffcc00"
                                : "red",
                          padding: 4,
                          width: "200px",
                          borderRadius: 4,
                        }}

                      >{String(item1?.status).toLocaleUpperCase()}</span>
                    </StyledTableBodyTextCell>
                    {/* <TableCell className={classes.tbodyTextRedeemReport}> */}
                    <StyledTableBodyTextCell>
                      <span>{item1?.fulfilmentdate}</span>
                    </StyledTableBodyTextCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter className="stickyPosition">
        <TablePagination className="footerPagination"
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          // count={props?.rows?.data?.length}
          count={props?.rows?.data?.map((item) => item.reward?.length)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              marginLeft: "28px",
            },
            "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
              marginBottom: "-1px",
            },
            "& .MuiTablePagination-actions": {
              marginBottom: "-1px",
            },
          }}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const RewardsReportTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        // padding: 16,
        borderRadius: 8,
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "50vw",
        backgroundColor: "inherit",
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888', // Scrollbar color
          borderRadius: '10px', // Rounded corners
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}
      className='manageUser'
    >
      {!_.isEmpty(props?.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead className="stickyPosition">
            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>Camp Name</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Participate Points Budget</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Participate Points Used</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span> Reference Points Budget</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Reference Points Used</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Awarded Target Bonus Pts</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Target Volunteers</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span> Volunteers Participated</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span> Campaign Remaining Days</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    <StyledTableBodyTextCell>
                      <span>{item?.campaign}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.participatebudgetpoints}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.participatepointused}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.referralbudgetpoints}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.refpointsused}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.targetbonuspointgiven}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.targetvol}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.volparticipated}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.daysrem}</span>
                    </StyledTableBodyTextCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter className="stickyPosition">
        <TablePagination className="footerPagination"
          rowsPerPageOptions={[20, 40, 60]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{
            '&.MuiTablePagination-caption': {
              width: '125px',
            },
            "& .MuiTablePagination-actions": {
              marginBottom: "-1px",
            },
          }}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              marginLeft: "28px",
            },
            "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
              marginBottom: "-1px",
            },
          }}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const CampSubmissionReportTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        backgroundColor: "inherit",
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888', // Scrollbar color
          borderRadius: '10px', // Rounded corners
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}

      className='manageUser'
    >
      {!_.isEmpty(props?.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            {/* <StyledTableCell> */}

            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>Camp Name</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>Change maker</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Contact No.</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Email ID</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Organization</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Registration Date</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Registration Status</span>
              </StyledTableHeadTextCell>

            </TableRow>
          </TableHead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  // {activityList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // .map((item, index) => (

                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    <StyledTableBodyTextCell>
                      <span>{item?.campaignname}</span>
                    </StyledTableBodyTextCell>

                    <StyledTableBodyTextCell>
                      <span>
                        {item?.firstname} {item?.lastname}
                      </span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.contactno}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.emailid}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.organization}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.registerdate}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.registrationstatus}</span>
                    </StyledTableBodyTextCell>

                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      {!props.isFetching ? (
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[10, 20, 40]}
            component="div"
            count={props?.rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginLeft: "28px",
              },
              "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
                marginBottom: "-1px",
              },
              "& .MuiTablePagination-actions": {
                marginBottom: "-1px",
              },
            }}
          />
        </TableFooter>
      ) : (
        ""
      )}
    </TableContainer>
  );
};

export const SponsorAdminDashboardTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const navigate = useNavigate();
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        backgroundColor: "inherit",
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888', // Scrollbar color
          borderRadius: '10px', // Rounded corners
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}

      className='manageUser'
    >
      {!_.isEmpty(props?.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            {/* <StyledTableCell> */}

            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>Camp Name</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>Create Date</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Start Date</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>End Date</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Reward Budget Given</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  // {activityList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // .map((item, index) => (

                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    <StyledTableBodyTextCell>
                      <span>{item?.title}</span>
                    </StyledTableBodyTextCell>

                    <StyledTableBodyTextCell>
                      <span>{item?.createdate}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.startdate}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.enddate}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.sponsorbudget}</span>
                    </StyledTableBodyTextCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      {!props.isFetching ? (
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[10, 20, 40]}
            component="div"
            count={props?.rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginLeft: "28px",
              },
              "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
                marginBottom: "-1px",
              },
              "& .MuiTablePagination-actions": {
                marginBottom: "-1px",
              },
            }}
          />
        </TableFooter>
      ) : (
        ""
      )}
    </TableContainer>
  );
};

export const DataAlertReportTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        backgroundColor: "inherit",
        maxHeight: "80vh",
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888', // Scrollbar color
          borderRadius: '10px', // Rounded corners
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555', // Scrollbar color on hover
        },
      }}

      className='manageUser'
    >
      {!_.isEmpty(props?.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            {/* <StyledTableCell> */}

            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>Alert Message</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => item?.map((item1) => (
                  // .map((item, index) => (

                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>

                    <StyledTableBodyTextCell>
                      <span>{item1?.datainconsistency}</span>
                    </StyledTableBodyTextCell>

                  </TableRow>
                )))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      {!props.isFetching ? (
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[10, 20, 40]}
            component="div"
            count={props?.rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginLeft: "28px",
              },
              "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
                marginBottom: "-1px",
              },
              "& .MuiTablePagination-actions": {
                marginBottom: "-1px",
              },
            }}
          />
        </TableFooter>
      ) : (
        ""
      )}
    </TableContainer>
  );
};


export const UserPerformanceReportTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const navigate = useNavigate();
  return (
    <div
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "50vw",
        backgroundColor: "inherit",
      }}
      className="userPerformanceReport"
    >
      {!_.isEmpty(props?.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            {/* <StyledTableCell> */}

            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>User Name</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>Contact No.</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>No. of Campaigns Participated</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Total Reward Points</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  // {activityList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // .map((item, index) => (


                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    {/* {JSON.stringify(`/user/report/${localStorage.getItem("userid")}/${item?.phoneno}`)} */}
                    <StyledTableBodyTextCell
                      onClick={() =>
                        //alert("CLICKED")

                        navigate(`/user/report/${localStorage.getItem("userid")}/${item?.phoneno}`)
                      }
                      style={{ textAlign: "center", cursor: "pointer", color: "blue" }}
                    >
                      <span>{item?.firstname} {item?.lastname}</span>
                    </StyledTableBodyTextCell>

                    <StyledTableBodyTextCell>
                      <span>{item?.phoneno}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.participationcount}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.rewardpoint}</span>
                    </StyledTableBodyTextCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              marginLeft: "28px",
            },
            "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
              marginBottom: "-1px",
            },
            "& .MuiTablePagination-actions": {
              marginBottom: "-1px",
            },
          }}
        />
      </TableFooter>
    </div>
  );
};



export const UserPerformanceReportDetailsTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const navigate = useNavigate();
  return (
    <div
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "50vw",
        backgroundColor: "inherit",
      }}
    >
      {!_.isEmpty(props?.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            {/* <StyledTableCell> */}

            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>Campaign Name</span>
              </StyledTableHeadTextCell>

              <StyledTableHeadTextCell>
                <span>Registered Date</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Reward Date</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span> Points Earned By</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span># of times participated</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Points Earned</span>
              </StyledTableHeadTextCell>
            </TableRow>
          </TableHead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  // {activityList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // .map((item, index) => (

                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    <StyledTableBodyTextCell>
                      <span>{item?.campname}</span>
                    </StyledTableBodyTextCell>

                    <StyledTableBodyTextCell>
                      <span>{item?.registerdate}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.rewarddate}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.pointsearnedby}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.countofparticipation}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.points}</span>
                    </StyledTableBodyTextCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              marginLeft: "28px",
            },
            "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
              marginBottom: "-1px",
            },
            "& .MuiTablePagination-actions": {
              marginBottom: "-1px",
            },
          }}
        />
      </TableFooter>
    </div>
  );
};

export const PFRewardsReportTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const navigate = useNavigate();
  return (
    <div
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "50vw",
        backgroundColor: "inherit",
      }}
    >
      {!_.isEmpty(props?.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            {/* <StyledTableCell> */}

            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>Camp Name</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Camp Status</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Total Campaign Budget</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Platform Fees</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Campaign Expense Fees</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span> Total Reward Budget</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span> Platform Reward Budget</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span> Campaign Marketplace Budget</span>
              </StyledTableHeadTextCell>
            </TableRow>
            {/* </StyledTableCell> */}
          </TableHead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  // {activityList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // .map((item, index) => (


                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    {/* {JSON.stringify(`/user/report/${localStorage.getItem("userid")}/${item?.phoneno}`)} */}
                    <StyledTableBodyTextCell
                      onClick={() =>
                        //alert("CLICKED")

                        navigate(`/camp/detail/report/${localStorage.getItem("userid")}/${item?.campid}`)
                      }
                      style={{ textAlign: "center", cursor: "pointer", color: "blue" }}
                    >
                      <span>{item?.title}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.status}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.totalcampbudget}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.platformfees}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.campaignexpensefees}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.rewardbudget}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.platformreward}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.campaignmarketplacereward}</span>
                    </StyledTableBodyTextCell>
                    {/* <StyledTableBodyTextCell>
                    <span>{item?.campaignunusedreward}</span>
                  </StyledTableBodyTextCell> */}
                    {/* <StyledTableBodyTextCell>
                      <span>{item?.volparticipated}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.daysrem}</span>
                    </StyledTableBodyTextCell> */}
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[20, 40, 60]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              marginLeft: "28px",
            },
            "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
              marginBottom: "-1px",
            },
            "& .MuiTablePagination-actions": {
              marginBottom: "-1px",
            },
          }}
        />
      </TableFooter>
    </div>
  );
};


export const SponsorBasedReportTableComponent = (props) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div
      ref={tableRef}
      component={Paper}
      // style={{
      //   padding: 16,
      //   borderRadius: 8,
      //   // width: "max-content",
      //   borderRadius: 6,
      //   background: "#ffffff",
      //   marginTop: 16,
      //   minWidth: "50vw",
      //   backgroundColor: "inherit",
      // }}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        backgroundColor: "inherit",
        overflowX: "auto",
      }}
    >
      {!_.isEmpty(props?.rows) ? (
        <Table stickyHeader aria-label="customized table" style={{ width: "100%", borderRadius: "10px" }}>
          <TableHead>
            {/* <StyledTableCell> */}

            <TableRow style={{ borderBottom: "1px solid #d3d3d3", borderTop: "1px solid #d3d3d3", height: 60 }}>
              <StyledTableHeadTextCell>
                <span>Sponsor Name</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Camp Name</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Campaign Budget</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Sponsor's Total Budget</span>
              </StyledTableHeadTextCell>
              <StyledTableHeadTextCell>
                <span>Sponsor Budget for Campaign</span>
              </StyledTableHeadTextCell>

            </TableRow>
            {/* </StyledTableCell> */}
          </TableHead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  // {activityList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // .map((item, index) => (


                  <TableRow style={{
                    borderBottom: "1px solid #d3d3d3",
                    "&:last-child": {
                      borderBottom: "none",
                    }
                  }}>
                    {/* {JSON.stringify(`/user/report/${localStorage.getItem("userid")}/${item?.phoneno}`)} */}
                    <StyledTableBodyTextCell
                      // onClick={() =>
                      //   //alert("CLICKED")

                      //   navigate(`/camp/detail/report/${localStorage.getItem("userid")}/${item?.campid}`)
                      // }
                      style={{ textAlign: "center", cursor: "pointer", color: "blue" }}
                    >
                      <span>{item?.sponsorname}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.title}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.camptotalbudget}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.initialbudget}</span>
                    </StyledTableBodyTextCell>
                    <StyledTableBodyTextCell>
                      <span>{item?.sponsorbudget}</span>
                    </StyledTableBodyTextCell>

                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[20, 40, 60]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              marginLeft: "28px",
            },
            "& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input": {
              marginBottom: "-1px",
            },
            "& .MuiTablePagination-actions": {
              marginBottom: "-1px",
            },
          }}
        />
      </TableFooter>
    </div>
  );
};

