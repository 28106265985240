import React from "react";
// import './main.scss';
import _ from "lodash";
// import API from '../../services';
// import { Progress } from 'reactstrap';
import { useState } from "react";
import uplodIcon from "../assets/upload.png";
import { getCampDocs, getCampDocsReset } from "../redux/action/campFilesAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import FileUploadImg from "../assets/file-up.png";
import { Button } from "@mui/material";
import { isEmpty } from "lodash";

const UploadDocsButton = (parentTriggerFunction) => {
  const dispatch = useDispatch();
  const locationPath = useLocation();
  // let { id, label, uploadUrl } = props;
  const [isUploding, setUploding] = useState(false);
  const [uploadedImgs, setUplodedImgs] = useState([]);
  const [uploadProgress, setProgress] = useState(0);
  const [files, setFiles] = useState();
  const [fileSize, setFileSize] = useState(true);
  const [fileUploadProgress, setFileUploadProgress] = useState(false);
  const [fileUploadResponse, setFileUploadResponse] = useState(null);

  React.useEffect(() => {
    dispatch(getCampDocsReset());
    setFileUploadResponse("");
    // setFiles();
  }, [parentTriggerFunction.selectedCategory]);

  const { isCampDocSuccess, campDocResponse, isCampDocError } = useSelector(
    (state) => state.getCampDocs
  );

  const handleChange = (e) => {
    // let { files } = e.target;
    // setSelectedFile(e.target.files[0]);
    setFiles(e.target.files);

  };

  // React.useEffect(() => {
  //   if (isCampDocSuccess) {
  //     for (let i=0; i<files.length; i++) {
  //       const lbl = files[i];
  //       files[i].name = ""
  //     }
  //   }

  // }, [isCampDocSuccess]);
  const handleUpload = () => {
    // let { files } = e.target;
    // setSelectedFile(e.target.files[0]);

    if (isEmpty(files)) {
      setFileUploadResponse("Please select file(s) to upload");
    
      return;
    }
 
    setFileSize(true);
    setFileUploadProgress(true);
    setFileUploadResponse(null);
    let formData = new FormData();
  
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 51200000) {
        setFileSize(false);
        setFileUploadProgress(false);
        setFileUploadResponse(null);
        return;
      }

      formData.append(`files`, files[i]);
      // dispatch(
      //   getCampDocs({
      //     campid: props.camp,
      //     userId: localStorage.getItem("userid"),
      //     formData,
      //   })
      // );
    }
    
    // dispatch(
    //   getCampDocs({
    //     campid: props.camp,
    //     userId: localStorage.getItem("userid"),
    //     formData,
    //   })
    // );

    Promise.all([
      dispatch(
        getCampDocs({
          campid: parentTriggerFunction.camp,
          userId: localStorage.getItem("userid"),
          formData,
        })
      ),
    ]).then(() => parentTriggerFunction.parentTriggerFunction());

    // setUploding(true);
    // let { data } = await API.post(uploadUrl, formData, {
    //     onUploadProgress: ({ loaded, total }) => {
    //         let progress = ((loaded / total) * 100).toFixed(2);
    //         setProgress(progress);
    //     }
    // });
    // setUplodedImgs(data);
    // setUploding(false);
    setFileUploadProgress(false);
    setFileUploadResponse("File(s) uploaded");
    // alert("File uploaded");
  };


  return (
    <div class="cyagg-sec-space">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div>
              {/* <div class="filecardupcoming" style={{padding:"0px 0px 0px 0px", boxShadow: "0 0px 0px 0"}}> */}
              <div class="filecardupcoming">
                <h4>Upload Documents</h4>
                {/* <div class="drop_box" style={{padding:"0px 0px 0px 0px"}}> */}
                <div>
                  {/* <header>
                    <h4>Select Doc here</h4>
                  </header>
                  <p>Files Supported: PDF, TEXT, DOC , DOCX</p> */}
                  <input
                    type="file"
                    // hidden
                    accept=".doc,.docx,.pdf"
                    id="fileID"
                    // style={{ color:"orange", display: "none" }}
                    // style={{ color:"orange", backgroundColor:"orange" }}
                    multiple
                    onChange={handleChange}
                    placeholder="Select File and Upload"
                    // class="btn"
                  />
                  {/* <button class="btn" style={{width:"50%"}} onClick={handleUpload}> */}
                  <button
                    class="btn"
                    style={{ marginTop: "5px" }}
                    onClick={handleUpload}
                  >
                    Upload
                  </button>
                  {/* <button class="btn">Choose File</button> */}
                </div>

                {!fileSize && (
                  <p style={{ color: "red" }}>File size exceeded 50MB!!</p>
                )}
                {fileUploadProgress && (
                  <p style={{ color: "orange" }}>Uploading File(s)</p>
                )}
                {fileUploadResponse != null && (
                  <p style={{ color: "green" }}>{fileUploadResponse}</p>
                )}
                {/* {isUploding ? (
                  <div className="flex-grow-1 px-2">
                    TRUE
                    
                    <div className="text-center">{uploadProgress}%</div>
                     <Progress value={uploadProgress} />
                  </div>
                ) : null} */}
              </div>
              {/* {
                    uploadedImgs && !isUploding ? (
                        uploadedImgs.map(uploadedImg => (
                            <img src={uploadedImg} key={uploadedImg} alt="UploadedImage" className="img-thumbnail img-fluid uploaded-img mr-2" />
                        ))
                    ) : null
                } */}
            </div>
          </div>
          {/* <div class="col-md-6">
            <div class="text-center">
              <img
                src={FileUploadImg}
                class="img-fluid mob-space1"
                style={{ width: "300px" }}
              />
              <p style={{ textAlign: "justify", fontSize: 12 }}>
                1. Please upload documents or scanned copies as required by the
                campaign. You can view your uploaded documents in <a href= "/myongoingcampaignspvt">Your Campaign Documents </a>
                 section
              </p>
              <p style={{ textAlign: "justify", fontSize: 12 }}>
                2. Please ensure that file size is less than 50 MB
              </p>
              <p style={{ textAlign: "justify", fontSize: 12 }}>
                3. Your uploaded documents will be visible only to you and your organization Admin/
                Campaign Owner
              </p>
            </div>
          </div> */}
          {/* {campFilesSuccess && <span>Files uploaded</span>} */}
        </div>
      </div>
    </div>

    // <div className="form-group">
    //     <label htmlFor="create-yaad--title" className="text-primary font-weight-bold"></label>
    //     <div className="d-flex">
    //         <div className="d-flex">
    //             <div className="file-uploader-mask d-flex justify-content-center align-items-center">
    //                 <img className="file-uploader-icon" src={uplodIcon} height="40px" alt="Upload-Icon" />
    //             </div>
    //             <input multiple className="file-input" type="file" id="create-yaad--title" onChange={handleChange} />
    //         </div>
    //         {
    //             isUploding ? (
    //                 <div className="flex-grow-1 px-2">
    //                     <div className="text-center">{uploadProgress}%</div>
    //                     {/* <Progress value={uploadProgress} /> */}
    //                 </div>
    //             ) : null
    //         }
    //     </div>
    //     <div className="d-flex flex-wrap mt-4">
    //         {
    //             uploadedImgs && !isUploding ? (
    //                 uploadedImgs.map(uploadedImg => (
    //                     <img src={uploadedImg} key={uploadedImg} alt="UploadedImage" className="img-thumbnail img-fluid uploaded-img mr-2" />
    //                 ))
    //             ) : null
    //         }
    //     </div>
    //     {campFilesSuccess &&
    //     <span>Files uploaded</span>
    //     }
    // </div>
  );
};

export default UploadDocsButton;
