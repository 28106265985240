import React, { useEffect, useState, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Badge, Box, useTheme, Tooltip, useMediaQuery } from "@mui/material";
import _ from "lodash";
import { isArray, isEmpty } from "lodash";
import PropTypes from "prop-types";
import {
  userProfile,
  userProfileReset,
} from "../redux/action/UserProfileActions";
import { getVolunteerCount } from "../redux/action/volunteerCountAction";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { socialLoginReset } from "../redux/action/loginAction";
import { volDashBoardReward } from "../redux/action/DashboardReward";
import { LogoutOutlined } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import { FaCoins } from 'react-icons/fa';
import { Circles } from "react-loader-spinner";
import styled from 'styled-components';
import { FaPrayingHands } from 'react-icons/fa';
import ShowBroadcastMessage from "./ShowBroadcastMessage";


const RewardHeaderIcon = styled('div')(({ theme, isMobile }) => ({
  width: isMobile ? '15px' : '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // border: '3px solid #fff',
  // borderRadius: '18px',
  position: 'relative',
  bottom: '2px',
  color: '#f0b90b',

  fontSize: isMobile ? '15px' : '27px',
}));

const RewardContainer = styled('div')(({ theme, isMobile }) => ({
  position: 'relative',
  padding: '7px',
  paddingRight: '25px',
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  width: isMobile ? '100px' : '113px',
  height: '39px',
}));

const RewardPoints = styled('span')(({ theme }) => ({
  fontSize: '18px',
  color: 'indigo',
  transition: 'opacity 0.5s ease-in-out',
  opacity: 0,
}));

const LoaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '18px',
  height: '18px',
  transition: 'opacity 0.5s ease-in-out',
  opacity: 1,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const dispatch = useDispatch();

  // const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
  //   (state) => state.profile
  // );

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const TopHeaderScreen1 = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [campaignList, setCampaignList] = useState([]);
  const [rewarddash, setRewardDash] = useState([]);
  const [image, setImage] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const [broadcastMessage, setBroadcastMessage] = useState([]);

  const isMobile = useMediaQuery('(max-width:600px)');

  const [menuOpen, setMenuOpen] = React.useState(localStorage.getItem('myActiveMenu') === "" ? 'Campaigns' : localStorage.getItem('myActiveMenu'));
  const [subMenuOpen, setSubMenuOpen] = React.useState(localStorage.getItem('mySubMenu') === "" ? 'Home' : localStorage.getItem('mySubMenu'));

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  // console.log(profileResponse)
  const { isSuccess: myRewardSuccess, response: myRewardResponse } =
    useSelector((state) => state.volDashBoardReward);
  const userid = localStorage.getItem("userid");


  const headerContent = useMemo(() => {
    if (!profileResponse || !profileResponse?.firstname) return '';
    return isMobile && profileResponse.firstname.length > 10
      ? `${profileResponse.firstname.slice(0, 10)}...`
      : profileResponse.firstname;
  }, [profileResponse, isMobile]);

  const clearStorage = () => {
    localStorage.clear();
    dispatch(userProfileReset());
    dispatch(socialLoginReset());
  };
  const updateNotificationFlag = async () => {
    try {
      let formData = new FormData();
      // formData.append("notifyflag", "true");
      formData.append("flag", "true");

      const res = await axios({
        url: `/notification/flag/${profileResponse?.phoneno}`,
        method: "put",
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      dispatch(userProfile(localStorage.getItem("userid")));
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getVolunteerCamp = async () => {
    try {
      const res = await axios.get(
        `vol_dashboard/camp/${profileResponse?.orgrole}/${profileResponse?.organization}`
      );
      setCampaignList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
  const getMyReward = async () => {
    try {
      const res = await axios.get(`/vol_dashboard/myreward/${userid}`);
      setRewardDash(res?.data);
      dispatch(volDashBoardReward(res?.data, res?.data?.[0]?.phoneno));
      return res;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (rewarddash && rewarddash.rewardpoint !== undefined) {
      setLoading(false);
    }
  }, [rewarddash]);

  // Memoize the reward points value
  const rewardPoints = useMemo(() => {
    return loading ? 'Loading...' : rewarddash.rewardpoint;
  }, [rewarddash.rewardpoint, loading]);

  const activeNav = (parentMenuName, subMenuName, itemPath) => {
    navigate(itemPath);
    console.log("Label Text====", parentMenuName);
    if (localStorage.getItem('myActiveMenu') !== "") {
      localStorage.removeItem('myActiveMenu');
    }
    if (localStorage.getItem('mySubMenu') !== "") {
      localStorage.removeItem('mySubMenu');
    }
    localStorage.setItem('myActiveMenu', parentMenuName);
    localStorage.setItem('mySubMenu', subMenuName);
    setMenuOpen(parentMenuName);
    setSubMenuOpen(subMenuName);
  };

  const activeSingleMenu = (singleMenuName, itemPath) => {
    navigate(itemPath);
    if (localStorage.getItem('myActiveMenu') !== "") {
      localStorage.removeItem('myActiveMenu');
    }
    if (localStorage.getItem('mySubMenu') !== "") {
      localStorage.removeItem('mySubMenu');
    }
    localStorage.setItem('mySubMenu', singleMenuName);
    setMenuOpen("");
    setSubMenuOpen(singleMenuName);
  }


  useEffect(() => {
    dispatch(userProfile(userid));
    dispatch(getVolunteerCount());
    getVolunteerCamp();
    getMyReward();
    // dispatch(volDashBoardReward());
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
        try {
            const response = await axios.get(`/user/profile/bc/get/${profileResponse?.uniqueuserid}`);
            setBroadcastMessage(response.data); 
        } catch (err) {
            console.log(err.message);
        }
    };

    fetchUserData();
}, [profileResponse?.uniqueuserid]);

  const formatNumber = (num) => {
    if (num >= 1e9) return (num / 1e9).toFixed(1) + 'B';
    if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M';
    if (num >= 1e3) return (num / 1e3).toFixed(1) + 'K';
    return num;
  };

  console.log("broadcastMessage = ", broadcastMessage)
  const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
        setIsVisible(false);
    };
  return (
    <div className="wrappingMenuToggle">
      <div className="nav-top">
        <nav class="navbar-expand-lg navbar-white mobileNavBar">
          <div class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">





              {isVisible  && broadcastMessage && !isEmpty(broadcastMessage) && (
                <ShowBroadcastMessage
                    broadcastmsg={broadcastMessage}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        zIndex: 1000, 
                    }}
                    onClose={handleClose}
                    uniqueuserid = {profileResponse?.uniqueuserid}
                />
            
            )}





              <li class="nav-item dropdown">
                <div className="headerWelcome"
                  style={{
                    marginRight: isMobile ? "25px" : "360px", marginTop: isMobile ? '0px' : '5px',
                    fontSize: isMobile ? 12 : 16, display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: isMobile ? '0px' : '5px',
                    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                    color: '#333',
                  }}
                >
                  <span style={{
                    paddingRight: '2px',
                    color: '#2c3e50',
                    fontFamily: 'Georgia, serif'
                  }}>
                    Welcome
                  </span>
                  <span style={{
                    fontWeight: 'bold',
                    color: '#34495e',
                    fontFamily: 'Segoe UI',
                    whiteSpace: isMobile ? 'nowrap' : 'normal',
                    overflow: isMobile ? 'hidden' : 'visible',
                    textOverflow: isMobile ? 'ellipsis' : 'clip',
                    maxWidth: isMobile ? '78px' : 'none'
                  }}>
                    {headerContent} 
                  </span>
                  <span>{isMobile ? "" :<FaPrayingHands size={25} color="#b8860b" />}</span>
                </div>
              </li>

              <Tooltip title="Home">
                <li
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginRight: "15px",
                  }}
                  className="homeIcon"
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={(e) => activeNav("Campaigns", "Home", "/home")}
                  >
                    <i class="flaticon-home-line"></i>
                  </span>
                </li>
              </Tooltip>
              <Tooltip title="Search">
                <li
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginRight: "15px",
                  }}
                  className="SearchIcon"
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={(e) => activeNav("NoMenu", "NoSubMenu", "/searchpage")}
                  >
                    <SearchIcon />
                  </span>
                </li>
              </Tooltip>


              <li class="nav-item dropdown">
                <div
                  style={{
                    marginRight: "35px",
                    backgroundColor: "rgba(235, 149, 50, 1)",
                    maxWidth: isMobile ? "113px" : "113px",
                    height: "39px",
                    borderRadius: "100px",
                    opacity: "90%",
                    color: "#FFFFFF",
                    fontSize: 15,
                    fontWeight: "bold",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "padding-box",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Tooltip title="Points">
                    <RewardContainer onClick={(e) => activeNav("NoMenu", "NoSubMenu", "/mypoints")} isMobile={isMobile}>
                      <RewardHeaderIcon isMobile={isMobile} >
                        <FaCoins />
                      </RewardHeaderIcon>
                      &nbsp;
                      {loading ? (
                        <LoaderContainer style={{ opacity: loading ? 1 : 0 }}>
                          <Circles
                            height="18"
                            width="18"
                            color="#FFFFFF"
                            ariaLabel="circles-loading"
                          />
                        </LoaderContainer>
                      ) : (
                        <RewardPoints style={{ opacity: loading ? 0 : 1 }}>
                          {formatNumber(rewardPoints)}
                        </RewardPoints>
                      )}
                    </RewardContainer>
                  </Tooltip>
                </div>
              </li>


              <li class="nav-item dropdown ">
                <a
                  href="#"
                  class="nav-link dropdown-toggle icon-clr"
                  data-toggle="dropdown"
                >
                  <Tooltip title="Notifications">
                    <i
                      class="fa fa-bell fa-lg"
                      style={{ color: "gray" }}
                      onClick={() =>
                        !props.notificationShow && updateNotificationFlag()
                      }
                    ></i>

                    <Badge
                      badgeContent={profileResponse?.notificationcount}
                      color="error"
                      sx={{
                        "& .MuiBadge-badge": {
                          backgroundColor: "#D32F2F",
                          color: "#fff",
                        },
                      }}

                      onClick={() =>
                        !props.notificationShow && updateNotificationFlag()
                      }
                    ></Badge>
                  </Tooltip>
                </a>

                <div class="dropdown-menu dropdown-menu-right">
                  <div
                    class="notificationWrapper"
                    style={{
                      position: "absolute",
                      width: 330,
                      height: "50vh",
                      right: "0%",
                      top: 0,
                      boxShadow: "1px 2px 10px rgba(0,0,0,0.2)",
                      borderRadius: 8,
                      overflow: "scroll",
                      background: "#fff",
                    }}
                  >
                    <div
                      class="messageHeader"
                      style={{
                        textTransform: "capitalize",
                        float: "left",
                        width: "330px",
                        backgroundColor: "#00399a",
                        color: "#fff",
                        fontSize: "18px",
                        padding: "10px",
                        position: "fixed",
                      }}
                    >
                      Alerts center
                    </div>
                    <div style={{ marginTop: "48px" }}>
                      {_.isEmpty(profileResponse?.notification) ? (
                        <div>
                          <span
                            style={{
                              background: "#fff",
                              float: "left",
                              width: "100%",
                              padding: "10px 12px",
                            }}
                          >
                            No notifications yet
                          </span>
                        </div>
                      ) : (
                        profileResponse?.notification?.map((item, index) => (
                          <div>
                            <div
                              class=""
                              style={{
                                background: "#fff",
                                float: "left",
                                width: "100%",
                                padding: "10px 12px",
                                borderBottom: "1px solid #ccc",
                              }}
                            >
                              <div
                                class=""
                                style={{
                                  float: "left",
                                  width: "50px",
                                  marginRight: "10px",
                                }}
                              >
                                <img
                                  src={item.logo}
                                  class="notification-image"

                                />
                              </div>
                              <div
                                class=""
                                style={{
                                  color: "#000",
                                  float: "left",
                                  width: "calc(100% - 60px)",
                                }}

                                onClick={() => {
                                  item.type === "campaign" && item.status === "Active"
                                    ?
                                    activeNav("NoMenu", "NoSubMenu", `/participate/campaign/${item.campid}/${profileResponse?.phoneno}`)
                                    : item.type === "campaign" && item.status === "Upcoming" ?
                                      activeNav("NoMenu", "NoSubMenu", `/participate/campaign/${item.campid}/${profileResponse?.phoneno}`)
                                      : item.type === "campaign" && item.status === "Completed" ?
                                        activeNav("NoMenu", "NoSubMenu", `/home`)
                                        : activeNav("NoMenu", "NoSubMenu", `/mypoints`)
                                }}
                              >
                                <span
                                  style={{
                                    color: "#000",
                                    display: "inline-block",
                                    fontSize: 10,
                                    color: "grey",
                                    display: "block",
                                  }}
                                >
                                  {moment(item?.createdate).fromNow()}
                                </span>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    display: "block",
                                    "&:hover": {
                                      textDecoration: "underline",
                                      color: "blue",
                                    },
                                  }}
                                >
                                  {item?.msg}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        display: "block",
                        marginTop: "48px",
                        marginLeft: '5px',
                        "&:hover": {
                          textDecoration: "underline",
                          color: "blue",
                        },
                      }}
                      onClick={(e) => activeNav("NoMenu", "NoSubMenu", "/notificationarchive")}
                    >
                      <u>See More</u>
                    </div>
                  </div>
                </div>
              </li>
              &nbsp; &nbsp;

              <li class="nav-item dropdown">
                <Tooltip title="Profile">
                  <a
                    href="#"
                    class="nav-link"
                    size="40"
                    data-toggle="dropdown"
                    // title="Profile"
                    className={
                      profileResponse?.profilephoto ? "removePadding" : ""
                    }
                  >
                    {profileResponse?.profilephoto ? (
                      <Avatar
                        src={
                          image
                            ? URL.createObjectURL(image)
                            : profileResponse?.profilephoto
                        }
                        style={{
                          width: "50px !important",
                          height: "50px !important",
                          flexDirection: "row",
                        }}
                      />
                    ) : (

                      <Avatar
                        src={<PersonIcon />}
                        style={{
                          backgroundColor: "white", color: "gray", width: "40px !important",
                          height: "40px !important",
                          flexDirection: "row",
                        }}
                      />
                    )}
                  </a>
                </Tooltip>
                <div class="dropdown-menu dropdown-menu-right">
                  <a
                    class="dropdown-item"
                    onClick={() =>
                      activeSingleMenu("Profile", `/newprofile/${profileResponse?.profileurl}`)
                    }
                  >
                    <i class="fa fa-fw fa-user"></i> Profile
                  </a>
                  <a
                    class="dropdown-item"
                    title="Available in future release"
                    disabled="disabled"
                    href="#"
                  >
                    <i class="fa fa-fw fa-gear"></i> Settings
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() =>
                      activeSingleMenu("Contacts", `/contactlist`)
                    }
                  >
                    <i class="fa fa-fw fa-user"></i> Contacts
                  </a>
                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      navigate("/");
                      clearStorage();
                    }}
                  >
                    <i class="fa fa-fw fa-sign-out"></i> Logout
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div class="btns toggleMenu" id="toggleMenuClick">
        <span class="fa fa-bars"></span>
      </div>
    </div>
  );
};

export default TopHeaderScreen1;
