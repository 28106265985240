import * as React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import CampaignIcon from "@mui/icons-material/Campaign";
import CampaignSharpIcon from '@mui/icons-material/CampaignSharp';
import PersonIcon from "@mui/icons-material/Person";
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import CorporateFareSharpIcon from '@mui/icons-material/CorporateFareSharp';
import { CircleBulletIcon } from "../assets/icon/CircleBullet";
import {LogoutOutlined, Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  userProfile,
  userProfileReset,
} from "../redux/action/UserProfileActions";
import { socialLoginReset } from "../redux/action/loginAction";
import { getVolunteerCount } from "../redux/action/volunteerCountAction";
import { Dashboard } from "@mui/icons-material";
import _ from "lodash";
import axios from "axios";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import StoreIcon from '@mui/icons-material/Store';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StarsIcon from '@mui/icons-material/Stars';
import InfoIcon from '@mui/icons-material/Info';
import RedeemIcon from '@mui/icons-material/Redeem';
import GroupsIcon from '@mui/icons-material/Groups';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

function ResponsiveDrawer(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(localStorage.getItem('myActiveMenu') === "" ? 'Campaigns' : localStorage.getItem('myActiveMenu'));
  const [subMenuOpen, setSubMenuOpen] = React.useState(localStorage.getItem('mySubMenu') === "" ? 'Home' : localStorage.getItem('mySubMenu'));
  const [loading, setLoading] = React.useState(false);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const getProfileInfo = async () => {
    try {
      const res = await axios.get(
        `cyaaguser/${localStorage.getItem("userid")}`
      );
      // console.log("===== getrpofle info===");
      localStorage.setItem("userProfileUrl", res.data.profileurl);
      // console.log(res.data.profileurl);
      // setMyCampaignList(res?.data);
      //console.log("hhh",res?.data)

      return res;
      // }
    } catch (err) {
      throw err;
    }
  };


  const menuItems = [

    {
      id: 1,
      label: "Dashboard",
      // path: "/dashboard", 
      icon: <Dashboard />,
      subitems: [
        {
          id: 1,
          name: <item><span style={{ marginLeft: 10 }}>Overall View</span></item>,
          path: "/dashboard",
        },

        // {
        //   id: 2,
        //   name: <item><span style={{ marginLeft: 10 }}>Volunteers on Map</span></item>,
        //   path: "/volmapview",
        // },
        {
          id: 3,
          name: <item><span style={{ marginLeft: 10 }}>Report on Campaign</span></item>,
          path: "/campreport",
        },
        {
          id: 4,
          name: <item><span style={{ marginLeft: 10 }}>Impact of Campaign</span></item>,
          path: "/campaignimpact",
        },
        {
          id: 5,
          name: <item><span style={{ marginLeft: 10 }}>Report on PF Rewards</span></item>,
          path: "/pfrewardsreport",
        },
        {
          id: 6,
          name: <item><span style={{ marginLeft: 10 }}>Track Sponsor</span></item>,
          path: "/sponsorreport",
        },
      ],
    },
    {
      id: 2,
      label: "Organizations",
      // icon: <OrgIcon />,
      // icon: <CorporateFareTwoToneIcon />,
      icon: <CorporateFareSharpIcon />,
      subitems: [
        {
          id: 1,
          name: <item><span style={{ marginLeft: 10 }}>Home</span></item>,
          path: "/organizationlist",
        },
        {
          id: 2,
          name: <item><span style={{ marginLeft: 10 }}>Onboard Organization</span></item>,
          path: "/createorganization",
        },
        {
          id: 3,
          name: <item><span style={{ marginLeft: 10 }}>Manage Organizations</span></item>,
          path: "/revieworganization",
        },
      ],
    },
    {
      id: 3,
      label: "Sponsors",
      // icon: <OrgIcon />,
      // icon: <CorporateFareTwoToneIcon />,
      icon: <AccountBalanceIcon />,
      subitems: [
        {
          id: 1,
          name: <item><span style={{ marginLeft: 10 }}>Home</span></item>,
          path: "/sponsorlist",
        },
        {
          id: 2,
          name: <item><span style={{ marginLeft: 10 }}>Onboard Sponsor</span></item>,
          path: "/createsponsor",
        },
        {
          id: 3,
          name: <item><span style={{ marginLeft: 10 }}>Manage Sponsors</span></item>,
          path: "/reviewsponsor",
        },
      ],
    },
    {
      id: 4,
      label: "Users",
      // icon: <VolIcon />,
      //icon: <PersonIcon /> ,
      // icon: <PeopleTwoToneIcon />,
      icon: <PeopleSharpIcon />,
      subitems: [
        // {
        //   id: 1,
        //   name: <item><CircleBulletIcon/><span style={{marginLeft: 10}}>List</span></item>,
        //   path: "/volunteerlist",
        // },
        {
          id: 1,
          name: <item><span style={{ marginLeft: 10 }}>Onboard Users</span></item>,
          path: "/onboardvolunteer",
        },
        {
          id: 2,
          name: <item><span style={{ marginLeft: 10 }}>Manage Users</span></item>,
          path: "/reviewvolunteer",
        },
        {
          id: 3,
          name: <item><span style={{ marginLeft: 10 }}>Review User Onboarding</span></item>,
          path: "/reviewcm",
        },
        {
          id: 4,
          name: <item><span style={{ marginLeft: 10 }}>Performance Report</span></item>,
          path: "/userperfreport",
        },
      ],
    },
    {
      id: 5,
      label: "Campaigns",
      // icon: <CampIcon />,
      // icon: <CampaignTwoToneIcon />,
      icon: <CampaignSharpIcon />,
      subitems: [

        {
          id: 1,
          name: <item><span style={{ marginLeft: 10 }}>Home</span></item>,
          path: "/home",
        },
        // {
        //   id: 2,
        //   name: <item><span style={{ marginLeft: 10 }}>Create Campaigns</span></item>,
        //   path: "/createcampaign",
        // },
        {
          id: 3,
          name: <item><span style={{ marginLeft: 10 }}>Manage Campaigns</span></item>,
          path: "/reviewcampaign",
        },
        {
          id: 4,
          name: <item><span style={{ marginLeft: 10 }}>Registration Status</span></item>,
          path: "/campaignregisterstatus",
        },
        {
          id: 5,
          name: <item><span style={{ marginLeft: 10 }}>Campaign Gallery</span></item>,
          path: "/myongoingcampaigns",
        },
        {
          id: 6,
          name: <item><span style={{ marginLeft: 10 }}>Campaign Documents</span></item>,
          path: "/myongoingcampaignspvt",
        },
        {
          id: 7,
          name: <item><span style={{ marginLeft: 10 }}>Landing Camp Order</span></item>,
          path: "/managelandingcamporder",
        },
      ],
    },
    {
      id: 4,
      label: "Teams",
      //icon: <VolIcon />,
      icon: <GroupsIcon />,

      subitems: [
        {
          id: 1,
          name: <item><span style={{ marginLeft: 10 }}>About Teams</span></item>,
          path: "/about/team",
        },
        {
          id: 2,
          name: <item><span style={{ marginLeft: 10 }}>Create Teams</span></item>,
          path: "/create/team",
        },
        {
          id: 3,
          name: <item><span style={{ marginLeft: 10 }}>Your Teams</span></item>,
          path: "/my/team",
        },
        {
          id: 4,
          name: <item><span style={{ marginLeft: 10 }}>Join Teams</span></item>,
          path: "/join/team",
        },

      ],
    },
    {
      id: 6,
      label: "Marketplace",
      path: "/marketplace",
      icon: <StoreIcon />
    },
    {
      id: 6,
      label: "Redeem Reward",
      icon: <RedeemIcon />,
      subitems: [
        {
          id: 1,
          name: "Manage Redeem",
          path: "/redeemed",
        },
        {
          id: 2,
          name: "Redeem Status",
          path: "/redeemreport",
        },
      ],
    },
    {
      id: 7,
      label: "Rewards Admin",
      path: "/addrewards",
      icon: <StarsIcon />,
      subitems: [
        {
          id: 1,
          name: <item><span style={{ marginLeft: 10 }}>Onboard Rewards</span></item>,
          path: "/onboardrewards",
        },
        {
          id: 2,
          name: <item><span style={{ marginLeft: 10 }}>Onboard Recognitions</span></item>,
          path: "/onboardrecognitions",
        },
        {
          id: 3,
          name: <item><span style={{ marginLeft: 10 }}>Add Campaign Rewards</span></item>,
          path: "/addcamprewards",
        },
        {
          id: 4,
          name: <item><span style={{ marginLeft: 10 }}>Add Platform Rewards</span></item>,
          path: "/addpfrewards",
        },
        {
          id: 5,
          name: <item><span style={{ marginLeft: 10 }}>Add Campaign Recognitions</span></item>,
          path: "/addcamprecog",
        },
        {
          id: 6,
          name: <item><span style={{ marginLeft: 10 }}>Add Platform Recognitions</span></item>,
          path: "/addpfrecog",
        },
        {
          id: 7,
          name: <item><span style={{ marginLeft: 10 }}>Manage Rewards</span></item>,
          path: "/reviewrewards",
        },
        {
          id: 8,
          name: <item><span style={{ marginLeft: 10 }}>Report</span></item>,
          path: "/rewardsreport",
        },

      ],
    },
    {
      id: 8,
      label: "Announcements",
      path: "/announcement",
      icon: <NotificationImportantIcon />
    },

    {
      id: 9,
      label: "Profile",
      path: `/newprofile/${localStorage.getItem("userProfileUrl")}`,
      // path: `/newprofile/${localStorage.getItem("userid")}`,
      icon: <PersonIcon />
    },

    {
      id: 10,
      label: "About Us",
      path: "/aboutus",
      icon: <InfoIcon />
    },
    {
      id: 11,
      label: "Support",
      path: "/contactus",
      icon: <InfoIcon />
    },

    {
      id: 12,
      label: "Logout",
      path: "/",
      icon: <LogoutOutlined />
    },
    // { 
    //   id: 7, 
    //   label: "Form Testing", 
    //   path: "/formtest", 
    //   icon: <EmojiEventsIcon /> 
    // },
  ];
  // This is for SO Admin
  const adminMenu = [
    {
      id: 1,
      label: "Dashboard",
      // path: "/SOAdashboard", 
      icon: <Dashboard />,
      subitems: [
        {
          id: 1,
          name: "Overall View",
          path: "/SOAdashboard",
          // path: "/pdf",
        },
        // {
        //   id: 2,
        //   name: <item><span style={{ marginLeft: 10 }}>Campaign on Map</span></item>,
        //   path: "/campmapview",
        // },
        // {
        //   id: 2,
        //   name: <item><span style={{ marginLeft: 10 }}>Volunteers on Map</span></item>,
        //   path: "/volmapview",
        // },
        {
          id: 2,
          name: "Report on Campaign",
          path: "/campreport",
        },
        {
          id: 3,
          name: "Impact of Campaign",
          path: "/campaignimpact",
        },

      ],
    },
    {

      id: 2,
      label: "Organizations",
      // icon: <OrgIcon />,
      // icon: <CorporateFareTwoToneIcon />,
      icon: <CorporateFareSharpIcon />,
      subitems: [
        {
          id: 1,
          //name: <item><CircleBulletIcon /><span style={{ marginLeft: 10 }}>List</span></item>,
          name: "Organization Profile",
          path: "/organizationlist",
        },
        {

          id: 2,
          name: "Manage Camp Order",
          path: "/managecamporder",
        },
        {

          id: 3,
          //name: <item><CircleBulletIcon /><span style={{ marginLeft: 10 }}>List</span></item>,
          name: "Campaign Subscription",
          path: "/subscriptionlist",

        },
        // {

        //   id: 4,
        //   //name: <item><CircleBulletIcon /><span style={{ marginLeft: 10 }}>List</span></item>,
        //   name: "Broadcast",
        //   path: "/broadcastmsg",

        // },
      ],
    },
    {
      id: 3,
      label: "Users",
      //icon: <VolIcon />,
      icon: <PersonIcon />,
      // icon: <PeopleTwoToneIcon />,
      icon: <PeopleSharpIcon />,
      subitems: [
        {
          id: 1,
          name: "Onboard Users",
          path: "/onboardvolunteer",
        },
        {
          id: 2,
          name: "Manage Users",
          path: "/reviewvolunteer",
        },
        {
          id: 3,
          name: "Review User Onboarding",
          path: "/reviewcm",
        },
        {
          id: 4,
          name: "Performance Report",
          path: "/userperfreport",
        },
      ],
    },
    {
      id: 4,
      label: "Teams",
      //icon: <VolIcon />,
      icon: <GroupsIcon />,

      subitems: [
        {
          id: 1,
          name: "About Teams",
          path: "/about/team",
        },
        {
          id: 1,
          name: "Create Teams",
          path: "/create/team",
        },
        {
          id: 2,
          name: "Your Teams",
          path: "/my/team",
        },
        {
          id: 3,
          name: "Join Teams",
          path: "/join/team",
        },

      ],
    },
    {
      id: 5,
      label: "Campaigns",
      icon: <CampaignSharpIcon />,
      subitems: [
        {
          id: 1,
          name: "Home",
          path: "/home",
        },
        {
          id: 2,
          name: "Create Campaigns",
          path: "/createcampaign",
        },
        {
          id: 3,
          name: "Design Form",
          path: "/regform",
        },
        {
          id: 4,
          name: "Your Org Campaigns",
          path: "/mycampaignview",
        },
        {
          id: 5,
          name: "Review Registrations",
          path: "/reviewcampaignregistration",
        },
        {
          id: 6,
          name: "Registration Status",
          path: "/campaignregisterstatus",
        },
        {
          id: 7,
          name: "Campaign Gallery",
          path: "/myongoingcampaigns",
        },
        {
          id: 8,
          name: "Campaign Documents",
          path: "/myongoingcampaignspvt",
        },
      ],
    },

    {
      id: 6,
      label: "Marketplace",
      path: "/marketplace",
      icon: <StoreIcon />
    },

    {
      id: 7,
      label: "Redeem Reward",
      icon: <RedeemIcon />,
      subitems: [
        {
          id: 1,
          name: "Manage Redeem",
          path: "/redeemed",
        },
        {
          id: 2,
          name: "Redeem Status",
          path: "/redeemreport",
        },
      ],
    },
    {
      id: 8,
      label: "Rewards Admin",
      path: "/addrewards",
      icon: <StarsIcon />,
      subitems: [

        {
          id: 1,
          name: "Add Campaign Rewards",
          path: "/addcamprewards",
        },

        {
          id: 2,
          name: "Add Campaign Recognitions",
          path: "/addcamprecog",
        },

        {
          id: 3,
          name: "Manage Rewards",
          path: "/reviewrewards",
        },
        {
          id: 4,
          name: "Report",
          path: "/rewardsreport",
        },

      ],
    },
    {
      id: 9,
      label: "Announcements",
      icon: <NotificationImportantIcon />,
      subitems: [
        {
          id: 1,
          name: "Create New",
          path: "/broadcastmsg",
        },
        {
          id: 2,
          name: "History",
          path: "/announcement",
        }
        
      ],
    },
    {
      id: 10,
      label: "Profile",
      path: `/newprofile/${localStorage.getItem("userProfileUrl")}`,
      // path: `/newprofile/${localStorage.getItem("userid")}`,
      icon: <PersonIcon />
    },
    // { 
    //   id: 7, 
    //   label: "NewProfile", 
    //   path: "/newprofile",
    //   icon: <PersonIcon /> 
    // },

    {
      id: 11,
      label: "About Us",
      path: "/aboutus",
      icon: <InfoIcon />
    },
    {
      id: 12,
      label: "Support",
      path: "/contactus",
      icon: <InfoIcon />
    },

    {
      id: 13,
      label: "Logout",
      path: "/",
      icon: <Logout />
    },
    // { 
    //   id: 7, 
    //   label: "Rewards", 
    //   path: "/mypoints", 
    //   icon: <EmojiEventsIcon /> 
    // },
  ];

  const volMenu = [
    {
      id: 1,
      label: "Campaigns",
      icon: <CampaignIcon />,
      subitems: [

        {
          id: 1,
          name: <item><span style={{ marginLeft: 10 }}>Home</span></item>,
          
          // path: "/volunteer",
          path: "/home",
        },

        {
          id: 2,
          name: <item><span style={{ marginLeft: 10 }}>Registration Status</span></item>,
          path: "/campaignregisterstatus",
        },
        {
          id: 3,
          name: <item><span style={{ marginLeft: 10 }}>Campaign Gallery</span></item>,
          path: "/myongoingcampaigns",
        },
        {
          id: 3,
          name: <item><span style={{ marginLeft: 10 }}>Campaign Documents</span></item>,
          path: "/myongoingcampaignspvt",
        },

      ],
    },
    {
      id: 2,
      label: "Teams",
      //icon: <VolIcon />,
      icon: <GroupsIcon />,

      subitems: [
        {
          id: 1,
          name: <item><span>About Teams</span></item>,
          path: "/about/team",
        },
        {
          id: 1,
          name: <item><span>Create Teams</span></item>,
          path: "/create/team",
        },
        {
          id: 2,
          name: <item><span>Your Teams</span></item>,
          path: "/my/team",
        },
        {
          id: 3,
          name: <item><span>Join Teams</span></item>,
          path: "/join/team",
        },

      ],
    },
    {
      id: 3,
      label: "Rewards",
      //icon: <VolIcon />,
      icon: <EmojiEventsIcon />,

      subitems: [
        {
          id: 1,
          name: <item><span style={{ marginLeft: 10 }}>Your Rewards</span></item>,
          path: "/mypoints",
        },
        {
          id: 2,
          name: <item><span style={{ marginLeft: 10 }}>Redeem Status</span></item>,
          path: "/redeemreport",
        },

      ],
    },
    {
      id: 4,
      label: "Marketplace",
      path: "/marketplace",
      icon: <StoreIcon />
    },
    {
      id: 4,
      label: "Announcements",
      path: "/announcement",
      icon: <NotificationImportantIcon />
    },

    {
      id: 5,
      label: "Profile",
      path: `/newprofile/${localStorage.getItem("userProfileUrl")}`,
      // path: `/newprofile/${localStorage.getItem("userid")}`,
      icon: <PersonIcon />
    },
    {
      id: 6,
      label: "About Us",
      path: "/aboutus",
      icon: <InfoIcon />
    },
    {
      id: 7,
      label: "Support",
      path: "/contactus",
      icon: <InfoIcon />
    },

    {
      id: 8,
      label: "Logout",
      path: "/",
      icon: <Logout />
    },
  ];

  const sponsorAdminMenu = [
    {
      id: 1,
      label: "Dashboard",
      // path: "/SOAdashboard", 
      icon: <Dashboard />,
      subitems: [
        {
          id: 1,
          name: <item><span style={{ marginLeft: 10 }}>Overall View</span></item>,
          path: "/sponsoradmindashboard",
          // path: "/pdf",
        },
        {
          id: 2,
          name: <item><span style={{ marginLeft: 10 }}>Campaign Budget Report</span></item>,
          path: "/sponsoradminreport",
          // path: "/pdf",
        },
      ],
    },
    {
      id: 1,
      label: "Campaigns",
      icon: <CampaignIcon />,
      subitems: [

        {
          id: 1,
          name: <item><CircleBulletIcon /><span style={{ marginLeft: 10 }}>Home</span></item>,
          // path: "/volunteer",
          path: "/home",
        },

        {
          id: 2,
          name: <item><CircleBulletIcon /><span style={{ marginLeft: 10 }}>Participation Status</span></item>,
          path: "/campaignregisterstatus",
        },
        {
          id: 3,
          name: <item><CircleBulletIcon /><span style={{ marginLeft: 10 }}>Campaign Gallery</span></item>,
          path: "/myongoingcampaigns",
        },
        {
          id: 3,
          name: <item><CircleBulletIcon /><span style={{ marginLeft: 10 }}>Campaign Documents</span></item>,
          path: "/myongoingcampaignspvt",
        },

      ],
    },
    {
      id: 4,
      label: "Teams",
      //icon: <VolIcon />,
      icon: <GroupsIcon />,

      subitems: [
        {
          id: 1,
          name: <item><span >About Teams</span></item>,
          path: "/about/team",
        },
        {
          id: 1,
          name: <item><span>Create Teams</span></item>,
          path: "/create/team",
        },
        {
          id: 2,
          name: <item><span>Your Teams</span></item>,
          path: "/my/team",
        },
        {
          id: 13,
          name: <item><span>Join Teams</span></item>,
          path: "/join/team",
        },

      ],
    },
    {
      id: 2,
      label: "Rewards",
      path: "/mypoints",
      icon: <EmojiEventsIcon />
    },

    {
      id: 3,
      label: "Marketplace",
      path: "/marketplace",
      icon: <StoreIcon />
    },
    {
      id: 4,
      label: "Announcements",
      path: "/announcement",
      icon: <NotificationImportantIcon />
    },

    {
      id: 4,
      label: "Profile",
      path: `/newprofile/${localStorage.getItem("userProfileUrl")}`,
      // path: `/newprofile/${localStorage.getItem("userid")}`,
      icon: <PersonIcon />
    },
    {
      id: 5,
      label: "About Us",
      path: "/aboutus",
      icon: <InfoIcon />
    },
    {
      id: 6,
      label: "Support",
      path: "/contactus",
      icon: <InfoIcon />
    },

    {
      id: 6,
      label: "Logout",
      path: "/",
      icon: <Logout />
    },
  ];

  const campAdminMenu = [
    {
      id: 1,
      label: "Dashboard",
      // path: "/SOAdashboard", 
      icon: <Dashboard />,
      subitems: [
        {
          id: 1,
          name: <item><span style={{ marginLeft: 10 }}>Overall View</span></item>,
          path: "/SOAdashboard",
          // path: "/pdf",
        },
        // {
        //   id: 1,
        //   name: <item><span style={{ marginLeft: 10 }}>Overall View</span></item>,
        //   path: "/SOAdashboard",
        //   // path: "/pdf",
        // },
        {
          id: 2,
          name: <item><span style={{ marginLeft: 10 }}>Report on Campaign</span></item>,
          path: "/campownerreport",
          // path: "/pdf",
        },
      ],
    },
    {

      id: 2,
      label: "Organizations",
      // icon: <OrgIcon />,
      // icon: <CorporateFareTwoToneIcon />,
      icon: <CorporateFareSharpIcon />,
      subitems: [
        {
          id: 1,
          //name: <item><CircleBulletIcon /><span style={{ marginLeft: 10 }}>List</span></item>,
          name: "Organization Profile",
          path: "/organizationlist",
        },
      ],
    },
    {
      id: 3,
      label: "Users",
      //icon: <VolIcon />,
      icon: <PersonIcon />,
      // icon: <PeopleTwoToneIcon />,
      icon: <PeopleSharpIcon />,
      subitems: [
        {
          id: 1,
          name: "Onboard Users",
          path: "/onboardvolunteer",
        },
        // {
        //   id: 2,
        //   name: "Manage Users",
        //   path: "/reviewvolunteer",
        // },
        {
          id: 3,
          name: "Review User Onboarding",
          path: "/reviewcm",
        },
        // {
        //   id: 4,
        //   name: "Performance Report",
        //   path: "/userperfreport",
        // },
      ],
    },
    {
      id: 3,
      label: "Teams",
      //icon: <VolIcon />,
      icon: <GroupsIcon />,

      subitems: [
        {
          id: 1,
          name: <item><span>About Teams</span></item>,
          path: "/about/team",
        },
        {
          id: 1,
          name: <item><span>Create Teams</span></item>,
          path: "/create/team",
        },
        {
          id: 2,
          name: <item><span>Your Teams</span></item>,
          path: "/my/team",
        },
        {
          id: 13,
          name: <item><span>Join Teams</span></item>,
          path: "/join/team",
        },

      ],
    },
    {
      id: 4,
      label: "Campaigns",
      icon: <CampaignSharpIcon />,
      subitems: [
        {
          id: 1,
          name: "Home",
          path: "/home",
        },
        {
          id: 2,
          name: "Create Campaigns",
          path: "/createcampaign",
        },
        {
          id: 3,
          name: "Design Form",
          path: "/regform",
        },
        {
          id: 4,
          name: "Your Org Campaigns",
          path: "/mycampaignview",
        },
        {
          id: 5,
          name: "Review Registrations",
          path: "/reviewcampaignregistration",
        },
        {
          id: 6,
          name: "Registration Status",
          path: "/campaignregisterstatus",
        },
        {
          id: 7,
          name: "Campaign Gallery",
          path: "/myongoingcampaigns",
        },
        {
          id: 8,
          name: "Campaign Documents",
          path: "/myongoingcampaignspvt",
        },
        // {
        //   id: 9,
        //   name: "Report on Rewards",
        //   path: "/rewardsreport",
        // },
      ],
    },
    {
      id: 5,
      label: "Marketplace",
      path: "/marketplace",
      icon: <StoreIcon />
    },
    
    {
      id: 6,
      label: "Redeem Reward",
      //icon: <VolIcon />,
      icon: <RedeemIcon />,

      subitems: [
        {
          id: 2,
          name: "Redeem Status",
          path: "/redeemreport",
        },

      ],
    },
    // { 
    //   id: 7, 
    //   label: "Rewards Admin", 
    //   path: "/addrewards", 
    //   icon: <StarsIcon />,
    //   subitems: [

    //     {
    //       id: 1,
    //       name: <item><span style={{ marginLeft: 10 }}>Add Campaign Rewards</span></item>,
    //       path: "/addcamprewards",
    //     },

    //     {
    //       id: 2,
    //       name: <item><span style={{ marginLeft: 10 }}>Add Campaign Recognitions</span></item>,
    //       path: "/addcamprecog",
    //     },

    //     {
    //       id: 3,
    //       name: <item><span style={{ marginLeft: 10 }}>Manage Rewards</span></item>,
    //       path: "/reviewrewards",
    //     },
    //     {
    //       id: 4,
    //       name: <item><span style={{ marginLeft: 10 }}>Report</span></item>,
    //       path: "/rewardsreport",
    //     },

    //   ],
    // },

    {
      id: 7,
      label: "Announcements",
      path: "/announcement",
      icon: <NotificationImportantIcon />
    },
    {
      id: 8,
      label: "Profile",
      path: `/newprofile/${localStorage.getItem("userProfileUrl")}`,
      // path: `/newprofile/${localStorage.getItem("userid")}`,
      icon: <PersonIcon />
    },
    {
      id: 9,
      label: "About Us",
      path: "/aboutus",
      icon: <InfoIcon />
    },
    {
      id: 10,
      label: "Support",
      path: "/contactus",
      icon: <InfoIcon />
    },

    {
      id: 11,
      label: "Logout",
      path: "/",
      icon: <Logout />
    },

  ];



  const handleClick = event => {
    event.currentTarget.classList.toggle('active');
    event.currentTarget.parentNode.classList.toggle('acitveParent');
  };

  const activeSingleMenu = (singleMenuName, itemPath) => {
    navigate(itemPath);
    if (localStorage.getItem('myActiveMenu') !== "") {
      localStorage.removeItem('myActiveMenu');
    }
    if (localStorage.getItem('mySubMenu') !== "") {
      localStorage.removeItem('mySubMenu');
    }
    localStorage.setItem('mySubMenu', singleMenuName);
    setMenuOpen("");
    setSubMenuOpen(singleMenuName);
  }

  const activeNav = (parentMenuName, subMenuName, itemPath) => {
    // Prevent the default behavior of the anchor element
    navigate(itemPath);
    console.log("Parent Label Text====", parentMenuName);
    console.log("Submenu Label Text====", subMenuName);
    console.log("Itempath Text====", itemPath);
    // console.log(subMenuName);
    // if (typeof subMenuName === 'object') {
    //   console.log("It is object",subMenuName);
    //   console.log("==========Kumaran=======",subMenuName.props.children.props.children);
    //   subMenuName = subMenuName.props.children.props.children;
    // } else {
    //   console.log("It is not object",subMenuName);
    // }
    if (localStorage.getItem('myActiveMenu') !== "") {
      localStorage.removeItem('myActiveMenu');
    }
    if (localStorage.getItem('mySubMenu') !== "") {
      localStorage.removeItem('mySubMenu');
      // console.log("Submenu Inside====", subMenuName);
    }
    // console.log("Submenu outside====", subMenuName);
    localStorage.setItem('myActiveMenu', parentMenuName);
    localStorage.setItem('mySubMenu', subMenuName);
    setMenuOpen(parentMenuName);
    setSubMenuOpen(subMenuName);
  };

  const {
    isSuccess: profileImageSuccess,
    response: profileImageResponse,
    isError: profileImageError,
  } = useSelector((state) => state.profileImage);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const clearStorage = () => {
    localStorage.clear();
    dispatch(userProfileReset());
    dispatch(socialLoginReset());
  };


  React.useEffect(() => {
    dispatch(getVolunteerCount());
  }, []);

  React.useEffect(() => {
    if (profileImageSuccess || profileImageError) {
      setLoading(false);
    }
  }, [profileImageSuccess, profileImageError]);

  React.useEffect(() => {

    dispatch(userProfile(localStorage.getItem("userid")));
  }, []);

  React.useEffect(() => {
    Promise.all([getProfileInfo()])
  })


  const iconMenu = (iconLabel) => {
    if (iconLabel === 'Dashboard') {
      return <Dashboard />
    }
    else if (iconLabel === 'Organizations') {
      // return  <OrgIcon />
      // return <CorporateFareTwoToneIcon />
      return <CorporateFareSharpIcon />

    }
    else if (iconLabel === 'Sponsors') {
      // return  <OrgIcon />
      // return <CorporateFareTwoToneIcon />
      return <AccountBalanceIcon />

    }
    else if (iconLabel === 'Users') {
      // return <VolIcon />
      // return <PeopleTwoToneIcon />
      return <PeopleSharpIcon />
    }
    else if (iconLabel === 'Campaigns') {
      // return  <CampIcon />
      // return <CampaignTwoToneIcon />
      return <CampaignSharpIcon />
    }
    else if (iconLabel === 'Marketplace') {
      // return  <CampIcon />
      // return <CampaignTwoToneIcon />
      return <StoreIcon />
    }
    else if (iconLabel === 'Redeem Reward') {
      // return  <CampIcon />
      // return <CampaignTwoToneIcon />
      return <RedeemIcon />
    }

    else if (iconLabel === 'Rewards Admin') {
      // return  <CampIcon />
      // return <CampaignTwoToneIcon />
      return <StarsIcon />
    }
    else if (iconLabel === 'Profile') {
      return <PersonIcon />
    }
    else if (iconLabel === 'Support') {
      return <ContactSupportIcon />
    }
    else if (iconLabel === 'Rewards') {
      return <EmojiEventsIcon />
    }
    else if (iconLabel === 'Teams') {
      return <GroupsIcon />
    }
    else if (iconLabel === 'About Us') {
      return <InfoIcon />
    }
    else if (iconLabel === 'Announcements') {
      return <NotificationImportantIcon />
    }

    else {
      return <Logout />
    }
  };
  const drawer = (
    <List className="sidebar-menu">
      {profileResponse?.orgrole?.toLowerCase() === "cyaagadmin"
        ? menuItems.map((text, index) => (
          <li
            className={`${menuOpen === text.label ? 'acitveParent' : ''}`}
            key={text}
            disablePadding
          >
            <a name={text.path} style={{ cursor: "pointer" }} className={`feat-btn ${subMenuOpen === text.label && !text.hasOwnProperty('subitems') ? 'acitveChild' : ''}`} onClick={text.hasOwnProperty('subitems') ? handleClick : () => { activeSingleMenu(text.label, text.path); text.label === "Logout" && clearStorage(); }} ><i> {iconMenu(text.label)}</i>
              {text.label}<span style={{ display: text.hasOwnProperty('subitems') ? "block" : "none" }} class="fa fa-angle-up "></span></a>

            <ul className="feat-show">
              {text.subitems?.map((item, index) => {
                return (
                  <li style={{ cursor: "pointer" }} className={`${subMenuOpen === item.name ? 'acitveChild' : ''}`}>
                    <a name={item.path} onClick={(e) => activeNav(text.label, item.name, item.path)}><i class="fa fa-circle-o list-dic" aria-hidden="true"></i>{item.name}</a>

                  </li>
                );
              })}
            </ul>
          </li>
        ))
        : profileResponse?.orgrole?.toLowerCase() === "admin" || profileResponse?.orgrole?.toLowerCase() === "Admin"
          ? adminMenu.map((text, index) => (
            <li
              className={`${menuOpen === text.label ? 'acitveParent' : ''}`}
              key={text}
              disablePadding
            >
              <a name={text.path} style={{ cursor: "pointer" }} className={`feat-btn ${subMenuOpen === text.label && !text.hasOwnProperty('subitems') ? 'acitveChild' : ''}`} onClick={text.hasOwnProperty('subitems') ? handleClick : () => { activeSingleMenu(text.label, text.path); text.label === "Logout" && clearStorage(); }} ><i> {iconMenu(text.label)}</i>

                {text.label}<span style={{ display: text.hasOwnProperty('subitems') ? "block" : "none" }} class="fa fa-angle-up "></span></a>

              <ul className="feat-show">
                {text.subitems?.map((item, index) => {
                  return (
                    <li style={{ cursor: "pointer" }} className={`${subMenuOpen === item.name ? 'acitveChild' : ''}`}>
                      <a name={item.path} onClick={(e) => activeNav(text.label, item.name, item.path)}><i class="fa fa-circle-o list-dic" aria-hidden="true"></i>{item.name}</a>
                    </li>
                  );
                })}
              </ul>
            </li>
          )) : profileResponse?.orgrole?.toLowerCase() === "sponsoradmin" || profileResponse?.orgrole?.toLowerCase() === "sponsoradmin"
            ? sponsorAdminMenu.map((text, index) => (
              <li
                className={`${menuOpen === text.label ? 'acitveParent' : ''}`}
                key={text}
                disablePadding
              >
                <a name={text.path} style={{ cursor: "pointer" }} className={`feat-btn ${subMenuOpen === text.label && !text.hasOwnProperty('subitems') ? 'acitveChild' : ''}`} onClick={text.hasOwnProperty('subitems') ? handleClick : () => { activeSingleMenu(text.label, text.path); text.label === "Logout" && clearStorage(); }} ><i> {iconMenu(text.label)}</i>
                  {text.label}<span style={{ display: text.hasOwnProperty('subitems') ? "block" : "none" }} class="fa fa-angle-up "></span></a>
                <ul className="feat-show">
                  {text.subitems?.map((item, index) => {
                    return (
                      <li style={{ cursor: "pointer" }} className={`${subMenuOpen === item.name ? 'acitveChild' : ''}`}>
                        <a name={item.path} onClick={(e) => activeNav(text.label, item.name, item.path)}><i class="fa fa-circle-o list-dic" aria-hidden="true"></i>{item.name}</a>
                      </li>
                    );
                  })}
                </ul>
              </li>
            )) : profileResponse?.orgrole?.toLowerCase() === "campadmin" || profileResponse?.orgrole?.toLowerCase() === "campadmin"
              ? campAdminMenu.map((text, index) => (
                <li
                  className={`${menuOpen === text.label ? 'acitveParent' : ''}`}
                  key={text}
                  disablePadding
                >
                  <a name={text.path} style={{ cursor: "pointer" }} className={`feat-btn ${subMenuOpen === text.label && !text.hasOwnProperty('subitems') ? 'acitveChild' : ''}`} onClick={text.hasOwnProperty('subitems') ? handleClick : () => { activeSingleMenu(text.label, text.path); text.label === "Logout" && clearStorage(); }} ><i> {iconMenu(text.label)}</i>
                    {text.label}<span style={{ display: text.hasOwnProperty('subitems') ? "block" : "none" }} class="fa fa-angle-up "></span></a>
                  <ul className="feat-show">
                    {text.subitems?.map((item, index) => {
                      return (
                        <li style={{ cursor: "pointer" }} className={`${subMenuOpen === item.name ? 'acitveChild' : ''}`}>

                          <a name={item.path} onClick={(e) => activeNav(text.label, item.name, item.path)}><i class="fa fa-circle-o list-dic" aria-hidden="true"></i>{item.name}</a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              )) : volMenu.map((text, index) => (
                <li
                  className={`${menuOpen === text.label ? 'acitveParent' : ''}`}
                  key={text}
                  disablePadding
                >
                  <a name={text.path} style={{ cursor: "pointer" }} className={`feat-btn ${subMenuOpen === text.label && !text.hasOwnProperty('subitems') ? 'acitveChild' : ''}`} onClick={text.hasOwnProperty('subitems') ? handleClick : () => { activeSingleMenu(text.label, text.path); text.label === "Logout" && clearStorage(); }} ><i> {iconMenu(text.label)}</i>
                    {text.label}<span style={{ display: text.hasOwnProperty('subitems') ? "block" : "none" }} class="fa fa-angle-up "></span></a>
                  <ul className="feat-show">
                    {text.subitems?.map((item, index) => {
                      return (
                        <li style={{ cursor: "pointer" }} className={`${subMenuOpen === item.name ? 'acitveChild' : ''}`}>
                          <a name={item.path} onClick={(e) => activeNav(text.label, item.name, item.path)}><i class="fa fa-circle-o list-dic" aria-hidden="true"></i>{item.name}</a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ))}
    </List>
  );
  return (
    <nav class="sidebar">
      <div className="text"><img src={profileResponse?.logo} alt="cYAAG" /></div>
      {drawer}
    </nav>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;