
import React, { useState, useRef } from 'react';
import { Box, Button, Card, useMediaQuery, Avatar, Chip, Dialog, DialogTitle, DialogContent, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { Button as MuiButton } from '@mui/material';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { isArray, isEmpty } from "lodash";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import styled, { keyframes } from 'styled-components';
import PopupMarketplace from '../routes/PopupMarketplace';
import CEOImage from "../assets/ceo.png";
import SVGCoin from '../icons/SVGCoin';
import SVGFree from '../icons/SVGFree';
import RedeemConfirmation from "../components/RedeemConfirmation";
import ConfirmationDialog from "../components/ConfirmationDialog";
import PostRedeemConfirmation from "../components/PostRedeemConfirmation";

const StyledCard = styled(Card)(({ theme }) => ({
  width: '200px',
  marginBottom: "30px",
  float: "left",
  // margin: '10px',
  marginLeft: '1px',
  // backgroundColor:'gray'
  flexShrink: 0,
  minHeight:'264px'
}));




const RewardCard = ({
  page,
  marketplacelist
}) => {

  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [displayRewardPopup, setDisplayRewardPopup] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [displayRedeemConfirmationModal, setDisplayRedeemConfirmationModal] =
    useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [redeemindex, setRedeemIndex] = useState(0);

  const handleCardClick = (index) => {
    console.log("Clicked card index:", index);
    setRewardDisplayAndIndex(true, index);
  };

  const setRewardDisplayAndIndex = (isOpen, index) => {
    if (isOpen) {
      setSelectedIndex(index);
      setDisplayRewardPopup(true);
    }
    console.log("Popup state updated: isOpen =", isOpen, "index =", index);
  };

  function setDisplayAndIndex(bool, index) {
    setDisplayRedeemConfirmationModal(bool);
    setRedeemIndex(index);
  }


  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const handleImageLoad = async (img) => {
    const im = await Promise.all(img);
    
    if (im) {
      return true;
    } else {
      return false;
    }
  };



  return (
    <div class="containers">
      <div class="cyagg-sec-space">
        <div>
          <div class="col-md-12">
            <div class="" style={{ float: 'left', width: '100%' }}>
              <div className={page === "marketplace" ? "boxContainerWrapper" : "boxContainerWrapperDesktop"}>
                {marketplacelist &&
                  // marketplacelist.filter((reward, index) => page !== "landing" || index < 5)
                  marketplacelist.map((reward, index) => (

                    <StyledCard
                      key={index}
                      sx={{
                        marginBottom: '16px',
                        marginRight: '16px'
                      }}
                      className="boxContent"
                    >
                      <Box 
                        // onClick={() => handleCardClick(index)} 
                        onClick={() => {
                          if (page !== "landing") {
                            handleCardClick(index);
                          }
                        }}
                        style={{ cursor: page !== "landing" ? 'pointer': 'initial', }}
                      >
                        {
                          !(reward?.logo === "") ? (
                            reward?.expired === "yes" ?
                              <img
                                src={reward?.logo}
                                style={{ width: "100%", opacity: 0.4, maxHeight: '80px' }}
                              />
                              :
                              <img
                                src={reward?.logo}
                                style={{ width: "100%", maxHeight: '80px' }}
                              />
                          ) : (
                            reward?.expired === "yes" ?
                              <img
                                src={CEOImage}
                                style={{ width: "100%", opacity: 0.4, maxHeight: '80px' }}
                              />
                              :
                              <img
                                src={CEOImage}
                                style={{ width: "100%", maxHeight: '80px' }}
                              />
                          )
                        }
                      </Box>
                      <Box>
                        <div className="text-center">
                          <div className="row sponserMarketplaceWrapper">
                            {reward?.sponsor?.length > 1 ? (
                              <marquee scrollamount="2">
                                {Array.isArray(reward?.sponsor) &&
                                  reward?.sponsor?.map((reward, index) =>
                                    handleImageLoad(reward.sponsorlogo) ? (
                                      <img
                                        style={{
                                          width: "auto",
                                          height: "30px",
                                          marginRight: "20px",
                                          marginTop: "5px",
                                        }}
                                        src={reward.sponsorlogo}
                                        key={index}
                                        alt="reward"
                                      />
                                    ) : null
                                  )}
                              </marquee>
                            ) : (
                              <div>
                                {Array.isArray(reward?.sponsor) &&
                                  reward?.sponsor?.map((reward, index) =>
                                    handleImageLoad(reward.sponsorlogo) ? (
                                      <img
                                        style={{
                                          width: "auto",
                                          height: "30px",
                                        }}
                                        src={reward.sponsorlogo}
                                        key={index}
                                        alt="reward"
                                      />
                                    ) : null
                                  )}
                              </div>
                            )}
                          </div>
                          <style>
                            {`
                              .marquee {
                                display: inline-block;
                                white-space: nowrap;
                                animation: marquee 10s linear infinite;
                              }

                              @keyframes marquee {
                                0% { transform: translateX(100%); }
                                100% { transform: translateX(-100%); }
                              }
                            `}
                          </style>
                        </div>
                      </Box>

                      <Box display="flex" justifyContent="space-between" mb={1}>
                        {
                          reward?.expired === "yes" ? (
                            <h3 class="expiry-txt" style={{ color: "red" }}>
                              EXPIRED
                            </h3>
                          ) : ""
                        }
                      </Box>
                      <Box display="flex" justifyContent="space-between" mb={1} pl={1}>
                        {
                          <h5 
                            class="mb-0 mt-0 reward-desc-title" 
                            // onClick={() => handleCardClick(index)} 
                            onClick={() => {
                              if (page !== "landing") {
                                handleCardClick(index);
                              }
                            }}
                            style={{ cursor: page !== "landing" ? 'pointer': 'initial', }}
                            
                          >
                            {reward?.rewardtype}
                          </h5>
                        }
                      </Box>
                      <Box display="flex" justifyContent="space-between" mb={1} pl={1}>
                        {
                          <h5 
                            class="mb-0 mt-0 reward-desc-title" 
                            // onClick={() => handleCardClick(index)}
                            onClick={() => {
                              if (page !== "landing") {
                                handleCardClick(index);
                              }
                            }}
                            style={{
                              color: '#808080',
                              fontFamily: "Roboto Serif",
                              fontSize: '12px',
                              fontStyle: 'italic',
                              fontWeight: 400,
                              lineHeight: 'normal',
                              cursor: page !== "landing" ? 'pointer': 'initial',
                            }}
                          >
                            {reward?.title}
                          </h5>
                        }
                      </Box>
                      <Box display="flex" justifyContent="space-between" mb={1} pl={1}>
                        {
                          reward?.campid !== "camp00001" ? (
                            <h5 class="mb-0 mt-0 reward-desc-title"
                              style={{
                                color: '#808080',
                                fontFamily: "Roboto Slab",
                                fontSize: '12px',
                                fontWeight: 400,
                                lineHeight: 'normal',
                                wordWrap: 'break-word',
                                whiteSpace: 'normal'
                              }}
                            >
                              On successful participation in
                              <span
                                style={{
                                  color: '#007BFF',
                                  textDecoration: 'none',
                                  fontWeight: 500,
                                  cursor: page !== "landing" && page!== "participation" ? 'pointer': 'initial',
                                  paddingLeft: '4px'
                                }}
                                onMouseOver={(e) => {
                                  if (page === "marketplace") {
                                    e.target.style.textDecoration = 'underline';
                                  }
                                }}
                                onMouseOut={(e) => {
                                  if (page !== "landing" && page !== "participation") {
                                    e.target.style.textDecoration = 'none';
                                  }
                                }}
                                
                                onClick={() => {
                                  if (page !== "landing" && page !== "participation") {
                                    navigate(`/participate/campaign/${reward?.campid}/${profileResponse?.phoneno}`);
                                  }
                                }}
                              >
                                {reward?.campname}
                              </span>
                            </h5>
                          ) : (
                            <h5 class="mb-0 mt-0 reward-desc-title"
                              style={{
                                color: '#808080',
                                fontFamily: "Roboto Slab",
                                fontSize: '12px',
                                fontWeight: 400,
                                lineHeight: 'normal',
                                wordWrap: 'break-word',
                                whiteSpace: 'normal'
                              }}
                            >
                              On earning the required points, Redeem now

                            </h5>
                          )
                        }
                      </Box>

                      <div class="redeem-pts">
                        <div class="row">
                          <div class="col-6" style={{ display: "inline-flex" }}>
                            {reward?.rewardcat === "rewards" &&
                              reward?.campid === "camp00001" ? (
                              <div class="re-po-left">
                                <SVGCoin style={{ marginTop: '4px' }} />
                                <div class="pts" style={{ marginLeft: "19px" }}>
                                  <h5>{reward?.points}</h5>
                                  <span>Points</span>
                                </div>
                              </div>
                            ) : (
                              <div class="re-po-left">
                                <SVGFree style={{ marginTop: '4px' }} />
                                <div class="pts" style={{ marginLeft: "19px" }}>
                                  <h5>0</h5>
                                  <span>Points</span>
                                </div>
                              </div>
                            )}
                            {
                              page === "marketplace" &&
                              <div>
                                {
                                  ((reward?.campid === "camp00001" &&
                                    reward?.rewardcat === "rewards" && !(reward?.expired === "yes")) || (reward?.registrationstatus ===
                                      "approved" && !(reward?.campid === "camp00001") && !(reward?.expired === "yes") &&
                                      reward?.rewardcat === "rewards") ||
                                    (reward?.registrationstatus === "approved" && !(reward?.campid === "camp00001") && !(reward?.expired === "yes") &&
                                      reward?.rewardcat === "recognitions" && reward?.recogeligibility === "yes")) ? (

                                    <div class="re-po-right" style={{ marginLeft: "17px" }}>
                                      <Button
                                        onClick={() =>
                                          setDisplayAndIndex(
                                            true,
                                            index
                                          )
                                        }
                                        style={{ float: "right" }}
                                      >
                                        <a>Redeem</a>
                                      </Button>
                                    </div>
                                  ) : (
                                    <div class="re-po2-center" style={{ marginLeft: "17px" }}>
                                      <Button
                                        disabled
                                      >
                                        <a>Redeem</a>
                                      </Button>
                                    </div>
                                  )}
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <PopupMarketplace
                        isOpen={displayRewardPopup}
                        setIsOpen={() => setDisplayRewardPopup(false)}
                        reward={marketplacelist[selectedIndex]}
                      />
                    </StyledCard>

                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default RewardCard;
