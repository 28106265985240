import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Box, TextField, Select, MenuItem, Button, TextareaAutosize, InputLabel, FormControl, Typography, Chip, Grid, Autocomplete, Snackbar, Alert } from '@mui/material';
import Multiselect from 'multiselect-react-dropdown';
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import TopHeaderScreen from "../../components/TopHeader1";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { Reorder } from 'framer-motion';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from '@mui/material/Alert';
import RedStar from "../../components/RedStar";
import styled from 'styled-components';

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
})); 

const FormGroup0 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#F0F8FF',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// Styled component for formGroup00
const FormGroup00 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#FAEBD7',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'block',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: '16px',
    '@media (min-width: 600px)': {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
});

const Item = styled(Box)({
    flex: '1 1 48%',
    '@media (max-width: 600px)': {
        flex: '1 1 100%',
    },
});

const FullWidthItem = styled(Box)({
    flex: '1 1 100%',
});

const CreateTeam = (props) => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        tagLine: '',
        campaign: [],
        campaignGoal: [],
        visibility: '',
        logo: null,
        about: '',
        members: [],
    });

    console.log("formData = ", formData)
    const [campaignOptions, setCampaignOptions] = useState([]);
    const [changemakerResults, setChangemakerResults] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [createTeamResponse, setCreateTeamResponse] = useState('');
    const [componentKey, setComponentKey] = useState(Date.now());
    const [snackbarMultiSelectMessage, setSnackbarMultiSelectMessage] = useState('');
    const [snackbarMultiSelectOpen, setSnackbarMultiSelectOpen] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [snackbarAddMemberMessage, setSnackbarAddMemberMessage] = useState('');
    const [snackbarAddMemberOpen, setSnackbarAddMemberOpen] = useState(false);

    const [errors, setErrors] = useState({});
    const nameRef = useRef(null);
    const tagLineRef = useRef(null);
    const campaignRef = useRef(null);
    const visibilityRef = useRef(null);
    const logoRef = useRef(null);
    const aboutRef = useRef(null);
    const addMembersRef = useRef(null);
    const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

    console.log("campaignOptions = ", campaignOptions);
    console.log("changemakerResults = ", changemakerResults)

    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
        (state) => state.profile
    );

    const fetchChangemakerData = async (searchQuery) => {
        try {
            const response = await axios.get(`/teams/search/vol/test/${searchQuery}`);
            setChangemakerResults(response.data); // Update changemaker results
        } catch (error) {
            console.error("Error fetching Changemaker data:", error);
            setChangemakerResults([]); // Clear changemaker results on error
        }
    };

    useEffect(() => {
        const fetchCampaignOptions = async () => {
            try {
                const response = await axios.get(`/teams/campaign/list/${profileResponse?.organization}/${profileResponse?.phoneno}`);
                const campaigns = response.data.map(campaign => ({
                    name: campaign.name,
                    goal: campaign.goal
                }));
                setCampaignOptions(campaigns);
            } catch (error) {
                console.error('Error fetching campaign options:', error);
            }
        };
        fetchCampaignOptions();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if ((name === 'name' && errors.name) || (name === 'tagLine' && errors.tagLine) || (name === 'about' && errors.about)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: '',
            }));
        }
        if (value === '') {
            let capitalizedField;
            if (name === 'tagLine') {
                capitalizedField = 'Tag Line';
            } else if (name === 'about') {
                capitalizedField = 'About your Team';
            } else {
                capitalizedField = name.charAt(0).toUpperCase() + name.slice(1);
            }
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: `${capitalizedField} is required`,
            }));
        }
        if (name === 'about' && value.length > 100) {
            // Trim the value to 100 characters if it exceeds the limit
            setFormData({
                ...formData,
                [name]: value.slice(0, 100),
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSelectChange = (name) => (event) => {
        setFormData({
            ...formData,
            [name]: event.target.value,
        });
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     setFormData({
    //         ...formData,
    //         logo: file,
    //     });

    //     setErrors((prevErrors) => ({
    //         ...prevErrors,
    //         logo: '',
    //     }));
    // };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

        if (file && !validImageTypes.includes(file.type)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                logo: 'Please upload a valid image file (JPEG, PNG, GIF).',
            }));
            return;
        }

        setFormData({
            ...formData,
            logo: file,
        });

        setErrors((prevErrors) => ({
            ...prevErrors,
            logo: '',
        }));
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleMultiselectChange = (selectedList) => {
        console.log("selectedList len = ", selectedList.length);
        if (selectedList.length <= 2) {
            const selectedCampaignGoals = selectedList.map(item => {
                const selectedCampaign = campaignOptions.find(campaign => campaign.name === item.name);
                return selectedCampaign ? selectedCampaign.goal : '';
            });
            setFormData({
                ...formData,
                campaign: selectedList.map(campaign => campaign.name),
                campaignGoal: selectedCampaignGoals,
            });
            setErrors((prevErrors) => ({
                ...prevErrors,
                campaign: '',
            }));
        } else {
            selectedList.pop();
            setSnackbarMultiSelectMessage("No more than 2 campaigns can be selected");
            setSnackbarMultiSelectOpen(true);
        }
    };

    const handleMemberInputChange = (event, values) => {
        const uniqueValues = [];
        const uniqueUserIDs = new Set();
        let isDuplicate = false;
        let isInvalidOrganization = false;
        console.log("values = ", values);
        console.log("values.length = ", values.length);

        values.slice(1).forEach(value => {

            console.log("value.organization for ", value.uniqueuserid, " = ", value.organization)
            console.log("profileresp org  = ", profileResponse?.organization)
            console.log("compare org = ", value.organization !== profileResponse?.organization)
            if (uniqueUserIDs.has(value.uniqueuserid)) {
                isDuplicate = true;
            } else if (formData.visibility === "private" && value.organization !== profileResponse?.organization) {
                console.log("org mismatch")
                isInvalidOrganization = true;
            } else {
                console.log("NOT INVALID")
                uniqueUserIDs.add(value.uniqueuserid);
                uniqueValues.push(value);
            }
        });
        if (isDuplicate) {
            setSnackbarAddMemberMessage("Duplicate members cannot be added");
            setSnackbarAddMemberOpen(true);
        }

        if (isInvalidOrganization) {
            setSnackbarAddMemberMessage("Members must be from the same organization for private visibility");
            setSnackbarAddMemberOpen(true);
            return;
        }

        if (uniqueValues.length <= 4) {
            const selectedMembers = uniqueValues.map(value => ({
                firstname: value.firstname,
                lastname: value.lastname,
                profilephoto: value.profilephoto,
                uniqueuserid: value.uniqueuserid,
                organization: value.organization,
            }));

            if (values[0]) {
                selectedMembers.unshift(values[0]);
            }

            setSelectedMembers(selectedMembers);
            setFormData({
                ...formData,
                members: selectedMembers
            });
        } else {
            uniqueValues.pop();

            setSnackbarAddMemberMessage("No more than 5 members can be added");
            setSnackbarAddMemberOpen(true);
        }
    };

    const handleSubmit = async () => {
        console.log("handleSubmit")
        const preservedMember = selectedMembers.length > 0 ? selectedMembers[0] : null;
        let validationErrors = {};

        if (!formData.name) {
            validationErrors.name = 'Name is required';
            nameRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (!formData.tagLine) {
            validationErrors.tagLine = 'Tag Line is required';
            tagLineRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (formData.campaign.length === 0) {
            validationErrors.campaign = 'Please select up to 2 campaigns';
            campaignRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (!formData.visibility) {
            validationErrors.visibility = 'Visibility is required';
            visibilityRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (!formData.logo) {
            validationErrors.logo = 'Logo is required';
            logoRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (!formData.about) {
            validationErrors.about = 'About your Team is required';
            aboutRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {

            const data = new FormData();
            data.append('name', formData.name);
            data.append('tagLine', formData.tagLine);
            data.append('campaign', formData.campaign.join(','));
            data.append('campaignGoal', formData.campaignGoal.join(','));
            data.append('visibility', formData.visibility);
            data.append('logo', formData.logo);
            data.append('about', formData.about);
            // Extract and join member names
            console.log("formdata.members = ", formData.members)
            const memberNames = formData.members.map(member => `${member.uniqueuserid}`).join(',');
            data.append('members', memberNames);

            try {
                const response = await axios.post(`/teams/create/${profileResponse?.phoneno}`, data);
                console.log(response.data);
                setCreateTeamResponse(response.data);
                setSnackbarOpen(true); // Show the snackbar
                setTimeout(() => {
                    setSnackbarOpen(false); // Hide the snackbar after 3 seconds
                }, 4000);
                setFormData({
                    name: '',
                    tagLine: '',
                    campaign: [],
                    campaignGoal: [],
                    visibility: '',
                    logo: null,
                    about: '',
                    // members: []
                    members: [
                        {
                            firstname: profileResponse.firstname,
                            lastname: profileResponse.lastname,
                            profilephoto: profileResponse.profilephoto,
                            uniqueuserid: profileResponse.uniqueuserid
                        }
                    ]
                });
                setSelectedMembers(preservedMember ? [preservedMember] : []);
                setComponentKey(Date.now());
            } catch (error) {
                console.error('There was an error creating the team!', error);
            }
        }
    };

    useEffect(() => {
        if (profileSuccess && profileResponse) {
            const loggedInUser = {
                firstname: profileResponse.firstname,
                lastname: profileResponse.lastname,
                profilephoto: profileResponse.profilephoto,
                uniqueuserid: profileResponse.uniqueuserid,
            };
            setSelectedMembers([loggedInUser]);
            setFormData({
                ...formData,
                members: [loggedInUser]
            });
        }
    }, [profileSuccess, profileResponse]);

    const numberOfFields = 1;
    const [fieldVisibility, setFieldVisibility] = React.useState(
        Array(numberOfFields).fill(false)
    );

    const toggleField = (index) => {
        const updatedFieldVisibility = [...fieldVisibility];
        updatedFieldVisibility[index] = !updatedFieldVisibility[index];
        setFieldVisibility(updatedFieldVisibility);
    };
    const isDesktop = useMediaQuery('(min-width:600px)');

    return (
        <div className="wrapper"ref={topRef}>
            <ResponsiveDrawer {...props} />
            <section className="containers">
                <TopHeaderScreen {...props} />
                <div class="content content-wrap">
                    <div class="content-mrg createTeamPage">
                        <Button onClick={() => navigate(-1)} className="backWrapper">
                            <i className={`fa fa-caret-left`} />
                            <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
                        </Button>
                        <div style={{ fontFamily: "monospace", fontSize: 16 }}>
                            <div
                                style={{
                                    display: "flex",
                                    //   width: "75%",
                                    justifyContent: "flex-end",
                                    //   marginRight: 5,
                                    marginBottom: 20,
                                }}
                            >
                                <marquee behavior="scroll" direction="left" style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', color: 'red' }}>
                                    Please do not leave this page until you submit as you may risk
                                    losing the partially filled in form{" "}
                                </marquee>
                            </div>
                        </div>
                        <div
                            class="content-title"
                            style={{
                                fontSize: 25,
                                fontWeight: "600",
                                marginBottom: 15,
                                marginTop: 5,
                            }}
                        >
                            Create Teams
                        </div>
                        <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4, border: "1px solid #dadce0", borderRadius: 2, }}>

                            <Container>
                                <Item ref={nameRef}>
                                    <StyledTypography variant="subtitle1" gutterBottom>
                                        Name <RedStar /><span style={{ color: 'gray', marginLeft: "2px" }}> (max 50 characters)</span>
                                    </StyledTypography>
                                    {/* <TextField */}
                                    <TextField
                                        fullWidth
                                        name="name"
                                        placeholder = "Your answer"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        error={!!errors.name}
                                        helperText={errors.name}
                                        inputProps={{ maxLength: 50 }}
                                        
                                        
                                        FormHelperTextProps={{
                                            style: {
                                                marginLeft: 0,
                                                marginTop: -1,
                                                color: '#FF0000',
                                                fontSize: '0.875rem'
                                            }
                                        }}
                                    />
                                </Item>
                                <Item ref={tagLineRef}>
                                    <StyledTypography variant="subtitle1" gutterBottom>
                                        Tag Line <RedStar /><span style={{ color: 'gray', marginLeft: "2px" }}> (max 50 characters)</span>
                                    </StyledTypography>
                                    <TextField
                                    
                                        fullWidth
                                        name="tagLine"
                                        placeholder = "Your answer"
                                        value={formData.tagLine}
                                        onChange={handleInputChange}
                                        error={!!errors.tagLine}
                                        helperText={errors.tagLine}
                                        inputProps={{ maxLength: 50 }}
                                        FormHelperTextProps={{
                                            style: {
                                                marginLeft: 0,
                                                marginTop: -1,
                                                color: '#FF0000',
                                                fontSize: '0.875rem'
                                            }
                                        }}
                                    />
                                </Item>
                                <Item ref={campaignRef}>
                                    <StyledTypography variant="subtitle1" gutterBottom>
                                        Campaign <RedStar /><span style={{ color: 'gray', marginLeft: "2px" }}> (select upto 2)</span>
                                    </StyledTypography>
                                    <FormControl fullWidth>
                                        <Multiselect
                                            key={componentKey}
                                            isObject={true}
                                            displayValue="name"
                                            onRemove={handleMultiselectChange}
                                            onSelect={handleMultiselectChange}
                                            options={campaignOptions}
                                            style={{ minHeight: '150px' }}
                                            // className="multiSelectContainer"
                                            className={`multiSelectContainer ${errors.campaign ? 'error' : ''}`}
                                            
                                        />
                                        {errors.campaign && <Typography variant="body2" color="#FF0000">{errors.campaign}</Typography>}
                                        <Snackbar
                                            open={snackbarMultiSelectOpen}
                                            autoHideDuration={3000}
                                            onClose={() => setSnackbarMultiSelectOpen(false)}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        >
                                            <Alert onClose={() => setSnackbarMultiSelectOpen(false)} severity="warning">
                                                {snackbarMultiSelectMessage}
                                            </Alert>
                                        </Snackbar>
                                    </FormControl>
                                </Item>
                                <Item className="goalWrapper">
                                    <StyledTypography variant="subtitle1" gutterBottom>Campaign Goal <RedStar /></StyledTypography>
                                    {
                                        formData.campaignGoal.length === 0 ? (

                                            <TextField
                                                fullWidth
                                                placeholder = "Select the Campaign"
                                                name="campaignGoal"
                                                value=""
                                                sx={{
                                                    cursor: 'not-allowed'
                                                }}

                                            />
                                        ) :
                                            (
                                                formData.campaignGoal.map((goal, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            alignItems: 'center',
                                                            padding: '10px 12px 10px 12px',
                                                            marginBottom: '10px',
                                                            width: '100%',
                                                            height: '55px',
                                                            borderRadius: '4px',
                                                            backgroundColor: '#fff', // Similar background to Chip
                                                            whiteSpace: 'normal',
                                                            wordBreak: 'break-word',
                                                            overflowY: 'auto',
                                                            textOverflow: 'ellipsis',
                                                            border: '1px solid #ccc',
                                                            cursor: 'not-allowed'
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                whiteSpace: 'normal',
                                                                wordBreak: 'break-word',
                                                                cursor: 'not-allowed'
                                                            }}
                                                        >
                                                            {goal}
                                                        </Typography>
                                                    </Box>
                                                )))}
                                </Item>
                                <Item ref={visibilityRef}>
                                    <StyledTypography variant="subtitle1" gutterBottom>Visibility <RedStar /></StyledTypography>
                                    <FormControl fullWidth>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "flex-end",
                                            }}
                                            className="menuDropDownTeams"
                                        >
                                            <Select
                                                placeholder="Select"
                                                name="visibility"
                                                value={formData.visibility}
                                                onChange={handleSelectChange('visibility')}
                                                error={!!errors.visibility}
                                                style={{ width: '365px' }}
                                            >
                                                <MenuItem value="public">Public</MenuItem>
                                                <MenuItem value="private">Private</MenuItem>
                                            </Select>
                                        </div>
                                        {errors.visibility && <Typography variant="body2" color="#FF0000">{errors.visibility}</Typography>}
                                    </FormControl>
                                </Item>
                                <Item ref={logoRef}>
                                    <StyledTypography variant="subtitle1" gutterBottom >
                                        Logo <RedStar /><span style={{ color: 'gray', marginLeft: "2px" }}> (less than 50 MB)</span>
                                    </StyledTypography>
                                    <Button variant="contained" component="label" fullWidth
                                        sx={{
                                            backgroundColor: 'transparent',
                                            color: 'black',
                                            height: "56px",
                                            border: errors.logo ? '1px solid #FF0000' : '1px solid black',
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            }
                                        }}
                                    >
                                        Upload Logo
                                        <input type="file" accept="image/*" hidden onChange={handleFileChange} />
                                    </Button>
                                    {formData.logo && (
                                        <Typography variant="body2" gutterBottom>
                                            {formData.logo.name}
                                        </Typography>
                                    )}
                                    {errors.logo && <Typography variant="body2" color="#FF0000">{errors.logo}</Typography>}
                                </Item>
                                <Item ref={aboutRef}>
                                    <StyledTypography variant="subtitle1" gutterBottom>
                                        {/* About your Team <RedStar /><span style={{ color: 'gray', marginLeft: "2px" }}> (max 100 characters)</span> */}
                                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                          <Box sx={{ display: 'flex', alignItems: 'center', width: '45%' }}>
                                            About your Team <RedStar />
                                          </Box>
                                          <Box sx={{ width: '50%', color: 'gray', marginLeft: '2px', textAlign: 'right', fontSize: 15 }}>
                                            (max 100 characters)
                                          </Box>
                                        </Box>
                                    </StyledTypography>
                                    <TextareaAutosize
                                        minRows={3}
                                        placeholder="Your answer"
                                        style={{
                                            width: '100%',
                                            marginLeft: "2px",
                                            borderColor: errors.about ? 'red' : 'lightgray',
                                            borderWidth: errors.about ? '1px' : '1px',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            
                                        }}
                                        name="about"
                                        value={formData.about}
                                        onChange={handleInputChange}

                                    />
                                    {errors.about && <Typography variant="body2" style= {{color:"#FF0000", marginTop:'-6px'}}>{errors.about}</Typography>}
                                </Item>
                                <Item style={{ position: 'relative' }}>
                                    <StyledTypography variant="subtitle1" gutterBottom style={{ position: 'relative' }}>
                                        Add Members <RedStar /><span style={{ color: 'gray', marginLeft: "2px" }}> (max 5)</span>
                                        <i
                                            class="fa fa-question-circle"
                                            style={{
                                                color: "#DF7F13",
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                marginTop: '5px'
                                            }}
                                            onClick={() => toggleField(1)}
                                        ></i>
                                        {fieldVisibility[1] && (
                                            <div

                                                style={{
                                                    position: "absolute",
                                                    top: "100%",  // Position the div below the parent element
                                                    marginTop: "0px", // Adjust margin or padding to ensure proper spacing
                                                    left: 0, // Align with the left edge of the parent element
                                                    // width: isDesktop ? '42%' : '92%', // Adjust width based on screen size

                                                    backgroundColor: 'cyan',
                                                    fontSize: "12px",
                                                    zIndex: 1000, // Ensure the tooltip appears above other content
                                                }}
                                            >
                                                Teams feature is only for changemakers registered on the platform.   Please request interested friends to register to the platform, then you can add them to your team.
                                            </div>
                                        )}
                                    </StyledTypography>
                                    <div style={{ marginTop: fieldVisibility[1] ? '66px' : '0' }}>
                                        <Autocomplete
                                            key={componentKey}
                                            multiple
                                            freeSolo
                                            options={changemakerResults}
                                            value={selectedMembers}
                                            onChange={(event, values) => {
                                                // Ensure logged-in user cannot be removed
                                                const loggedInUser = selectedMembers[0]; // Assuming the logged-in user is the first in the list
                                                if (values.find(v => v.uniqueuserid === loggedInUser.uniqueuserid)) {
                                                    handleMemberInputChange(event, values);
                                                } else {
                                                    handleMemberInputChange(event, [loggedInUser, ...values]);
                                                }
                                            }}
                                            getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                                            onInputChange={(event, value) => {

                                                if (value !== "") {
                                                    fetchChangemakerData(value);
                                                }
                                            }}
                                            renderOption={(props, option) => (
                                                <li {...props}>


                                                    {option.profilephoto !== null ? (
                                                        <img
                                                            src={option.profilephoto}
                                                            alt={`${option.firstname} ${option.lastname}`}
                                                            style={{ width: 24, height: 24, borderRadius: "50%", marginRight: 8 }}
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                width: 24,
                                                                height: 24,
                                                                borderRadius: "50%",
                                                                backgroundColor: "#ccc",
                                                                marginRight: 8,
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                color: "#fff",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            {option.firstname[0]}{option.lastname[0]}
                                                        </div>
                                                    )}
                                                    {option.firstname} {option.lastname}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Search members"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </div>
                                    <Snackbar
                                        open={snackbarAddMemberOpen}
                                        autoHideDuration={3000}
                                        onClose={() => setSnackbarAddMemberOpen(false)}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                    >
                                        <Alert onClose={() => setSnackbarAddMemberOpen(false)} severity="warning">
                                            {snackbarAddMemberMessage}
                                        </Alert>
                                    </Snackbar>
                                </Item>
                            </Container>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    // className="btn save-btn"
                                    className="btn save-btn"
                                    onClick={handleSubmit}
                                >
                                    <span>Submit</span>
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </div>
                <CopyRight />
                <FixedFooterIcon />
            </section>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'middle', horizontal: 'center' }}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        top: '90%',
                        // left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }
                }}
            >
                {
                    createTeamResponse?.status === "success" ?
                        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                            {/* Team created successfully! */}
                            {createTeamResponse?.message}

                        </Alert>
                        :
                        <Alert onClose={() => setSnackbarOpen(false)} severity="failure" sx={{ width: '100%' }}>
                            {/* Team created successfully! */}
                            {createTeamResponse?.message}
                        </Alert>
                }
            </Snackbar>
        </div>
    );
};

export default CreateTeam;