import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, useMediaQuery} from "@mui/material";
import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import TopHeaderScreen from "./TopHeader1";
import { useNavigate } from "react-router-dom";
import { FixedFooterIcon } from "./FixedFooterIcon";
import Highcharts from "highcharts";
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from "highcharts-react-official";
import Sunburst from 'highcharts/modules/sunburst';
// import Gauge from "highcharts/modules/gauge";
import axios from "axios";
import { useSelector } from "react-redux";

HighchartsMore(Highcharts);
Sunburst(Highcharts);
// Gauge(Highcharts);

export default function CMDashboardScreen(props) {
  const navigate = useNavigate();
  const [chartData, setChartData] = useState([]);
  const topRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    axios.get(`/user/cm/metrics/${profileResponse?.phoneno}`)
      .then((response) => {
        setChartData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, []);

  const transformedData = chartData.length > 0 ? [
    { name: "Registered Camps", y: chartData[0].registeredcamps },
    { name: "Approved Camps", y: chartData[0].participatedapprovedcamps },
    { name: "Rejected Camps", y: chartData[0].participatedrejectedcamps }
  ] : [];

  
  const optionsPie1 = {
    chart: {
      type: 'pie',
      height: 'auto'
    },
    title: {
      text: 'Campaign Action View'
    },
    series: [
      {
        name: 'Camps',
        data: transformedData,
        colorByPoint: true
      }
    ],
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    credits: {
      enabled: false
    }
  };

  // Transform data for line chart
  const transformedBarData = chartData.length > 0 && chartData[1]?.orgparticipate ? chartData[1].orgparticipate : [];
  const orgNames = transformedBarData.map(item => item.orgname);
  const submittedData = transformedBarData.map(item => item.submitted);
  const approvedData = transformedBarData.map(item => item.approved);

  const optionsBar1 = {
    chart: {
      type: 'bar',

    },
    title: {
      text: 'Submission and Approval Data'
    },
    xAxis: {
      categories: orgNames,
      title: {
        text: 'Organization'
      },
      labels: {
        formatter: function() {
          const text = this.value;
          const maxLength = 15; 
          if (text.length > maxLength) {
            return text.match(new RegExp('.{1,' + maxLength + '}', 'g')).join('<br/>');
          }
          return text;
        },
        useHTML: true,
        style: {
          fontSize: '12px',
          fontFamily: 'Arial',
          zIndex: 1
        },
        
      }
    },
    yAxis: {
      title: {
        text: 'Number of Camps'
      }
    },
    series: [
      {
        name: 'Submitted',
        data: submittedData,
        color: '#7cb5ec'
      },
      {
        name: 'Approved',
        data: approvedData,
        color: '#434348'
      }
    ],
    credits: {
      enabled: false
    }, 
    tooltip: {
      formatter: function() {
        const point = this.point;
        const seriesName = this.series.name;
        const value = this.y;
        return `
          ${seriesName}: ${value}
        `;
      },
      // useHTML: true,
      backgroundColor: "rgba(255,255,255,1)",
      style: {
        zIndex: 999
      },
    }
  };

  
  const optionsGauge1 = {
    chart: {
      type: 'gauge',
      plotBorderWidth: 0,
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      height: '400px'
    },
    title: {
      text: 'CMs in Your Organization'
    },
    pane: {
      startAngle: -150,
      endAngle: 150,
      background: [
        {
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
          innerRadius: '80%',
          outerRadius: '100%',
          shape: 'arc'
        }
      ]
    },
    yAxis: {
      min: 0,
      max: 1000,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        text: 'Count'
      },
      labels: {
        y: 15
      }
    },
    dataLabels: {
      enabled: true, 
      format: '{point.y}', 
      style: {
        textOutline: 'none' 
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Users',
      data: [chartData.length > 0 ? chartData[0].myorgchangemakers : 0],
      tooltip: {
        valueSuffix: ' users'
      },
    }]
  };

  const optionsBullet1 = {
    chart: {
      type: 'bullet',
      height: '200px'
    },
    title: {
      text: '' // Remove the title or set it as desired
    },
    yAxis: {
      title: {
        text: '' // Remove the axis title or set it as desired
      },
      categories: [''], // Empty category or modify as needed
      tickAmount: 1,
      labels: {
        formatter: function() {
          return this.value;
        }
      }
    },
    series: [{
      name: 'Users',
      data: [chartData.length > 0 ? chartData[0].myorgchangemakers : 0],
      target: 150 // Optional: Set a target value if needed
    }],
    credits: {
      enabled: false
    }
  };

  const optionsColumn1 = {
    chart: {
      type: 'column' // Use 'bar' for a horizontal bar chart
    },
    title: {
      text: 'Reward Points by Campaign'
    },
    xAxis: {
      categories: chartData.length > 0 ? chartData[2].camprewardpoints.map(camp => camp.campname) : [],
      title: {
        text: 'Campaigns'
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Reward Points'
      }
    },
    series: [{
      name: 'Reward Points',
      data: chartData.length > 0 ? chartData[2].camprewardpoints.map(camp => camp.rewardpoints) : []
    }],
    tooltip: {
      valueSuffix: ' points'
    },
    credits: {
      enabled: false
    }
  };

  return (
    <div className="wrapper" ref={topRef}>
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button
              onClick={() => navigate(-1)}
              className="backWrapper"
            >
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
                
              }}
            >
              Overall Dashboard View
            </div>
            <Grid container spacing={2} sx={{ padding: '8px', gap:3 }}>
              <Grid className="svgResponsiveGraph" item xs={isMobile ? 12: 5.5} sx={{boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)'}}>
                <HighchartsReact highcharts={Highcharts} options={optionsPie1} />
              </Grid>
              <Grid item xs={isMobile ? 12: 5.5} sx={{boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)'}}>
                <HighchartsReact highcharts={Highcharts} options={optionsBar1} />
              </Grid>
              <Grid item xs={isMobile ? 12: 5.5} sx={{boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)'}}>
                <HighchartsReact highcharts={Highcharts} options={optionsGauge1} />
              </Grid>
              <Grid item xs={isMobile ? 12: 5.5} sx={{boxShadow: '0 2px 4px rgba(0, 0, 0, 0.8)'}}>
                <HighchartsReact highcharts={Highcharts} options={optionsColumn1} />
              </Grid>
            </Grid>

          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
