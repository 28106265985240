import React, { useState, useEffect } from 'react';
import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, ListItemText, OutlinedInput, TextField, Snackbar } from '@mui/material';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import axios from "axios";
import { FixedFooterIcon } from "./FixedFooterIcon";
import TopHeaderScreen from "./TopHeader1";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVolunteerName } from "../redux/action/getVolunteerName";
import { isArray, isEmpty } from "lodash";
import { convertToRaw } from 'draft-js';
import AsyncSelect from "react-select/async";


const BroadcastMessage = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [options, setOptions] = useState([]); 

    
    const { response: volunteerNameResponse, isFetching: volunteerNameFetching } =
        useSelector((state) => state.volunteerName);
    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
        (state) => state.profile
    );
    const [selectedOption, setSelectedOption] = useState('organization');
    React.useEffect(() => {
        if (profileResponse) {
            dispatch(
                getVolunteerName({
                    organization: profileResponse?.organization,
                    userid: localStorage.getItem("userid"),
                })
            );
        }
    }, []);

    React.useEffect(() => {
        getCampName();
        onLoadCallUser();
      }, [volunteerNameResponse]);


    const onLoadCallUser = () => {
        if (!isEmpty(volunteerNameResponse)) {
            const formattedOptions = volunteerNameResponse.map(volunteer => ({
                id: volunteer.uniqueuserid,
                phoneno: volunteer.phoneno,
                name: `${volunteer.firstname} ${volunteer.lastname}`
            }));
            setOptions(formattedOptions);  
       }
    }

    const [campName, setCampName] = React.useState([]);
    const [selectedcampname, setselectedcampname] = React.useState([]);

   

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        if(event.target.value === 'campaign') {
            setselectedcampname([]);
        } else {
            onLoadCallUser();
        }
            setSelectedUsers([]);
            setEditorState(EditorState.createEmpty());
      };

      const handleSelectChange = (event) => {
        const value = event.target.value;
        console.log(options.length,"==== checkitIteam===",value.length);
        const isValuePresent = value.includes("All");
        console.log("==== FIRST =====",!value.includes('All'));
        if (value.includes('All') && value.length < options.length || (value.length === options.length && !value.includes('All'))) {
            console.log("==== SECOND =====",selectedUsers);
            if (!selectedUsers.some(user => user.name === 'All')) {
                console.log("==== THIRD =====",selectedUsers);
                setSelectedUsers([
                    { name: 'All', id: null, phoneno: null },
                    ...options.map(option => ({
                        name: option.name,
                        id: option.uniqueuserid,
                        phoneno: option.contactno
                    }))
                ]);
            } else {
                console.log("==== FOUR =====",selectedUsers);
                setSelectedUsers([]);
            }
        } else {
            console.log("==== FIVE =====",selectedUsers);
            if (selectedUsers.some(user => user.name === 'All')) {
                console.log("==== SIX =====",selectedUsers);
                const filteredUsers = value.filter(val => val !== 'All').map(selectedName => {
                    const option = options.find(opt => opt.name === selectedName);
                    return option ? { name: option.name, id: option.uniqueuserid, phoneno: option.contactno } : null;
                }).filter(Boolean);
                setSelectedUsers(filteredUsers);
            } else if (value.length === options.length) {
                console.log("==== SEVEN =====",selectedUsers);
                setSelectedUsers([
                    { name: 'All', id: null, phoneno: null },
                    ...options.map(option => ({
                        name: option.name,
                        id: option.uniqueuserid,
                        phoneno: option.contactno
                    }))
                ]);
            } else {
                console.log("==== EIGHT =====",value);
                const selected = value.map(selectedName => {
                    const option = options.find(opt => opt.name === selectedName);

                    console.log("*******",option);
                    // return option ? { name: option.name, id: option.uniqueuserid, phoneno: option.contactno } : null;
                    return option ? { name: option.name, id: option.id, phoneno: option.phoneno } : null;
                }).filter(Boolean);
                setSelectedUsers(selected);
            }
        }
    };

    const handleEditorStateChange = (state) => {
        setEditorState(state);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const messageContent = editorState.getCurrentContent().getPlainText();
        const contentState = editorState.getCurrentContent();
        const messageContent = JSON.stringify(convertToRaw(contentState));
        // console.log('Selected Users:', selectedUsers);
        console.log('messageContent:', messageContent);
        if (messageContent === "{}" || isEmpty(selectedUsers)) {
            setSnackbarOpen(true); // Open Snackbar
            setSnackbarMessage("Please select the user and add a message"); 
            return; 
        }
        try {
            const response = await axios.post('/notification/bc', {
                users: selectedUsers,
                message: messageContent,
                phoneno: profileResponse?.phoneno
            });

            console.log('API Response:', response.data);
            setSnackbarOpen(true);
            setSnackbarMessage("Message broadcasted");
        } catch (error) {
            console.error('Error during API call:', error);
            // Open Snackbar and set the error message
            setSnackbarOpen(true);
            setSnackbarMessage("Failed to broadcast the message. Please try again.");
            return; // Exit early on error
        }

        console.log("selectedUsers = ", selectedUsers);
        setSelectedUsers([]);
        setEditorState(EditorState.createEmpty());

    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    
      const loadOptions = (searchValue, callback) => {
        setTimeout(() => {
            console.log("Filtered option camp Name **** ======", campName); 
          const filteredOptions = campName.filter((category) => {
            console.log("Filtered option category ***** ======", category);
            return category.label.toLowerCase().includes(searchValue.toLowerCase());
          });
          console.log("Filtered options====== **** ", filteredOptions);
          callback(filteredOptions); 
        });
      };
      
      const colorStyles = {
        control: (styles) => ({ ...styles }),
        multiValue: (styles, { data }) => {
          return { ...styles, backgroundColor: "#0096FF", color: "#fff" };
        },
        multiValueLabel: (styles, { data }) => {
          return { ...styles, color: "#fff" };
        },
        multiValueRemove: (styles, { data }) => {
          return {
            ...styles,
            color: "#fff",
            cursor: "pointer",
            ":hover": {
              color: "#fff",
            },
          };
        },
      };
    
       const getCampName = async () => {
        try {
          const response = await axios.get(
            `vol_dashboard/camp/my/${profileResponse?.orgrole}/${profileResponse?.organization}/${profileResponse?.phoneno}`
          );
      
            const formattedData = response.data.map(campaign => ({
                label: campaign.name,   
                value: campaign.name  
            }));
            
          setCampName(formattedData);
          
          //return response;
        } catch (err) {
          throw err;
        }
      };

      const getUserOnCampaignSelect = async (selectedCampaign) => {
        try {
            const res = await axios.get(
                `/user/profile/bc/camp/list/${selectedCampaign[0].value}/${profileResponse?.phoneno}`
            );
    
            // Check if the response data exists and is an array
            if (Array.isArray(res.data)) {
                // Format the data to match the structure needed for the select dropdown
                const formattedData = res.data.map(volunteer => ({
                    id: volunteer.uniqueuserid,  
                    name: `${volunteer.firstname} ${volunteer.lastname}`,  
                    phoneno: volunteer.contactno  
                }));
          console.log("KKKKKKKKKKK",formattedData);
          setOptions(formattedData);
                return formattedData;
            }

            console.error("Unexpected API response format", res.data);
            return [];
    
        } catch (err) {
            console.error("Error fetching campaign data:", err);
            throw err;  
        }
    };
    
    

    console.log("options = ", options)
    return (
        <div className="wrapper">
            <ResponsiveDrawer {...props} />
            <section className="containers">
                <TopHeaderScreen {...props} />
                <div class="content content-wrap">
                    <div class="content-mrg">
                        <Button onClick={() => navigate(-1)} className="backWrapper">
                            <i className={`fa fa-caret-left`} />
                            <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
                        </Button>
                        <div
                            class="content-title"
                            style={{
                                fontSize: 25,
                                fontWeight: "600",
                                marginBottom: 15,
                                marginTop: 5,
                            }}
                        >
                            Broadcast Message
                        </div>
                        <div className='announcementWrapper'>
                        <label for="organization" style={{cursor:'pointer'}}>
                            <input
                            id="organization"
                            type="radio"
                            value="organization"
                            checked={selectedOption === 'organization'}
                            onChange={handleOptionChange}
                            style={{marginRight:5,cursor:'pointer'}}
                            />
                            Organization
                        </label>

                        <label for="campaign" style={{marginLeft:20,cursor:'pointer'}}>
                            <input
                            id="campaign"
                            type="radio"
                            value="campaign"
                            checked={selectedOption === 'campaign'}
                            onChange={handleOptionChange}
                            style={{marginRight:5,cursor:'pointer'}}
                            />
                            Campaign
                        </label>
                        </div>
                       
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 2,
                                maxWidth: '600px',
                                margin: '0 auto',
                            }}
                        >
                           {selectedOption === 'campaign' && ( <FormControl sx={{ width: '100%' }}>
                                <AsyncSelect
                                    loadOptions={loadOptions}
                                    defaultOptions={campName}
                                    value={selectedcampname}
                                    isMulti
                                    placeholder="Select Campaign"
                                    isOptionDisabled={() => selectedcampname.length >= 1}
                                    styles={colorStyles}
                                    onChange={async (event) => { 
                                    setselectedcampname(event);
                                    try {
                                        // Replace `apiCallFunction` with the actual function you are using to make the API call
                                        const response = await getUserOnCampaignSelect(event);  // Make the API request
                                        console.log("**********=====**********",response);  // Handle the response here (optional)
                                    } catch (error) {
                                        console.error("Error making API call:", error);  // Handle errors here
                                    }
                                    }}
                                />
                            </FormControl>
                            )}

                            {/* To: Multi-select Dropdown */}
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="select-users-label" style={{zIndex:'0'}}>To</InputLabel>
                                <Select
                                    labelId="select-users-label"
                                    multiple
                                    value={selectedUsers.map(user => user.name)} // Map `selectedUsers` to names for Select value
                                    onChange={handleSelectChange}
                                    input={<OutlinedInput label="To" />}
                                    renderValue={(selected) => selected.join(', ')} // Display the names as comma-separated
                                >
                                    <MenuItem value="All">
                                        <Checkbox checked={selectedUsers.some(user => user.name === 'All')} />
                                        <ListItemText primary="All" />
                                    </MenuItem>
                                    {options !== false &&
                                        options.map((option) => (
                                            <MenuItem key={option.id} value={option.name}>
                                                <Checkbox id={selectedUsers.some(user => user.name === option.name) ? option.name : 'default'} checked={selectedUsers.some(user => user.name === option.name)}
                                                />
                                                <ListItemText className='optionName' primary={option.name} />
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>

                            {/* Comment: Rich Text Editor */}
                            <Box sx={{ border: '1px solid #ddd', borderRadius: '4px', padding: '8px',width:"100%" }}>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={handleEditorStateChange}
                                    placeholder="Message to be broadcasted"
                                />
                            </Box>

                            {/* Submit Button */}
                            <Button type="submit" variant="contained" sx={{ alignSelf: 'auto' }}>
                                Submit
                            </Button>
                        </Box>
                        <Snackbar
                            open={snackbarOpen}
                            onClose={handleSnackbarClose}
                            message={snackbarMessage}
                            autoHideDuration={3000}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BroadcastMessage;