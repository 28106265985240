import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { Box, Button, Card, CardContent, Paper, Typography, useTheme, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const Overlay = styled("div")(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
  backdropFilter: 'blur(5px)',
}));

const PopupContainer = styled("div")(({ theme, isMobile }) => ({
  position: 'relative',
  width: isMobile ? '95%' : '90%',
  maxWidth: '400px',
  maxHeight: '80vh',
  background: 'linear-gradient(135deg, #ffffff, #f7f7f7)',
  border: 'none',
  borderRadius: '15px',
  padding: '20px',
  position: 'relative',
  boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
  zIndex: 1001,
  overflowY: 'auto',
  overflowX: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: '#bbb #f1f1f1',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#bbb',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  transition: 'transform 0.3s ease-in-out',
  transform: isMobile ? 'translateY(-10px)' : 'translateY(0)',
}));


const PopupMarketplace = ({ isOpen, setIsOpen, reward }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  

  const closePopup = () => {
    console.log("Closing popup...");
    setIsOpen(false);
  };

  
  

  // useEffect(() => {
  //   return () => {
  //     if (isOpen) {
  //       setIsOpen(false);
  //     }
  //   };
  // }, [isOpen, setIsOpen]);

  // if (!isOpen) return null;

  useEffect(() => {
    const loadImages = async () => {
      const sponsorImages = reward?.sponsor?.map((sponsor) =>
        handleImageLoad(sponsor.sponsorlogo)
      );
  
      try {
        const results = await Promise.all(sponsorImages);
        if (results.every((res) => res === true)) {
          setIsLoaded(true);
          console.log("All images successfully loaded");
        }
      } catch (error) {
        console.log("Error loading images", error);
      }
    };
  
    if (reward?.sponsor?.length) {
      loadImages();
    }
  }, [reward?.sponsor]);
  
  if (!isOpen) return null;

  const isMobile = window.innerWidth <= 768;


  const handleImageLoad = (imgSrc) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imgSrc;
  
      img.onload = () => {
        console.log(`Image loaded: ${imgSrc}`);
        resolve(true);
      };
  
      img.onerror = () => {
        console.log(`Image failed to load: ${imgSrc}`);
        reject(false);
      };
    });
  };
  
  


  // const handleImageLoad = async (img) => {
  //   const im = await Promise.all(img);
  //   console.log(im);
  //   if (im) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // if (!isLoaded) {
  //   return (
  //     <Overlay>
  //       <Typography variant="h6" align="center" sx={{ color: '#fff' }}>Loading...</Typography>
  //     </Overlay>
  //   );
  // }

  if (!isLoaded) {
    return (
      <Overlay>
        <Typography variant="h6" align="center" sx={{ color: '#fff' }}>Loading...</Typography>
      </Overlay>
    );
  }
  return (
    <Overlay>
      <PopupContainer isMobile={isMobile}>
        <Dialog
          open={isOpen}
          onClose={closePopup}
          maxWidth="sm"
          fullWidth
          sx={{
            backdropFilter: 'blur(5px)',
            zIndex: 1100,
          }}
        >
          <DialogTitle>
            <Box mt={3} sx={{ textAlign: 'center' }}>
              <img
                src={reward?.logo}
                // src={`${reward?.logo}?t=${new Date().getTime()}`}
                alt={reward?.rewardtype}
                style={{ width: '50%', height: 'auto', borderRadius: '10px' }}
                
              />
            </Box>
            <Typography variant="h5" align="center">{reward?.rewardtype}</Typography>
            <IconButton
              aria-label="close"
              onClick={closePopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                zIndex: 1200
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              <div className="text-center">
                <div className="row sponserMarketplaceWrapper">
                    <Typography variant="h6" sx={{ fontWeight: 'bold', float: 'left' }}>Sponsored By</Typography>
                  <div style={{marginBottom: '10px'}}>

                    {
                      Array.isArray(reward?.sponsor) &&
                      reward?.sponsor?.map((sponsor, index) =>
                        // handleImageLoad(reward.sponsorlogo) ? (
                          <img
                            style={{
                              width: "auto",
                              height: "30px",
                              marginRight: "20px",
                              marginTop: "5px",
                            }}
                            src={sponsor.sponsorlogo}
                           
                            key={index}
                            alt="reward"
                          />
                        // ) : null
                      )}
                  </div>

                </div>
                
              </div>
            </Box>
            {/* <Box textAlign="center" padding="16px"> */}
            <Box textAlign="left" padding="16px">
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginLeft : '-11px' }}>Reward Details</Typography>
              <Typography variant="body1" sx={{marginLeft: '2px'}}> 1. This is a {reward?.rewardtype}</Typography>
              <Typography variant="body1">
                2. {reward?.campid !== "camp00001" ? "You will redeem for FREE" : `Redeem for ${reward?.points} Points`}
              </Typography>
              
              <Typography variant="body1">
                3. {reward?.campid !== "camp00001" ? `Applicable to all users who completed and were approved for ${reward?.campname}` : `Applicable to users with enough points`}
              </Typography>
              <Typography variant="body1">
                4. Expires in {reward?.toexpiredays} days
              </Typography>
            </Box>
            <Box textAlign="left" padding="16px">
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginLeft : '-11px'  }}>How to redeem?</Typography>
              <Typography variant="body1" sx={{marginLeft: '2px'}}> 1. Click Redeem</Typography>
              <Typography variant="body1">
                2. Click OK on redeem confirmation page
              </Typography>
              <Typography variant="body1">
                3. See your status on Redeem Status page
              </Typography>
              <Typography variant="body1">
                4. If status is closed, you can download your reward
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
      </PopupContainer>
    </Overlay>
  );
};

export default PopupMarketplace;