// import * as React, {useRef} from "react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Skeleton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import moment from "moment";
import axios from "axios";

import _ from "lodash";
import RedStar from "./RedStar";
import TopHeaderScreen from "./TopHeader1";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import { SponsorAdminDashboardTableComponent } from "./Table";
import styled from 'styled-components';
const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

function CampReport(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campSubmissionlist, setCampSubmissionlist] = React.useState([]);
  const [investedBudgetList, setInvestedBudgetList] = React.useState([]);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
 
  const getSponsorAdminDashboard = async () => {
   
    // console.log(selectedpointsrange.length === 0);
    try {
      // const res = ""
      //   if ((selectedrewardtype.length === 0) && !(selectedpointsrange.length === 0)) {
     
      const res = await axios
        .get(
          `/sponsor/dashboard/${profileResponse?.phoneno}`
        )
        .then((response) => {
          setCampSubmissionlist(response?.data);
          return response;
        });
      //   }
    } catch (err) {
      throw err;
    }
  };

  const getInvestedBudgetForSponsorAdminDashboard = async () => {
    
    // console.log(selectedpointsrange.length === 0);
    try {
      
      const res = await axios
        .get(
          `/sponsor/dashboard/investedbudget/${profileResponse?.phoneno}`
        )
        .then((response) => {
          setInvestedBudgetList(response?.data);
          return response;
        });
      //   }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getSponsorAdminDashboard();
    getInvestedBudgetForSponsorAdminDashboard();
  }, []);
  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Report on Campaigns and Budgets
            </div>
            
            <header id="SOADashboardScreen">
              <form autoComplete="off">
                <div
                  style={{
                    border: "1px solid #dadce0",
                    borderRadius: 8,
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  ></div>
                  <div
                    className="centerAlign"
                    style={{ padding: "16px 0", marginBottom: 50 }}
                  >
                    <div>Your Initial Budget = <Skeleton  variant="rounded" style={{display: "inline", backgroundColor: "rgba(0, 255, 0, 1)"}}>{campSubmissionlist?.[0]?.initialbudget}</Skeleton></div>
                    
                    <div> Invested Budget = <Skeleton  variant="rounded" style={{display: "inline", backgroundColor: "rgba(235, 149, 50, 1)"}}>{investedBudgetList?.[0]?.investedbudget}</Skeleton> </div>
                    <br />
                    <FormGroupFull>
                      <div>
                        <SponsorAdminDashboardTableComponent
                          rows={campSubmissionlist}
                        />
                      </div>
                    </FormGroupFull>
                  </div>
                </div>
              </form>
            </header>
          </div>
        </div>
        <CopyRight />
      </section>
    </div>
  );
}
export default CampReport;
