import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Avatar, Box, Typography, Snackbar, TextareaAutosize } from '@mui/material';
import { ListItem, Button,useMediaQuery, TextField } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import TopHeaderScreen from "../../components/TopHeader1";
import RedStar from "../../components/RedStar";
import styled from 'styled-components';

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
})); 

const FormGroup0 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#F0F8FF',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// Styled component for formGroup00
const FormGroup00 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#FAEBD7',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'block',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: '16px',
    '@media (min-width: 600px)': {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
});


const Item = styled(Box)({
    flex: '1 1 48%',
    '@media (max-width: 600px)': {
        flex: '1 1 100%',
    },
});


const EditTeam = (props) => {
    const [errors, setErrors] = useState({ name: '', tagLine: '', about: '' });
    const navigate = useNavigate();
    const { teamid } = useParams();

    const [formData, setFormData] = useState({
        name: '',
        tagLine: '',
        campaign: [],
        campaignGoal: [],
        visibility: '',
        // logo: null,
        about: '',
        members: [],
    });

    const [campaignOptions, setCampaignOptions] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [editTeamResponse, setEditTeamResponse] = useState('');
    const [componentKey, setComponentKey] = useState(Date.now());
    const [myTeamData, setMyTeamData] = useState([]);
    const topRef = useRef(null);

    useEffect(() => {
      if (topRef.current) {
        topRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, []);

    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
        (state) => state.profile
    );

    useEffect(() => {
        const fetchCampaignOptions = async () => {
            try {
                const response = await axios.get(`/teams/campaign/list/${profileResponse?.organization}/${profileResponse?.phoneno}`);
                const campaigns = response.data.map(campaign => ({
                    name: campaign.name,
                    goal: campaign.goal
                }));
                setCampaignOptions(campaigns);
            } catch (error) {
                console.error('Error fetching campaign options:', error);
            }
        };
        fetchCampaignOptions();
    }, [profileResponse]);

    useEffect(() => {
        const fetchMyTeamSpecificPage = async () => {
            try {
                const response = await axios.get(`/teams/my/team/${teamid}/${profileResponse?.phoneno}`);
                setMyTeamData(response.data);
            } catch (error) {
                console.error('Error fetching my team', error);
            }
        };
        fetchMyTeamSpecificPage();
    }, [profileResponse]);

    useEffect(() => {

        if (myTeamData.length > 0) {
            const teamData = myTeamData[0];

            const formDataCampaignOptions = teamData?.campname.map((campaignName, index) => ({
                id: index,
                name: campaignName,
            }));

            const formDataAddedMembers = teamData?.memberdetails.map((member, index) => ({
                id: index,
                firstname: member.firstname,
                lastname: member.lastname,
                profilephoto: member.profilephoto,
                uniqueuserid: member.uniqueuserid,
                organization: member.organization,
                totalpoints: member.totalpoints,
            }));
            setFormData({
                ...formData,
                name: teamData.name || '',
                tagLine: teamData.tagline || '',
                campaign: formDataCampaignOptions,
                campaignGoal: teamData.goal,
                visibility: teamData.visibility || '',
                // logo: teamData.logo || null,
                about: teamData.about || '',
                members: formDataAddedMembers,
            });
            // setSelectedMembers(formDataAddedMembers);
        }
    }, [myTeamData]);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'about' && value.length > 100) {

            setFormData({
                ...formData,
                [name]: value.slice(0, 100),
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };


    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleSubmit = async () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.tagLine) newErrors.tagLine = 'Tag Line is required';
        if (!formData.about) newErrors.about = 'About your Team is required';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        const data = new FormData();
        data.append('name', formData.name);
        data.append('tagLine', formData.tagLine);
        data.append('about', formData.about);
        data.append('teamid', teamid)

        try {
            const response = await axios.post(`/teams/edit/${profileResponse?.phoneno}`, data);
            setEditTeamResponse(response.data);
            setSnackbarOpen(true);
            // setTimeout(() => {
            //     setSnackbarOpen(false);
            // }, 6000);

            setComponentKey(Date.now());
        } catch (error) {
            console.error('There was an error editing the team!', error);
        }
    };

    // const handleInputChangeWithErrorReset = (e) => {
    //     handleInputChange(e);
    //     setErrors({ ...errors, [e.target.name]: '' });
    // };

    const handleInputChangeWithErrorReset = (e) => {
        const { name, value } = e.target;
    
        // Reset error message if the field is not empty
        if (value.trim() !== '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: '',
            }));
        }
    
        // Set error message if the field is empty
        if (value.trim() === '') {
            let capitalizedField;
            if (name === 'tagLine') {
                capitalizedField = 'Tag Line';
            } else if (name === 'about') {
                capitalizedField = 'About your Team';
            } else {
                capitalizedField = name.charAt(0).toUpperCase() + name.slice(1);
            }
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: `${capitalizedField} is required`,
            }));
        }
    
        // Trim the value to 100 characters if it exceeds the limit for 'about' field
        if (name === 'about' && value.length > 100) {
            setFormData({
                ...formData,
                [name]: value.slice(0, 100),
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <div className="wrapper" ref={topRef}>
            <ResponsiveDrawer {...props} />
            <section className="containers">
                <TopHeaderScreen {...props} />
                <div className="content content-wrap">
                    <div className="content-mrg createTeamPage">
                        <Button style={{ padding: "6px 8px", color: "#1976d2" }} onClick={() => navigate(-1)} >
                            <i className={`fa fa-caret-left`} />
                            <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
                        </Button>
                        <div style={{ fontFamily: "monospace", fontSize: 16 }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: 20,
                                }}
                            >
                                <marquee behavior="scroll" direction="left" style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', color: 'red' }}>
                                    Please do not leave this page until you submit as you may risk
                                    losing the partially filled in form{" "}
                                </marquee>
                            </div>
                        </div>
                        <div
                            className="content-title"
                            style={{
                                fontSize: 25,
                                fontWeight: "600",
                                marginBottom: 15,
                                marginTop: 5,
                            }}
                        >
                            Edit Team - <span style={{ color: "#007BFF" }}>{formData.name}</span>
                        </div>
                        <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4, border: "1px solid #dadce0", borderRadius: 4, }}>
                            <Container>
                                <Item>
                                    <StyledTypography variant="subtitle1" gutterBottom>
                                        Name <RedStar /><span style={{ color: 'gray', marginLeft: "2px" }}> (max 50 characters)</span>
                                    </StyledTypography>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChangeWithErrorReset}
                                        inputProps={{ maxLength: 50 }}
                                        error={!!errors.name}
                                        helperText={errors.name}
                                        FormHelperTextProps={{
                                            style: {
                                                marginLeft: 0,
                                                marginTop: -1,
                                                color: '#FF0000',
                                                fontSize: '0.875rem'
                                            }
                                        }}
                                        
                                    />
                                    {/* {errors.name && <Typography variant="body2" style={{ color: '#FF0000' }}>{errors.name}</Typography>} */}
                                </Item>
                                <Item>
                                    <StyledTypography variant="subtitle1" gutterBottom>
                                        Tag Line <RedStar /><span style={{ color: 'gray', marginLeft: "2px" }}> (max 50 characters)</span>
                                    </StyledTypography>
                                    <TextField
                                        fullWidth
                                        name="tagLine"
                                        value={formData.tagLine}
                                        onChange={handleInputChangeWithErrorReset}
                                        inputProps={{ maxLength: 50 }}
                                        error={!!errors.tagLine}
                                        helperText={errors.tagLine}
                                        FormHelperTextProps={{
                                            style: {
                                                marginLeft: 0,
                                                marginTop: -1,
                                                color: '#FF0000',
                                                fontSize: '0.875rem'
                                            }
                                        }}
                                    />
                                    {/* {errors.tagLine && <Typography variant="body2" style={{ color: '#FF0000' }}>{errors.tagLine}</Typography>} */}
                                </Item>
                                <Item>
                                    <StyledTypography variant="subtitle1" gutterBottom>
                                        About your Team <RedStar /><span style={{ color: 'gray', marginLeft: "2px" }}> (max 100 characters)</span>
                                    </StyledTypography>
                                    <TextareaAutosize
                                        minRows={3}
                                        placeholder="About your Team"
                                        // style={{ width: '100%', marginLeft: "2px", borderColor: "lightgray" }}
                                        style={{
                                            width: '100%',
                                            marginLeft: "2px",
                                            borderColor: errors.about ? 'red' : 'lightgray',
                                            borderWidth: errors.about ? '2px' : '1px',
                                            padding: '8px',
                                            borderRadius: '4px',
                                        }}
                                        name="about"
                                        value={formData.about}
                                        onChange={handleInputChangeWithErrorReset}
                                        
                                    />
                                    {errors.about && <Typography variant="body2" style={{ color: '#FF0000' , marginTop:'-6px'}}>{errors.about}</Typography>}
                                </Item>

                            </Container>
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className="btn save-btn"
                                        onClick={handleSubmit}
                                    >
                                        <span>Save</span>
                                    </Button>
                                    <Button
                                        onClick={() => navigate(`/team/home/${teamid}`)}
                                        className="btn cancel-btn"
                                        variant="contained"
                                        color="secondary"
                                        style={{
                                            width: '135px',
                                            background: '#e9ecef',
                                            // borderRadius: 7,
                                            // color: '#3B3F5C',
                                            // fontFamily: 'Poppins !important',
                                            height: '53px',
                                        }}
                                    >
                                        <span>Cancel</span>
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                </div>
                <CopyRight />
                <FixedFooterIcon />
            </section>
            <Snackbar
                open={snackbarOpen}
                // autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'middle', horizontal: 'center' }}
                sx={{
                    '& .MuiSnackbarContent-root': {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'fixed',
                      bottom: isMobile ? '10px' : '16px', // Adjust bottom spacing for mobile
                      right: isMobile ? '10px' : '16px', // Adjust right spacing for mobile
                      left: isMobile ? '10px' : '20px', // Center on mobile by setting left and right
                      transform: isMobile ? 'none' : 'translateX(0)',
                      width: isMobile ? 'calc(100% - 20px)' : 'auto', // Ensure it doesn't overflow on mobile
                    }
                  }}
            >
                {
                    editTeamResponse?.status === "success" ?
                        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                            {editTeamResponse?.message}
                        </Alert>
                        :
                        <Alert onClose={() => setSnackbarOpen(false)} severity="failure" sx={{ width: '100%' }}>
                            {editTeamResponse?.message}
                        </Alert>
                }
            </Snackbar>
        </div>
    );
};

export default EditTeam;