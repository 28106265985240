import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, IconButton, Menu, MenuItem, Chip, Alert, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import TopHeaderScreen from "../../components/TopHeader1";
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CryptoJS from 'crypto-js';
import ShareButtons from '../Teams/ShareButtons';
import CustomShieldIcon from '../../components/CustomShieldIcon';

const secretKey = 'wq4r35';

const encryptTeamId = (teamId) => {
    // Encrypt the teamId
    const encrypted = CryptoJS.AES.encrypt(teamId, secretKey).toString();

    // Convert to Base64 and replace URL-unsafe characters
    const base64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encrypted));
    const urlSafeBase64 = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

    return urlSafeBase64;
};

const HeaderTableRow = styled(TableRow)({
    backgroundColor: '#808080', // Add the background color here
});

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: '16px',
    '@media (min-width: 600px)': {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
});

const Item = styled(Box)({
    flex: '1 1 48%',
    '@media (max-width: 600px)': {
        flex: '1 1 100%',
    },
});

const FullWidthItem = styled(Box)({
    flex: '1 1 100%',
});

const MyTeam = (props) => {
    const navigate = useNavigate();
    const [myTeamData, setMyTeamData] = useState([]);
    const [mySpecificTeamData, setMySpecificTeamData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [myTeamLBData, setMyTeamLBData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [addTeamMemberResponse, setAddTeamMemberResponse] = useState('');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [showShareButtons, setShowShareButtons] = useState(false);
    const [shareButtonPosition, setShareButtonPosition] = useState({ top: 0, left: 0 });
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    const topRef = useRef(null);

    useEffect(() => {
        if (topRef.current) {
        topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);


    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
        (state) => state.profile
    );

    const fetchMyTeam = async () => {
        try {
            const response = await axios.get(`/teams/my/team/${localStorage.getItem("userid")}`);
            setMyTeamData(response?.data)
        } catch (error) {
            console.error('Error fetching my team', error);
        }
    };

    const fetchMyTeamSpecificPage = async (teamidfrommenu) => {
        try {
            const response = await axios.get(`/teams/my/team/${teamidfrommenu}/${localStorage.getItem("userid")}`);
            setMySpecificTeamData(response.data);

        } catch (error) {
            console.error('Error fetching my team', error);
        }
    };

    console.log("mySpecificTeamData = ", mySpecificTeamData)


    const fetchMyTeamLB = async () => {
        try {
            const response = await axios.get(`/teams/lb/${localStorage.getItem("userid")}`);
            setMyTeamLBData(response?.data)
        } catch (error) {
            console.error('Error fetching my team', error);
        }
    };


    useEffect(() => {
        fetchMyTeam();
        fetchMyTeamLB();
    }, [profileResponse]);

    const handleMenuClick = async (event, teamId) => {
        setAnchorEl(event.currentTarget);
        setSelectedTeam(teamId);
        setShowShareButtons(false);
        await fetchMyTeamSpecificPage(teamId);
        // setSelectedUserId(userId);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedTeam(null);
        // setSelectedUserId(null)
    };
    const handleCreateTeam = () => {
        navigate("/create/team");
    };


    const handleLeaveTeam = async () => {
        try {
            // setSnackbarOpen(true);
            setAddTeamMemberResponse('');
            const response = await axios.post(`/teams/leave/memberowner/${localStorage.getItem("userid")}`, {
                teamid: selectedTeam,
                member: localStorage.getItem("userid"),
            });
            if (response.data.status === "success") {
                console.log(response.data.message);
                // setSnackbarMessage(response.data.message);
                setAddTeamMemberResponse(response.data);

            } else {
                console.error(response.data.message);
                // setSnackbarMessage(response.data.message);
                setAddTeamMemberResponse(response.data);

            }
            await fetchMyTeam();
            await fetchMyTeamLB();
        } catch (error) {
            console.error('There was an error making the user an owner!', error);

        } finally {
            setSnackbarOpen(true);
            handleMenuClose();
        }
    };

    const handleOpenConfirmDialog = () => {
        // setSelectedUserId(userId);
        setConfirmDialogOpen(true);
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const handleConfirmRemove = async () => {
        await handleLeaveTeam();
        setConfirmDialogOpen(false);
    };


    const handleShareClick = (event) => {
        const buttonRect = event.currentTarget.getBoundingClientRect();
        setShareButtonPosition({
            top: buttonRect.bottom + window.scrollY,
            left: buttonRect.left + window.scrollX
        });
        setShowShareButtons(!showShareButtons);
        handleMenuClose();
    };

    const handleShareClose = () => {
        setShowShareButtons(false);
    };

    const handleDeleteTeam = async () => {
        try {

            setAddTeamMemberResponse('');
            const response = await axios.post(`/teams/delete/team/${localStorage.getItem("userid")}`, {
                teamid: selectedTeam,

            });
            if (response.data.status === "success") {
                console.log(response.data.message);
                // setSnackbarMessage(response.data.message);
                setAddTeamMemberResponse(response.data);

            } else {
                console.error(response.data.message);
                // setSnackbarMessage(response.data.message);
                setAddTeamMemberResponse(response.data);

            }
            await fetchMyTeam();
            await fetchMyTeamLB();
        } catch (error) {
            console.error('There was an error deleting the team!', error);

        } finally {
            setSnackbarOpen(true);
            handleMenuClose();
        }
    };


    const handleOpenDeleteDialog = () => {
        // setSelectedUserId(userId);
        setConfirmDeleteOpen(true);
    };

    const handleConfirmDeleteClose = () => {
        setConfirmDeleteOpen(false);
    };

    const handleConfirmDelete = async () => {
        await handleDeleteTeam();
        setConfirmDeleteOpen(false);
    };


    return (
        <div className="wrapper" ref={topRef}>
            <ResponsiveDrawer {...props} />
            <section className="containers">
                <TopHeaderScreen {...props} />
                <div className="content content-wrap">
                    <div className="content-mrg createTeamPage">
                        <Button style={{ padding: "10px", color: "#1976d2" }} onClick={() => navigate(-1)}>
                            <i className="fa fa-caret-left" />
                            <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
                        </Button>
                        <div
                            className="content-title"
                            style={{
                                fontSize: 25,
                                fontWeight: "600",
                                marginBottom: 15,
                                marginTop: 5,
                            }}
                        >
                            Your Team
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    float: "right",
                                    marginBottom: "2px",
                                    backgroundColor: '#01539D', color: 'white',
                                    padding: "6px 16px"
                                }}

                                onClick={handleCreateTeam}
                            >
                                Create Teams&nbsp; <i class="fa fa-arrow-right"></i>
                            </Button>
                        </div>
                        <TableContainer style={{ marginBottom: "50px", maxHeight: "500px", overflowY: "auto" }}>
                            <Table stickyHeader style={{ borderCollapse: 'collapse' }}>
                                <TableHead>
                                    <TableRow style={{ borderTop: "none", borderBottom: "none" }}>
                                        <TableCell sx={{ width: { xs: '10%', sm: '10%', md: '10%' }, color: 'white', backgroundColor: '#808080' }}>Actions</TableCell>
                                        <TableCell sx={{ width: { xs: '20%', sm: '20%', md: '20%' }, color: 'white', backgroundColor: '#808080' }}>Team Name</TableCell>
                                        <TableCell sx={{ width: { xs: '40%', sm: '40%', md: '40%' }, color: 'white', backgroundColor: '#808080' }}>Team Goal</TableCell>
                                        <TableCell sx={{ width: { xs: '30%', sm: '30%', md: '30%' }, color: 'white', backgroundColor: '#808080' }}>Team Members</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {
                                        myTeamData.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                                    <div className="message-container">
                                                        <p style={{ marginBottom: "0px" }}>No teams found. Create your team or join a team to get started.</p>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            myTeamData.map((team, index) => (
                                                <React.Fragment key={team._id}>
                                                    <TableRow key={team._id} style={{ minHeight: "200px", marginBottom: '20px', borderTop: "none", borderBottom: "2px solid #000" }}>
                                                        <TableCell style={{ minHeight: "150px" }}>
                                                            <IconButton onClick={(event) => handleMenuClick(event, team.teamid)}>
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Menu
                                                                anchorEl={anchorEl}
                                                                open={Boolean(anchorEl)}
                                                                onClose={handleMenuClose}
                                                            >
                                                                <MenuItem onClick={() => handleOpenConfirmDialog()}>
                                                                    <ExitToAppIcon style={{ marginRight: 8, color: 'gray' }} />
                                                                    Leave from team
                                                                </MenuItem>
                                                                {
                                                                    mySpecificTeamData &&


                                                                    <MenuItem onClick={handleMenuClose}>
                                                                        <InfoIcon style={{ marginRight: 8, color: '#ADD8E6' }} />
                                                                        <Link to={`/team/home/${mySpecificTeamData?.[0]?.teamid}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                            About team
                                                                        </Link>
                                                                    </MenuItem>

                                                                }
                                                                <MenuItem onClick={handleShareClick}>
                                                                    <ShareIcon style={{ marginRight: 8, color: 'orange' }} />
                                                                    Share team
                                                                </MenuItem>

                                                                {
                                                                    mySpecificTeamData &&
                                                                        mySpecificTeamData?.[0]?.areyouowner === "yes" ?
                                                                        <>
                                                                            <MenuItem onClick={handleMenuClose}>
                                                                                <EditIcon style={{ marginRight: 8, color: '#565656' }} />
                                                                                <Link to={`/edit/team/${mySpecificTeamData?.[0]?.teamid}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                    Edit team
                                                                                </Link>

                                                                            </MenuItem>

                                                                            <MenuItem onClick={() => handleOpenDeleteDialog()}>
                                                                                <DeleteIcon style={{ marginRight: 8, color: 'gray' }} />
                                                                                <span >Delete team</span>
                                                                            </MenuItem>
                                                                        </>

                                                                        :
                                                                        <>
                                                                            <MenuItem disabled>
                                                                                <EditIcon style={{ marginRight: 8, color: '#565656' }} />
                                                                                <Link to={`/edit/team/${(team.teamid)}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                                    Edit team
                                                                                </Link>

                                                                            </MenuItem>


                                                                            <MenuItem disabled>
                                                                                <DeleteIcon style={{ marginRight: 8, color: 'red' }} />
                                                                                Delete team
                                                                            </MenuItem>
                                                                        </>
                                                                }

                                                            </Menu>
                                                            {showShareButtons && (
                                                                <div

                                                                    style={{
                                                                        position: 'absolute',
                                                                        bottom: '106px',
                                                                        right: '0',
                                                                        // right: isMobile ? '10%' : 'auto',
                                                                        transform: 'none',
                                                                        zIndex: 1000,
                                                                        width: isMobile ? 'auto' : 'auto',
                                                                        maxWidth: isMobile ? '300px' : 'none',
                                                                        backgroundColor: 'white',
                                                                        border: '1px solid #ccc',
                                                                        padding: '10px',
                                                                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                                                                    }}
                                                                >
                                                                    <ShareButtons
                                                                        url={
                                                                            "Hey! We're working on exciting social campaigns and I think you'd be a great addition to our team - " +
                                                                            mySpecificTeamData?.[0]?.name +
                                                                            "! Join us in making a difference and lets together win reward points. Click on this link https://app.cyaag.com now to join the team - " +
                                                                            mySpecificTeamData?.[0]?.name +
                                                                            " and get started."
                                                                        }
                                                                        onClose={handleShareClose} />
                                                                </div>
                                                            )}
                                                            <Dialog
                                                                open={confirmDialogOpen}
                                                                onClose={handleConfirmDialogClose}
                                                                sx={{
                                                                    '& .MuiBackdrop-root': {
                                                                        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Slightly dark backdrop with 30% opacity
                                                                    },
                                                                }}
                                                            >
                                                                <DialogTitle>{"Confirm Existing Team"}</DialogTitle>
                                                                <DialogContent>
                                                                    <DialogContentText>
                                                                        Are you sure you want to leave this team?
                                                                    </DialogContentText>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={handleConfirmDialogClose} color="primary">
                                                                        No
                                                                    </Button>
                                                                    <Button onClick={handleConfirmRemove} color="primary" autoFocus>
                                                                        Yes
                                                                    </Button>
                                                                </DialogActions>
                                                            </Dialog>

                                                            <Dialog
                                                                open={confirmDeleteOpen}
                                                                onClose={handleConfirmDeleteClose}
                                                                sx={{
                                                                    '& .MuiBackdrop-root': {
                                                                        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Slightly dark backdrop with 30% opacity
                                                                    },
                                                                }}
                                                            >
                                                                <DialogTitle>{"Confirm Deleting Team"}</DialogTitle>
                                                                <DialogContent>
                                                                    <DialogContentText>
                                                                        Are you sure you want to delete this team - {mySpecificTeamData?.[0]?.name} ? Once Deleted
                                                                        you cannot recover it.
                                                                    </DialogContentText>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={handleConfirmDeleteClose} color="primary">
                                                                        No
                                                                    </Button>
                                                                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                                                                        Yes
                                                                    </Button>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </TableCell>
                                                        <TableCell style={{ display: "inline-flex", alignItems: "center", width: '245px', minHeight: "150px" }}>
                                                            <div style={{
                                                                background: "linear-gradient(135deg, #ff7e5f, #feb47b)",
                                                                borderRadius: "5px",
                                                                width: "100%",
                                                                display: "flex",
                                                                padding: "8px",
                                                                alignItems: "center",
                                                                minHeight: "56px",

                                                            }}
                                                            >
                                                                <Avatar src={team.logo} alt={team.name} />
                                                                <div style={{ marginLeft: "8px", display: "flex", flexDirection: "column" }}>

                                                                    <Link
                                                                        to={`/team/home/${(team.teamid)}`}
                                                                        className="clickable-link"
                                                                        // style={{wordBreak: "break-all"}}
                                                                        style={{
                                                                            textOverflow: "ellipsis",
                                                                            wordBreak: "keep-all"
                                                                        }}
                                                                    >
                                                                        {team.name}
                                                                    </Link>
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                                    <Chip label={team.isowner.charAt(0).toUpperCase() + team.isowner.slice(1)}
                                                                        style={{ marginTop: '3px', padding: '0 3px', fontSize: '0.75rem', height: '20px', width: "100px" }}
                                                                    />
                                                                    {
                                                                        team.visibility === "private" &&
                                                                        
                                                                            <LockIcon style={{ fontSize: 16, color: 'red', marginLeft: '4px' }} />
                                                                        
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </TableCell>

                                                        <TableCell style={{ width: '900px', minHeight: "150px" }}>
                                                            <div style={{
                                                                maxHeight: '115px', overflowY: 'auto', display: 'block',
                                                                // scrollbarWidth: 'thin',

                                                            }}>
                                                                {team.goal.map((goal, index) => (
                                                                    <div key={index}>
                                                                        {goal}
                                                                        {index < team.goal.length - 1 && <hr style={{ margin: '4px 0', borderTop: '1px dotted' }} />}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <style>
                                                                {`
                                                                    /* WebKit browsers */
                                                                    div::-webkit-scrollbar {
                                                                        width: 3px; /* Adjust the width as needed */
                                                                        height: 3px;
                                                                    }
                                                                    
                                                                    div::-webkit-scrollbar-thumb {
                                                                        background-color: darkgrey;
                                                                        border-radius: 10px;
                                                                    }

                                                                    div::-webkit-scrollbar-track {
                                                                        background-color: #f1f1f1;
                                                                        border-radius: 10px;
                                                                    }
                                                                `}
                                                            </style>
                                                        </TableCell>
                                                        <TableCell style={{ display: "inline-flex", flexWrap: "wrap", minHeight: "150px" }}>
                                                        
                                                            <div style={{ overflowX: 'auto', maxWidth: "500px", display: "inline-flex" }}>
                                                                {team.memberdetails.map((member, index) => (
                                                                    <Box
                                                                        key={member.firstname}
                                                                        display="flex"
                                                                        alignItems="center"
                                                                        style={{ marginRight: index < team.memberdetails.length - 1 ? '8px' : '0' }}
                                                                    >
                                                                        
                                                                        <Avatar src={member.profilephoto} alt={member.firstname} />
                                                                        <div style={{ marginLeft: '4px' }}>{member.firstname} {member.lastname}</div>
                                                                    </Box>
                                                                ))}
                                                            </div>
                                                        </TableCell>

                                                        {/* ))} */}
                                                    </TableRow>

                                                    {index < myTeamData.length - 1 && (
                                                        <TableRow>
                                                            <TableCell colSpan={4} style={{ backgroundColor: 'black', padding: '0' }} />
                                                        </TableRow>
                                                    )}
                                                </React.Fragment>
                                            )))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div class="cyagg-sec-space">
                            <div class="container" style={{padding: '0px 0px 0px 0px'}}>
                                <div class="row">
                                    <div class="col-12">
                                        <div>
                                            <h4 class="site-title">Leaderboard Across All Your Teams</h4>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {
                                        myTeamLBData?.[0]?.rank === undefined ?
                                            <div className="message-container">
                                                <p style={{ marginBottom: "0px" }}>Create your team or join a team and get the Leaderboard running!</p>
                                            </div>
                                            : ""
                                    }
                                    <div class="row">
                                        {/* <div
                                            class="col-md-4 hide-on-mobile"
                                            style={{ paddingRight: "0px" }}
                                        >
                                            <div class="leaderboard-left">
                                                <div class="text-center">
                                                    <h5>Top Player</h5>
                                                    {
                                                        myTeamLBData?.[0]?.profilephoto != "" && myTeamLBData?.[0]?.profilephoto !== undefined ? (
                                                            <img
                                                                src={myTeamLBData?.[0]?.profilephoto}
                                                            ></img>
                                                        ) : (
                                                            <span>
                                                                
                                                                <AccountCircleIcon style={{ minWidth: "200px", minHeight: "200px" }} fontSize="large" />
                                                            </span>
                                                        )
                                                    }
                                                    <span style={{ fontSize: 36, padding: 24 }}>
                                                        {myTeamLBData &&
                                                            myTeamLBData?.[0]?.firstname}{" "}
                                                        {myTeamLBData?.[0]?.lastname}
                                                    </span>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div class="col-md-12 col-12">
                                            <div class="" style={{ maxHeight: "497px",overflow:"auto",minHeight:"200px" }}>
                                                <table stickyHeader class="leader-b-table" style={{ marginLeft: "2px" }}>
                                                    {/* <thead> */}
                                                    <thead style={{ top: isMobile ? "-2px" : "-1px", position: "sticky", zIndex: 10}}>
                                                        <tr>
                                                            <th scope="col" style={{ textAlign: "center" }}>
                                                                {" "}
                                                                Rank
                                                            </th>
                                                            <th scope="col" style={{ textAlign: "center" }}>
                                                                Top Player Name
                                                            </th>
                                                            <th scope="col" style={{ textAlign: "center" }}>
                                                                Total Points
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {myTeamLBData &&
                                                            myTeamLBData?.map((row) => (
                                                                <tr>
                                                                    {
                                                                        row?.rank === 1 || row?.rank === 2 || row?.rank === 3 ?
                                                                            <td
                                                                                data-label="Account"
                                                                                style={{ textAlign: "center", backgroundColor: "#74C3FC" }}
                                                                            >
                                                                                <div style={{ position: "relative", display: "inline-block" }}>
                                                                                    {
                                                                                        row?.rank === 1 ?
                                                                                            <CustomShieldIcon color="gold" />
                                                                                            : row?.rank === 2 ?
                                                                                                <CustomShieldIcon color="whitesmoke" />
                                                                                                : row?.rank === 3 ?
                                                                                                    <CustomShieldIcon color="#CD7F32" />
                                                                                                    :
                                                                                                    <CustomShieldIcon color="silver" />
                                                                                    }
                                                                                    <span style={{
                                                                                        position: "absolute",
                                                                                        top: "50%",
                                                                                        left: "50%",
                                                                                        transform: "translate(-50%, -50%)",
                                                                                        fontSize: "16px",
                                                                                        color: "black",
                                                                                        fontWeight: "bold"
                                                                                    }}>
                                                                                        {row.rank}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            :
                                                                            <td
                                                                                data-label="Account"
                                                                                style={{ textAlign: "center", }}
                                                                            >
                                                                                <div style={{ position: "relative", display: "inline-block" }}>

                                                                                    <CustomShieldIcon color="silver" />
                                                                                    <span style={{
                                                                                        position: "absolute",
                                                                                        top: "50%",
                                                                                        left: "50%",
                                                                                        transform: "translate(-50%, -50%)",
                                                                                        fontSize: "16px",
                                                                                        color: "black",
                                                                                        fontWeight: "bold"
                                                                                    }}>
                                                                                        {row.rank}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                    }
                                                                    {
                                                                        row?.rank === 1 || row?.rank === 2 || row?.rank === 3 ?
                                                                            <td data-label="Due Date" style={{ backgroundColor: "#74C3FC" }}>
                                                                                <div class="top-player-box" >

                                                                                    <div class="top-player-box" style={{ textAlign: "center" }}>
                                                                                        {row?.profilephoto != "" ? (
                                                                                            <img src={row?.profilephoto}></img>
                                                                                        ) : (
                                                                                            <Avatar style={{ display: "inline-block", verticalAlign: "middle" }} src="/broken-image.jpg" />
                                                                                        )}
                                                                                    </div>
                                                                                    <div class="top-player-box" style={{ textAlign: "center" }}>
                                                                                        {"   "}
                                                                                        {row?.firstname === "YOU" ? (
                                                                                            <span
                                                                                                style={{
                                                                                                    color: "blueviolet",
                                                                                                    fontWeight: "bold",
                                                                                                }}
                                                                                            >
                                                                                                {row?.firstname}
                                                                                            </span>
                                                                                        ) : (
                                                                                            <span>{row?.firstname}</span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            :
                                                                            <td data-label="Due Date">
                                                                                <div class="top-player-box" >

                                                                                    <div class="top-player-box" style={{ textAlign: "center" }}>
                                                                                        {row?.profilephoto != "" ? (
                                                                                            <img src={row?.profilephoto}></img>
                                                                                        ) : (
                                                                                            <Avatar style={{ display: "inline-block", verticalAlign: "middle" }} src="/broken-image.jpg" />
                                                                                        )}
                                                                                    </div>
                                                                                    <div class="top-player-box" style={{ textAlign: "center" }}>
                                                                                        {"   "}
                                                                                        {row?.firstname === "YOU" ? (
                                                                                            <span
                                                                                                style={{
                                                                                                    color: "orange",
                                                                                                    fontWeight: "bold",
                                                                                                }}
                                                                                            >
                                                                                                {row?.firstname}
                                                                                            </span>
                                                                                        ) : (
                                                                                            <span>{row?.firstname}</span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                    }
                                                                    {row?.rank === 1 || row?.rank === 2 || row?.rank === 3 ?
                                                                        <td
                                                                            data-label="Amount"
                                                                            style={{ textAlign: "center", backgroundColor: "#74C3FC", fontWeight: 600, fontSize: "18px" }}
                                                                        >
                                                                            {row?.totalpoints?.toFixed(1)}
                                                                        </td>
                                                                        :
                                                                        <td
                                                                            data-label="Amount"
                                                                            style={{ textAlign: "center", fontWeight: 600, fontSize: "18px" }}
                                                                        >
                                                                            {row?.totalpoints?.toFixed(1)}
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <style>
                                                {`
                                                    /* WebKit browsers */
                                                    .fixedHeader::-webkit-scrollbar {
                                                        height: 4px; /* Adjust the height as needed */
                                                    }

                                                    .fixedHeader::-webkit-scrollbar-thumb {
                                                        background-color: darkgrey;
                                                        border-radius: 10px;
                                                    }

                                                    .fixedHeader::-webkit-scrollbar-track {
                                                        background-color: #f1f1f1;
                                                        border-radius: 10px;
                                                    }
                                                `}
                                            </style>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CopyRight />
                <FixedFooterIcon />
            </section>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'middle', horizontal: 'center' }}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        top: '90%',
                        // left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }
                }}
            >

                {
                    addTeamMemberResponse &&
                        addTeamMemberResponse?.status === "success" ?
                        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%', backgroundColor: '#28A745', color: 'black', }}>

                            {addTeamMemberResponse?.message}

                        </Alert>
                        :
                        <Alert onClose={() => setSnackbarOpen(false)} severity="failure" sx={{ width: '100%', backgroundColor: '#B9544B', color: 'white', }}>

                            {addTeamMemberResponse?.message}
                        </Alert>
                }

            </Snackbar>
        </div>
    );
};

export default MyTeam;
