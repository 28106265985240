import React from "react";
import { isArray } from "lodash";
import _ from "lodash";
import { isEmpty } from "lodash";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { userProfile } from "../redux/action/UserProfileActions";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../components/Header";
import { CopyRight } from "../components/CopyRight";
import TopHeaderScreen from "../components/TopHeader1";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import RedeemConfirmation from "../components/RedeemConfirmation";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { Box, Button, Card, CardContent, Chip, Paper, Typography, useTheme, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import PostRedeemConfirmation from "../components/PostRedeemConfirmation";
import CEOImage from "../assets/ceo.png";
import AsyncSelect from "react-select/async";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import { styled } from '@mui/system';
import SVGCoin from '../icons/SVGCoin';
import SVGFree from '../icons/SVGFree';
import PopupMarketplace from './PopupMarketplace';
import RewardCard from "../components/RewardCard";

const StyledCard = styled(Card)(({ theme }) => ({
  width: '200px',
  marginBottom: "30px",
  float: "left",
  // margin: '10px',
  [theme.breakpoints.down('sm')]: {
    width: '200px',
  },
  marginLeft: '1px',
  minHeight:'264px'
  // backgroundColor:'gray'
}));


function Marketplace(props) {
  const navigate = useNavigate();
  const [marketplacelist, setmarketplacelist] = useState(null);
  const [rewardTypeList, setRewardTypeList] = React.useState([]);
  const [pointsList, setPointsList] = React.useState([]);
  const [selectedrewardtype, setselectedrewardtype] = React.useState([]);
  const [selectedpointsrange, setselectedpointsrange] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [afterRedeem, setAfterRedeem] = useState([]);
  const [displayRedeemConfirmationModal, setDisplayRedeemConfirmationModal] =
    useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [redeemindex, setRedeemIndex] = useState(0);
  const [postRedeemDisplayModal, setPostRedeemDisplayModal] = useState(false);
  const [redeemCount, setRedeemCount] = useState(0);
  const [displayRewardPopup, setDisplayRewardPopup] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleCardClick = (index) => {
    console.log("Clicked card index:", index);
    setRewardDisplayAndIndex(true, index);
    setSelectedIndex(index);
  };

  const setRewardDisplayAndIndex = (isOpen, index) => {
    if (isOpen) {
      setSelectedIndex(index);
      setDisplayRewardPopup(true);
    }
    console.log("Popup state updated: isOpen =", isOpen, "index =", index);
  };


  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const [searchValues, setSearchValues] = React.useState({
    rewardType: "",
    points: "",
  });
  const marketplaceRef = React.useRef();

  function setDisplayAndIndex(bool, index) {
    setDisplayRedeemConfirmationModal(bool);
    setRedeemIndex(index);
  }
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(userProfile(localStorage.getItem("userid")));

  }, []);


  React.useEffect(() => {
    getRewardsFromMarketplace();
    marketplaceRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [redeemCount]);

  React.useEffect(() => {
    searchRewardTypeList();
  }, []);

  React.useEffect(() => {
    searchPoints();
  }, []);

  React.useEffect(() => {
    if (selectedrewardtype.length === 0 && !selectedpointsrange.length === 0) {

      getRewardsFromMarketplaceOnSearch();
    } else if (
      !selectedrewardtype.length === 0 &&
      selectedpointsrange.length === 0
    ) {

      getRewardsFromMarketplaceOnSearch();
    } else {
      getRewardsFromMarketplaceOnSearch();
    }
  }, []);


  const getRewardsFromMarketplace = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
      const res = await axios
        .get(`/marketplace/read/${localStorage.getItem("userid")}`)
        .then((response) => {
          setmarketplacelist(response?.data);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };


  const getRewardsFromMarketplaceOnSearch = async () => {

    // console.log(selectedpointsrange.length === 0);
    try {
      // const res = ""
      if (
        selectedrewardtype.length === 0 &&
        !(selectedpointsrange.length === 0)
      ) {

        const res = await axios
          .get(
            `/marketplace/read/${localStorage.getItem("userid")}/"SPACE"/${selectedpointsrange[0]["label"]}`
          )
          .then((response) => {
            setmarketplacelist(response?.data);
            return response;
          });
      } else if (
        !(selectedrewardtype.length === 0) &&
        selectedpointsrange.length === 0
      ) {

        const res = await axios
          .get(
            `/marketplace/read/${localStorage.getItem("userid")}/${JSON.stringify(
              selectedrewardtype
            )}/SPACE`
          )
          .then((response) => {
            setmarketplacelist(response?.data);
            return response;
          });
      } else if (
        !(selectedrewardtype.length === 0) &&
        !(selectedpointsrange.length === 0)
      ) {


        const res = await axios
          .get(
            `/marketplace/read/${profileResponse?.phoneno}/${JSON.stringify(
              selectedrewardtype
            )}/${selectedpointsrange[0]["label"]}`
            // `/marketplace/read/${profileResponse?.phoneno}/${selectedrewardtype[0]["label"]}/${selectedpointsrange[0]["label"]}`
          )
          .then((response) => {
            setmarketplacelist(response?.data);
            return response;
          });
      } else {

        const res = await axios
          .get(`/marketplace/read/${localStorage.getItem("userid")}`)
          .then((response) => {
            setmarketplacelist(response?.data);
            return response;
          });
      }
    } catch (err) {
      throw err;
    }
  };



  const searchRewardTypeList = async () => {
    try {
      const res = await axios.get(`searchrewardtypes/${localStorage.getItem("userid")}`);
      setRewardTypeList(res?.data);

      return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  const searchPoints = async () => {
    try {
      const res = await axios.get(`searchpoints`);
      setPointsList(res?.data);

      return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  const handleRedeem = async () => {
    // setDisplayConfirmationModal(true);


    const payload = {
      //campid: locationPath?.state?.campid,
      // createdate: locationPath?.state?.createdate,
      // reviewstatus: reviewstatus,
      // reviewcomments: reviewComments,
      phoneno: localStorage.getItem("userid"),
      rewardtype: marketplacelist?.[redeemindex]?.rewardtype,
      mpid: marketplacelist?.[redeemindex]?.mpid,
      campid: marketplacelist?.[redeemindex]?.campid,
      title: marketplacelist?.[redeemindex]?.title,
      subtitle: marketplacelist?.[redeemindex]?.subtitle,
      points: marketplacelist?.[redeemindex]?.points,
      // sponsor: JSON.stringify(marketplacelist?.data?.[redeemindex]?.sponsor),
      sponsor: marketplacelist?.[redeemindex]?.sponsor,
      rewardfamilyid: marketplacelist?.[redeemindex]?.rewardfamilyid,

      // phoneno: locationPath?.state.phoneno,
    };
    try {
      const res = await axios.post(
        `/reward/redeem/${profileResponse?.phoneno}`,
        payload
      );
      // navigate("/reviewcampaign");
      setAfterRedeem(res?.data);

      // alert("calling set display false")
      setDisplayRedeemConfirmationModal(false);
      if (res?.data?.message === "Reward successfully redeemed") {
        setPostRedeemDisplayModal(true);
        // navigate("/marketplace");
      } else {
        setDisplayConfirmationModal(true);
      }
      // navigate('/marketplace');
      setRedeemCount(redeemCount + 1);
      return res;
    } catch (err) {
      throw err;
    }
  };

  // Hide the modal
  const hideRedeemConfirmationModal = () => {
    setDisplayRedeemConfirmationModal(false);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handlePostRedeemModal = () => {
    setPostRedeemDisplayModal(false);
    navigate("/marketplace");
  };

  const handleImageLoad = async (img) => {
    const im = await Promise.all(img);
    // console.log(im);
    if (im) {
      return true;
    } else {
      return false;
    }

    // Additional actions to perform after the image is loaded
  };

  const options = {
    items: 3,
    margin: 10,
    loop: false,
    nav: false,
    autoplaySpeed: 900,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const rewardTypeloadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = rewardTypeList.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );

      callback(filteredOptions);
    });
  };

  const pointsloadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = pointsList.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );

      callback(filteredOptions);
    });
  };

  const colorStyles = {
    control: (styles) => ({ ...styles }),
    // option: (styles, {data, isDisabled, isFocussed, isSelected}) => {
    //   return{...styles, color: "blue"};
    // },
    multiValue: (styles, { data }) => {
      return { ...styles, backgroundColor: "#0096FF", color: "#fff" };
    },
    multiValueLabel: (styles, { data }) => {
      return { ...styles, color: "#fff" };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": {
          color: "#fff",
        },
      };
    },
  };
  const theme = useTheme();
  // React.useEffect(() => {
  //   console.log("displayRewardPopup:", displayRewardPopup);
  //   console.log("selectedIndex:", selectedIndex);
  // }, [displayRewardPopup, selectedIndex]);


  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg" ref={marketplaceRef}>
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14, color: "#1976d2" }}>
                Back
              </span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Marketplace
            </div>
            <>
              <div>
                <div class="formMarketPlace">
                  <div>
                    <div
                      class="form-market-wrap marketplaceSearchTitle"
                      id=""
                      role="tabpanel"
                      aria-labelledby="nav-places-tab"

                    >
                      <h6>What are you looking for?</h6>
                      <form action="#" method="get">
                        <div
                          class="marketplaceSearch"
                          name="rewardtype"
                        >
                          <AsyncSelect
                            maxMenuHeight={160}
                            loadOptions={rewardTypeloadOptions}
                            defaultOptions={rewardTypeList}
                            value={selectedrewardtype}
                            isMulti
                            styles={colorStyles}
                            class="selectDrop"
                            inputId="rewardtype"
                            placeholder="Select rewards"
                            onChange={(event) => {
                              setselectedrewardtype(event);
                            }}
                          />
                        </div>
                        <div
                          class="marketplaceSearch"
                        >
                          <AsyncSelect
                            maxMenuHeight={160}
                            loadOptions={pointsloadOptions}
                            defaultOptions={pointsList}
                            value={selectedpointsrange}
                            isMulti
                            placeholder="Select points range"
                            isOptionDisabled={() =>
                              selectedpointsrange.length >= 1
                            }
                            styles={colorStyles}
                            name="pointsrange"
                            onChange={(event) => {
                              setselectedpointsrange(event);
                            }}
                          />
                        </div>
                        <Button
                          class="btn market-search"
                          variant="contained"
                          onClick={getRewardsFromMarketplaceOnSearch}
                          className="btn save-btn"
                          disableElevation
                          name="search"
                        >
                          <i class="fa fa-search pr-2" aria-hidden="true"></i>
                          <span>Search</span>
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div>
                {
                  !isEmpty(marketplacelist) && marketplacelist &&
                  <RewardCard
                    page="marketplace"
                    marketplacelist={marketplacelist}
                  />
                }
              </div> */}

              <div class="containers">
                <div class="cyagg-sec-space">
                  <div>
                    <div class="col-md-12">
                      <div class="" style={{ float: 'left', width: '100%', backgroundColor: '#FAFAFA' }}>
                        <div className="boxContainerWrapper">
                          {marketplacelist &&
                            marketplacelist?.map((reward, index) => (
                              <StyledCard
                                key={index}
                                sx={{
                                  marginBottom: '16px',
                                  marginRight: '16px'
                                }}
                                className="boxContent"
                              >
                                <Box onClick={() => handleCardClick(index)} style={{ cursor: 'pointer' }}>
                                  {
                                    !(reward?.logo === "") ? (
                                      reward?.expired === "yes" ?
                                        <img
                                          src={reward?.logo}
                                          style={{ width: "100%", opacity: 0.4, maxHeight: '80px' }}
                                        />
                                        :
                                        <img
                                          src={reward?.logo}
                                          style={{ width: "100%", maxHeight: '80px' }}
                                        />
                                    ) : (
                                      reward?.expired === "yes" ?
                                        <img
                                          src={CEOImage}
                                          style={{ width: "100%", opacity: 0.4, maxHeight: '80px' }}
                                        />
                                        :
                                        <img
                                          src={CEOImage}
                                          style={{ width: "100%", maxHeight: '80px' }}
                                        />
                                    )
                                  }
                                </Box>
                                <Box>
                                  <div className="text-center">
                                    <div className="row sponserMarketplaceWrapper">
                                      {reward?.sponsor?.length > 1 ? (
                                        <marquee scrollamount="2">
                                          {Array.isArray(reward?.sponsor) &&
                                            reward?.sponsor?.map((reward, index) =>
                                              handleImageLoad(reward.sponsorlogo) ? (
                                                <img
                                                  style={{
                                                    width: "auto",
                                                    height: "30px",
                                                    marginRight: "20px",
                                                    marginTop: "5px",
                                                  }}
                                                  src={reward.sponsorlogo}
                                                  key={index}
                                                  alt="reward"
                                                />
                                              ) : null
                                            )}
                                        </marquee>
                                      ) : (
                                        <div>
                                          {Array.isArray(reward?.sponsor) &&
                                            reward?.sponsor?.map((reward, index) =>
                                              handleImageLoad(reward.sponsorlogo) ? (
                                                <img
                                                  style={{
                                                    width: "auto",
                                                    height: "30px",
                                                  }}
                                                  src={reward.sponsorlogo}
                                                  key={index}
                                                  alt="reward"
                                                />
                                              ) : null
                                            )}
                                        </div>
                                      )}
                                    </div>
                                    <style>
                                      {`
                                        .marquee {
                                          display: inline-block;
                                          white-space: nowrap;
                                          animation: marquee 10s linear infinite;
                                        }

                                        @keyframes marquee {
                                          0% { transform: translateX(100%); }
                                          100% { transform: translateX(-100%); }
                                        }
                                      `}
                                    </style>
                                  </div>
                                </Box>

                                <Box display="flex" justifyContent="space-between" mb={1}>
                                  {
                                    reward?.expired === "yes" ? (
                                      <h3 class="expiry-txt" style={{ color: "red" }}>
                                        EXPIRED
                                      </h3>
                                    ) : ""
                                  }
                                </Box>
                                <Box display="flex" justifyContent="space-between" mb={1} pl={1}>
                                  {
                                    <h5 class="mb-0 mt-0 reward-desc-title" onClick={() => handleCardClick(index)} style={{ cursor: 'pointer' }}>
                                      {reward?.rewardtype}
                                    </h5>
                                  }
                                </Box>
                                <Box display="flex" justifyContent="space-between" mb={1} pl={1}>
                                  {
                                    <h5 class="mb-0 mt-0 reward-desc-title" onClick={() => handleCardClick(index)}
                                      style={{
                                        color: '#808080',
                                        fontFamily: "Roboto Serif",
                                        fontSize: '12px',
                                        fontStyle: 'italic',
                                        fontWeight: 400,
                                        lineHeight: 'normal',
                                        cursor: 'pointer'
                                      }}
                                    >
                                      {reward?.title}
                                    </h5>
                                  }
                                </Box>
                                <Box display="flex" justifyContent="space-between" mb={1} pl={1}>
                                  {
                                    reward?.campid !== "camp00001" ? (
                                      <h5 class="mb-0 mt-0 reward-desc-title"
                                        style={{
                                          color: '#808080',
                                          fontFamily: "Roboto Slab",
                                          fontSize: '12px',
                                          fontWeight: 400,
                                          lineHeight: 'normal',
                                          wordWrap: 'break-word',
                                          whiteSpace: 'normal'
                                        }}
                                      >
                                        On successful participation in
                                        <span
                                          style={{
                                            color: '#007BFF',
                                            textDecoration: 'none',
                                            fontWeight: 500,
                                            cursor: 'pointer',
                                            paddingLeft: '4px'
                                          }}
                                          onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
                                          onMouseOut={(e) => e.target.style.textDecoration = 'none'}
                                          onClick={() => navigate(
                                            `/participate/campaign/${reward?.campid}/${profileResponse?.phoneno}`
                                          )}
                                        >
                                          {reward?.campname}
                                        </span>
                                      </h5>
                                    ) : (
                                      <h5 class="mb-0 mt-0 reward-desc-title"
                                        style={{
                                          color: '#808080',
                                          fontFamily: "Roboto Slab",
                                          fontSize: '12px',
                                          fontWeight: 400,
                                          lineHeight: 'normal',
                                          wordWrap: 'break-word',
                                          whiteSpace: 'normal'
                                        }}
                                      >
                                        On earning the required points, Redeem now

                                      </h5>
                                    )
                                  }
                                </Box>

                                <div class="redeem-pts">
                                  <div class="row">
                                    <div class="col-6" style={{ display: "inline-flex" }}>
                                      {reward?.rewardcat === "rewards" &&
                                        reward?.campid === "camp00001" ? (
                                        <div class="re-po-left">
                                          <SVGCoin style={{ marginTop: '4px' }} />
                                          <div class="pts" style={{ marginLeft: "19px" }}>
                                            <h5>{reward?.points}</h5>
                                            <span>Points</span>
                                          </div>
                                        </div>
                                      ) : (
                                        <div class="re-po-left">
                                          <SVGFree style={{ marginTop: '4px' }} />
                                          <div class="pts" style={{ marginLeft: "19px" }}>
                                            <h5>0</h5>
                                            <span>Points</span>
                                          </div>
                                        </div>
                                      )}
                                      {
                                        ((reward?.campid === "camp00001" &&
                                          reward?.rewardcat === "rewards" && !(reward?.expired === "yes")) || (reward?.registrationstatus ===
                                            "approved" && !(reward?.campid === "camp00001") && !(reward?.expired === "yes") &&
                                            reward?.rewardcat === "rewards") ||
                                          (reward?.registrationstatus === "approved" && !(reward?.campid === "camp00001") && !(reward?.expired === "yes") &&
                                            reward?.rewardcat === "recognitions" && reward?.recogeligibility === "yes")) ? (

                                          <div class="re-po-right" style={{ marginLeft: "17px" }}>
                                            <Button
                                              onClick={() =>
                                                setDisplayAndIndex(
                                                  true,
                                                  index
                                                )
                                              }
                                              style={{ float: "right" }}
                                            >
                                              <a>Redeem</a>
                                            </Button>
                                          </div>
                                        ) : (
                                          <div class="re-po2-center" style={{ marginLeft: "17px" }}>
                                            <Button
                                              disabled
                                            >
                                             <a className={`marketplacelist ${selectedIndex}`}>Redeem</a>
                                            </Button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                {/* <PopupMarketplace
                                  isOpen={displayRewardPopup}
                                  setIsOpen={() => setDisplayRewardPopup(false)}
                                  reward={marketplacelist[selectedIndex]}
                                /> */}
                              </StyledCard>

                            ))}
                        </div>
                        {displayRewardPopup && (
                            <PopupMarketplace
                              isOpen={displayRewardPopup}
                              setIsOpen={() => setDisplayRewardPopup(false)}
                              reward={marketplacelist[selectedIndex]}
                            />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>




            </>
          </div>
        </div>

        <RedeemConfirmation
          showModal={displayRedeemConfirmationModal}
          confirmModal={handleRedeem}
          hideModal={hideRedeemConfirmationModal}
          message={"Are you sure you want to redeem this reward?"}
        />
        <ConfirmationDialog
          showModal={displayConfirmationModal}
          hideModal={hideConfirmationModal}
          message={"Either You do not have enough points or reward is not available for redeem"}
        />
        <PostRedeemConfirmation
          showModal={postRedeemDisplayModal}
          confirmModal={handlePostRedeemModal}
          message={
            "Congratulations!!! You have successfully redeemed the reward. You can check for updates from Redeem Status on left nav pane."
          }
        />
        <div className="marketplaceCopyright">
          <CopyRight />
        </div>
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default Marketplace;
