import {
  Chip,
  Button,
  Avatar,
  useMediaQuery,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVolunteerCount } from "../../redux/action/volunteerCountAction";
import { getCampaign } from "../../redux/action/CampaignAction";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate, useLocation } from "react-router-dom";
import RewardMainImage from "../../assets/rew.png";
import cYAAGlogo from "../../assets/sdp.jpeg";
import Impact1 from "../../assets/impact1.png";
import Impact2 from "../../assets/impact2.png";
import Impact3 from "../../assets/impact3.png";
import VideoInterviewImage from "../../assets/videointerview.png";
import CertificateImage from "../../assets/certificate.png";
import DiscountCouponImage from "../../assets/discountcoupon.png";
import GiftCardImage from "../../assets/giftcard.png";
import InternshipImage from "../../assets/internship.png";
import SocialMediaImage from "../../assets/socialmedia.png";
import CEOImage from "../../assets/ceo.png";
import { isEmpty, isArray } from "lodash";
import BronzeLvl from "../../assets/bronze.png";
import SilverLvl from "../../assets/silver.png";
import GoldLvl from "../../assets/gold.png";
import DiamondLvl from "../../assets/diamond.png";
import PlatinumLvl from "../../assets/platinum.png";
import { Audio, Circles } from "react-loader-spinner";
import CampaignCard from "../../components/CampaignCard";
import { styled } from '@mui/material/styles';

const SubtitleWrapper = styled('div')({
  textAlign: 'center',
});

const PointsWrapper = styled('div')({
  padding: 3,
  margin: 4,
  borderRadius: 4,
  textAlign: 'center',
});

const CampaignPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();
  const [campaignList, setCampaignList] = useState([]);
  const [rewardList, setRewardList] = useState([]);
  const [marketPlace, setMarketPlace] = useState([]);
  const [sponsor, setSponsor] = useState([]);
  const [refDetails, setRefDetails] = useState([]);
  const [referredUser, setReferredUser] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasCampLoaded, setHasCampLoaded] = React.useState(false);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const { isSuccess: campSuccess, response: campResponse } = useSelector(
    (state) => state.getCampaign
  );
  // console.log(campResponse);
  // const refferalId = localStorage.getItem("refferalId");
  // const userid = localStorage.getItem("userid");


  useEffect(() => {
    localStorage.setItem("userid", "");
    localStorage.setItem("profileurl", "");
    localStorage.setItem("token", "");
    // localStorage.setItem("title", "");
    localStorage.setItem("title", locationPath?.pathname.split("/")?.[2]);
  }, [campaignList, locationPath?.pathname.split("/")?.[2]]);

  useEffect(() => {
    if (campaignList?.data?.length > 0) {
      setHasCampLoaded(true);
    }

  }, [campaignList]);




  useEffect(() => {
    // getVolunteerCamp();
    getRewardList();
  }, []);

  useEffect(() => {
    getMarketPlace();
  }, [setMarketPlace]);

  React.useEffect(() => {

    dispatch(getVolunteerCount());
    dispatch(
      getCampaign(profileResponse.orgrole, profileResponse.organization)
    );
    getSponsors();

  }, [campaignList]);

  // useEffect(() => {
  //   // Promise.all([getRefCodeAutomatically()])
  //   //   .then(() => getCampaignDetails());
  //   getRefCodeAutomatically();
  //   getCampaignDetails();
  //   // dispatch(getVolunteerCount());

  // }, [campaignList?.data?.length]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await getRefCodeAutomatically();
  //       if (refDetails && Object.keys(refDetails).length !== 0) {
  //         await getCampaignDetails();
  //       }
  //     } catch (error) {
  //       // Handle errors if needed
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, [refDetails]); // Include refDetails as a dependency


  useEffect(() => {
    getCampaignDetails();
  }, []); // Include refDetails as a dependency

  console.log("locationPath?.pathname.split()?.[1] = ", locationPath?.pathname.split("/")?.[1])
  console.log("locationPath?.pathname.split()?.[2] = ", locationPath?.pathname.split("/")?.[2])
  console.log("locationPath?.pathname.split()?.[3] = ", locationPath?.pathname.split("/")?.[3])
  const getCampaignDetails = async () => {

    try {
      setIsLoading(true);
      console.log("isLoading = ", isLoading);
      const res = await axios.get(`vol_dashboard/camp/direct/nonref/${locationPath?.pathname.split("/")?.[2]}`);
      setCampaignList(res);
    } finally {
      setIsLoading(false);
      localStorage.setItem("title", locationPath?.pathname.split("/")?.[2]);
    }
  };

  console.log("localStorage.getItem = ", localStorage.getItem("title"));


  const getRewardList = async () => {
    try {
      const res = await axios.get(`/success/stories`).then((response) => {
        setRewardList(response?.data);
        return response;
      });
    } catch (err) {
      throw err;
    }
  };

  const getMarketPlace = async () => {
    try {
      const res = await axios
        .get(`/landing/marketplace/read`)
        .then((response) => {
          setMarketPlace(response?.data);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  const getSponsors = async () => {
    try {
      const res = await axios
        .get(`/sponsor`)
        .then((response) => {
          if (response.data) {
            setSponsor(response?.data);
          }
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  const options = {
    items: 3,
    margin: 10,
    loop: false,
    nav: false,
    autoplaySpeed: 900,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const optionsmob = {
    items: 3,
    margin: 10,
    loop: true,
    nav: false,
    autoplaySpeed: 900,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const optionsFour = {
    items: 4,
    margin: 10,
    loop: true,
    nav: false,
    autoplaySpeed: 900,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  const optionsTwo = {
    items: 2,
    margin: 10,
    loop: false,
    nav: false,
    autoplaySpeed: 900,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const isDesktop = useMediaQuery("(min-width:600px)");
  const [SDGCampMapping, setSDGCampMapping] = React.useState([]);

  // console.log("campaignList = ", campaignList);
  // console.log("campaignList = ", campaignList?.data?.length);
  // console.log("isLoading = ", isLoading);
  // console.log("hasCampLoaded = ", hasCampLoaded);

  console.log("referredUser = ", referredUser)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`camp/sdg/mapping`);
        setSDGCampMapping(res?.data);
      } catch (err) {
        console.error("Error fetching SDG camp mapping:", err);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [campaignList]);

  const handleImageLoad = async (img) => {
    const im = await Promise.all(img);
    // console.log(im);
    if (im) {
      return true;
    } else {
      return false;
    }

    // Additional actions to perform after the image is loaded
  };

  console.log("SDGCampMapping = ", SDGCampMapping)
  return (
    <div class="landingPageWrapper">
      <div class="desktop-nav header navbar" style={{ marginBottom: "0px", padding: "7px 0px 0px 0px" }}>
        <div class="container" style={{ "justifyContent": "center" }}>
          <div class="nav-logo-land-direct">
            {" "}
            <img src={cYAAGlogo} class="img-fluid" alt="logo" style={{ maxHeight: "45px" }} />

            <figcaption >Powered by cYAAG</figcaption>

          </div>
          {/* <ul class="navbar-nav" style={{ display: "inline-block" }}>
            <li class="nav-item" name="login" style={{ display: "inline-block" }}>
              <a
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </a>
            </li>
            <li
              class="nav-item"
              style={{ display: "inline-block", marginLeft: "20px" }}
            >
              <a
                class="cy-reg-btn"
                onClick={() => {
                  navigate("/register");
                }}
              >
                Sign Up
              </a>
            </li>
          </ul> */}
        </div>
      </div>
      <div>


        <div class="container">
          <div class="cyagg-sec-space campaigns-tabs">
            <div>
              <div class="row" style={{ justifyContent: "center" }}>
                <div class="col-10">
                  <div>
                    {/* <h4 class="site-title-camp-direct" >Welcome! You were invited by {" "} <span style={{ color: '#db8d0f' }}>
                          {referredUser["firstname"]}
                        </span>{" "}
                        <span style={{ color: '#db8d0f' }}>
                          {referredUser["lastname"]}
                        </span>
                    </h4> */}
                  </div>
                </div>
              </div>
            </div>
            {
              isLoading || campaignList?.data?.length === 0 || SDGCampMapping.length === 0 ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
                  <Circles
                    type="TailSpin"
                    color="rgb(155, 236, 34)"
                    height={70}
                    width={70}
                    timeout={5000}
                    ariaLabel="three-circles-rotating"
                  />
                </div>
              ) : (


                <div class="row" style={{ justifyContent: "center" }}>
                  <div class="col-md-6">
                    <div>
                      <div>

                        {/* {campaignList && campaignList?.data?.[0]?.active === "Completed" ? (
  <Typography
    style={{
      textAlign: "center",
    }}
  >
    <Chip
      key={campaignList?.data?.[0].active}
      fontSize="small"
      size="small"
      style={{
        backgroundColor: "gray",
        color: "white",
        marginBottom: "5px",
      }}
      label={campaignList?.data?.[0].active}
    />
  </Typography>
) : (
  <Typography
    style={{
      textAlign: "center",
    }}
  >
    <Chip
      key={campaignList?.data?.[0].active}
      fontSize="small"
      size="small"
      style={{
        backgroundColor:
          campaignList?.data?.[0].active === "Paused"
            ? "#1976d2"
            : "green",
        color: "white",
        marginBottom: "5px",
      }}
      label={campaignList?.data?.[0].active}
    />
  </Typography>
)} */}

                        <Typography style={{ textAlign: "center" }}>
                          <Chip
                            key={campaignList?.data?.[0].active}
                            fontSize="small"
                            size="small"
                            style={{
                              backgroundColor:
                                campaignList?.data?.[0].active === "Completed"
                                  ? "#6C757D"
                                  : campaignList?.data?.[0].active === "Paused"
                                    ? "#EF4E4E"
                                    : campaignList?.data?.[0].active === "Upcoming"
                                      ? "#FADB5F"
                                      : campaignList?.data?.[0].active === "Active"
                                        ? "#0D731E"
                                        : "#000000", // Default color, if none of the conditions match
                              color:
                                campaignList?.data?.[0].active === "Upcoming"
                                  ? "black"
                                  : "white",
                              marginBottom: "5px",
                            }}
                            label={campaignList?.data?.[0].active}
                          />
                        </Typography>
                      </div>
                      {SDGCampMapping &&
                        <div>
                          <CampaignCard
                            organizationlogo={campaignList?.data?.[0]?.organizationlogo}
                            name={campaignList?.data?.[0]?.name}

                            logo={campaignList?.data?.[0]?.logo}
                            video={campaignList?.data?.[0]?.video}
                            sponsor={campaignList?.data?.[0]?.sponsor}
                            tag={campaignList?.data?.[0]?.tag}
                            SDGCampMapping={SDGCampMapping}
                            goalquantity={campaignList?.data?.[0]?.goalquantity}
                            unitofgoal={campaignList?.data?.[0]?.unitofgoal}
                            committedcount={campaignList?.data?.[0]?.committedcount}
                            achievedcount={campaignList?.data?.[0]?.achievedcount}
                            submittedcount={campaignList?.data?.[0]?.submittedcount}
                            campaigntype={campaignList?.data?.[0]?.campaigntype}
                            campid={campaignList?.data?.[0]?.campid}
                            maxpointstobeearned={campaignList?.data?.[0]?.maxpointstobeearned}
                            dayrem={campaignList?.data?.[0]?.dayrem}
                            hourrem={campaignList?.data?.[0]?.hourrem}
                            minrem={campaignList?.data?.[0]?.minrem}
                            profileResponse={profileResponse}
                            index={0}
                            goal={campaignList?.data?.[0]?.goal}
                            desc={campaignList?.data?.[0]?.desc}
                            whattodo={campaignList?.data?.[0]?.whattodo}
                            guidelines={campaignList?.data?.[0]?.guidelines}
                            landing="no"
                            status={campaignList?.data?.[0]?.active}
                            directlink="yes"
                            showcamplb="no"
                            participatepage="no"
                          />


                        </div>
                      }
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>


        <div class="container">
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-7">
                  <div>
                    <h4 class="site-title">Our Sponsors</h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="home-demo">
              <OwlCarousel className="owl-theme" {...optionsFour}>
                {sponsor?.map((item, index) => (
                  <div class="item">
                    <div class="succ-story-badge-wrap">
                      <div>
                        {item?.sponsorlevel === "bronze" ? (
                          <img src={BronzeLvl} class="img-fluid"></img>
                        ) : item?.sponsorlevel === "silver" ? (
                          <img src={SilverLvl} class="img-fluid"></img>
                        ) : item?.sponsorlevel === "gold" ? (
                          <img src={GoldLvl} class="img-fluid"></img>
                        ) : item?.sponsorlevel === "diamond" ? (
                          <img src={DiamondLvl} class="img-fluid"></img>
                        ) : (
                          <img src={PlatinumLvl} class="img-fluid"></img>
                        )}
                      </div>
                      <div class="text-center succ-story-details">
                        <h6>{item?.sponsor}</h6>
                        <p>{String(item?.sponsorlevel)} </p>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>


        <div class="container">
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-7">
                  <div>
                    <h4 class="site-title">Win Rewards!</h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div>
                  <img class="hide-on-mobile" src={RewardMainImage} />
                </div>
              </div>
              <div class="col-md-8">
                <div class="">
                  <OwlCarousel className="owl-theme" {...options}>
                    {marketPlace?.map(
                      (item, index) =>
                        index < 5 && (
                          <div class="item">
                            <div class="reward-wrap">
                              <div class="landingPageRewardWrapper">

                                {item?.rewardtype ===
                                  "30 mins with a CEO" ? (
                                  <img
                                    class="reward-image"
                                    src={CEOImage}
                                    style={{ width: "100%" }}
                                  />
                                ) : item?.rewardtype ===
                                  "Certificate of Participation" ? (
                                  <img
                                    class="reward-image"
                                    src={CertificateImage}
                                    style={{ width: "100%" }}
                                  />
                                ) : item?.rewardtype ===
                                  "Video Interview with You" ? (
                                  <img
                                    class="reward-image"
                                    src={VideoInterviewImage}
                                    style={{ width: "100%" }}
                                  />
                                ) : item?.rewardtype ===
                                  "Discount Coupons" ? (
                                  <img
                                    class="reward-image"
                                    src={DiscountCouponImage}
                                    style={{ width: "100%" }}
                                  />
                                ) : item?.rewardtype ===
                                  "Gift Cards" ? (
                                  <img
                                    class="reward-image"
                                    src={GiftCardImage}
                                    style={{ width: "100%" }}
                                  />
                                ) : item?.rewardtype ===
                                  "Internship Opportunity" ? (
                                  <img
                                    class="reward-image"
                                    src={InternshipImage}
                                    style={{ width: "100%" }}
                                  />
                                ) : item?.rewardtype ===
                                  "Social Media Recognition" ? (
                                  <img
                                    class="reward-image"
                                    src={SocialMediaImage}
                                    style={{ width: "100%" }}
                                  />
                                ) : (
                                  <img
                                    class="reward-image"
                                    src={RewardMainImage}
                                    style={{ width: "100%" }}
                                  />
                                )}
                              </div>

                              <div class="reward-details">
                                <div>
                                  <div>
                                    <div id="toggleAccordion">
                                      <div class="card mb-1">
                                        <div
                                          class="card-header"
                                          id="headingOne4"
                                        >
                                          <h5 class="mb-0 mt-0 reward-desc-title">
                                            <span
                                              role="menu"
                                              class=""
                                              data-toggle="collapse"
                                              data-target={"#" + index}
                                              aria-expanded="true"
                                              aria-controls="iconChangeAccordionOne"
                                            >
                                              {item?.rewardtype}
                                              <i class="flaticon-down-arrow float-right"></i>
                                            </span>
                                          </h5>
                                        </div>

                                        <div
                                          id={index}
                                          class="collapse"
                                          aria-labelledby="headingOne4"
                                          data-parent="#toggleAccordion"
                                        >
                                          <div class="card-body">
                                            <p class="mb-3">
                                              <SubtitleWrapper>
                                                <span
                                                  style={{
                                                    fontSize: 12,
                                                    color: "blue",
                                                  }}
                                                >
                                                  {" "}
                                                  {item?.subtitle}{" "}
                                                </span>
                                              </SubtitleWrapper>
                                            </p>
                                            <p class="mb-3">
                                              <SubtitleWrapper>
                                                <span style={{ fontSize: 12, color: 'blue' }}>
                                                  {item?.subtitle}
                                                </span>
                                              </SubtitleWrapper>
                                            </p>
                                            <p className="mb-3">
                                              <SubtitleWrapper>
                                                <span
                                                  style={{
                                                    fontSize: 12,
                                                    color: "green",
                                                  }}
                                                >
                                                  {" "}
                                                  {item?.title}{" "}
                                                </span>
                                              </SubtitleWrapper>
                                            </p>

                                            <p class="mb-3">
                                              <PointsWrapper>
                                                <span style={{ fontSize: 12 }}>
                                                  {" "}
                                                  You need{" "}
                                                </span>
                                                <Chip
                                                  key={item?.points}
                                                  size="small"
                                                  label={item?.points}
                                                  style={{
                                                    backgroundColor: "white",
                                                    color: "#DAA520",
                                                    fontSize: 18,
                                                    fontWeight: "bold",
                                                  }}
                                                />{" "}
                                                <span style={{ fontSize: 12 }}>
                                                  pts to redeem
                                                </span>
                                              </PointsWrapper>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="redeem-pts">
                                    <div class="row">
                                      <div class="col-6">
                                        <div class="re-po-left">
                                          <img src="https://png.pngtree.com/png-clipart/20220213/original/pngtree-coins-stack-icon-vector-png-image_7267762.png" />
                                          <div class="pts">
                                            <h5>{item?.points}</h5>
                                            <span>Points</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div
                                          class="re-po-right-direct"
                                          // onClick={() => navigate(`/login`)}
                                          // onClick={() => navigate(`/login`)}
                                          disabled
                                        >
                                          <a>Redeem</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="text-center">
                                    <h6 class="sponsor-title">Sponsors</h6>
                                    <div class="row sponserWrapper">
                                      <div class="">
                                        {isArray(item?.sponsor) &&
                                          item?.sponsor?.map((reward, index) => (
                                            isDesktop ? (
                                              <img
                                                style={{
                                                  width: "auto",
                                                  height: "50px",
                                                }}
                                                src={reward?.sponsorlogo}
                                              />
                                            ) : (
                                              <img
                                                style={{
                                                  width: "auto",
                                                  height: "50px",
                                                }}
                                                src={reward?.sponsorlogo}
                                              />
                                            )
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-7">
                  <div>
                    <h4 class="site-title">Success Stories</h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="home-demo">
              <OwlCarousel className="owl-theme" {...optionsFour}>
                {rewardList?.map((item, index) => (
                  <div class="item">
                    <div class="succ-story-wrap">
                      <div>
                        <img src={item.image} />
                      </div>
                      <div class="text-center succ-story-details">
                        <h6>{item.name}</h6>
                        <p>{item.msg} </p>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row"></div>
        </div>
        <div class="container hide-on-mobile">
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-md-8">
                  <h4 class="site-title">Create Triple Impact</h4>
                  <div class="impact-sec">
                    <h4 class="site-title">Social Impact</h4>
                    <p style={{ textAlign: "justify" }}>
                      Through cYAAG you can continually track how much
                      difference you made to people’s lives by participating in
                      campaigns – such as providing education to underprivileged
                      children, improving access to healthcare, supplying
                      drinking water, providing care for senior citizens,
                      improving communications in rural areas, empowering women,
                      providing for animal welfare, making communities cleaner
                      and greener, and addressing homelessness and poverty. You
                      can see the impact you are having as an individual and
                      through the extended community you have created on cYAAG.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div>
                    <img src={Impact1} />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-md-4">
                  <div>
                    <img src={Impact2} />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="impact-sec">
                    <h4 class="site-title">Financial Impact</h4>
                    <p style={{ textAlign: "justify" }}>
                      Campaigns on cYAAG also provide positive financial
                      benefits to people – making social businesses profitable,
                      creating new jobs, improving income levels, increasing
                      agricultural productivity, creating new businesses, and
                      increasing the number of women entrepreneurs. You can
                      track such financial impact metrics on cYAAG!{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-md-8">
                  <div class="impact-sec">
                    <h4 class="site-title">Environmental Impact</h4>
                    <p style={{ textAlign: "justify" }}>
                      cYAAG also helps you track the difference you are making
                      to the environment – like reducing carbon footprint,
                      planting trees, reducing water and air pollution,
                      implementing sustainable energy solutions, improving waste
                      management, reducing microplastics, improving
                      biodiversity, and much more!{" "}
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div>
                    <img src={Impact3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container hide-on-desk">
          <div class="cyagg-sec-space">
            <div>
              <OwlCarousel className="owl-theme" {...optionsmob}>
                <div class="item">
                  <div class="impact-mob-card">
                    <div class="text-center">
                      <h4 class="site-title">Social Impact</h4>
                      <p class="text">
                        Through cYAAG you can continually track how much
                        difference you made to people’s lives by participating
                        in campaigns – such as providing education to
                        underprivileged children, improving access to
                        healthcare, supplying drinking water, providing care for
                        senior citizens, improving communications in rural
                        areas, empowering women, providing for animal welfare,
                        making communities cleaner and greener, and addressing
                        homelessness and poverty. You can see the impact you are
                        having as an individual and through the extended
                        community you have created on cYAAG.
                      </p>
                      <img src={Impact1} />
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="impact-mob-card">
                    <div class="text-center">
                      <h4 class="site-title">Financial Impact</h4>
                      <p class="text">
                        Campaigns on cYAAG also provide positive financial
                        benefits to people – making social businesses
                        profitable, creating new jobs, improving income levels,
                        increasing agricultural productivity, creating new
                        businesses, and increasing the number of women
                        entrepreneurs. You can track such financial impact
                        metrics on cYAAG!{" "}
                      </p>
                      <img src={Impact2} />
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="impact-mob-card">
                    <div class="text-center">
                      <h4 class="site-title">Environmental Impact</h4>
                      <p class="text">
                        cYAAG also helps you track the difference you are making
                        to the environment – like reducing carbon footprint,
                        planting trees, reducing water and air pollution,
                        implementing sustainable energy solutions, improving
                        waste management, reducing microplastics, improving
                        biodiversity, and much more!{" "}
                      </p>
                      <img src={Impact3} />
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="cyagg-sec-space">
            <div class="testimonial-wrap">
              <div class="row">
                <div class="col-md-4 hide-on-spec">
                  <div class="testi-img">
                    <img src="assests/img/testi.png" />
                  </div>
                </div>
                <div class="col-md-8 add-full-width">
                  <div class="testi-content">
                    <h2>What our changemakers say about cYAAG</h2>
                    <div>
                      <OwlCarousel className="owl-theme" items="1" nav dots>
                        <div class="item">
                          <div class="they-said-wrap">
                            <Avatar> </Avatar>
                            <div class="testi-desc">
                              <h6>Student from RV University, Bangalore, India</h6>
                              <p style={{ fontSize: 16 }}>
                                This platform empowers social changemakers to
                                create impact!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="item">
                          <div class="they-said-wrap">
                            <Avatar> </Avatar>
                            <div class="testi-desc">
                              <h6>CEO of a Healthcare Innovations Company, Delhi, India</h6>
                              <p style={{ fontSize: 16 }}>
                                cYAAG gets results. It is the current day
                                Digital Word of Mouth!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="item">
                          <div class="they-said-wrap">
                            <Avatar> </Avatar>
                            <div class="testi-desc">
                              <h6>⁠Director of an Assistive Technology Foundation, San Francisco, USA</h6>
                              <p style={{ fontSize: 16 }}>
                                The goal was to reach visually impaired people.
                                Participants and recipients were inspired and
                                motivated. This YAAG has wings.
                              </p>
                            </div>
                          </div>
                        </div>
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignPage;
