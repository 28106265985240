import React, {useEffect, useRef} from 'react';
import { Button } from '@mui/material';
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import TopHeaderScreen from "../../components/TopHeader1";
import { useNavigate } from "react-router-dom";


const TeamFAQ = (props) => {
  const navigate = useNavigate();
  const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  const faqs = [
    {
      question: "What are Teams in the Platform?",
      answer: "Teams in the platform are groups of changemakers who join together to participate in social campaigns. These teams collaborate to achieve campaign goals and, in the process, earn points and rewards as a team. This setup encourages collective effort and amplifies the impact of social initiatives."
    },
    {
      question: "Where can I find team information?",
      answer: "On the homepage, in the left navigation, you will find information under the 'Teams' section. On mobile, you can access this information through the sticky footer."
    },
    {
      question: "How do I create a new team on the platform?",
      answer: "Clicking on the create teams option on the left navigation will direct you to the Create Team page, where you can enter all the necessary details to create teams."
    },
    {
      question: "What information do I need to provide when creating a team?",
      answer: "When creating a team, you will need to enter details such as the team name, tagline, select a campaign, choose visibility (public or private), upload a team logo, provide information about your team, and select members from the add member search available options."
    },
    {
      question: "What is the difference between a private and a public team?",
      answer: "Private Team: In a private team, all changemakers can see the team name on the Leaderboard, but only members of the same organization can join the team. Once a request to join is accepted by the owner, members can see all the content of that team. Public Team: All changemakers on the platform can see public teams and their content at any time. They can request to join a team, and once their request is accepted by the team owner, they become a member of the team."
    },
    {
      question: "What will I see on my team page, and what information is available there?",
      answer: "On your team page, you will see a list of teams you have created as well as teams you are a part of on the platform. 'Your Teams' shows only the teams you own or are a member of, while 'Join Team' shows all teams available on the platform. Additionally, you can find essential details about your team, including the team name (hyperlinked), team goal, and a list of team members. You can also see the leaderboard of members in all your teams."
    },
    {
      question: "How can a member and owner view the team information?",
      answer: "As a member and owner you can click on the team name or select 'About Team' from the action options to see the team information."
    },
    {
      question: "What information can I find on the Your Team’s Leaderboard?",
      answer: "On the Your Team’s Leaderboard, you will find your team members' ranks, the names of the top players, and the total points earned by each team member."
    },
    {
      question: "How can you increase a team's points by referring a campaign to others?",
      answer: "You can refer campaigns to other changemakers. When they participate by clicking the URL you've shared on social media, both you and they will receive referral points."
    },
    {
      question: "If you are interested in another team focused on multiple campaigns, how can they join?",
      answer: "In the 'Your Teams' page, under actions, they can choose to leave their current team. Then, in the 'Join Teams' page, they can send a request to join the team they are interested in and the owner approves they can become a member there."
    },
    {
      question: "Can a member leave the team?",
      answer: "Members have the option to leave the team whenever they want. This option is available on the 'Your Team' page in the action section as 'Leave Team'."
    },
    {
      question: "How can an owner add a new campaign to the team?",
      answer: "An owner can add a new campaign by clicking on the edit icon, searching for the campaign, and then updating the team."
    },
    {
      question: "How can an owner add new members to the team?",
      answer: "An owner can add new members while creating a team or by searching for the first or last name of changemakers on the platform from a specific team page."
    },
    {
      question: "What can members see within the team?",
      answer: "Members can see team details, team member’s statistics, and activities."
    },
    {
      question: "How are points added to and deducted from each team?",
      answer: "Points are added to a team when members register for campaigns and their registrations are approved by the campaign admin and also by referring to the campaign. When a member leaves the team, points are reduced accordingly."
    },
    {
      question: "Why can't you add 2 more members if there are already 4 members on the Specific Team page?",
      answer: "The team has a limit of 5 members. You must choose the appropriate number of members to add, respecting the team's limit."
    },
    {
      question: "How can we get more points for the team?",
      answer: "You can earn more points for the team by inviting other changemakers to participate in campaigns and by actively participating in campaigns yourself. Additionally, you can add more campaigns by selecting and adding them to your team."
    },
    {
      question: "How can an owner delete a team?",
      answer: "An owner can delete the team by first removing all members from the team using the 'Remove from Team' option under the action button in the 'Your Team Members' section. After all members are removed, the owner can then find the 'Delete Team' option under the actions for the team in the 'Your Teams' section."
    },
    {
      question: "How does an owner of a team know if a changemaker has sent a request to join their team?",
      answer: "An owner of a team can see if any changemakers have sent requests to join their team by checking the request tab. The number shown on the request tab indicates how many requests the owner has received on the Join Team page."
    },
    {
      question: "What happens if a member chooses two teams which focus on the same campaign in the Join Team page?",
      answer: "If members of the same team or different team have chosen the same campaign, it will show 'NFY' in the Join Team page."
    },
    {
      question: "Why changemakers see NFY instead of join?",
      answer: "On the 'Join Team' page, if two teams are focusing on the same campaign and a changemaker is already part of one of these teams, they will see 'NFY' instead of the 'Join' button to prevent joining multiple teams for the same campaign."
    },
    {
      question: "How can a changemaker join a team?",
      answer: "A changemaker can join a team by clicking the join button, which changes the status to 'PND' (pending), once the owner approves, they can join the respective team."
    },
    {
      question: "How can a changemaker access information about a team?",
      answer: "Changemakers can access information about the team by clicking on 'About Team' from the action button or by clicking on the team name."
    },
    {
      question: "What information is available to a changemaker regarding team availability when considering joining a team?",
      answer: "As a changemaker, you can view the team's availability and determine how many more members can join on the team."
    },
    {
      question: "How can a team owner invite changemakers to join their team?",
      answer: "Team owners can invite changemakers by using the share icon on the team's page. When a changemaker logs into the platform, they can visit the 'Join Team' section and click 'Join' next to the team they're interested in. The owner will then receive a request from the changemaker in their 'Requests' tab. The owner can review this request and accept it to allow the changemaker to join the team."
    },
  ];

  return (
    <div className="wrapper" ref={topRef}>
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
        <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper" >
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div className="content content-wrap">
              <div className="teamfaq-container">
                <h3 style={{ marginBottom: "36px", fontFamily: "Roboto, sans-serif", fontSize: '25px', fontWeight: 600, marginLeft: '-14px' }}>Frequently Asked Questions</h3>
                {faqs.map((faq, index) => (
                  <div key={index} className="teamfaq-item">
                    <h2>{index + 1}. {faq.question}</h2>
                    <p>{faq.answer}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
};

export default TeamFAQ;
