/** @format */

import React, { useEffect, useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { campLeaderBoard } from "../../redux/action/CampLeaderBoardAction";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { CopyRight } from "../../components/CopyRight";
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ProfileNoPhoto from "../../assets/profileNoPhoto.png";
import { Button } from "@mui/material";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { Avatar , useMediaQuery} from '@mui/material';
import CustomShieldIcon from '../../components/CustomShieldIcon';
import LockIcon from '@mui/icons-material/Lock';

const CampLeaderBoardPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationPath = useLocation();
  const [teamDetails, setTeamDetails] = useState([]);
  const isMobile = useMediaQuery('(max-width:600px)');

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const { isSuccess: campLeaderBoardSuccess, response: campLeaderBoardResponse } =
    useSelector((state) => state.campLeaderBoard);

  const fetchTeamdetailsFromCampid = async () => {
    try {
      const response = await axios.get(`/teams/team/metrics/${locationPath?.pathname.split("/")?.[3]}/${profileResponse?.phoneno}`);
      setTeamDetails(response?.data);
    } catch (error) {
      console.error('Error fetching my team', error);
    }
  };

  useEffect(() => {
    fetchTeamdetailsFromCampid();
  }, []);

  // Check if teamDetails is loaded before performing calculations
  const truncateName = (name, maxLength = 15) => {
    if (name.length > maxLength) {
      return `${name.substring(0, maxLength)}...`;
    }
    return name;
  };
  const totalTeams = teamDetails ? teamDetails.length : 0;
  const totalChangemakers = teamDetails ? teamDetails.reduce((sum, team) => sum + team.members.length, 0) : 0;
  const chartData = teamDetails ? teamDetails.map((team) => ({
    name: truncateName(team.name),
    members: team.members.length,
    Teampoints: team.teampoints,
  })) : [];

  console.log("chartData = ", chartData)

  const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    // dispatch(campLeaderBoard(localStorage.getItem("userid"), locationPath?.pathname.split("/")?.[3]));
    dispatch(
      campLeaderBoard({
        phoneno: localStorage.getItem("userid"),
        campid: locationPath?.pathname.split("/")?.[3],
      })
    );
    // dispatch(myRank(localStorage.getItem("userid")));
  }, [dispatch]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const members = payload[0].payload.members;
      const teampoints = payload[0].payload.Teampoints.toFixed(1);
      
      console.log("members = ", members);
      return (
        <div className="custom-tooltip">
          {/* <p>{`${teampoints} team points`}</p>
          <p>{`${members} members`}</p> */}
          <p>{`Team pts : ${teampoints}`}</p>
          <p>{`Members : ${members}`}</p>

        </div>
      );
    }
    return null;
  };

  console.log("campLeaderBoardResponse = ", campLeaderBoardResponse)
  

  return (
    <div className="wrapper" ref={topRef}>
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
        {/* <div style={{padding: "59px 20px 3px 20px", margin: "10px 0",}}> */}
          <Button onClick={() => navigate(-1)} className="backWrapper">
            <i className={`fa fa-caret-left`} />
            <span style={{ paddingLeft: 3, fontSize: 14, color: "#1976d2" }}>
              Back
            </span>
          </Button>
          <div>
            <span
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Campaign Leaderboard
            </span>
          </div>
        {/* </div> */}
        <div class="cyagg-sec-space">
          <div class="container">
            {
              campLeaderBoardResponse?.[0]?.rank === undefined ?
                <div className="message-container">
                  <p style={{ marginBottom: "0px" }}>Register now to be the first one on the Leaderboard below!</p>
                </div>
                : ""
            }
            <div class="row">
              <div class="row" style={{ marginLeft: "30px", marginRight: "7px" }}>
                <div class="row">
                  <h4 class="site-title" style={{ color: "blue" }}>{campLeaderBoardResponse?.[0]?.campname}</h4>
                </div>
              </div>
            </div>
            <div style={{ marginBottom: "50px" }}>
              <div class="row" style={{ marginLeft: "-8px" }}>
                <div
                  class="col-md-4 hide-on-mobile"
                  style={{ paddingRight: "0px" }}
                >
                  <div class="leaderboard-left">
                    <div class="text-center">
                      <h5>Top Player</h5>
                      {
                        campLeaderBoardResponse?.[0]?.profilephoto != "" ? (
                          <img
                            src={campLeaderBoardResponse?.[0]?.profilephoto}
                          ></img>
                        ) : (
                          <span>
                            <AccountCircleIcon style={{ minWidth: "200px", minHeight: "200px" }} fontSize="large" />
                          </span>
                        )
                      }
                      <span style={{ fontSize: 36, padding: 24 }}>
                        {campLeaderBoardResponse &&
                          campLeaderBoardResponse?.[0]?.firstname}{" "}
                        {campLeaderBoardResponse?.[0]?.lastname}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 col-12" style={{ paddingLeft: "0px" }}>
                  <div class="u-custom-scrollbar-one-camp">
                    <table stickyHeader class="leader-b-table">
                      {/* <thead> */}
                      <thead style={{ top: isMobile ? "-2px" : "-1px", position: "sticky", zIndex: 10}}>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            {" "}
                            Rank
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Top Player Name
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Total Points
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        
                        {campLeaderBoardSuccess && campLeaderBoardResponse?.[0]?.rank !== undefined &&
                          campLeaderBoardResponse?.map((row) => (
                            <tr>
                              {
                                row?.rank === 1 || row?.rank === 2 || row?.rank === 3 ?
                                  <td
                                    data-label="Account"
                                    style={{ textAlign: "center", backgroundColor: "#74C3FC" }}
                                  >
                                    <div style={{ position: "relative", display: "inline-block" }}>
                                      {
                                        row?.rank === 1 ?
                                          <CustomShieldIcon color="gold" />
                                          : row?.rank === 2 ?
                                            <CustomShieldIcon color="whitesmoke" />
                                            : row?.rank === 3 ?
                                              <CustomShieldIcon color="#CD7F32" />
                                              :
                                              <CustomShieldIcon color="silver" />
                                      }
                                      <span style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        fontSize: "16px",
                                        color: "black",
                                        fontWeight: "bold"
                                      }}>
                                        {row.rank}
                                      </span>
                                    </div>
                                  </td>
                                  :
                                  <td
                                    data-label="Account"
                                    style={{ textAlign: "center" }}
                                  >
                                    <div style={{ position: "relative", display: "inline-block" }}>
                                     
                                      <CustomShieldIcon color="silver" />
                                    

                                      <span style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        fontSize: "16px",
                                        color: "black",
                                        fontWeight: "bold"
                                      }}>
                                        {row.rank}
                                      </span>
                                    </div>
                                  </td>
                              }
                              {

                                row?.rank === 1 || row?.rank === 2 || row?.rank === 3 ?
                                  <td data-label="Due Date" style={{ backgroundColor: "#74C3FC" }}>
                                    <div class="top-player-box">
                                      <div class="top-player-box" style={{ textAlign: "center" }}>
                                        {row?.profilephoto != "" && !(row?.rank === undefined) ? (
                                          <Link to={`/newprofile/${row?.profileurl}`}>
                                            <img src={row?.profilephoto} alt="Profile" />
                                          </Link>
                                        ) : row?.rank === undefined ? (
                                          ""
                                        )
                                          : (
                                            <Link to={`/newprofile/${row?.profileurl}`}>
                                              <img src={ProfileNoPhoto} alt="No Profile" />
                                            </Link>
                                          )}
                                      </div>
                                      {
                                        row?.rank !== undefined ?
                                          <div class="top-player-box1" style={{ textAlign: "center" }}>
                                            {row?.firstname === "YOU" ? (
                                              <Link to={`/newprofile/${row?.profileurl}`}>
                                                <span
                                                  style={{
                                                    color: "blueviolet",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {row?.firstname}
                                                </span>
                                              </Link>
                                            ) : (
                                              <Link to={`/newprofile/${row?.profileurl}`}>
                                                <span>{row?.firstname}</span>
                                              </Link>
                                            )}
                                          </div>
                                          :
                                          <div >
                                            <p style={{ padding: "50px" }}></p>
                                          </div>
                                      }
                                    </div>
                                  </td>
                                  :

                                  <td data-label="Due Date" >
                                    <div class="top-player-box">
                                      <div class="top-player-box" style={{ textAlign: "center" }}>
                                        {row?.profilephoto != "" && !(row?.rank === undefined) ? (
                                          <Link to={`/newprofile/${row?.profileurl}`}>
                                            <img src={row?.profilephoto} alt="Profile" />
                                          </Link>
                                        ) : row?.rank === undefined ? (
                                          ""
                                        )
                                          : (
                                            <Link to={`/newprofile/${row?.profileurl}`}>
                                              <img src={ProfileNoPhoto} alt="No Profile" />
                                            </Link>
                                          )}
                                      </div>
                                      {
                                        row?.rank !== undefined ?
                                          <div class="top-player-box1" style={{ textAlign: "center" }}>
                                            {row?.firstname === "YOU" ? (
                                              <Link to={`/newprofile/${row?.profileurl}`}>
                                                <span
                                                  style={{
                                                    color: "blueviolet",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {row?.firstname}
                                                </span>
                                              </Link>
                                            ) : (
                                              <Link to={`/newprofile/${row?.profileurl}`}>
                                                <span>{row?.firstname}</span>
                                              </Link>
                                            )}
                                          </div>
                                          :
                                          <div >
                                            <p style={{ padding: "50px" }}></p>
                                          </div>
                                      }
                                    </div>
                                  </td>

                              }

                              {
                                row?.rank === 1 || row?.rank === 2 || row?.rank === 3 ?
                                  <td
                                    data-label="Period"
                                    style={{ textAlign: "center", backgroundColor: "#74C3FC" }}
                                  >
                                    {row?.rewardpoint}
                                  </td>
                                  :
                                  <td
                                    data-label="Period"
                                    style={{ textAlign: "center" }}
                                  >
                                    {row?.rewardpoint?.toFixed(1)}
                                  </td>
                              }
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="teamcampheader">
              <h4 className="teamcamptitle">Teams engaged in this campaign</h4>
            </div>
            <div className="teamcampcontainer">
              {teamDetails ? (
                <>
                  <ul className="teamcamplist">
                    {teamDetails.map((team) => (
                      // <span className="teamcamplistItem">
                      //   <Avatar src={team.logo} alt={`${team.name} logo`} />
                    
                      //   <span>{team.name}</span>
                      //   {
                      //       team.visibility === "private" &&
                      //       <div style={{marginTop: "2px" }}>
                      //           <LockIcon style={{ fontSize: 16, color: 'red', marginLeft: '43px' }} />
                      //       </div>
                      //   }
                      // </span>
                      <li className="teamcamplistItem" key={team.name}>
                        <div className="teamDetailsRow">
                          <Avatar src={team.logo} alt={`${team.name} logo`} />
                          <span className="teamName">{team.name}</span>
                        </div>
                        {team.visibility === 'private' && (
                          <div className="lockIcon">
                            <LockIcon />
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>

                  <div className="teamcampmetrics">
                    <div className="teamcampmetric">
                      <div className="teamcampmetric-label">Active Teams</div>
                      <div className="teamcampmetric-value">{totalTeams}</div>
                    </div>
                    <div className="teamcampmetric">
                      <div className="teamcampmetric-label">Total Members</div>
                      <div className="teamcampmetric-value">{totalChangemakers}</div>
                    </div>
                  </div>

                  <div className="teamcampchartContainer" style={{ overflowX: 'auto' }}>
                  <div style={{ width: '1000px'}}>
                    <ResponsiveContainer width="100%" height={400}>
                      <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 70 }}>
                        <defs>
                          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.8} />
                          </linearGradient>
                        </defs>
                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                        {/* <XAxis dataKey="name" tick={{ fill: '#8884d8' }} /> */}
                        <XAxis 
        dataKey="name" 
        tick={{ fill: '#8884d8' }} 
        angle={-10} 
        textAnchor="end"
        interval={0}
      />
                        <YAxis tick={{ fill: '#8884d8' }} />
                        {/* <Tooltip contentStyle={{ backgroundColor: '#fff', border: '1px solid #ccc' }} /> */}
                        <Tooltip content={<CustomTooltip />} cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }} wrapperStyle={{ zIndex: 1000 }} />
                        <Legend />
                        
                        <Bar dataKey="Teampoints" fill="url(#colorUv)" animationDuration={1500} />
                      </BarChart>
                    </ResponsiveContainer>
                    </div>
                  </div>
                </>
              ) : (
                <p className="teamcamploading">Loading team details...</p>
              )}
            </div>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
        </div>
        </div>
      </section>
    </div>
  );
};
export default CampLeaderBoardPage;