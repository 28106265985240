// import * as React, {useRef} from "react";
import React from "react";
import {
  Avatar,
  Box,
  Button,
  Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import axios from "axios";
import _ from "lodash";
import TopHeaderScreen from "../../components/TopHeader1";
import { useNavigate, useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import { UserPerformanceReportDetailsTableComponent } from "../../components/Table";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import styled from 'styled-components';

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
}));

const FormGroup0 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#F0F8FF',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// Styled component for formGroup00
const FormGroup00 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#FAEBD7',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'block',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));

function UserPerformanceReportDetails(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();
  const [activityList, setActivityList] = React.useState(undefined);
  const [myOrgRank, setMyOrgRank] = React.useState(undefined);

  React.useEffect(() => {
    getPerformanceReportOnUser();
  }, []);

  const getPerformanceReportOnUser = async () => {
    try {
      const res = await axios.get(
        `/so/admin/individual/volreport/${localStorage.getItem("userid")}/${locationPath?.pathname.split("/")?.[4]
        }`
      );

      setActivityList(res?.data);
      return res;
    } catch (err) {

      throw err;
    }
  };

  React.useEffect(() => {
    getCampRankReportOnUser();
  }, []);

  const getCampRankReportOnUser = async () => {
    try {
      const res = await axios.get(
        `/my/org/rank/${locationPath?.pathname.split("/")?.[4]}`
      );

      setMyOrgRank(res?.data);
      return res;
    } catch (err) {

      throw err;
    }
  };

  const downloadDocument = () => {

    const pdf = new jsPDF("l", "pt", "a2");
    var width = pdf.internal.pageSize.getWidth()
    pdf.text('report', width / 2, 20)

    // const pdf = new jsPDF({
    //   orientation: "landscape",
    //   unit: "pt",
    //   format: [4, 2]
    // });
    //   pdf.autoTable(pdf, {
    //     // head: head,
    //     // body: someData,
    //     didParseCell: (hookData) => {
    //         if (hookData.section === 'head') {
    //             if (hookData.column.dataKey === 'Registered Date') {
    //                 hookData.cell.styles.halign = 'right';
    //             }
    //         }
    //     }
    // }); SOADashboardScreen

    // pdf.html(document.querySelector("#downloadPdf"), {
    pdf.html(document.querySelector("#SOADashboardScreen"), {
      callback: function (pdf) {
        var pageCount = pdf.internal.getNumberOfPages();
        // var width = pdf.internal.pageSize.getWidth()
        // pdf.text('report', width/2, 20, { align: 'center' })
        // pdf.deletePage(pageCount);


        pdf.save("cyaag.pdf");
      },
    });
  };


  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 20,
                // fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              <span style={{ fontWeight: "1000", fontSize: 22 }}>
                {activityList?.[0]?.firstname} {activityList?.[0]?.lastname}
              </span>
              's Performance Report
            </div>
            {/* <div>
              <Button
                className="btn download-btn"
                variant="contained"
                onClick={downloadDocument}
              >
                Download
              </Button>
            </div> */}
            <div style={{ fontFamily: "monospace", fontSize: 16 }}></div>




            <header id="SOADashboardScreen">
              <form autoComplete="off">
                <div
                  style={{
                    border: "1px solid #dadce0",
                    borderRadius: 8,
                    backgroundColor: "#fff",
                    padding: "16px",
                  }}
                >
                  {/* Mobile view */}
                  <div className="mobileView">
                    <FormGroupFull style={{ marginBottom: "15px", textAlign: "center" }}>
                      <StyledLargeAvatar
                        src={activityList?.[0]?.profilephoto}

                        style={{ display: "inline-flex" }}
                      />
                      <div>
                        <Typography color="textSecondary" variant="body2">
                          {activityList?.[0]?.emailid}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {activityList?.[0]?.state}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {activityList?.[0]?.country}
                        </Typography>
                      </div>
                    </FormGroupFull>
                    <FormGroupFull style={{ marginBottom: "15px", textAlign: "center" }}>
                      <Typography
                        style={{
                          borderRadius: "100px",
                          color: "white",
                          display: "inline-flex",
                          padding: "5px 10px",
                          backgroundColor: "rgba(235, 149, 50, 1)",
                        }}
                      >
                        Your Organization
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {activityList?.[0]?.organization}
                      </Typography>
                    </FormGroupFull>
                    <FormGroupFull style={{ marginBottom: "15px", textAlign: "center" }}>
                      <Typography
                        style={{
                          borderRadius: "100px",
                          color: "white",
                          display: "inline-flex",
                          padding: "5px 10px",
                          backgroundColor: "rgba(235, 149, 50, 1)",
                        }}
                      >
                        Your Organization Rank
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {myOrgRank?.[0].rank}
                      </Typography>
                    </FormGroupFull>
                  </div>

                  {/* Desktop view */}
                  <div style={{ display: "none", textAlign: "center" }}>
                    <FormGroupFull>
                      <Typography
                        style={{
                          borderRadius: "100px",
                          color: "white",
                          display: "inline-flex",
                          padding: "5px 10px",
                          backgroundColor: "rgba(235, 149, 50, 1)",
                        }}
                      >
                        Your Organization
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {activityList?.[0]?.organization}
                      </Typography>
                    </FormGroupFull>
                    <FormGroupFull>
                      <StyledLargeAvatar
                        src={activityList?.[0]?.profilephoto}
                        style={{ display: "inline-flex" }}
                      />
                      <div>
                        <Typography color="textSecondary" variant="body2">
                          {activityList?.[0]?.emailid}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {activityList?.[0]?.state}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {activityList?.[0]?.country}
                        </Typography>
                      </div>
                    </FormGroupFull>
                    <FormGroupFull>
                      <Typography
                        style={{
                          borderRadius: "100px",
                          color: "white",
                          display: "inline-flex",
                          padding: "5px 10px",
                          backgroundColor: "rgba(235, 149, 50, 1)",
                        }}
                      >
                        Your Organization Rank
                      </Typography>
                      <Typography color="textSecondary" variant="body2">
                        {myOrgRank?.[0].rank}
                      </Typography>
                    </FormGroupFull>
                  </div>
                  <FormGroupFull>
                    <div id="showAll" style={{ overflow: "auto", textAlign: "center" }}>
                      <div id="downloadPdf">
                        <UserPerformanceReportDetailsTableComponent rows={activityList} />
                      </div>
                    </div>
                  </FormGroupFull>
                </div>
              </form>
            </header>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default UserPerformanceReportDetails;
