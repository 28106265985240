import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TableFooter,
  TablePagination,
  DialogActions,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  Checkbox,
  Box
} from "@mui/material";
import { Modal } from "react-bootstrap";
import { Autocomplete } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { purchaseYaag } from "../redux/action/PurchaseYaag";
import { validateReward } from "../redux/action/ValidatorAction";
import moment from "moment";
import { validatorList } from "../redux/action/ValidatorList";
import axios from "axios";
import ActionAlert from "./ActionAlert";
import { bindActionCreators } from "redux";
import AssessmentIcon from '@mui/icons-material/Assessment';
import FilePresentIcon from '@mui/icons-material/FilePresent';
// import SearchBar from "material-ui-search-bar";
import SearchBar from "./SearchBar";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: '#808080',
    color: '#ffffff',
    textTransform: 'uppercase',
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
  },
  '&.tbodyText': {
    height: 80,
    textAlign: 'center',
    color: '#565656',
    fontSize: 15,
    fontWeight: 400,
  },
}));

// Styled TableRow component
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // borderBottom: '1px solid green !important',
  '&:last-child': {
    borderBottom: 'none',
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#f7dbb3',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#e9e7e8',
  },
  '&:nth-of-type(odd) div.actionTableWrapper td': {
    backgroundColor: '#f7dbb3',
  },
  '&:nth-of-type(even) div.actionTableWrapper td': {
    backgroundColor: '#e9e7e8',
  },


}));

export const ValidatorTable = (props) => {
  const dispatch = useDispatch();
  const [checkedAllValue, setCheckedAllValue] = useState();
  const [countValue, setCountValue] = useState(true);
  const tableRef = useRef(null);
  const [author, setAuthor] = useState([]);
  const [checked, setChecked] = useState(false);

  const [showModal, setShowModal] = React.useState(false);
  const [modalData, setModalData] = useState(null);


  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [reviewComments, setReviewComments] = React.useState("");
  const [campaignList, setCampaignList] = useState([
    "campaignname",
    "formuserfirstname",
    "formuserfirstname",
    "formuserphoneno",
    "registrationstatus",
    "rewarddate",
  ]);

  const bulkArray = {
    registrationid: [
      {
        registrationid: "",
        campid: "",
        rewarddate: "",
        registrationstatus: "",
      },
    ],
  };

  const closeModalWindow = () => {
    setShowModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const [campaignList, setCampaignList] = useState();
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  // console.log(profileResponse?.phoneno);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const { isSuccess: validatorListSuccess, response: validatorListResponse } =
    useSelector((state) => state.validatorList);
  const { isSuccess: validateRewardSuccess, response: validateRewardResponse } =
    useSelector((state) => state.validateReward);
  useEffect(() => { }, [campaignList]);


  const refresh = async () => {
    await sleep(5000);
  };
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const validationList1 = async () => {
    try {
      const res = await axios.get(
        `/validator/list/${profileResponse?.phoneno}`
      );
      const vaa = await res?.data;
      //setCampaignList(oldau => [...oldau, vaa]);
      return res;
    } catch (err) {
      throw err;
    }
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  useEffect(() => {
    dispatch(validatorList());
    setAuthor(props);
    validationList1();

    var chck = document.getElementsByName("vehicleParent");
    for (var ch of chck) {
      ch.checked = false;
    }
    var checkboxes = document.getElementsByName("vehicle");
    for (var checkbox of checkboxes) {
      checkbox.checked = false;
    }
  }, [validatorListSuccess]);

  useEffect(() => {
    if (validateRewardSuccess) {
      console.log("SUCCESS")
      setReviewComments("");
    }
  }, []);

  const xunique = [
    ...new Map(
      props.rows.map((item) => [item["formcampaignname"], item])
    ).values(),
  ];
  const handleClick = (id, subStatus) => {
    setModalData(subStatus);
    document.getElementById('commentsId').value = "";
    if (subStatus === "approved" || subStatus === "rejected") {
      console.log((id.registrationstatus = subStatus));
      console.log(
        (id.registerdate = moment(new Date()).format("yyyy-MM-DD HH:mm:ss"))
      );
      console.log(JSON.stringify(props));
    }
    // if (validateRewardSuccess) {
    //   console.log("SUCCESS")
    console.log("setting review to null");
    setReviewComments("");
    // }
  };

  const acceptRejectIndividual = () => {
    setShowModal(true);
  };

  let temp = [];
  const handleBulkClick = (selectedItem, selectionName) => {
    // const checkboxCount = document.querySelectorAll("[name='vehicle']:checked").length;
    // if(checkboxCount > 0) {
    //   setCountValue(false);
    // } else {
    //   setCountValue(true);
    // }

    if (
      props.rows.filter((item) => item.registrationstatus === "submitted")
        .length ===
      [].slice
        .call(document.querySelectorAll("[name='vehicle']"))
        .filter(function (e) {
          return e.checked;
        }).length
    ) {
      document.getElementsByName("vehicleParent")[0].checked = true;
    } else {
      document.getElementsByName("vehicleParent")[0].checked = false;
    }
    //setCountValue([].slice.call(document.querySelectorAll("[name='vehicle']")).filter(function(e) { return e.checked; }).length);
    console.log(selectionName === "bulkSelection");
    if (selectionName === "bulkSelection") {
      console.log("cominginside========");
      let i = 0;
      for (let i = 0; i < selectedItem.length; i += 1) {
        const formattedArray = [];
        console.log("RegistrationIDDD ==", selectedItem[i].registrationid);
        formattedArray.push(
          selectedItem[i].formuserphone,
          selectedItem[i].registrationid,
          selectedItem[i].reviewcomments,
          selectedItem[i].campid,
          moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
          selectedItem[i].registrationstatus
        );
        temp.push(createDataValidator(...formattedArray));
        console.log(temp.length);
      }
    } else {
      console.log("temp value first", temp);
      var positionVal = temp
        .map((e) => e.registrationid)
        .indexOf(selectedItem.registrationid);
      console.log("position val===", positionVal);
      if (
        temp.map((e) => e.registrationid).indexOf(selectedItem.registrationid) >
        -1
      ) {
        console.log("======== slice inside before =====");
        console.log(temp);
        temp.splice(positionVal, 1);
        console.log("======== slice inside after =====");
        console.log(temp);
      } else {
        console.log("====== coming else part ========");
        const formattedArray1 = [];
        formattedArray1.push(
          selectedItem.formuserphone,
          selectedItem.registrationid,
          selectedItem.reviewcomments,
          selectedItem.campid,
          moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
          selectedItem.registrationstatus
        );
        if (document.querySelectorAll("[name='vehicle']:checked").length > 0) {
          document.getElementById("acceptAllId").disabled = false;
        } else {
          document.getElementById("acceptAllId").disabled = true;
        }

        // setCountValueee(document.querySelectorAll("[name='vehicle']:checked").length);
        temp.push(createDataValidator(...formattedArray1));
      }
      // setCountValueee(document.querySelectorAll("[name='vehicle']:checked").length);
      // setCountValue([].slice.call(document.querySelectorAll("[name='vehicle']")).filter(function(e) { return e.checked; }).length);
    }

    console.log("======== Temp array value=====");
    console.log(temp);
    // console.log([].slice.call(document.querySelectorAll("[name='vehicle']"))
    // .filter(function(e) { return e.checked; }).length);
    // setCountValue([].slice.call(document.querySelectorAll("[name='vehicle']"))
    // .filter(function(e) { return e.checked; }).length);
    setReviewComments("");
  };

  const checkAllValue = () => {
    temp = [];
    var checkboxes = document.getElementsByName("vehicle");
    if (document.getElementsByName("vehicleParent")[0].checked) {
      for (var checkbox of checkboxes) {
        checkbox.checked = true;
      }
      handleBulkClick(
        props.rows.filter((item) => item.registrationstatus === "submitted"),
        "bulkSelection"
      );
    } else {
      for (var checkbox of checkboxes) {
        checkbox.checked = false;
      }
      handleBulkClick([], "bulkSelection");
    }
    props.rows.filter((item) => item.registrationstatus === "submitted");
  };

  const acceptAll = (selectedVal, statusVal, reviewComments) => {

    if (selectedVal.length == 0) {
      setDialogOpen(true);
      return false;
    }
    let op = selectedVal.map((e, i) => {
      let temp = props.rows.find(
        (element) => element.registrationid == e.registrationid
      );
      if (temp) {
        temp.registrationstatus = statusVal;
        temp.reviewcomments = "";
        setCheckedAllValue(false);
      }
    });
    var checkboxes = document.getElementsByName("vehicle");
    for (var checkbox of checkboxes) {
      checkbox.checked = false;
    }
    temp = temp.map((entry) => ({ ...entry, registrationstatus: statusVal }));
    console.log({
      toberewardeddata: temp,
    });
    if (statusVal === "approved") {
      Promise.all([
        dispatch(
          purchaseYaag({
            toberewardeddata: temp,
          })
        ),
      ]).then(() =>
        dispatch(
          validateReward({
            registrationid: temp,
          })
        )
      );
    }
    console.log("===== Dispatched==")
    console.log(JSON.stringify(props));
  };

  function createDataValidator(
    phoneno,
    registrationid,
    reviewcomments,
    campid,
    rewarddate,
    registrationstatus
  ) {
    return {
      phoneno,
      registrationid,
      reviewcomments,
      campid,
      rewarddate,
      registrationstatus,
    };
  }


  const [value1, setValue1] = useState(props.value1 || '');
  const [inputValue, setInputValue] = useState(props.inputValue || '');
  return (


    <TableContainer ref={tableRef} component={Paper} style={{ padding: 36, paddingLeft: 0, paddingTop: 0 }} class="registrationbody">
      {!_.isEmpty(props.rows) ? (
        <div>
          <div class="registrationbody">
            <div class="inputVal">
              <Autocomplete
                //disableClearable
                disableClearable={props.value1 === ""}
                id="controllable-states-demo"
                value={props.rows.value1}
                onChange={(event, newValue) => {
                  props.setValue1(newValue);

                }}
                inputValue={props.inputValue}
                onInputChange={(event, newInputValue) => {
                  props.setInputValue(newInputValue);
                }}
                options={xunique}
                getOptionLabel={(option) => option.formcampaignname}
                style={{ width: 240 }}
                renderInput={(params) => (
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                    }}
                  >
                  <TextField
                    {...params}
                    placeholder="Search Campaign"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end" style={{ marginRight: -2 }}>
                          <SearchIcon style={{ color: 'gray',marginRight:'5px' }}/>
                        </InputAdornment>
                      ),
                      style: { paddingRight: '0px' , height: '48px'},
                    }}
                    InputLabelProps={{
                      style: { display: 'flex', alignItems: 'center', gap: '8px', color: '#bfbfbf' },
                    }}
                    style={{
                      flex: 1,
                      border: 'none', 
                      borderRadius: '4px', 
                    }}
                  />
                  </Box>
                  
                )}
              />
            </div>
            {props.rows[0].registrationstatus === "submitted" ? (
              <div>
                
                <StyledTableCell
                  style={{
                    textAlign: "center",
                    borderBottom: "0px",
                    backgroundColor: "transparent",
                    paddingLeft:"0px",
                  }}
                >
                  <Button
                    id="acceptAllId"
                    style={{
                      backgroundColor: "#16a654",
                      minWidth: 110,
                      padding: "7px 0px",
                      marginBottom: "10px",
                      fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginRight: 10,
    color: "#fff",
                    }}
                    
                    variant="contained"
                    onClick={() => {
                      acceptAll(temp, "approved", reviewComments);
                      dispatch(validatorList());
                      validationList1();
                    }}
                  >
                    Accept all
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#a62e2e",
                      minWidth: 110,
                      padding: "7px 0px",
                      marginBottom: "10px",
                      fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginRight: 10,
    color: "#fff",
                    }}
                    
                    variant="contained"
                    onClick={() => {
                      acceptAll(temp, "rejected", reviewComments);
                      dispatch(validatorList());
                      validationList1();
                    }}
                  >
                    Reject All
                  </Button>
                </StyledTableCell>

                <Dialog
                  open={dialogOpen}
                  onClose={handleCancel}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogContent>
                    <DialogContentText>
                      Please select Atleast one item.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    {/* <button class="btn btn-primary"
                      onClick={handleCancel}
                    >
                      Ok
                    </button> */}
                    <Button
                      variant="secondary"
                      style={{
                        // backgroundColor: "#db8d0f",
                        backgroundColor: "#E9ECEF",
                        color: "#fff",
                      }}
                      onClick={handleCancel}
                    >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>

              </div>
            ) : (
              ""
            )}
          </div>

          <div className='tableContainer' style={{ overflowX: "scroll", overflowX:'auto', marginTop:'5px','&::-webkit-scrollbar': {
      width: '6px', // Custom width for vertical scrollbar
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1', // Track color
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888', // Scrollbar color
      borderRadius: '10px', // Rounded corners
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555', // Scrollbar color on hover
    }, }}>
            <Table style={{width: "100%"}} className='customizedTableWidth' aria-label="customized table">
              <TableHead>
                <TableRow>
                  {props.rows[0].registrationstatus === "submitted" ? (
                    <StyledTableCell style={{ textAlign: "center", width: 35 }}>
                      <input
                        type="checkbox"
                        value="item"
                        name="vehicleParent"
                        onClick={() => {
                          checkAllValue();
                        }}
                      ></input>
                    </StyledTableCell>
                  ) : (
                    ""
                  )}
                  <StyledTableCell style={{ textAlign: "center", textTransform: 'capitalize' }}>
                    Campaign Name
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", textTransform: 'capitalize' }}>
                    Changemaker First Name
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", textTransform: 'capitalize' }}>
                  Changemaker Last Name
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", textTransform: 'capitalize' }}>
                  Changemaker Phone no
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", textTransform: 'capitalize' }}>
                    Registration Status
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" , textTransform: 'capitalize'}}>
                    Time Stamp
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center", textTransform: 'capitalize' }}>
                    Personal Library
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" , textTransform: 'capitalize'}}>
                    Document(s) Uploaded
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" , textTransform: 'capitalize'}}>
                    Public Library
                  </StyledTableCell>
                  {props.rows[0].registrationstatus === "submitted" && (
  <>
    <StyledTableCell style={{ textAlign: "center", textTransform: 'capitalize', width: "206px" }}>
     
    </StyledTableCell>
  </>
)}
                </TableRow>
              </TableHead>
              {props.isFetching ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <TableBody>
                  {props.inputValue === ""
                    ? props.rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <StyledTableRow>
                          {props.rows[0].registrationstatus === "submitted" ? (
                            <StyledTableCell
                              style={{ textAlign: "center", width: 50 }}
                            >
                              <input
                                type="checkbox"
                                id={item.registrationid}
                                value="item"
                                onClick={() => {
                                  handleBulkClick(item, "singleSelection");
                                }}
                                name="vehicle"
                              ></input>
                            </StyledTableCell>
                          ) : (
                            ""
                          )}
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {item.formcampaignname}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {item.formuserfirstname}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {item.formuserlastname}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {item.formuserphone}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {item.registrationstatus.charAt(0).toUpperCase() +
                              item.registrationstatus.slice(1)}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {item.registerdate}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            <span 
                              onClick={() =>
                                navigate(`/vol/reg/report/${item?.formuserphone}/${item?.registrationid}/${item?.campid}`, {
                                  state: item,
                                })
                              }
                              style={{ textAlign: "center", cursor: "pointer",height: 80,
                                textAlign: "center",
                                color: "#565656",
                                fontSize: 15,
                                fontWeight: "400",
                                width: "200px", }}
                            >
                              <FilePresentIcon style={{ textAlign: "center", cursor: "pointer" }} />
                            </span>
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {item.documentuploadcount}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            <span 
                              onClick={() =>
                                navigate(`/vol/stat/report/${item?.formuserphone}/${item?.registrationid}/${item?.campid}`, {
                                  state: item,
                                })
                              }
                              style={{ textAlign: "center", cursor: "pointer", height: 80,
                                textAlign: "center",
                                color: "#565656",
                                fontSize: 15,
                                fontWeight: "400",
                                width: "200px", }}
                            >
                              <AssessmentIcon style={{ textAlign: "center", cursor: "pointer" }} />
                            </span>
                          </StyledTableCell>

                          {!props.actionTaken && (
                            <div className="actionTableWrapper">
                              <StyledTableCell style={{ textAlign: "center" }}>
                                <textarea
                                  style={{ height: 70, borderRadius: 8, padding: 8 }}
                                  id="commentsId"
                                  placeholder="Comments"
                                  maxLength="300"

                                  onChange={(e) => setReviewComments(e.target.value)}
                                />

                                <Button
                                  variant="contained"
                                  style={{ backgroundColor: "#16a654", maxWidth: "70px", fontSize: 15,
                                    fontWeight: "bold",
                                    maxWidth: 69,
                                    lineHeight: 1.2,
                                    marginRight: 10,
                                    color: "#fff", }}
                                    

                                  onClick={() => {
                                    Promise.all([
                                      dispatch(
                                        purchaseYaag({
                                          toberewardeddata: [
                                            {
                                              registrationid:
                                                item.registrationid,
                                              campid: item.campid,
                                              phoneno: item.formuserphone,
                                            },
                                          ],
                                        })
                                      ),
                                    ])
                                      .then(
                                        () =>
                                          dispatch(
                                            validateReward({
                                              registrationid: [
                                                {
                                                  registrationid:
                                                    item.registrationid,
                                                  campid: item.campid,
                                                  reviewcomments: reviewComments,
                                                  registrationstatus:
                                                    "approved",
                                                  // rewarddate: moment(
                                                  //   new Date()
                                                  // ).format("DD-MM-yyyy"),
                                                  rewarddate: moment(new Date()).format("YYYY-MM-DD"),
                                                },
                                              ],
                                            })
                                          )
                                      )
                                      .then(() => handleClick(item, "approved"))
                                      .then(() => dispatch(validatorList()))
                                      .then(() => acceptRejectIndividual());
                                    validationList1();
                                  }}
                                >
                                  Accept
                                </Button>
                                <Button
                                  style={{ backgroundColor: "#a62e2e", maxWidth: "70px", marginRight: "0px", fontSize: 15,
                                    fontWeight: "bold",
                                    maxWidth: 69,
                                    lineHeight: 1.2,
                                    color: "#fff", }}
                                  variant="contained"
                                  onClick={() => {
                                    Promise.all([
                                      dispatch(
                                        validateReward({
                                          registrationid: [
                                            {
                                              registrationid:
                                                item.registrationid,
                                              campid: item.campid,
                                              reviewcomments: reviewComments,
                                              registrationstatus: "rejected",
                                              // rewarddate: moment(
                                              //   new Date()
                                              // ).format("DD-MM-yyyy"),
                                              rewarddate: moment(new Date()).format("YYYY-MM-DD"),
                                            },
                                          ],
                                        })
                                      ),
                                    ])
                                      .then(() => handleClick(item, "rejected"))
                                      .then(() => dispatch(validatorList()))
                                      .then(() => acceptRejectIndividual());
                                    validationList1();
                                  }}
                                >
                                  Reject
                                </Button>
                                {/* </StyledTableCell> */}

                              </StyledTableCell>
                            </div>
                          )}
                        </StyledTableRow>
                      ))
                    : props.sortedData.map((item, index) => (
                      <StyledTableRow>
                        {props.rows[0].registrationstatus === "submitted" ? (
                            <StyledTableCell style={{ textAlign: "center", width: 35 }}>
                              <input
                            type="checkbox"
                            id={item.registrationid}
                            value="item"
                            onClick={() => {
                              handleBulkClick(item, "singleSelection");
                            }}
                            name="vehicle"
                          ></input>
                            </StyledTableCell>
                          ) : (
                            ""
                          )}
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {item.formcampaignname}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {item.formuserfirstname}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {item.formuserlastname}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {item.formuserphone}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {item.registrationstatus.charAt(0).toUpperCase() +
                            item.registrationstatus.slice(1)}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {item.registerdate}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {item.documentuploadcount}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          <td 
                            onClick={() =>
                              navigate(`/vol/reg/report/${item?.formuserphone}/${item?.registrationid}/${item?.campid}`, {
                                state: item,
                              })
                            }
                            style={{ textAlign: "center", cursor: "pointer", padding: "0px 0px 0px 50px", height: 80,
                              textAlign: "center",
                              color: "#565656",
                              fontSize: 15,
                              fontWeight: "400",
                              width: "200px", }}
                          >
                            <FilePresentIcon style={{ textAlign: "center", cursor: "pointer" }} />
                          </td>
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          <span 
                            onClick={() =>
                              //alert("CLICKED")
                              navigate(`/vol/stat/report/${item?.formuserphone}/${item?.registrationid}/${item?.campid}`, {
                                state: item,
                              })
                            }
                            style={{ textAlign: "center", cursor: "pointer" ,height: 80,
                              textAlign: "center",
                              color: "#565656",
                              fontSize: 15,
                              fontWeight: "400",
                              width: "200px",}}
                          >
                            <AssessmentIcon style={{ textAlign: "center", cursor: "pointer" }} />
                          </span>
                        </StyledTableCell>
                        {!props.actionTaken &&
                          (campaignList
                            .map(function (e) {
                              return e.formcampaignname;
                            })
                            .indexOf(item.campid) <= 1 &&
                            item.registrationstatus === "submitted" ? (
                            <div>
                              <StyledTableCell style={{ textAlign: "center" }}>
                                <textarea
                                  style={{ height: 70, borderRadius: 8, padding: 8 }}
                                  id="commentsId"
                                  placeholder="Comments"
                                  maxLength="300"
                                  // value={(e) => (e.target.value)}
                                  onChange={(e) => setReviewComments(e.target.value)}
                                />
                                <Button
                                  variant="contained"
                                  // disabled={campaignList.map(function(e) { return e.registrationstatus; }).indexOf(item.registrationstatus === "rejected" ? "rejected":"") >= 0 || campaignList.map(function(e) { return e.registrationstatus; }).indexOf(item.registrationstatus === "approved" ? "approved":"") >= 0}
                                  style={{ backgroundColor: "#16a654", maxWidth: "70px", fontSize: 15,
                                    fontWeight: "bold",
                                    maxWidth: 69,
                                    lineHeight: 1.2,
                                    marginRight: 10,
                                    color: "#fff", }}
                                  onClick={() => {
                                    Promise.all([
                                      dispatch(
                                        purchaseYaag({
                                          toberewardeddata: [
                                            {
                                              registrationid:
                                                item.registrationid,

                                              campid: item.campid,
                                              phoneno: item.formuserphone,
                                            },
                                          ],
                                        })
                                      ),
                                    ])
                                      .then(() =>
                                        dispatch(
                                          validateReward({
                                            registrationid: [
                                              {
                                                registrationid:
                                                  item.registrationid,
                                                campid: item.campid,
                                                reviewcomments: reviewComments,
                                                registrationstatus: "approved",
                                                // rewarddate: moment(
                                                //   new Date()
                                                // ).format("DD-MM-yyyy"),
                                                rewarddate: moment(new Date()).format("YYYY-MM-DD"),
                                              },
                                            ],
                                          })
                                        )
                                      )
                                      .then(() => handleClick(item, "approved"))
                                      .then(() => dispatch(validatorList()))
                                      .then(() => acceptRejectIndividual());
                                    validationList1();

                                  }}
                                >
                                  Accept
                                </Button>
                                <Button
                                  style={{ backgroundColor: "#a62e2e", maxWidth: "70px", marginRight: "0px",fontSize: 15,
                                    fontWeight: "bold",
                                    maxWidth: 84,
                                    lineHeight: 1.2,
                                    marginRight: 10,
                                    color: "#fff", }}
                                  variant="contained"
                                  onClick={() => {
                                    Promise.all([
                                      dispatch(
                                        validateReward({
                                          registrationid: [
                                            {
                                              registrationid:
                                                item.registrationid,
                                              campid: item.campid,
                                              reviewcomments: reviewComments,
                                              registrationstatus: "rejected",
                                              // rewarddate: moment(
                                              //   new Date()
                                              // ).format("DD-MM-yyyy"),
                                              rewarddate: moment(new Date()).format("YYYY-MM-DD"),
                                            },
                                          ],
                                        })
                                      ),
                                    ])
                                      .then(() => handleClick(item, "rejected"))
                                      .then(() => dispatch(validatorList()))
                                      .then(() => acceptRejectIndividual());
                                    validationList1();
                                  }}
                                >
                                  Reject
                                </Button>
                              </StyledTableCell>
                            </div>
                          ) : null)
                        }
                      </StyledTableRow>
                    ))}
                </TableBody>
              )}
            </Table>
            <TableFooter>
              <TablePagination
                rowsPerPageOptions={[10, 20, 40]}
                component="div"
                count={props.rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableFooter>
          </div>
          {props.rows[0].registrationstatus === "submitted" ? (
            <div>
              
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <Modal
        show={showModal}
        onHide={() => closeModalWindow()}
      >
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
          <button
            type="button"
            onClick={() => closeModalWindow()}
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>

          <p>
            Registration status is {modalData}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{
              backgroundColor: "#A9A9A9",
              color: "#fff",
            }}
            onClick={() => closeModalWindow()}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </TableContainer>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidatorTable);