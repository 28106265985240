/** @format */

import React, { useEffect, useState } from "react";
import { Chip, Avatar, useMediaQuery } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { topAdopters } from "../redux/action/TopAdopterAction";
// import { useHistory } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { myRank } from "../redux/action/MyRankAction";
import { CopyRight } from "../components/CopyRight";
import ResponsiveDrawer from "../components/Header";
import TopHeaderScreen from "../components/TopHeader1";
import BronzeLvl from "../assets/bronze.png";
import SilverLvl from "../assets/silver.png";
import GoldLvl from "../assets/gold.png";
import DiamondLvl from "../assets/diamond.png";
import PlatinumLvl from "../assets/platinum.png";
import PointsOnAward from "../assets/pngwing 1.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UpNUp from "../assets/upnup.png";
import CAMPPROGRESS from "../assets/right-progress.png";
import RewardImage from "../assets/rewards.png";
import RewardMainImage from "../assets/rew.png";
import ProfileNoPhoto from "../assets/profileNoPhoto.png";
import { isEmpty, isArray } from "lodash";
import { fontWeight } from "@mui/system";
import { Button } from "@mui/material";
import PostRedeemConfirmation from "../components/PostRedeemConfirmation";
import CEOImage from "../assets/ceo.png";
import RedeemConfirmation from "../components/RedeemConfirmation";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CustomShieldIcon from '../components/CustomShieldIcon';
import { styled } from '@mui/material/styles';
import RewardCard from "../components/RewardCard";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: '#F8B34C',
    color: '#ffffff',
    textTransform: 'uppercase',
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
  },
  '&.tbodyText': {
    height: 80,
    textAlign: 'center',
    color: '#565656',
    fontSize: 15,
    fontWeight: 400,
  },
}));

// Styled TableRow component
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: '1px solid green !important',
  '&:last-child': {
    borderBottom: 'none',
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#f7dbb3',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#e9e7e8',
  },
}));



function createData(id, rank, firstname, rewardpoint) {
  return {
    id,
    rank,
    // fullname,
    firstname,
    rewardpoint,
  };
}

function MyPoints(props) {
  const [rows, setRows] = React.useState([]);
  const [level, setLevel] = React.useState("");
  const [nextLevel, setnextLevel] = React.useState("");
  //   const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rank, setRank] = React.useState({});
  const [me, setME] = React.useState(false);
  const [campregisterlist, setCampRegisterList] = useState([]);
  const [marketplacelist, setmarketplacelist] = useState([]);
  const [myredeemedrewards, setmyredeemedrewards] = useState([]);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const { isSuccess: topAdopterSuccess, response: topAdoptersResponse1 } =
    useSelector((state) => state.topAdopters);
  const { topAdoptersResponse, dashboardRewardResponse, myRankResponse } = props;

  console.log("topAdoptersResponse1 = ", topAdoptersResponse1)

  function setDisplayAndIndex(bool, index) {
    setDisplayRedeemConfirmationModal(bool);
    setRedeemIndex(index);
  }
  const [displayRedeemConfirmationModal, setDisplayRedeemConfirmationModal] = useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [redeemindex, setRedeemIndex] = useState(0);
  const [postRedeemDisplayModal, setPostRedeemDisplayModal] = useState(false);
  const [afterRedeem, setAfterRedeem] = useState([]);
  const mypointsRef = React.useRef();
  const { hash } = useLocation();

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (hash) {
      // Delay the scroll to ensure the component is fully rendered
      setTimeout(() => {
        const element = document.getElementById(hash.replace('#', ''));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Adjust delay if needed
    }
  }, [hash]);

  const options = {
    items: 3,
    margin: 10,
    loop: false,
    nav: false,
    autoWidth: false,
    autoplaySpeed: 900,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      566: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const handleRedeem = async () => {
    // setDisplayConfirmationModal(true);

    const payload = {
      //campid: locationPath?.state?.campid,
      // createdate: locationPath?.state?.createdate,
      // reviewstatus: reviewstatus,
      // reviewcomments: reviewComments,
      phoneno: localStorage.getItem("userid"),
      rewardtype: marketplacelist?.data?.[redeemindex]?.rewardtype,
      mpid: marketplacelist?.data?.[redeemindex]?.mpid,
      campid: marketplacelist?.data?.[redeemindex]?.campid,
      title: marketplacelist?.data?.[redeemindex]?.title,
      subtitle: marketplacelist?.data?.[redeemindex]?.subtitle,
      points: marketplacelist?.data?.[redeemindex]?.points,
      // sponsor: JSON.stringify(marketplacelist?.data?.[redeemindex]?.sponsor),
      sponsor: marketplacelist?.data?.[redeemindex]?.sponsor,

      // phoneno: locationPath?.state.phoneno,
    };
    try {
      const res = await axios.post(
        `/reward/redeem/${profileResponse?.phoneno}`,
        payload
      );
      // navigate("/reviewcampaign");
      setAfterRedeem(res?.data);
      // console.log(res?.data?.message);
      // alert("calling set display false")
      setDisplayRedeemConfirmationModal(false);
      if (res?.data?.message === "Reward successfully redeemed") {
        setPostRedeemDisplayModal(true);
        // navigate("/hacampscreen");
      } else {
        setDisplayConfirmationModal(true);
      }
      return res;
    } catch (err) {
      throw err;
    }
  };

  // Hide the modal
  const hideRedeemConfirmationModal = () => {
    setDisplayRedeemConfirmationModal(false);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handlePostRedeemModal = () => {
    setPostRedeemDisplayModal(false);
    navigate("/marketplace");
  };
  useEffect(() => {
    let temp = [];
    for (let i = 0; i < topAdoptersResponse.length; i += 1) {
      const formattedArray = [];
      formattedArray.push(
        topAdoptersResponse[i].rank,
        // topAdoptersResponse[i].fullname,
        topAdoptersResponse[i].firstname,
        topAdoptersResponse[i].rewardpoint,
        topAdoptersResponse[i].profilephoto,
        topAdoptersResponse[i].campparticipatedcount
      );

      temp.push(createData(i, ...formattedArray));
    }
    setRows(temp);
  }, [topAdoptersResponse]);


  useEffect(() => {
    dispatch(topAdopters(localStorage.getItem("userid")));
    dispatch(myRank(localStorage.getItem("userid")));
  }, [dispatch]);

  useEffect(() => {
    getCampRegistrationList();
    mypointsRef.current.scrollIntoView({ behavior: 'smooth' });

  }, []);

  useEffect(() => {

    getRewardsFromMarketplace();
  }, []);

  //   console.log(rows, "rank");

  const filteredData = rows && rows.filter((rows) => rows.firstname === "YOU");
  //   console.log(filteredData, "filteredData");
  // useEffect(() => {
  // 	if (data.table.filter(rows => rows.fullname === 'YOU')) {
  // 		setME(false);
  // 		console.log('me', me);
  // 	} else {
  // 		setME(true);
  // 		console.log('me', me);
  // 	}
  // }, [rows]);

  useEffect(() => {
    if (dashboardRewardResponse.level === "0") {
      setLevel("Starter");
      setnextLevel("Bronze");
    } else if (dashboardRewardResponse.level === "1") {
      setLevel("Bronze");
      setnextLevel("Silver");
    } else if (dashboardRewardResponse.level === "2") {
      setLevel("Silver");
      setnextLevel("Gold");
    } else if (dashboardRewardResponse.level === "3") {
      setLevel("Gold");
      setnextLevel("Diamond");
    } else if (dashboardRewardResponse.level === "4") {
      setLevel("Diamond");
      setnextLevel("Platinum");
    } else if (dashboardRewardResponse.level === "5") {
      setLevel("Platinum");
    }
  }, [dashboardRewardResponse]);

  const getCampRegistrationList = async () => {
    try {
      const res = await axios.get(
        `vol_dashboard/camp/${localStorage.getItem("userid")}`
      );
      setCampRegisterList(res?.data);
      // JSON.stringify(res)

      return res;
    } catch (err) {
      throw err;
    }
  };

  const getRewardsFromMarketplace = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
      const res = await axios.get(
        `/marketplace/read/${profileResponse?.phoneno}`
      );
      setmarketplacelist(res?.data);

      return res;
    } catch (err) {
      throw err;
    }
  };

  const getMyRedeemedRewards = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);

      const res = await axios.get(
        `reward/redeemed/${profileResponse?.phoneno}`
      );
      // console.log(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getMyRedeemedRewards().then((data) => {
      setmyredeemedrewards(data);
    });
  }, []);


  console.log("profileResponse = ", profileResponse?.profilephoto)
  console.log("trueorfalse = ", !profileResponse?.profilephoto == undefined)

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers" ref={mypointsRef}>
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
        {/* <div style={{padding: "59px 20px 3px 20px", margin: "10px 0",}}> */}
          <Button onClick={() => navigate(-1)} className="backWrapper">
            <i className={`fa fa-caret-left`} />
            <span style={{ paddingLeft: 3, fontSize: 14, color: "#1976d2" }}>
              Back
            </span>
          </Button>
          <div>
            <span
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Rewards
            </span>
          </div>
          <div class="reward-top-sec cyagg-sec-space">
            <div class="container pointsMobileWrapper">
              <div class="row">
                <div class="col-md-4 order-on-mob-2 col-6 leftAbsolute">
                  <div class="blockWrapperContainer">
                    <div class="t-reward-wrap text-center">
                      <div class="col-one">
                        <img src={PointsOnAward} class="img-fluid"></img>
                      </div>
                      <div class="col-two text-center">
                        <span>Points</span>

                        <h5 >{myRankResponse.rewardpoint}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="person-r-points">
                    <div class="per-img">
                      {
                        // !(searchedUser?.[0]?.profilephoto ===     undefined) ||
                        !(profileResponse?.profilephoto === undefined || profileResponse?.profilephoto === " " || profileResponse?.profilephoto === "") ? (
                          <img
                            class="one1"
                            src={profileResponse?.profilephoto}
                            onClick={() => navigate(`/newprofile/${profileResponse?.profileurl}`)}
                            style={{cursor: 'pointer'}}
                          ></img>
                        ) : profileResponse?.profilephoto === undefined ? (
                          // <span>
                          // <AccountCircleIcon fontSize="large" />
                          // </span>

                          <Avatar
                            class="one1"
                            style={{
                              width: "122px",
                              borderRadius: "50%",
                              height: "122px",
                              verticalAlign: "super",
                              maxWidth: "100%",
                              margin: "0 auto"
                            }}
                            src="/broken-image.jpg"
                            onClick={() => navigate(`/newprofile/${profileResponse?.profileurl}`)}
                          // src="../assets/broken-image.jpg"
                          >

                          </Avatar>
                          
                        ) : (

                          <Avatar
                            class="one1"
                            style={{
                              width: "122px",
                              borderRadius: "50%",
                              height: "122px",
                              verticalAlign: "super",
                              maxWidth: "100%",
                              margin: "0 auto"
                            }}
                            src="/broken-image.jpg"
                          // src="../assets/broken-image.jpg"
                          >

                          </Avatar>
                          // </span>
                          // />
                          // <Avatar class="one1" style={{display:"inline-block",verticalAlign:"middle"}} src="/broken-image.jpg" />
                          // <AccountCircleIcon fontSize="large" />

                          //   <img
                          //   src="/broken-image.jpg"
                          //   class="img-fluid new-profile-image"
                          // />
                        )
                      }
                    </div>
                    <div class="per-main-wrap">
                      <span class="per-main-name">
                        {profileResponse?.firstname} {profileResponse?.lastname}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-6 rightAbsolute">
                  <div class="blockWrapperContainer">
                    <div class="overall-wrap">
                      <div class="col-one">
                        {dashboardRewardResponse?.level === "1" ? (
                          <img src={BronzeLvl} class="img-fluid"></img>
                        ) : dashboardRewardResponse?.level === "2" ? (
                          <img src={SilverLvl} class="img-fluid"></img>
                        ) : dashboardRewardResponse?.level === "3" ? (
                          <img src={GoldLvl} class="img-fluid"></img>
                        ) : dashboardRewardResponse?.level === "4" ? (
                          <img src={DiamondLvl} class="img-fluid"></img>
                        ) : (
                          <img src={PlatinumLvl} class="img-fluid"></img>
                        )}
                      </div>
                      <div class="col-two text-center">
                        <span>Level</span>
                        <h5>{level}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              fontSize: 18,
              padding: 8,
              // textTransform: "uppercase",
              textAlign: "center",
              // color: "rgba(175,84,32,1)",
            }}
          >
            <span style={{ fontWeight: "600", color: "rgba(175,84,32,1)" }}>
              {dashboardRewardResponse &&
                dashboardRewardResponse.nextlevel + " "}{" "}
            </span>

            {nextLevel === "" ? "" : ` points more to reach `}
            {nextLevel === "" ? (
              ""
            ) : (
              <span style={{ fontWeight: "600", textTransform: "uppercase" }}>
                {nextLevel + ""}
              </span>
            )}
            {nextLevel === "" ? "" : ` level`}
          </div>
        {/* </div> */}

        <div class="progress-on-campaign cyagg-sec-space">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div>
                  <h4 class="site-title">Your Progress on Active Campaigns</h4>
                </div>
              </div>
            </div>

            <div>
              <div class="row">
                <div class="col-md-5">
                  {/* <div class="u-custom-scrollbar"> */}
                  <div class="u-custom-mypoints-scrollbar">
                    {campregisterlist &&
                      campregisterlist.length > 0 &&
                      campregisterlist?.map((camp) => (
                        <div class="progressing-a-campaigns">
                          <div class="row">
                            <div class="col-md-7 col-7">
                              <div class="content-col-1">
                                <h6>{camp.name}</h6>
                                <span>
                                  Points: <span>{camp.rewardpoints}</span>
                                </span>
                              </div>
                            </div>
                            <div class="col-md-5 col-5">
                              <div class="content-col-2">
                                <div class="campaigns-leaders-re">
                                  <img src={UpNUp}></img>

                                  {camp?.rank?.map((topper) =>
                                    topper?.profilephoto ? (
                                      <a>
                                        <img src={topper?.profilephoto} onClick={() => navigate(`/camp/leaderboard/${camp.campid}`)}></img>
                                      </a>
                                    ) : (
                                      <a>
                                        <img src={ProfileNoPhoto} onClick={() => navigate(`/camp/leaderboard/${camp.campid}`)}></img>
                                      </a>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {campregisterlist &&
                      campregisterlist.length == 0 &&
                      // campregisterlist?.map((camp) => (
                      <div class="progressing-a-0-campaigns">
                        Please register / participate in active campaigns
                      </div>
                    }
                  </div>
                </div>
                <div class="col-md-7">
                  <div>
                    <img src={CAMPPROGRESS} class="img-fluid"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cyagg-sec-space">
          <div class="container">
            <div class="row">
              <div class="col-6" id="platform-leaderboard">
                <div>
                  <h4 class="site-title">Platform Leaderboard</h4>
                </div>
              </div>
              <div class="col-6">
                <div
                  style={{ textAlign: "right" }}
                  onClick={() => navigate(`/marketplace`)
                  }
                >
                  <a class="story-signin" style={{padding:"6px 8px"}}>
                    View More <i class="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div
                  class="col-md-4 hide-on-mobile"
                  style={{ paddingRight: "0px" }}
                >
                  <div class="leaderboard-left">
                    <div class="text-center">
                      <h5>Top Player</h5>
                      {
                        topAdoptersResponse1?.[0]?.profilephoto != "" ? (
                          <img
                            src={topAdoptersResponse1?.[0]?.profilephoto}
                          ></img>
                        ) : (
                          // <i class="fa fa-user mr-2 fa-lg"></i>
                          <span>
                            <AccountCircleIcon fontSize="large" />
                          </span>
                        )
                        // <img src={ProfileNoPhoto}></img>
                      }
                      <span style={{ fontSize: 36, padding: 24 }}>
                        {topAdoptersResponse1 &&
                          topAdoptersResponse1?.[0]?.firstname}{" "}
                        {topAdoptersResponse1?.[0]?.lastname}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-md-8 col-12" style={{ paddingLeft: "0px", marginLeft: isMobile ? '5px' : '0px' }}>
                  <div class="fixedHeader" style={{ height: "465px" }}>
                    <table stickyHeader class="leader-b-table">
                      <thead style={{ top: isMobile ? "-2px" : "-1px" ,position: "sticky", zIndex: 10}}>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            {" "}
                            Rank
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Top Player Name
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            # of camps
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Total Points
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topAdopterSuccess &&
                          topAdoptersResponse1?.map((row) => (
                            <tr>

                              {
                                row?.rank === 1 || row?.rank === 2 || row?.rank === 3 ?
                                  <td
                                    data-label="Account"
                                    style={{ textAlign: "center", backgroundColor: "#74C3FC" }}
                                  >
                                    <div style={{ position: "relative", display: "inline-block" }}>
                                    {/* <div > */}
                                      {
                                        row?.rank === 1 ?
                                          <CustomShieldIcon  color="gold" />
                                          : row?.rank === 2 ?
                                            <CustomShieldIcon  color="whitesmoke" />
                                            : row?.rank === 3 ?
                                              <CustomShieldIcon  color="#CD7F32" />
                                              :
                                              <CustomShieldIcon  color="silver" />
                                      }
                                      <span style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        fontSize: "16px",
                                        color: "black",
                                        fontWeight: "bold",
                                        // zIndex: 2,
                                      }}>
                                        {row.rank}
                                      </span>
                                    </div>
                                  </td>
                                  :
                                  <td
                                    data-label="Account"
                                    style={{ textAlign: "center" }}
                                  >
                                    <div style={{ position: "relative", display: "inline-block" }}>
                                    {/* <div > */}
                                      <CustomShieldIcon  color="silver" />
                                      <span style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        fontSize: "16px",
                                        color: "black",
                                        fontWeight: "bold"
                                      }}>
                                        {row.rank}
                                      </span>
                                    </div>
                                  </td>
                              }

                              {

                                row?.rank === 1 || row?.rank === 2 || row?.rank === 3 ?
                                  <td data-label="Due Date" style={{ backgroundColor: "#74C3FC" }}>
                                    <div class="top-player-box" >
                                      <div class="top-player-box" style={{ textAlign: "center" }}>
                                        {row?.profilephoto != "" ? (
                                          <img src={row?.profilephoto}></img>
                                        ) : (
                                          <Avatar style={{ display: "inline-block", verticalAlign: "middle",  }} src="/broken-image.jpg" />
                                        )}
                                      </div>
                                      <div class="top-player-box" style={{ textAlign: "center" }}>
                                        {"   "}
                                        {row?.firstname === "YOU" ? (
                                          <span
                                            style={{
                                              color: "blueviolet",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {row?.firstname}
                                          </span>
                                        ) : (
                                          <span>{row?.firstname}</span>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  :
                                  <td data-label="Due Date" >
                                    <div class="top-player-box" >
                                      <div class="top-player-box" style={{ textAlign: "center" }}>
                                        {row?.profilephoto != "" ? (
                                          <img src={row?.profilephoto}></img>
                                        ) : (
                                          // <Avatar style={{ display: "inline-block", verticalAlign: "middle" }} src="/broken-image.jpg" />
                                          <Avatar style={{ display: "inline-block", verticalAlign: "middle",  }} src="/broken-image.jpg" />
                                        )}
                                      </div>
                                      <div class="top-player-box" style={{ textAlign: "center" }}>
                                        {"   "}
                                        {row?.firstname === "YOU" ? (
                                          <span
                                            style={{
                                              color: "blueviolet",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {row?.firstname}
                                          </span>
                                        ) : (
                                          <span>{row?.firstname}</span>
                                        )}
                                      </div>
                                    </div>
                                  </td>

                              }
                              {
                                row?.rank === 1 || row?.rank === 2 || row?.rank === 3 ?
                                  <td
                                    data-label="Amount"
                                    style={{ textAlign: "center", backgroundColor: "#74C3FC" }}
                                  >
                                    {row?.campparticipatedcount}
                                  </td>
                                  
                                  :
                                  <td
                                    data-label="Amount"
                                    style={{ textAlign: "center" }}
                                  >
                                    {row?.campparticipatedcount}
                                  </td>
                              }
                              {
                                row?.rank === 1 || row?.rank === 2 || row?.rank === 3 ?
                                  <td
                                    data-label="Period"
                                    style={{ textAlign: "center", backgroundColor: "#74C3FC" }}
                                  >
                                    {row?.rewardpoint}
                                  </td>

                                  :
                                  <td
                                    data-label="Period"
                                    style={{ textAlign: "center" }}
                                  >
                                    {row?.rewardpoint}
                                  </td>

                              }
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <style>
                    {`
                                                                
                                                                .fixedHeader {
                                                                    overflow-y: auto;
                                                                }
                                                                /* WebKit browsers */
                                                                .fixedHeader::-webkit-scrollbar {
                                                                    height: 4px; 
                                                                    width: 4px;
                                                                }

                                                                .fixedHeader::-webkit-scrollbar-thumb {
                                                                    background-color: darkgrey;
                                                                    border-radius: 10px;
                                                                }

                                                                .fixedHeader::-webkit-scrollbar-track {
                                                                    background-color: #f1f1f1;
                                                                    border-radius: 10px;
                                                                }
                                                            `}
                  </style>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cyagg-sec-space">
          <div class="container">
            <div class="row">
              <div class="col-7">
                <div>
                  <h4 class="site-title">Win Rewards!</h4>
                </div>
              </div>
              <div class="col-5">
                <div
                  style={{ textAlign: "right" }}
                  onClick={() => navigate(`/marketplace`)
                  }
                >
                  <a class="story-signin" style={{padding:"6px 8px"}}>
                    View More <i class="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-5"></div>
            </div>
            <div>
              <div class="row">
                <div class="col-md-4">
                  <div>
                    <img
                      class="hide-on-mobile"
                      src={RewardMainImage}
                      style={{ width: "93%" }}
                    ></img>
                  </div>
                </div>

                <div class="col-md-8">
                {
                          !isEmpty(marketplacelist) && marketplacelist &&
                        <RewardCard 
                          page="reward"
                          marketplacelist={marketplacelist}
                        />
}
                  {/* <div class="">
                    <OwlCarousel className="owl-theme" {...options}>
                      {marketplacelist &&
                        marketplacelist?.map(
                          (reward, index) =>
                            index < 5 && (
                              <div class="item">
                                <div class="reward-wrap">
                                  <div>
                                    {
                                      !(reward?.logo === "") ? (
                                        reward?.expired === "yes" ?
                                          <img
                                            class="reward-image"
                                            src={reward?.logo}
                                            style={{ width: "100%", opacity: 0.4 }}
                                          />
                                          :
                                          <img
                                            class="reward-image"
                                            src={reward?.logo}
                                            style={{ width: "100%" }}
                                          />
                                      ) : (
                                        reward?.expired === "yes" ?
                                          <img
                                            class="reward-image"
                                            src={CEOImage}
                                            style={{ width: "100%", opacity: 0.4 }}
                                          />
                                          :
                                          <img
                                            class="reward-image"
                                            src={CEOImage}
                                            style={{ width: "100%" }}
                                          />
                                      )
                                    }
                                  </div>
                                  <div class="reward-details">
                                    <div>
                                      <div>
                                        <div id="toggleAccordion">
                                          <div class="card mb-1">
                                            <div
                                              class="card-header"
                                              id="headingOne4"
                                            >
                                              <h5 class="mb-0 mt-0 reward-desc-title">
                                                <span
                                                  role="menu"
                                                  class=""
                                                  data-toggle="collapse"
                                                  data-target={"#" + index}
                                                  aria-expanded="true"
                                                  aria-controls="iconChangeAccordionOne"
                                                >
                                                  {reward?.rewardtype}
                                                  <i class="flaticon-down-arrow float-right"></i>
                                                </span>
                                              </h5>
                                            </div>
                                            <div
                                              id={index}
                                              class="collapse"
                                              aria-labelledby="headingOne4"
                                              data-parent="#toggleAccordion"
                                            >
                                              <div class="card-body">
                                                <p class="mb-3">
                                                  <div style={{textAlign: "center",}} >
                                                    <span
                                                      style={{
                                                        fontSize: 12,
                                                        color: "blue",
                                                      }}
                                                    >
                                                      {" "}
                                                      {
                                                        reward?.subtitle
                                                      }{" "}
                                                    </span>
                                                  </div>
                                                </p>
                                                <p class="mb-3">
                                                  <div style={{textAlign: "center",}}>
                                                    <span
                                                      style={{
                                                        fontSize: 12,
                                                        color: "green",
                                                      }}
                                                    >
                                                      {" "}
                                                      {reward?.title}{" "}
                                                    </span>
                                                  </div>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="redeem-pts">
                                        <div class="row">
                                          <div class="col-12">
                                            <div class="re-po1-right">
                                              {!(
                                                reward?.campid === "camp00001"
                                              ) ? (
                                                <Button
                                                  onClick={
                                                    () =>
                                                      navigate(
                                                        `/participate/campaign/${reward?.campid}/${profileResponse?.phoneno}`
                                                      )
                                                  }
                                                  style={{
                                                    textAlign: "left",
                                                    maxHeight: "50px"
                                                  }}
                                                >
                                                  <a
                                                    style={{
                                                      fontFamily: "sans-serif",
                                                    }}
                                                  >
                                                    {reward?.campname}
                                                  </a>
                                                </Button>
                                              ) : (
                                                <Button
                                                  onClick={
                                                    () => navigate(`/aboutus`)
                                                  }
                                                  style={{
                                                    textAlign: "left",
                                                    maxHeight: "50px"
                                                  }}
                                                >
                                                  <a
                                                    style={{
                                                      fontFamily: "sans-serif",
                                                    }}
                                                  >{reward?.campname}</a>
                                                </Button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="text-center">
                                        <h6 class="sponsor-title">
                                          Sponsors
                                        </h6>
                                        <div class="row sponserWrapper">
                                          <div class="">
                                            {isArray(reward?.sponsor) &&
                                              reward?.sponsor?.map(
                                                (reward, index) => (
                                                  <img
                                                    style={{
                                                      width: "auto",
                                                      height: "50px",
                                                    }}
                                                    src={
                                                      reward?.sponsorlogo
                                                    }
                                                  />
                                                )
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                    </OwlCarousel>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
        <RedeemConfirmation
          showModal={displayRedeemConfirmationModal}
          confirmModal={handleRedeem}
          hideModal={hideRedeemConfirmationModal}
          message={"Are you sure you want to redeem this reward?"}
        />
        <ConfirmationDialog
          showModal={displayConfirmationModal}
          hideModal={hideConfirmationModal}
          message={"You do not have enough points to redeem"}
        />
        <PostRedeemConfirmation
          showModal={postRedeemDisplayModal}
          confirmModal={handlePostRedeemModal}
          message={"You have successfully redeemed"}
        />
        </div>
        </div>
      </section>
      
      
    </div>
  );
}
const mapStateToProps = (state) => ({
  topAdoptersResponse: state.topAdopters.response,
  myRewardResponse: state.myRewardReducer && state.myReward.response,
  myRewardSuccess: state.myRewardReducer && state.myReward.isSuccess,
  dashboardRewardResponse:
    state.volDashBoardReward && state.volDashBoardReward.response,
  myRankResponse: state.myRank.response,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        topAdopters,
        myRank,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPoints);
