/** @format */

import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
} from "@mui/material";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  userProfile,
  userProfileReset,
} from "../redux/action/UserProfileActions";
import { CopyRight } from "./CopyRight";
import { volunteerMenuItems } from "../utils/dict";
import ResponsiveDrawer from "./Header";
import axios from "axios";
import { API_URL } from "../services/Constants";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { socialLoginReset } from "../redux/action/loginAction";
import { getUserProfileImage } from "../redux/action/userProfileImage";
import rewardIcon from "../assets/rewardIconNav.png";
import { volDashBoardReward } from "../redux/action/DashboardReward";
import GradeSharpIcon from "@mui/icons-material/GradeSharp";
import TopHeaderScreen from "./TopHeader1";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { FixedFooterIcon } from "./FixedFooterIcon";
import styled from 'styled-components';

const StyledCardTitleText = styled(Box)(({ theme }) => ({
  fontSize: 26,
  fontWeight: 400,
  width: 330,
  right: '0%',
  top: 0,
  boxShadow: '1px 2px 10px rgba(0,0,0,0.2)',
  borderRadius: 8,
  background: '#fff',
  '@media (max-width: 600px)': {
    fontSize: 18,
    fontWeight: 400,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const dispatch = useDispatch();

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const BroadcastArchive = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [BCArchiveList, setBCArchiveList] = useState([]);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const [apiCalled, setApiCalled] = useState(false); // State to track API call

  // Function to get broadcasted archives
  const getBroadcastedArchive = async () => {
    try {
      // Prevent the API call if it has already been made
      if (apiCalled) return;

      // Check if profileResponse?.uniqueuserid exists before making the API call
      if (!profileResponse?.uniqueuserid) {
        console.error("Unique User ID is missing.");
        return;
      }

      // Make the API request
      const res = await axios.get(
        `/user/profile/bc/archived/${profileResponse?.uniqueuserid}`
      );
      setBCArchiveList(res?.data);

      // Set flag to prevent further API calls
      setApiCalled(true);

      return res;
    } catch (err) {
      console.error("Error fetching broadcast archive:", err);
      throw err;
    }
  };

  // Dispatch profile data fetch
  useEffect(() => {
    dispatch(userProfile(localStorage.getItem("userid"))); // Dispatch to fetch profile data
  }, [dispatch]);

  // Call getBroadcastedArchive once profile data is successfully loaded
  useEffect(() => {
    if (profileSuccess && profileResponse?.uniqueuserid && !apiCalled) {
      // Now call getBroadcastedArchive only after profile data is available
      getBroadcastedArchive();
    }
  }, [profileSuccess, profileResponse, apiCalled]); // Run when profile data is updated


  return (
    <div className="wrapperNotificationArchive">
      <ResponsiveDrawer {...props} />
      <section className="containers" style={{ overflow: "hidden" }}>
        <TopHeaderScreen {...props} />
        <div class="content content-wrap" style={{ marginBottom: 50 }}>
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>

            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Announcement
            </div>

            <div>
              <StyledCardTitleText>
                <div style={{ marginTop: "0px" }}>
                  {_.isEmpty(BCArchiveList) ? (
                    <div>
                      <span
                        style={{
                          // background: "#fff",
                          // float: "left",
                          width: "100%",
                          // width: "300px",

                          padding: "10px 12px",
                        }}
                      >
                        No Announcements
                      </span>
                    </div>
                  ) : (
                    BCArchiveList && BCArchiveList?.map((item, index) => (
                      <div key={index}>
      {item?.broadcast?.map((broadcastItem, bIndex) => (
                        <div
                          key={broadcastItem.bcid || bIndex}
                          style={{
                            background: "#fff",
                            float: "left",
                            width: 336,
                            padding: "10px 12px",
                            borderBottom: "1px solid #ccc",
                            backgroundColor: "rgba(255,255,255,0.9)",
                            borderRadius: 10,
                            padding: 16,
                            marginTop: 20,
                            boxShadow: "0.5px 0.5px 5px rgba(0,0,0,0.3)",
                          }}
                        >
                          <div
                            class=""
                            style={{
                              color: "#000",
                              float: "left",
                              // width: "calc(100% - 60px)",
                            }}
                            
                          >
                            <span
                              style={{
                                color: "#000",
                                // display: "flex",
                                display: "inline-block",
                                // justifyContent: "flex-end",
                                fontSize: 16,
                                color: "grey",
                                display: "block",
                                marginBottom: "8px"
                              }}
                            >
                              {moment(broadcastItem.createdate).fromNow()}
                            </span>
                            <span
                              style={{
                                fontSize: "18px",
                                cursor: "pointer",
                                lineHeight:"23px",
                                maxWidth: "100%",
                                wordBreak: "break-word",
                                display: "block",
                                "&:hover": {
                                  textDecoration: "underline",
                                  color: "blue",
                                },
                              }}
                            >
                              {broadcastItem.msg}
                            </span>
                          </div>
                        </div>
                         ))}
                      </div>
                    ))
                  )}
                </div>
              </StyledCardTitleText>
            </div>
          </div>
        </div>
        <br />
        <br />
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
};

export default BroadcastArchive;
