import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import {
  userProfile,
  userProfileReset,
} from "../redux/action/UserProfileActions";
import { useDispatch, useSelector } from "react-redux";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [channelId, setChannelId] = useState(null);
  const socketRef = useRef(null);
  const hasSentClientId = useRef(false); // Track whether the clientId has been sent
  const reconnectTimeoutRef = useRef(null);
  const RECONNECT_DELAY = 5000;
  const dispatch = useDispatch();

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const userid = localStorage.getItem("userid");

  React.useEffect(() => {
    if(userid) {
      dispatch(userProfile(userid));
    }
  }, [userid, dispatch]);

  // Function to generate or set a channelId based on the current user context
  const generateChannelId = (sourceUserId, targetUserId) => {
    return `${sourceUserId}-${targetUserId}`;
  };


  const connectWebSocket = () => {
    console.log("Attempting to connect WebSocket...");
    // const ws = new WebSocket('wss://chatdev.cyaag.com');
    const ws = new WebSocket('ws://localhost:5000');
    console.log("WebSocket readyState (initial):", ws.readyState); 

    socketRef.current = ws;

    ws.onopen = () => {
      console.log('Connected to WebSocket');
      console.log("WebSocket readyState (onopen):", ws.readyState); 
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current); // Clear reconnection attempts
        reconnectTimeoutRef.current = null;
      }
      console.log("profileSuccess = ", profileSuccess);
      console.log("profileResponse?.uniqueuserid = ", profileResponse?.uniqueuserid)

      // Send clientId to the WebSocket server after connection
      if (profileSuccess && profileResponse?.uniqueuserid && !hasSentClientId.current) {
        let clientId = profileResponse?.uniqueuserid;
        const messageToServer = {
          type: 'clientId',  // Type of the message, can be customized
          clientId,          // Sending the clientId
        };
        ws.send(JSON.stringify(messageToServer));
        hasSentClientId.current = true; // Mark as sent
        console.log('Sent clientId to WebSocket server:', clientId);
      }

    };

    ws.onmessage = (event) => {
      try {
        console.log("Raw WebSocket message received:", event);
        const message = JSON.parse(event.data);
        console.log("Received WebSocket message in context:", message);
        console.log("message.message.channelId:", message.message.channelId);
        console.log("channelId = ", channelId);

        // Only add the message to state if the message's channelId matches the current channelId
        // if (message.message.channelId === channelId) {
          setMessages((prev) => [...prev, message]);
        // } else {
        //   console.log("Message ignored. Channel ID doesn't match:", message.message.channelId);
        // }
      } catch (error) {
        console.error("Failed to parse WebSocket message:", event.data, error);
      }
    };

    ws.onclose = (event) => {
      console.warn('WebSocket closed:', event.reason || 'Unknown reason');
      console.log("WebSocket readyState (onopen):", ws.readyState); 
      reconnect(); // Attempt to reconnect
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      ws.close(); // Ensure connection resets on error
    };
  };

  const reconnect = () => {
    if (!reconnectTimeoutRef.current) {
      console.log("Scheduling reconnection...");
      reconnectTimeoutRef.current = setTimeout(() => {
        hasSentClientId.current = true; 
        connectWebSocket();
      }, RECONNECT_DELAY);
    }
  };

  console.log("profileSuccess = ", profileSuccess)
  useEffect(() => {
    // if (profileSuccess && profileResponse?.uniqueuserid) {
      console.log("Profile loaded successfully. Initializing WebSocket...");
      connectWebSocket(); // Connect WebSocket only after profile is loaded
    // }
    
  }, [profileSuccess, profileResponse]);

   
  const sendMessage = (message) => {
    console.log("sendMessage called");
    // Generate a channelId if not set
    const currentChannelId = channelId || generateChannelId(message.sourceUserId, message.targetUserId);
    setChannelId(currentChannelId);
    console.log("message to be put on Socket = ", message);
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      console.log("Socket is open");
      const messageToSend = {
        message,
        channelId: currentChannelId, // Attach channelId
        notifyReceiver: true,
      };
      socketRef.current.send(JSON.stringify(messageToSend));
      console.log("message put on socket = ", JSON.stringify(messageToSend));
    } else {
      console.error('WebSocket is not open. Unable to send message:', message);
    }
  };

  return (
    <WebSocketContext.Provider value={{ socket: socketRef.current, messages, sendMessage, setChannelId }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);