import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Button } from "@mui/material";
import { CopyRight } from "./CopyRight";
import LoginLogo from "../assets/sdp.jpeg";
import "./Join/Join.css";
import { userProfile } from "../redux/action/UserProfileActions";
import ConfirmationDialog from "./ConfirmationDialog";
import styled from 'styled-components';


const LoginPgLogo = styled('div')({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (min-width: 960px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
});


const EmailVerification = (props) => {
  const [otp, setOtp] = React.useState("");
  const [validation, setValidation] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();
  const referralid = localStorage.getItem("referralid");
  const referedcampid = localStorage.getItem("referedcampid")
  const reflevel = localStorage.getItem("reflevel")

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state?.profile
  );


  const setOtpVal = (e) => {
    setOtp(e.target.value);
  };

  
  const {
    isSuccess: emailloginSuccess,
    isFetching: emailloginFetching,
    isError: emailloginError1,
    response: emaillogin,
  } = useSelector((state) => state?.socialEmailLogin);

  const sendEmailOTP = async () => {
    const phoneno = emaillogin.phoneno;
    
    const res = await axios.post(`/verifyemail`, {
      phoneno: phoneno,
      email: locationPath?.pathname.split("/")?.[3],
    });
    setDisplayConfirmationModal(true);
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] =  React.useState(false);
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    // navigate('/marketplace');
  };

  React.useEffect(() => {
    if (locationPath) {
      dispatch(userProfile(locationPath?.pathname.split("/")?.[2]));
    }
  }, []);

  
 


  console.log("title local storage check = ", (localStorage.getItem('title') !== null || localStorage.getItem('title') !== "" || localStorage.getItem('title') !== "null"))
  console.log("localStorage title = ", localStorage.getItem("title"))
  const handleVerify = async () => {
 
    try {
      const res = await axios.post(
        `/validateotp/email/login/${locationPath?.pathname.split("/")?.[2]}`,
        {
          token: otp,
        }
      );
      
      setValidation(res?.data?.message);
      if (res?.data?.message === "VALIDATED") {
       
        if (profileSuccess) {
          // localStorage.setItem("token", JSON.stringify(jwt(profileResponse?.token)))
          // localStorage.setItem("token", JSON.stringify((profileResponse?.token)))
          localStorage.setItem("token", profileResponse?.token);

          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${profileResponse?.token}`;
        }
        // if(localStorage.getItem('referralid') !== null && localStorage.getItem('referedcampid') !== null && localStorage.getItem('reflevel') !== null &&
        //         localStorage.getItem('referralid') !== "" && localStorage.getItem('referedcampid') !== "" && localStorage.getItem('reflevel') !== "") {
          if(referralid !== null && referedcampid !== null && reflevel !== null &&
            referralid !== "" && referedcampid !== "" && reflevel !== "" &&
            referralid !== "null" && referedcampid !== "null" && reflevel !== "null") {
          
            navigate(`/participate/campaign/${localStorage.getItem('referedcampid')}/${profileResponse?.phoneno}`)
        } else if (localStorage.getItem('title') !== null && localStorage.getItem('title') !== "" && localStorage.getItem('title') !== "null") {
          navigate(`/participate/camp/${localStorage.getItem('title')}`)
        }else if (profileResponse?.orgrole?.toLowerCase() === "investor") {
          navigate("/create_yaag");
        } else if (profileResponse?.orgrole?.toLowerCase() === "national") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "volunteer") {
          // navigate("/volunteer");
          navigate("/home");
        } else if (profileResponse?.orgrole?.toLowerCase() === "cyaagadmin") {
          navigate("/home");
        } else if (profileResponse?.orgrole?.toLowerCase() === "sponsoradmin") {
          navigate("/home");
        } else if (profileResponse?.orgrole?.toLowerCase() === "admin") {
          navigate("/home");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region1") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region2") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region3") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region4") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region5") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region6") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region7") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region8") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region9") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region10") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region11") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region12") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region13") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region14") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region15") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "campadmin") {
          navigate("/home");
        }
      } else {
        setValidation(res?.data?.message);
      }

      // const res = await axios({
      //   url: `/validateotp/${locationPath?.pathname.split("/")?.[2]}/${payload.userId}`,
      //   method: "post",
      //   // data: payload.formData,
      //   headers: {
      //     "content-type": "multipart/form-data",
      //   },
      // });
      // const data = get(res, "data");
      // return data;

      // setreferralrewardmsg(res?.data);
      return res;
      // }
    } catch (err) {
      // setLoading(false);

      throw err;
    }

    // if (profileResponse?.orgrole?.toLowerCase() === "investor") {
    //   navigate("/create_yaag");
    // } else if (profileResponse?.orgrole?.toLowerCase() === "national") {
    //   navigate("/exec");
    // } else if (profileResponse?.orgrole?.toLowerCase() === "volunteer") {
    //   // navigate("/volunteer");
    //   navigate("/home");
    // } else if (profileResponse?.orgrole?.toLowerCase() === "cyaagadmin") {
    //   navigate("/home");
    // } else if (profileResponse?.orgrole?.toLowerCase() === "sponsoradmin") {
    //   navigate("/home");
    // } else if (profileResponse?.orgrole?.toLowerCase() === "admin") {
    //   navigate("/home");
    // }
  };
  return (
    <div className="landingPageWrapper">
      {/* <ResponsiveDrawer {...props} /> */}
      <section className="containers emailOtp" style={{ overflow: "hidden" }}>
        {/* <TopHeaderScreen {...props} /> */}
        <div class="content content-wrap">
          {/* <div class="content-mrg"> */}
          <LoginPgLogo style={{ textAlign: "center" }}>
            {/* <img src={Login} style={{ width: "1%", alignItems: "center", height: "10px" }} /> */}

            <img
              src={LoginLogo}
              style={{
                height: 56,
                // width: 100,
              }}
            />
            <figcaption style={{marginLeft:"5px"}}>Powered by cYAAG</figcaption>
          </LoginPgLogo>
          <span
            style={{
              fontSize: 16,
              fontWeight: "600",
              // marginBottom: 15,
              marginTop: 120,
              display: "block",
              textAlign: "center",
            }}
          >
            Please enter the code as received in your email
          </span>
          <div className="joinOuterContainerEmail">
            <div className="emailOTP">
              {/* <h1 className="heading">Join</h1> */}
              <div >
                <input
                  defaultValue={otp}
                  type="number"
                  size="6"
                  maxLength="6"
                  id="create-yaad--title"
                  className="joinInput mt-20"
                  style={{
                    textAlign:"center", 
                  // fontSize:"18px", 
                  fontWeight:"500"
                }}
                  // placeholder="Code"
                  onChange={(e) => setOtpVal(e)}
                />
                
                <Button
              variant="contained"
              color="secondary"
              style={{marginTop:"10px", borderRadius: 30,
                fontSize: 14,
                textTransform: "uppercase",
                padding: 8,
                width: "60%",
                background: "#e1a23b",
                boxShadow: "none",
                fontWeight: "bold",
                marginTop: "40px",}}
              onClick={() => handleVerify()}
            >
              Verify
            </Button>

                <div
                  style={{
                    fontSize: "18px",
                    marginTop: "15px",
                    // display: "flex",
                    
                  }}
                >
                  {/* <Button style={{ color: "#c6c6c9" }}> */}
                  <Button style={{cursor:"default", color: "#c6c6c9"}}>
                    Didn't Receive OTP?{" "}
                  </Button>
                  <Button
                    style={{ color: "#e1a23b", paddingLeft: "3px" }}
                    onClick={sendEmailOTP}
                  >
                    Click Here
                  </Button>
                </div>

                {validation && validation === "VALIDATED" ? (
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "600",
                      // marginBottom: 15,
                      // marginTop: 5,
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    Your email has been successfully verified. Please
                    <a href="https://app.cyaag.com"> Click Here </a> and Sign in
                    with your phone number
                  </span>
                ) : validation === "NOT VALIDATED" ? (
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "600",
                      // marginBottom: 15,
                      // marginTop: 5,
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    Sorry, Your email verification has failed. Please ensure
                    that you are entering the code correctly.
                  </span>
                ) : validation === "EXCEPTION" ? (
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "600",
                      // marginBottom: 15,
                      // marginTop: 5,
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    Sorry, Your email verification has failed. Your organization
                    Administrator will get back to you on this. For any further
                    queries, please write to support@cyaag.com
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <CopyRight />
        <ConfirmationDialog
          showModal={displayConfirmationModal}
          // confirmModal={handleRedeem}
          hideModal={hideConfirmationModal}
          // message={"You do not have enough points to redeem"}
          message={"OTP has been resent. Please check your registered email"}
        />
      </section>
    </div>
  );
};

export default EmailVerification;
