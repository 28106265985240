import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Button, Grid, List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Box, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "./Header";
import TopHeaderScreen from "./TopHeader1";
import { CopyRight } from "./CopyRight";
import { FixedFooterIcon } from "./FixedFooterIcon";
import { useNavigate } from "react-router-dom";
import Chat from './Chat';
import { useWebSocket } from './WebSocketContext';
import ChatIcon from '@mui/icons-material/Chat';
import { keyframes } from '@mui/system';
import { CommentBankSharp } from '@mui/icons-material';

// Define the blinking animation
const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const SidebarButton = styled('div')(({ theme }) => ({
    backgroundColor: '#f1a540',
    color: 'white',
    padding: '10px',
    textAlign: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
        display: 'inline-block',
        width: '100%',
        marginBottom: '0',
    },
}));

function ContactList(props) {
    const navigate = useNavigate();
    const topRef = useRef(null);
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [showContactList, setShowContactList] = useState(false);

    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
        (state) => state.profile
    );


    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    console.log(`/user/profile/contactlist/get/${profileResponse?.uniqueuserid}`)
    useEffect(() => {
        // Replace with your API endpoints
        axios.get(`/user/profile/contactlist/get/${profileResponse?.uniqueuserid}`)
            .then(response => {
                setContacts(response.data);
            })
            .catch(error => {
                console.error('Error fetching contacts:', error);
            });
    }, []);


    const handleContactClick = (contact) => {
        setSelectedContact(contact);
        // handleChatOpen();
        setTargetUserId(contact.userid);
        setOpenChat(true);
        // setHasNewMessage((prev) => ({ ...prev, [contact.userid]: false }));
      };

    const handleRecentChatClick = () => {
        setShowContactList(true);
    };


    const [openChat, setOpenChat] = useState(false);
    const [targetUserId, setTargetUserId] = useState(null);

    // const [hasNewMessage, setHasNewMessage] = useState(false);
    // const { socket, messages, sendMessage } = useWebSocket();
    // console.log("Messages in Socket:", messages);

    // useEffect(() => {
    //     if (socket) {
    //         console.log("WebSocket connection status:", socket.readyState); // 1 = OPEN
    //     } else {
    //         console.log("socket = ", socket);
    //     }
    // }, [socket]);

    // useEffect(() => {
    //     if (messages.length > 0) {
    //       const newMessages = {};
    //       messages.forEach((msg) => {
    //         console.log("msg.targetUserId = ", msg.targetUserId);
    //         console.log("profileResponse?.uniqueuserid = ", profileResponse?.uniqueuserid);
    //         if (msg.targetUserId === profileResponse?.uniqueuserid) {
    //           newMessages[msg.sender] = true;
    //         }
    //       });
    //       setHasNewMessage((prev) => ({ ...prev, ...newMessages }));
    //     }
    //   }, [messages, profileResponse?.uniqueuserid]);

    
    console.log("selectedContact = ", selectedContact);
    // const handleSendMessage = (messageContent) => {
    //     if (socket && targetUserId) {
    //       const message = {
    //         sender: profileResponse?.uniqueuserid,
    //         targetUserId,
    //         content: messageContent,
    //       };
    //       sendMessage(message); // Custom WebSocket hook sends the message
    //     }
    //   };

    console.log("contacts - ", contacts);
    // console.log("messages - ", messages);

    return (
        <div className="wrapper" ref={topRef}>
            <ResponsiveDrawer {...props} />
            <section className="containers">
                <TopHeaderScreen {...props} />
                <div className="content content-wrap">
                    <div className="content-mrg createTeamPage">
                        <Button style={{ padding: "10px", color: "#1976d2" }} onClick={() => navigate(-1)}>
                            <i className="fa fa-caret-left" />
                            <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
                        </Button>
                        <div
                            className="content-title"
                            style={{
                                fontSize: 25,
                                fontWeight: "600",
                                marginBottom: 15,
                                marginTop: 5,
                            }}
                        >
                            Your Contacts
                        </div>
                        <Grid container spacing={2} sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
                            <Grid item xs={12} md={3}>
                                <SidebarButton onClick={handleRecentChatClick}>
                                    Recent chat
                                </SidebarButton>
                               
                            </Grid>
                            {/* Vertical separator for larger screens */}
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{
                                    display: { xs: 'none', md: 'block' },
                                    marginRight: 2,
                                    marginLeft: 2,
                                    borderColor: 'black'
                                }}
                            />
                            <Grid item xs={12} md={8}>
                                {showContactList && (
                                    <Box>
                                        {contacts.contactlist && contacts.contactlist.map(contact => {
                                            // const hasNewMessageForUser = messages.some(msg => msg.receiver === contact.userid);

                                            return (
                                                <List key={contact.id} >
                                                    <ListItem >
                                                        <ListItemAvatar>
                                                            <Avatar src={contact.profilephoto} alt={contact.firstname} />
                                                        </ListItemAvatar>
                                                        <ListItemText 
                                                            primary={contact.firstname} 
                                                            secondary={
                                                                <Box display="flex" flexDirection="column">
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        Your Contact CM
                                                                    </Typography>
                                                                    <ChatIcon 
                                                                        onClick={() => handleContactClick(contact)}
                                                                        sx={{
                                                                            fontSize: 20,
                                                                            cursor: 'pointer',
                                                                            // color: hasNewMessageForUser ? '#1976d2' : '#888',
                                                                            // marginTop: '4px',
                                                                            // animation: hasNewMessageForUser 
                                                                            //     ? `${blinkAnimation} 1s infinite` 
                                                                            //     : 'none',
                                                                        }} 
                                                                    />
                                                                </Box>
                                                            } 
                                                        />
                                                    </ListItem>
                                                    <Divider />
                                                </List>
                                            );
                                        })}
                                    </Box>
                                )}
                                {targetUserId && (
                                    <Chat
                                        openChat={openChat}
                                        setOpenChat={setOpenChat}
                                        sourceUserId={profileResponse?.uniqueuserid}
                                        targetUserId={targetUserId}
                                        // onSendMessage={handleSendMessage}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <CopyRight />
                <FixedFooterIcon />
            </section>
        </div>
    );
}

export default ContactList;
