import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useWebSocket } from './WebSocketContext';
import { useSelector } from "react-redux";
import axios from 'axios';

const Chat = ({ openChat, setOpenChat, sourceUserId, targetUserId }) => {
  const { messages, sendMessage , setChannelId} = useWebSocket();
  const [inputMessage, setInputMessage] = useState('');
  const [localMessages, setLocalMessages] = useState([]);
  const [messagesFromDB, setMessagesFromDB] = useState([]);

  const messagesEndRef = useRef(null);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  // Generate a unique channel ID for the pair of users (source and target)
  const generateChannelId = (user1, user2) => {
    const sortedIds = [user1, user2].sort(); // Sort to avoid reversal inconsistency
    return `${sortedIds[0]}-${sortedIds[1]}`;
  };

  const channelId = generateChannelId(sourceUserId, targetUserId);

  useEffect(() => {
    // Set the channelId when the chat opens
    setChannelId(generateChannelId(sourceUserId, targetUserId));

    return () => {
      // Clean up if necessary
    };
  }, [sourceUserId, targetUserId, setChannelId]);
  useEffect(() => {
    if (openChat) {
      setLocalMessages([]); // Clear local messages whenever chat is opened
    }
  }, [openChat]);

  console.log("Chat.js - messages = ", messages);
  console.log("messagesFromDB = ", messagesFromDB);
  console.log("localMessages = ", localMessages);
  console.log("openChat = ", openChat);
  console.log("sourceUserId = ", sourceUserId);
  console.log("targetUserId = ", targetUserId);
  console.log("channelId = ", channelId); // log the channelId

  // Fetch messages when chat opens
  useEffect(() => {
    if (openChat && sourceUserId && targetUserId) {
      console.log("fetching data from DB")
      const fetchMessages = async () => {
        try {
          const response = await axios.get(`/user/profile/chatmessage/get`, {
            params: { channelId } // Use the generated channelId for fetching messages
          });
          setMessagesFromDB(response.data || []);
        } catch (error) {
          console.error('Failed to fetch messages:', error);
        }
      };
      fetchMessages();
    }
  }, [openChat, sourceUserId, targetUserId, channelId]);



  useEffect(() => {
    const mergeMessages = () => {
      console.log("messagesFromDB:", messagesFromDB);
      console.log("messages (WebSocket):", messages);
  
      // Normalize messagesFromDB
      const normalizedMessagesFromDB = messagesFromDB.map((msg) => ({
        ...msg,
        channelId: msg.channelId || msg.message.channelId,
        message: {
          ...msg.message,
          created_at: msg.message.created_at || msg.message.timestamp, // Use created_at if present
        },
      }));
  
      // Normalize WebSocket messages
      const normalizedMessages = messages.map((msg) => ({
        ...msg,
        channelId: msg.channelId || msg.message.channelId,
      }));
  
      console.log("Normalized messagesFromDB:", normalizedMessagesFromDB);
      console.log("Normalized messages:", normalizedMessages);
  
      // Combine messages
      const combinedMessages = [...normalizedMessagesFromDB, ...normalizedMessages];
      console.log("Combined Messages:", combinedMessages);
  
      // Deduplicate
      const uniqueMessagesMap = new Map();
      combinedMessages.forEach((msg) => {
        const key = `${msg.message.sender}-${msg.message.receiver}-${msg.message.text}-${msg.message.timestamp}`;
        if (!uniqueMessagesMap.has(key)) {
          uniqueMessagesMap.set(key, msg);
        }
      });
  
      // Convert to sorted array
      const uniqueMessages = Array.from(uniqueMessagesMap.values());
      uniqueMessages.sort((a, b) => new Date(a.message.timestamp) - new Date(b.message.timestamp));
  
      // Set local messages
      setLocalMessages(uniqueMessages);
      
    };
  
    mergeMessages();
  }, [messages, messagesFromDB]);

  useEffect(() => {
    if (localMessages.length > 0) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [localMessages]);

  const handleSendMessage = async () => {
    try {
      if (inputMessage.trim()) {
        const newMessage = {
          status: "sent",
          message: {
            text: inputMessage,
            sender: sourceUserId,
            receiver: targetUserId,
            timestamp: new Date().toISOString(),
            notifyReceiver: true,
          },
          channelId,
        };

        
        setLocalMessages((prevMessages) => [...prevMessages, newMessage]);
        setInputMessage('');

        await axios.post('/user/profile/chatmessage/save', newMessage);
        sendMessage(newMessage);

        // Refetch messages from the database
        // const response = await axios.get(`/user/profile/chatmessage/get`, {
        //   params: { channelId }, // Use channelId for message fetching
        // });
        // setMessagesFromDB(response.data || []);
        }
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  
  
  return (
    <Dialog open={openChat} onClose={() => setOpenChat(false)}>
      <DialogTitle
        sx={{
          minWidth: 576,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {"Chat"}
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => setOpenChat(false)}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent style={{ minWidth: 576 }}>
        <Typography variant="body2">
          Status:
          <Box
            component="span"
            sx={{
              display: 'inline-block',
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: 'green',
              marginLeft: 1,
            }}
          />
        </Typography>

        <Box
          sx={{
            height: '200px',
            overflowY: 'auto',
            padding: '8px',
            backgroundColor: '#f0f0f0',
          }}
        >
          {localMessages && localMessages.map((msg, index) => {
            const { text, sender, receiver, status, timestamp } = msg.message;
            // console.log("text = ", text)
            // console.log("sender = ", sender)
            const isSentByUser = sender === profileResponse?.uniqueuserid;

            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: isSentByUser ? 'flex-end' : 'flex-start',
                  marginBottom: '8px',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    backgroundColor: isSentByUser ? '#d1e7dd' : '#f8d7da',
                    borderRadius: '8px',
                    padding: '8px',
                    maxWidth: '70%',
                  }}
                >
                  {text}
                </Typography>
                <Box
                  sx={{
                    marginLeft: '8px',
                    alignSelf: 'flex-end',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="caption">
                    {new Date(timestamp).toLocaleDateString()}
                  </Typography>
                  <Typography variant="caption">
                    {new Date(timestamp).toLocaleTimeString()}
                  </Typography>
                </Box>
              </Box>
            );
          })}
          <div ref={messagesEndRef} />
        </Box>

        <Box sx={{ marginTop: '16px', display: 'flex', alignItems: 'center' }}>
          <TextField
            variant="outlined"
            label="Type a message"
            fullWidth
            multiline
            rows={1}
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            sx={{
              marginRight: '8px',
              height: 'auto',
            }}
            inputProps={{
              style: {
                whiteSpace: 'normal',
                overflow: 'visible',
              },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            sx={{
              padding: '15px 16px',
              backgroundColor: 'rgb(235, 149, 50)',
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgb(215, 129, 30)',
              },
            }}
          >
            Send
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Chat;