import React from "react";
import _ from "lodash";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const CampImpactComponent = (props) => {
  const splitString = (val) => {
    if (val) {
      const vallArray = val.split("\n\n");
      return (
        <ul>
          {vallArray.map((bulletVal, index) => (
            <li>
              <p>{vallArray[index]}.</p>
            </li>
          ))}
        </ul>
      );
    }
  };

  return (
    <div class="containers">
      <div class="cyagg-sec-space">
        <div class="borderWrapper">
          <div
            class="tab-content customOwlNav row"
            id="animateLineContent-4"
            style={{
              display: "flex",
              flexDirection: "row",
              // alignItems: "center",
            }}
          >
            <div class="col-md-4 col-sm-12 col-xs-12">
              <div
                class="campimpact-wrap-marketplace"
                style={{ border: "solid" }}
              >
                <h6 style={{ fontSize: "20px", fontWeight: "500" }}>
                  Money Invested ({props?.rows?.[0].currencytype})
                </h6>
                <div style={{ fontSize: "30px", fontWeight: "800" }}>
                  <div>{props?.rows?.[0].moneyinvested}</div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12">
              <div
                class="campimpact-wrap-marketplace"
                style={{ border: "solid" }}
              >
                <h6 style={{ fontSize: "20px", fontWeight: "500" }}>
                  Time Invested (Hours)
                </h6>
                <div style={{ fontSize: "30px", fontWeight: "800" }}>
                  <div>{props?.rows?.[0].timeinvested}</div>
                </div>
              </div>
            </div>
            {!(props?.rows?.[0]?.unitofgoal === "") ?
              <div class="col-md-4 col-sm-12 col-xs-12">
                <div
                  class="campimpact-wrap-marketplace"
                  style={{ border: "solid" }}
                >
                  <h6 style={{ fontSize: "20px", fontWeight: "500" }}>
                    {props?.rows?.[0].unitofgoal}
                  </h6>
                  <div style={{ fontSize: "30px", fontWeight: "800" }}>
                    <div>{props?.rows?.[0].goalquantity}</div>
                  </div>
                </div>
              </div>
              : ""
            }
          </div>
        </div>
        {/* <div class="borderWrapper" style={{ marginTop: "30px" }}>
          <div class="col-md-12">
            <div
              class="tab-content customOwlNav"
              id="animateLineContent-4"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <h6 style={{ fontSize: "20px", fontWeight: "500" }}>
                  Impact Assessment
                </h6>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "justify",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "normal",
                      fontSize: "16px",
                      marginTop: "20px",
                    }}
                  >
                    {splitString(props?.rows?.[0].chatgpt1)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          !(props?.rows?.[0].chatgpt2 === "") &&
          <div class="borderWrapper" style={{ marginTop: "30px" }}>
            <div class="col-md-12">
              <div
                class="tab-content customOwlNav"
                id="animateLineContent-4"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <h6 style={{ fontSize: "20px", fontWeight: "500" }}>
                    {props?.rows?.[0].question2}
                  </h6>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      textAlign: "justify",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: "16px",
                        marginTop: "20px",
                      }}
                    >
                      {splitString(props?.rows?.[0].chatgpt2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        } */}
      </div>
    </div>
  );
};

export default CampImpactComponent;
