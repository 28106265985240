import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ResponsiveDrawer from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { CopyRight } from "../../components/CopyRight";
import TopHeaderScreen from "../../components/TopHeader1";
import { userProfile } from "../../redux/action/UserProfileActions";
import _ from "lodash";
import { Button } from "@mui/material";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import ShareIcon from '@mui/icons-material/Share';
import { Stack } from "@mui/material";
import { campLeaderBoard } from "../../redux/action/CampLeaderBoardAction";
import RewardMainImage from "../../assets/rew.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { isEmpty, isArray } from "lodash";
import { Chip } from "@mui/material";
import CampaignLeaderBoardIcon from "../../assets/CampaignLeaderBoardIcon.jpeg"
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import styled, { keyframes } from 'styled-components';
import RewardCard from "../../components/RewardCard";

const blinking = keyframes`
  0% { filter: brightness(0.9); }
  50% { filter: brightness(1.1); }
  100% { filter: brightness(1); }
`;

const BlinkingSVG = styled.svg`
  cursor: pointer;
  animation: ${blinking} 1s infinite;
`;

const SVGCampLeaderboard = (props) => (
  <BlinkingSVG width="40" height="40" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <rect width="40" height="40" fill="url(#pattern0_231_5)" />
    <defs>
      <pattern id="pattern0_231_5" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_231_5" transform="scale(0.02)" />
      </pattern>
      <image id="image0_231_5" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADPklEQVR4nO2Y20+SYRzHadnW/9PWVRsXvFt31tq8wHnAWWBBaFlgIIqkIWLiAhEUrAulPKQiMkhF8Ihazo43WRq1aalbuixMgV97XivFUHhBfNf2fLfPxgaM3+f5PacXBgMHBwcHBwcHh4yXIMReFmvDy2JBIkwQhH+cxbrKoCPAYBzzEsTmBz4flqTShJjLy0My67SIuJnMFDSaKzIZ+DWahFiSSFBnArSIPOOeOoFEPgqFCXfEJxAgkSAtIu5s5slE10YYBAH0iJQyU7rL2LDSWw0wakqIpZ4q6C5j09QRLGJKTkfS0tqOn5fYTqdKewkqXJB0ndXLr4GvWxN16nzruwebHkNyp9Y5qd2aKrFDvIxZ9PsW6Hfp4GWTCHoU6bBsUydXJFViD0pa5kHtWKXMQSI/3fWkANoQEEchAsWtn5IiMqkXkh2JJvK8UwmF+oIQx1w0SpUcs9iZaRafIUV42mkQNr6mTLSphZh7JI8q4u2ogCyTCG5ZVZQpaC0LccziRQav+D5cLm0OXZFbglThy5tDrzoOT8Q42UQZlUsL2SZRgHFYhxoWGd3pSFaccEwiiNiRrSEjzFpkMFEngAm9EN62yCAwZExaR7669DD2uCIu+pql0KDMCUUUmWkshCcqDkwbr4O3TkAWMmXIT5rIfmwM1oGv/TY5kL52BbkT7rt1RxL5bK2EwPBOB1BXrIp0CA43UC5msUsJY1o+rDqj3wBgD/1VuTCgzoWhWh75+/aKTFhz1sYuspcZ0w2wKthxiSTC2q6ifxcML0w34xNZ79eSIzGu4x+pBESoA9X65kERdZFVh4ZcK87KHPg+oDvy4rc8BvhiVZHrw13DA1f1RfAP6qmJLHTdAVt5BrhruPCDBglAu5lD8/euhmYFWvR7p/eBIss9anJxTdXnhy16utj0GGC+TUFeQp8aCmIX8Wi44FBmk88RaG7+gW6p4do86C3PiF1k9/V7N/GcBfHid+nC1iUaVDS90FYcswh6MxIHPeUdNsu27ek9ePcSeGq45GtnJSexc4QOQiPbMu8flsI7SwksdCohOPLvORYmgj6MvvQ/Mmsp2RH575Gz6fm7FQcHBwcHB4eRnPwCG4GUygmgFQIAAAAASUVORK5CYII=" />
    </defs>
  </BlinkingSVG>
);

export default function QRScreen(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [campaignList, setCampaignList] = useState(null);
  const [newCampaignList, setNewCampaignList] = useState(null);

  const registeredRef = React.useRef();

  const [campregisterlist, setCampRegisterList] = useState([]);
  const [myreferralurl, setmyreferralurl] = useState([]);
  const [share, setShare] = React.useState(false);
  const [marketplacelist, setmarketplacelist] = useState([]);
  const [SDGCampMapping, setSDGCampMapping] = React.useState([]);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const { isSuccess: rewardSuccess, response: rewardResponse } = useSelector(
    (state) => state.myReward
  );
  const { isSuccess: campLeaderBoardSuccess, response: campLeaderBoardResponse } =
    useSelector((state) => state.campLeaderBoard);

  const getSDGCampMapping = async () => {
    try {
      const res = await axios.get(`camp/sdg/mapping`);
      // setActivityList(res?.data);
      setSDGCampMapping(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getSDGCampMapping();
  }, []);


  const getCampaignDetails = async () => {
    try {
      const res = await axios.get(
        `vol_dashboard/camp/${location?.pathname.split("/")?.[2]}/${profileResponse?.orgrole
        }/${profileResponse?.organization}/${profileResponse?.phoneno}`
      );
      setCampaignList(res?.data);

      return res;
    } catch (err) {
      throw err;
    }
  };
  const getCampRegistrationList = async () => {
    try {
      const res = await axios.get(
        `camp/reg/status/${localStorage.getItem("userid")}/${location?.pathname.split("/")?.[2]
        }`
      );
      setCampRegisterList(res?.data);

      return res;
    } catch (err) {
      throw err;
    }
  };

  const getRewardsFromMarketplace = async () => {
    try {
      const res = await axios
        .get(`/marketplace/read/${profileResponse?.phoneno}`)
        .then((response) => {

          setmarketplacelist(response?.data);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getRewardsFromMarketplace();
  }, []);

  useEffect(() => {
    dispatch(userProfile(localStorage.getItem("userid")));
  }, []);

  useEffect(() => {

    getCampaignDetails();
    getCampRegistrationList();

    registeredRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [profileResponse]);

  const handleShare = async () => {
    // alert("Handle Share")
    try {
      // setDialogOpen(true);
      const res = await axios
        .get(
          `refrralurl/${location?.pathname.split("/")?.[2]}/${localStorage.getItem("userid")}`
        )
        .then((response) => {
          setmyreferralurl(response);

          return response;
        });
    } catch (err) {
      // setLoading(false);

      throw err;
    }
  };

  const getNewCampaignDetails = async () => {
    try {
      // console.log(profileResponse?.orgrole)
      await axios
        .get(
          `vol_dashboard/camp/${location?.pathname.split("/")?.[2]}/${profileResponse?.orgrole
          }/${profileResponse?.organization}/${profileResponse?.phoneno}`
        )
        .then((response) => {
          setNewCampaignList(response);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getNewCampaignDetails();
  }, []);
  useEffect(() => {
    // dispatch(campLeaderBoard(localStorage.getItem("userid"), locationPath?.pathname.split("/")?.[3]));
    dispatch(
      campLeaderBoard({
        phoneno: localStorage.getItem("userid"),
        campid: location?.pathname.split("/")?.[2],
      })
    );
    // dispatch(myRank(localStorage.getItem("userid")));
  }, [dispatch]);

  const options = {
    items: 3,
    margin: 10,
    loop: false,
    nav: false,
    autoplaySpeed: 900,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const handleHomeButtonClick = () => {
    navigate(`/home`);
  };

  const handleRegButtonClick = () => {
    navigate(`/campaignregisterstatus`);
  };


  return (
    <div className="wrapper" ref={registeredRef}>
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg" >
            <Button onClick={() => navigate(-1)} className="backWrapper">
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div style={{ boxShadow: "0px 2px 2px #0000001A", padding: 8, display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "center" }} >
              {location?.pathname.split("/")?.[3].startsWith("Already") ? (
                <span
                  style={{
                    fontSize: 20,
                    padding: "20px 0%",
                    textAlign: "center",
                    color: "red",
                  }}

                >
                  You have already registered for these dates - Thank You
                </span>
              ) : location?.pathname.split("/")?.[3].startsWith("Reached") ? (
                <span
                  style={{
                    fontSize: 20,
                    padding: "20px 0%",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  Please be informed that the campaign has reached its goal.
                  Thank you. Please explore other campaigns and participate and
                  win points/rewards.
                </span>
              ) : location?.pathname.split("/")?.[3].startsWith("Time") ? (
                <span
                  style={{
                    fontSize: 20,
                    padding: "20px 0%",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  Please be informed that the campaign has completed. Thank you.
                  Please explore other campaigns and participate and win
                  points/rewards.
                </span>
              ) : _.isEmpty(campaignList) ? (
                <span
                  style={{
                    fontSize: 20,
                    padding: "20px 3%",
                    textAlign: "center",
                  }}
                >
                  Thank you for joining!
                </span>
              ) : (
                <span style={{ fontSize: "32px", textAlign: "center" }}>
                  Thank you for joining the <u>{campaignList[0].name}</u>  campaign!
                </span>
              )}
            </div>
            {
              _.isEmpty(campaignList) ? (
                <div style={{ boxShadow: "0px 2px 2px #0000001A", padding: 8, display: "flex", justifyContent: "space-between", flexDirection: "column", marginTop: 20, alignItems: "center" }} >

                  <span
                    style={{ textAlign: "center" }}
                  >
                    You will soon win points for participating in this campaign.
                    You will win bonus points when the campaign goal is achieved!
                    Invite friends to help achieve the goal and win even more points!
                  </span>
                </div>
              ) :
                <div style={{ boxShadow: "0px 2px 2px #0000001A", padding: 8, display: "flex", justifyContent: "space-between", flexDirection: "column", marginTop: 20, alignItems: "center" }} >

                  <span
                    style={{ textAlign: "center" }}
                  >
                    You will soon win <u style={{ fontWeight: 1000 }}>{campaignList[0].buylvlpt1}</u> points for participating in this campaign.
                    You will win bonus points when the campaign goal is achieved!
                    Invite friends to help achieve the goal and win even more points!
                  </span>
                </div>

            }

            <div class="row" style={{ justifyContent: "center", marginTop: "15px", marginBottom:'10px' }}>
              <div class="col-md-4">
                <div class="cyagg-sec-space" style={{ marginTop: "15px" }}>
                  {!isEmpty(campaignList) &&
                    <div class="container">
                      <div class="row customShareMobile">
                        <div class="text-center mob-space">
                          <h4 class="site-title" style={{ textAlign: "center", fontFamily: "'Poppins', 'sans-serif'" }}>Invite Friends, Increase Impact, Earn More Points</h4>
                          <div class="navigationS">
                            <div
                              class="menuToggleS"
                              onClick={handleShare}
                            >
                              <ShareIcon style={{ color: "orange" }} />
                            </div>
                            <div class="menuS">
                              <ul>
                                <WhatsappShareButton

                                  // url={
                                  //   "Hello!" +
                                  //   "\n" +
                                  //   "\n" +
                                  //   campaignList[0]?.socialmediamsg +
                                  //   "\n" +
                                  //   "\n" +
                                  //   "Please register by clicking on the link below. Both you and I will also get points, rewards, and recognition on the cYAAG Sustainable Development Platform." +
                                  //   "\n" +
                                  //   myreferralurl?.data?.url
                                  // }
                                  url={
                                    "Hello! I am personally recommending this social impact opportunity for you " + myreferralurl?.data?.url +
                                    "\n" +
                                    "\n" +
                                    campaignList[0]?.socialmediamsg +
                                    "\n" +
                                    "\n" +
                                    "Please register by clicking on the link below. Both you and I will also get points, rewards, and recognition on the cYAAG Sustainable Development Platform." +
                                    "\n" +
                                    myreferralurl?.data?.url
                                  }
                                >

                                  <WhatsappIcon
                                    size={32}
                                    round={true}
                                  ></WhatsappIcon>
                                </WhatsappShareButton>

                                <FacebookShareButton

                                  url={
                                    "Hello! I am personally recommending this social impact opportunity for you " + myreferralurl?.data?.url +
                                    "\n" +
                                    "\n" +
                                    campaignList[0]?.socialmediamsg +
                                    "\n" +
                                    "\n" +
                                    "Please register by clicking on the link below. Both you and I will also get points, rewards, and recognition on the cYAAG Sustainable Development Platform." +
                                    "\n" +
                                    myreferralurl?.data?.url
                                  }
                                  hashtag="#cyaag"
                                >
                                  <FacebookIcon
                                    size={32}
                                    round={true}
                                  ></FacebookIcon>
                                </FacebookShareButton>

                                <EmailShareButton
                                  url=""


                                  body={
                                    "Hello! I am personally recommending this social impact opportunity for you " + myreferralurl?.data?.url +
                                    "\n" +
                                    "\n" +
                                    campaignList[0]?.socialmediamsg +
                                    "\n" +
                                    "\n" +
                                    "Please register by clicking on the link below. Both you and I will also get points, rewards, and recognition on the cYAAG Sustainable Development Platform." +
                                    "\n" +
                                    myreferralurl?.data?.url
                                  }
                                  subject="Invitation to cYAAG"
                                >
                                  <EmailIcon size={32} round={true} />
                                </EmailShareButton>

                                <TwitterShareButton

                                  url={
                                    "Hello! I am personally recommending this social impact opportunity for you " + myreferralurl?.data?.url +

                                    "\n" +
                                    "\n" +
                                    "Please register by clicking on the link above. Both you and I will also get points, rewards, and recognition on the cYAAG Sustainable Development Platform."

                                  }
                                >
                                  <TwitterIcon size={32} round={true}></TwitterIcon>
                                </TwitterShareButton>
                              </ul>
                            </div>
                          </div>
                          {/* <p>You win more points when your friends register, even more when they invite others, and when the campaign goal is achieved! We are providing you an invite message that you can edit before sending.</p> */}
                          <p>You win more points when your friends register, even more when they invite others, and when the campaign goal is achieved! We are providing you an invite message that you can edit before sending.</p>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>

              <div class="col-md-4 customMobieView">
                <div class="cyagg-sec-space reg-camp-sec">
                  <div class="container">
                    <div>

                      <h4 class="site-title" style={{ textAlign: "center", fontFamily: "'Poppins', 'sans-serif'" }}>Campaign Progress</h4>
                      <div class="campaigns-wrap campaigns-wrap-new">
                        <div>
                          <div>

                            <h5 title={campaignList?.[0]?.name} class="campaign-title-new" style={{textAlign:'center'}}>
                              {campaignList?.[0]?.name}
                            </h5>

                            <div class="campaign-hashtags">
                              {campaignList?.[0]?.tag.map((tag) => (
                                <a class="hash-styles">{tag.tag}</a>
                              ))}
                            </div>
                          </div>
                        </div>


                        <div>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={0.5}
                          >
                            <h5 class="campaign-goal">
                              <a
                                class="hash-styles-goal"
                                style={{
                                  padding:
                                    "0px 5px 0px 0px",
                                  cursor: "initial",
                                }}
                              >
                                <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{campaignList?.[0]?.goalquantity}{" "} {campaignList?.[0]?.unitofgoal}</span>
                              </a>
                            </h5>
                          </Stack>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <div>
                              <div class="campaigns-details">
                                <div class="row">
                                  <div class="col-md-5 col-5">
                                    <div class="cam-cols">
                                      <div class="timer-here">
                                        <div class="text-center">
                                          <span class="campaign-time-title">
                                            Earn upto
                                          </span>
                                        </div>
                                        <div>
                                          <ul id="countdown" style={{ listStyle: "none" }}>
                                            <li id="hours">
                                              <div class="numberhome"></div>
                                              <div class="label"></div>
                                            </li>
                                            <li id="minutes">
                                              <div class="numberhome">
                                                {
                                                  campaignList?.[0]
                                                    ?.maxpointstobeearned
                                                }
                                              </div>
                                              <div class="label">Points</div>
                                            </li>
                                            <li id="seconds">
                                              <div class="numberhome"></div>
                                              <div class="label"></div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-md-6 col-6">
                                    <div class="cam-cols">
                                      <div class="timer-here">
                                        <div class="text-center">
                                          <span class="campaign-time-title">
                                            Time Left
                                          </span>
                                        </div>
                                        <div>
                                          <ul id="countdown" style={{ listStyle: "none" }}>
                                            <li id="hours">
                                              <div class="number">
                                                {
                                                  campaignList?.[0]
                                                    ?.dayrem
                                                }
                                              </div>
                                              <div class="label">Days</div>
                                            </li>
                                            <li id="minutes">
                                              <div class="number">
                                                {
                                                  campaignList?.[0]
                                                    ?.hourrem
                                                }
                                              </div>
                                              <div class="label">Hrs</div>
                                            </li>
                                            <li id="seconds">
                                              <div class="number">
                                                {
                                                  campaignList?.[0]
                                                    ?.minrem
                                                }
                                              </div>
                                              <div class="label">Min</div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="p-bar-new">
                                  <div class="row ">
                                    <div class="col-6">
                                      <div>
                                        <h6>
                                          <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                                            {Math.round(
                                              (campaignList?.[0]?.committedcount)
                                            )}
                                          </span>
                                        </h6>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div
                                        style={{
                                          textAlign:
                                            "right",
                                        }}
                                      >
                                        <h6>
                                          <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                          {" "}
                                          <span style={{ backgroundColor: "#A9D18E" }}> {campaignList?.[0]?.achievedcount}</span>
                                          {" "}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width: `${(campaignList?.[0]?.committedcount / campaignList?.[0]?.goalquantity) * 100}%`,
                                        backgroundColor: "#FFDB58",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        zIndex: 1,
                                        height: "100%"
                                      }}
                                      aria-valuenow={Math.round((campaignList?.[0]?.committedcount / campaignList?.[0]?.goalquantity) * 100)}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>

                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width: `${(campaignList?.[0]?.achievedcount / campaignList?.[0]?.goalquantity) * 100}%`,
                                        backgroundColor: "#A9D18E",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        zIndex: 2,
                                        height: "100%"
                                      }}
                                      aria-valuenow={Math.round((campaignList?.[0]?.achievedcount / campaignList?.[0]?.goalquantity) * 100)}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                  <div style={{ flex: 1 }}>
                                    <h6>
                                      <span style={{ color: "#AAAAAA" }}>Signed Up:</span>{" "}
                                      <span style={{ backgroundColor: "#DAE8FD" }}>{campaignList?.[0]?.submittedcount}</span>
                                      <SVGCampLeaderboard style={{ marginBottom: '-15px', marginLeft: '2px' }} onClick={() => navigate(`/camp/leaderboard/${campaignList?.[0]?.campid}`)} />
                                    </h6>
                                  </div>
                                  <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                    <Chip
                                      key={campaignList?.[0]?.campaigntype}
                                      fontSize="small"
                                      size="small"
                                      label={campaignList?.[0]?.campaigntype}
                                    />
                                    {/* <img
                                      src={CampaignLeaderBoardIcon}
                                      style={{ width: 25, height: 25, cursor: "pointer", }}
                                      onClick={() =>
                                        navigate(
                                          `/camp/leaderboard/${campaignList?.[0]?.campid}`
                                        )
                                      }
                                    /> */}
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            

            <div class="container">
              <div class="cyagg-sec-space">
                <div>
                  <div class="row">
                    <div class="col-7">
                      <div>
                        <h4 class="site-title">Win Rewards!</h4>
                      </div>
                    </div>
                    <div class="col-5">
                      <div
                        style={{ textAlign: "right" }}
                        onClick={() => navigate(`/marketplace`)}
                      >
                        <a class="story-signin" style={{ padding: "6px 8px" }}>
                          View More <i class="fa fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div>
                      <img
                        class="hide-on-mobile"
                        src={RewardMainImage}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div class="col-md-8">
                    {
                      !isEmpty(marketplacelist) && marketplacelist &&
                      <RewardCard
                        page="postregistration"
                        marketplacelist={marketplacelist}
                      />
                    }



                    {/* <div class="">
                      <OwlCarousel className="owl-theme" {...options}>
                        {marketplacelist &&
                          marketplacelist?.data?.map(
                            (reward, index) =>
                              index < 5 && (
                                <div class="item">
                                  <div class="reward-wrap">
                                    <div>
                                      <img
                                        class="reward-image"
                                        src={reward?.logo}
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                    <div class="reward-details">
                                      <div>
                                        <div>
                                          <div id="toggleAccordion">
                                            <div class="card mb-1">
                                              <div
                                                class="card-header"
                                                id="headingOne4"
                                              >
                                                <h5 class="mb-0 mt-0 reward-desc-title">
                                                  <span
                                                    role="menu"
                                                    class=""
                                                    data-toggle="collapse"
                                                    data-target={"#" + index}
                                                    aria-expanded="true"
                                                    aria-controls="iconChangeAccordionOne"
                                                  >
                                                    {reward?.rewardtype}
                                                    <i class="flaticon-down-arrow float-right"></i>
                                                  </span>
                                                </h5>
                                              </div>

                                              <div
                                                id={index}
                                                class="collapse"
                                                aria-labelledby="headingOne4"
                                                data-parent="#toggleAccordion"
                                              >
                                                <div class="card-body">
                                                  <p class="mb-3">
                                                    <div>
                                                      <span
                                                        style={{
                                                          fontSize: 12,
                                                          color: "blue",
                                                        }}
                                                      >
                                                        {" "}
                                                        {reward?.subtitle}{" "}
                                                      </span>
                                                    </div>
                                                  </p>
                                                  <p class="mb-3">
                                                    <div>
                                                      <span
                                                        style={{
                                                          fontSize: 12,
                                                          color: "green",
                                                        }}
                                                      >
                                                        {" "}
                                                        {reward?.title}{" "}
                                                      </span>
                                                    </div>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="redeem-pts">
                                          <div class="row">
                                          </div>
                                        </div>
                                        <div class="text-center">
                                          <h6 class="sponsor-title">
                                            Sponsors
                                          </h6>
                                          <div class="row sponserWrapper">
                                            <div class="">
                                              {isArray(reward?.sponsor) &&
                                                reward?.sponsor?.map(
                                                  (reward, index) => (
                                                    <img
                                                      style={{
                                                        width: "auto",
                                                        height: "50px",
                                                      }}
                                                      src={reward?.sponsorlogo}
                                                    />
                                                  )
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                      </OwlCarousel>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div class="row" style={{ justifyContent: "center", marginTop: "15px" }}>
              <div class="col-md-5" style={{ marginTop: "20px" }}>
                <div class="cyagg-sec-space reg-camp-sec" style={{ border: "2px solid #000", padding: "10px", borderRadius: "15px", borderColor: "lightgray" }}>
                  <div class="container">
                    <div style={{ textAlign: "center" }}>
                      <Button
                        onClick={handleHomeButtonClick}
                        disableElevation
                        style={{ marginBottom: 10, marignTop: 5 }}
                      >
                        <span
                          style={{ cursor: "pointer", display: "block", lineHeight: "normal" }}
                        >
                          <a class="story-post-reg">
                            <i title="Home" class="flaticon-home-fill-x customAlign"></i>
                            {" "} Home
                          </a>
                        </span>
                      </Button>
                      <div>
                        <span style={{ display: "block", lineHeight: "normal" }}>See many more opportunities for making social impact, winning rewards and recognition!</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-5" style={{ marginTop: "20px", marginBottom: "72px" }}>
                <div class="cyagg-sec-space reg-camp-sec" style={{ border: "2px solid #000", padding: "10px", borderRadius: "15px", borderColor: "lightgray" }}>
                  <div class="container">
                    <div style={{ textAlign: "center" }}>
                      <Button
                        onClick={handleRegButtonClick}
                        style={{ marginBottom: 10, marignTop: 5 }}
                      >
                        <span style={{ cursor: "pointer", display: "block", lineHeight: "normal" }}>
                          <a class="story-post-reg">
                            <i title="Reg" class="flaticon-notes-1 customAlign"></i>
                            {" "} Registration Status
                          </a>
                        </span>
                      </Button>
                      <div>
                        <span style={{ display: "block", lineHeight: "normal" }}>Please check your current and previous campaign participation status.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
