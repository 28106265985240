import React, {useState} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled, keyframes } from '@mui/system';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AIChatAskAnything from "./AIChatAskAnything";
import { FaFire, FaFireAlt } from 'react-icons/fa';

export const FixedFooterIcon = (props) => {
    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
      (state) => state.profile
    );
    const navigate = useNavigate();
  const [showTeamMenu, setShowTeamMenu] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [showChat, setShowChat] = useState(false);

  const handleTeamClick = () => {
    if (showTeamMenu) {
        setShowTeamMenu(false); // Trigger exit animation
        setTimeout(() => setMenuVisible(false), 300); // Delay removing from DOM to allow animation to complete
    } else {
        setMenuVisible(true);
        setTimeout(() => setShowTeamMenu(true), 10); // Slight delay to ensure the menu is in the DOM before starting animation
    }
};

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`;

const AnimatedIcon = styled(FaFire)({
  color: '#FFA500',
  animation: `${pulse} 2s infinite ease-in-out`,
  fontSize: '24px',
  // marginTop: '20px'
});

const handleParentFunction = () => {
  setShowChat(false);
  handleChatClick();
}; 

const handleChatClick = () => {
 setShowChat(!showChat);
};

  const handleMenuOptionClick = (route) => {
    navigate(route);
    setShowTeamMenu(false); // Hide menu after clicking an option
  };
    return (
      <div className="bottomMenuModal">
      {menuVisible && (
        <>
        <div className="teamoverlay" onClick={handleTeamClick}></div>
        <div className="team-menu popUpWrapper">
          <div onClick={() => handleMenuOptionClick('/create/team')}>
            <i class="fa fa-plus"></i>
            <h6 >Create Teams</h6>
          </div>
          <div onClick={() => handleMenuOptionClick('/my/team')}>
            <i class="fa fa-users"></i>
            <h6>Your Teams</h6>
          </div>
          <div onClick={() => handleMenuOptionClick('/join/team')}>
            <i className="fa fa-thin fa-user-plus"></i>
            <h6>Join Teams</h6>
          </div>
        </div>
        </>
      )}
      <div class="fixed-bottom-sec-mob text-center">
      <div class="row">
          <div class="col-3">
              <div onClick={() => navigate('/home')}>
                  <i class="flaticon-home-line"></i>
                  <h6>Home</h6>
              </div>
          </div>
          <div class="col-3">
                <div onClick={() => navigate('/searchpage')}>
                  {/* <div class="disabledIcon"> */}
                    <i class="flaticon-search"></i>
                    <h6>Search</h6>
                </div>
                  
          </div>
          <div class="col-3">
              <div class="">
                  <div className="askIconWrapper">
                  <AnimatedIcon style={{ fontSize: '21px' }} onClick={handleChatClick}/>
                  </div>
                  {showChat && <AIChatAskAnything onParentFunction={handleParentFunction} /> }
                  <h6>Ask Agni</h6>
              </div>
          </div>
          <div class="col-3">
          <div onClick={handleTeamClick} className="clickable-icon">
                  <i class="flaticon-users"></i>
                  <h6>Team</h6>
              </div>
          </div>
      </div>
  </div>
  </div>
    );
  };