import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Avatar, Box, Typography, Snackbar, TextareaAutosize } from '@mui/material';
import { ListItem, Button, useMediaQuery, TextField } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import TopHeaderScreen from "../../components/TopHeader1";
import RedStar from "../../components/RedStar";
import styled from 'styled-components';

const HomeScreenMainContent = styled(Box)(({ theme }) => ({
  padding: '80px 0 100px 0px',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '80px 0 100px 330px',
  },
}));

const FormGroup0 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#F0F8FF',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// Styled component for formGroup00
const FormGroup00 = styled(Box)(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: 40,
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0,0.0,0.2,1)',
  backgroundColor: '#FAEBD7',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

const FormGroup = styled('div')(({ theme }) => ({
  '& .react-datepicker__input-container': {
    '& input': {
      height: '40px',
    },
  },
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));


const FormGroupFull = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0 16px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup1
const FormGroup1 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '25%',
  '@media (max-width: 1200px)': {
    width: '25%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup2
const FormGroup2 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  flexDirection: 'column',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '75px 0 0 16px',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup3
const FormGroup3 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px 0px 16px',
  pageBreakInside: 'avoid',
  width: '20%',
  '@media (max-width: 1200px)': {
    width: '20%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup4
const FormGroup4 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'right',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 0',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup7
const FormGroup7 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '25%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// FormGroup8
const FormGroup8 = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  textAlign: 'left',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '24px 0 0 20px',
  pageBreakInside: 'avoid',
  width: '100%',
  '@media (max-width: 1200px)': {
    width: '100%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledLabel
const StyledLabel = styled('label')(({ theme }) => ({
  display: 'block',
  background: '#e9e7e7',
  padding: '10px',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: 600,
  marginTop: '16px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

// StyledSpan
const StyledSpan = styled('span')(() => ({
  fontSize: '12px',
}));

// FocusThematic
const FocusThematic = styled('div')(({ theme }) => ({
  transition: 'background-color 200ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  marginBottom: '4px',
  padding: '0 16px',
  pageBreakInside: 'avoid',
  width: '50%',
  '@media (max-width: 1200px)': {
    width: '50%',
  },
  '@media (max-width: 992px)': {
    width: '50%',
  },
  '@media (max-width: 768px)': {
    width: '50%',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
  '@media (max-width: 400px)': {
    width: '100%',
  },
}));

// StyledInput
const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// StyledTextarea
const StyledTextarea = styled('textarea')(() => ({
  width: '100%',
  border: 'none',
  padding: '10px',
  display: 'block',
  borderRadius: '6px',
  height: '54px',
  boxShadow: '0px 3px 6px #00000029',
  color: 'black',
  '@media (max-width: 600px)': {
    fontSize: '15px',
  },
}));

// LogoButton
const LogoButton = styled('input')(() => ({
  width: '8%',
  border: 'none',
  padding: '10px',
  display: 'flex',
  height: '40px',
  color: 'white',
  '@media (max-width: 1920px)': {
    fontSize: '15px',
    width: '12%',
  },
  '@media (max-width: 1280px)': {
    fontSize: '15px',
    width: '15%',
  },
  '@media (max-width: 960px)': {
    fontSize: '15px',
    width: '20%',
  },
  '@media (max-width: 600px)': {
    fontSize: '15px',
    width: '30%',
  },
}));

// StyledTypography
const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .css-u4tvz2-MuiFormLabel-root': {
    fontFamily: 'Poppins',
  },
  display: 'block',
  display: 'flex',
  fontSize: '16px',
  marginTop: '16px',
  fontWeight: 500,
  lineHeight: '18px',
  '@media (max-width: 600px)': {
    fontSize: '14px',
  },
}));

const LoginPgLogo = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  background: '#f6f6f6',

  '@media (max-width: 768px)': { // 960px and up
    width: '100%',
    background: '#f6f6f6',
  },
}));

const StyledLargeAvatar = styled(Avatar)(({ theme }) => ({
  width: '200px !important',
  height: '200px !important',
  margin: '0 auto',
  marginTop: '5px',
  marginBottom: '5px',
  '@media (max-width: 400px)': {
    width: '120px !important',
    height: '120px !important',
  },
  '@media (max-width: 600px)': {
    width: '140px !important',
    height: '140px !important',
  },
  '@media (max-width: 768px)': {
    width: '150px !important',
    height: '150px !important',
  },
}));

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  padding: '16px',
  '@media (min-width: 600px)': {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});


const Item = styled(Box)({
  flex: '1 1 48%',
  '@media (max-width: 600px)': {
    flex: '1 1 100%',
  },
});


const EditProfileExpertise = (props) => {
  const [errors, setErrors] = useState({ expertise: '' });
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    expertise: ''
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [editProfileExpertiseResponse, setEditProfileExpertiseResponse] = useState('');
  const [componentKey, setComponentKey] = useState(Date.now());

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const maxCharacters = 200;

  useEffect(() => {
    if (profileResponse) {
      setFormData({
        ...formData,
        expertise: profileResponse.expertise || '',
      });
    }
  }, [profileResponse]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleSubmit = async () => {
    const newErrors = {};
    if (!formData.expertise) newErrors.about = 'Expertise is required';
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const data = new FormData();
    data.append('expertise', formData.expertise);
    data.append('phoneno', profileResponse?.phoneno)
    try {
      const response = await axios.put(`/user/profile/expertise/edit/${profileResponse?.phoneno}`, data);
      setEditProfileExpertiseResponse(response.data);
      setSnackbarOpen(true);
      // setTimeout(() => {
      //     setSnackbarOpen(false);
      // }, 6000);

      setComponentKey(Date.now());
    } catch (error) {
      console.error('There was an error editing the team!', error);
    }
  };
  const handleInputChangeWithErrorReset = (e) => {
    const { name, value } = e.target;

    // Reset error message if the field is not empty
    if (value.trim() !== '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: '',
      }));
    }

    // Set error message if the field is empty
    if (value.trim() === '') {
      let capitalizedField;
      if (name === 'expertise') {
        capitalizedField = 'Your Expertise';
      } else {
        capitalizedField = name.charAt(0).toUpperCase() + name.slice(1);
      }
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: `${capitalizedField} is required`,
      }));
    }

    // Trim the value to 200 characters if it exceeds the limit for 'about' field
    if (name === 'expertise' && value.length > 200) {
      setFormData({
        ...formData,
        [name]: value.slice(0, 200),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div className="content content-wrap">
          <div className="content-mrg createTeamPage">
            <Button style={{ padding: "6px 8px", color: "#1976d2" }} onClick={() => navigate(-1)} >
              <i className={`fa fa-caret-left`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div style={{ fontFamily: "monospace", fontSize: 16 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: 20,
                }}
              >
                <marquee behavior="scroll" direction="left" style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', color: 'red' }}>
                  Please do not leave this page until you submit as you may risk
                  losing the partially filled in form{" "}
                </marquee>
              </div>
            </div>
            <div
              className="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Edit Expertise <span style={{ color: "#007BFF" }}>{formData.name}</span>
            </div>
            <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4, border: "1px solid #dadce0", borderRadius: 4, }}>
              <Container>
                <Item>
                  <StyledTypography variant="subtitle1" gutterBottom>
                    Expertise <RedStar /><span style={{ color: 'gray', marginLeft: "2px" }}> (max 200 characters)</span>
                  </StyledTypography>
                  <TextareaAutosize
                    minRows={3}
                    placeholder="Your Expertise"
                    style={{
                      width: '100%',
                      marginLeft: "2px",
                      borderColor: errors.expertise ? 'red' : 'lightgray',
                      borderWidth: errors.expertise ? '2px' : '1px',
                      padding: '8px',
                      borderRadius: '4px',
                    }}
                    name="expertise"
                    value={formData.expertise}
                    onChange={handleInputChangeWithErrorReset}
                  />
                  {errors.expertise && <Typography variant="body2" style={{ color: '#FF0000', marginTop: '-6px' }}>{errors.expertise}</Typography>}
                  
                  <Typography variant="body2" style={{ color: formData.expertise.length > maxCharacters ? 'red' : 'gray' }}>
                    {maxCharacters - formData.expertise.length} characters remaining
                  </Typography>
                
                </Item>
              </Container>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="btn save-btn"
                    disabled = {!formData.expertise}
                    onClick={handleSubmit}
                  >
                    <span>Save</span>
                  </Button>
                  <Button
                    onClick={() => navigate(`/newprofile/${profileResponse?.phoneno}`)}
                    className="btn cancel-btn"
                    variant="contained"
                    color="secondary"
                    style={{
                      width: '135px',
                      background: '#e9ecef',
                      height: '53px',
                    }}
                  >
                    <span>Cancel</span>
                  </Button>
                </Box>
              </Box>
            </Box>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
      <Snackbar
        open={snackbarOpen}
        // autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        // sx={{
        //   '& .MuiSnackbarContent-root': {
        //     display: 'flex',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     position: 'fixed',
        //     bottom: isMobile ? '10px' : '16px',
        //     right: isMobile ? '10px' : '16px',
        //     left: isMobile ? '10px' : '20px',
        //     transform: isMobile ? 'none' : 'translateX(0)',
        //     width: isMobile ? 'calc(100% - 20px)' : 'auto',
        //   }
        // }}
      >
        <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={editProfileExpertiseResponse?.status === "success" ? "success" : "warning"}
            sx={{
                // width: '100%',
                width: isMobile ? 'calc(100% - 20px)' : 'auto',
                backgroundColor: editProfileExpertiseResponse?.status === "success" ? '#28A745 !important' : '#B9544B !important',
                color: 'white !important',
                boxShadow: 'none',
                zIndex: 9999,
            }}
        >
                        {editProfileExpertiseResponse?.message}
                    </Alert>

        
      </Snackbar>
    </div>
  );
};

export default EditProfileExpertise;