import React from 'react';
import axios from 'axios';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom';

const ShowBroadcastMessage = ({ broadcastmsg, style, onClose, uniqueuserid }) => {
    console.log("bc = ", broadcastmsg)
    console.log("bc msg = ", broadcastmsg?.[0]?.msg);
    console.log("uniqueuserid= ", uniqueuserid);
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = React.useState(localStorage.getItem('myActiveMenu') === "" ? 'Campaigns' : localStorage.getItem('myActiveMenu'));
    const [subMenuOpen, setSubMenuOpen] = React.useState(localStorage.getItem('mySubMenu') === "" ? 'Home' : localStorage.getItem('mySubMenu'));

    const activeSingleMenu = (singleMenuName, itemPath) => {
        navigate(itemPath);
        if (localStorage.getItem('myActiveMenu') !== "") {
          localStorage.removeItem('myActiveMenu');
        }
        if (localStorage.getItem('mySubMenu') !== "") {
          localStorage.removeItem('mySubMenu');
        }
        localStorage.setItem('mySubMenu', singleMenuName);
        setMenuOpen("");
        setSubMenuOpen(singleMenuName);
      }

    const handleClose = async () => {
        try {
            if (!uniqueuserid) {
                console.error("uniqueuserid is undefined");
                return;
            }
            
            const response = await axios.put(`/user/profile/bc/update/${uniqueuserid}`);
            console.log("API response:", response.data);
            if (onClose) {
                onClose();
            }
        } catch (error) {
            console.error("Error while calling API:", error);
        }
    };

    return (
        <div style={{ ...style, position: 'absolute',left: 'auto',right:'auto',width:'auto', display: 'flex', flexDirection: 'column', gap: '10px', overflow: 'visible', top:'70px',background:'#fff',maxWidth:'280px',width:'280px' }}>
            <div style={{ border:'1px solid #000',padding:'5px'}}>
                <div style={{ textAlign:"right"}}><h3 class="blink_text" style={{ float:"left",fontSize:"21px"}}>Broadcast</h3>
                <div style={{float: 'left' }} onClick={() =>
                      activeSingleMenu("Announcement", `/announcement`)
                    }>
                    <OpenInNewIcon className="broadcastLink"
                        style={{
                        width: '17px', 
                        height: '26px', 
                        marginLeft: '3px', 
                        display: 'inline-block',
                        cursor:'pointer'
                        }} 
                    />
                </div>
                <button
                    onClick={handleClose}
                    style={{
                        position: 'relative', 
                        // top: '10px',
                        right: '1px',
                        // backgroundColor: 'rgba(255, 255, 255, 0.7)'
                        border: 'none', 
                        background:'#fff',
                        borderRadius: '5px',
                        color: 'black',
                        fontSize: '20px',
                        cursor: 'pointer',
                        zIndex: 9999,
                        padding: '2px',
                        top:'-3px',
                    }}
                    aria-label="Close"
                >
                    &times;
                </button>
                </div>
                <div class="broadMessWrapper" style={{ overflowY: 'auto',float: 'left',maxHeight: '60vh'}}>
                {broadcastmsg.map((msg, index) => (
                    <div
                        key={msg.bcid}
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            color: 'white',
                            padding: '10px',
                            borderRadius: '5px',
                            marginTop:'1px',
                            // position: 'absolute',
                            // top: `${index * 10}px`, 
                            // left: `${index * 10}px`,
                            zIndex: 1000 - index,
                            fontSize: '12px',
                        }}
                    >
                        {msg.msg}
                    </div>
                ))}
                </div>
            </div>
        </div>
    );
};

export default ShowBroadcastMessage;
