import { ThemeProvider } from '@mui/material/styles';
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { appTheme } from "./appTheme";
import "./App.css";
import store from "./redux/createStore";

import LoginScreen from "./routes/LoginScreen";
import Otp from "./routes/otp";
import OtpPhoneChange from "./routes/OtpPhoneChange";
import HAScreen from "./routes/HAScreen/HAScreen";
import CreateOrganization from "./routes/Organization/CreateScreen";
import EditOrganizationScreen from "./routes/Organization/EditOrganizationScreen";
import CreateSponsor from "./routes/Sponsor/CreateSponsor";
import EditSponsorScreen from "./routes/Sponsor/EditSponsorScreen"
// import OnBoardVolunteer from "./routes/OnboardVolunteer/CreateScreen old";
import OnBoardVolunteer from "./routes/OnboardVolunteer/CreateScreen";
import CreateCampaign from "./routes/Campaigns/CreateScreen";
import CreateScreenMenu from "./routes/Campaigns/CreateScreenMenu";
// import CreateCampaign2 from "./routes/Campaigns/CreateScreen2";
// import CreateCampaign3 from "./routes/Campaigns/CreateScreen3";
import CreateActivity from "./routes/Activity/CreateScreen";
import VolunteerListScreen from "./routes/OnboardVolunteer/ListScreen";
import VolunteerManageScreen from "./routes/OnboardVolunteer/ManageScreen";
import VolunteerEditScreen from "./routes/OnboardVolunteer/VolunteerEditScreen";
import VolunteerDeleteScreen from "./routes/OnboardVolunteer/VolunteerDeleteScreen";
import ActivityListScreen from "./routes/Activity/ListScreen";
import OrganizationListScreen from "./routes/Organization/ListScreen";
import SponsorListScreen from "./routes/Sponsor/SponsorListScreen";
import { useState } from "react";
import ProfileScreen from "./routes/ProfileScreen";
import SocialProfile from "./routes/ProfileScreen/SocialProfile";
// import CampaignDetailScreen from "./routes/CampaignDetails/index";
import ReviewListScreen from "./routes/Campaigns/ReviewScreen";
import ReviewCampaignRegistrationScreen from "./routes/Campaigns/ReviewCampaignRegistrationScreen";
import ReviewCampaignParticipationScreen from "./routes/Campaigns/ReviewCampaignParticipationScreen";
// import ReviewCampaignRegistrationScreen1 from "./routes/Campaigns/ReviewCampaignRegistrationScreen1";

import ViewCampaignScreen from "./routes/Campaigns/ViewScreen";
import EditCampaignScreen1 from "./routes/Campaigns/EditCampaignScreen1";
import QRScreen from "./routes/CampaignDetails/QRScreen";
import EditProfileScreen from "./routes/ProfileScreen/EditProfileScreen";
import OrganizationViewScreen from "./routes/Organization/ViewScreen";
import OrganizationReviewScreen from "./routes/Organization/ReviewScreen";
import SponsorViewScreen from "./routes/Sponsor/SponsorViewScreen";
import SponsorReviewScreen from "./routes/Sponsor/SponsorReviewScreen";
import OrganizationViewAndReviewScreen from "./routes/Organization/ViewAndReviewScreen";
import DashboardScreen from "./components/DashboardScreen";
import PlatOnMap from "./components/PlatOnMap";
import VolunteerOnMap from "./components/VolunteerOnMap";
import SOADashboardScreen from "./components/SOADashboardScreen";
import MyCampaignScreen from "./routes/Campaigns/MyCampaignScreen";
import ResponsiveDrawer from "./components/Header";
import CampRegistrationStatus from "./routes/CampaignDetails/CampRegistrationStatus";
import MyPointsScreen from "./routes/MyPointsScreen";
import RegisterScreen from "./routes/OnboardVolunteer/RegisterScreen";
import PauseCampaignScreen from "./routes/Campaigns/PauseCampaignScreen";
import ResumeCampaignScreen from "./routes/Campaigns/ResumeCampaignScreen";
import Marketplace from "./routes/Marketplace";
// import AdminRewards from "./routes/Admin/AdminCampRewards";
import AdminCampRewards from "./routes/Admin/AdminCampRewards";
import AdminPFRewards from "./routes/Admin/AdminPFRewards";
import AdminCampRecognitions from "./routes/Admin/AdminCampRecognitions";
import AdminPFRecognitions from "./routes/Admin/AdminPFRecognitions";
import ManageRewards from "./routes/Admin/ManageRewards";
import RewardDeleteScreen from "./routes/Admin/RewardDeleteScreen";
import RewardEditScreen from "./routes/Admin/RewardEditScreen";
// import RewardEditScreen1 from "./routes/Admin/RewardEditScreen1";
import RewardsReport from "./routes/Admin/RewardsReport";
import NotificationArchive from "./components/NotificationArchive";
import LandingPage from "./routes/LandingPage/LandingPage";
import Form from "./components/Form";
import MyOngoingCampaign from "./routes/Campaigns/MyOngoingCampaign";
import MyOngoingCampaignPrivate from "./routes/Campaigns/MyOngoingCampaignPrivate";
import { Box } from "@mui/material";
import VideoDetails from "./components/VideoDetails";
import DeleteFileDetails from "./components/DeleteFileDetails";
import DeletePersonalFileDetails from "./components/DeletePersonalFileDetails";

import Chat from "./components/Chat";
// import socketIO from 'socket.io-client';
import Join from "./components/Join/Join";
import VolParticipationStat from "./routes/OnboardVolunteer/VolParticipationStat";
import PDF from "./components/PDF";
import Participate from "./components/Participate";
import Aboutus from "./routes/Aboutus/Aboutus";
import AboutusFromLanding from "./routes/Aboutus/AboutusFromLanding";
import RedeemReport from "./routes/Admin/RedeemReport";
import VolRegistrationStat from "./routes/OnboardVolunteer/VolRegistrationStat";
import CampReport from "./components/CampReport";
import ReviewUserOnboarding from "./routes/OnboardVolunteer/ReviewUserOnboarding";
import SponsorAdminDashboard from "./components/SponsorAdminDashboard";
import SponsorAdminReport from "./components/SponsorAdminReport";
import DataAlertReport from "./components/DataAlertReport";
import CampLeaderBoardPage from "./routes/CampaignDetails/CampLeaderBoardPage";
import SOActionOnRewardsRedeemed from "./routes/Admin/SOActionOnRewardsRedeemed";
import SearchPage from "./routes/SearchPage/SearchPage";
import EmailVerification from "./components/EmailVerification";
import UserPerformanceReport from "./routes/Admin/UserPerformanceReport";
import UserPerformanceReportDetails from "./routes/Admin/UserPerformanceReportDetails";
import CampOReport from "./components/CampOReport";
import EmailVerificationPostRegistration from "./components/EmailVerificationPostRegistration";
// import MyCommunity from "./routes/OnboardVolunteer/MyCommunity06jun2023";
import MyCommunity from "./routes/OnboardVolunteer/MyCommunity";
import PhoneNumberChange from "./components/PhoneNumberChange";
import SocialProfileExternal from "./routes/ProfileScreen/SocialProfileExternal";
import Error from "./routes/Error"
import Protected from "./components/Protected";
import OnboardNewRewards from "./routes/Admin/OnboardNewRewards";
import PFRewardsReport from "./routes/Admin/PFRewardsReport";
import OnboardRecognitions from "./routes/Admin/OnboardRecognitions";
import PFRewardsDetailReport from "./routes/Admin/PFRewardsDetailReport";
import SponsorBasedReport from "./routes/Admin/SponsorBasedReport";
import SOACampaignImpact from "./components/SOACampaignImpact";
import SubscriptionListScreen from "./routes/Organization/SubscriptionListScreen";
import SOAManageCampOrder from "./components/SOAManageCampOrder";
import AD from "./routes/AD";
import GetAToken from "./routes/GetAToken";
import FinalDestination from "./routes/FinalDestination";
import CampaignDirectPage from "./routes/Campaigns/CampaignDirectPage";
import RegForm from "./routes/Forms/RegForm";
import CampaignPage from "./routes/Campaigns/CampaignPage";
import PAManageLandingCampOrder from "./components/PAManageLandingCampOrder";
import CreateTeam from "./routes/Teams/CreateTeam";
import MyTeam from "./routes/Teams/MyTeam";
import Team from "./routes/Teams/Team";
import EditTeam from "./routes/Teams/EditTeam";
import PrivacyPolicy from "./routes/PrivacyAndRules/Privacypolicy";
import TermsCondition from "./routes/PrivacyAndRules/TermsCondition";
import PrivacyPolicyLanding from "./routes/PrivacyAndRules/PrivacypolicyLanding";
import TermsConditionLanding from "./routes/PrivacyAndRules/TermsConditionLanding";
import EditTeamCampaign from "./routes/Teams/EditTeamCampaign";
import JoinTeam from "./routes/Teams/JoinTeam";
import TeamFAQ from "./routes/Teams/TeamFAQ";
import EditProfileExpertise from "./routes/ProfileScreen/EditProfileExpertise";
import EditProfilePersonal from "./routes/ProfileScreen/EditProfilePersonal";
import EditProfileSkills from "./routes/ProfileScreen/EditProfileSkills";
import EditProfileInterestAreas from "./routes/ProfileScreen/EditProfileInterestAreas";
import PGRegisterScreen from "./routes/PaymentGateway/PGRegisterScreen";
import EmailVerificationPG from "./components/EmailVerificationPG";
import PGVerify from "./routes/PaymentGateway/PGVerify";
import GetStarted from "./components/GetStarted";
import CMDashboardScreen from "./components/CMDashboardScreen";
import PGRegisterScreenTemp from "./routes/PaymentGateway/PGRegisterScreenTemp";
import PGPaymentStatus from "./routes/PaymentGateway/PGPaymentStatus";
import PGContactSales from "./routes/PaymentGateway/PGContactSales";
import EmailVerificationPGContactSales from "./components/EmailVerificationPGContactSales";
import PGContactSalesConfirm from "./routes/PaymentGateway/PGContactSalesConfirm";
import ContactUs from "./components/ContactUs";
import {WebSocketProvider} from './components/WebSocketContext';
import ContactList from "./components/ContactList";
import BroadcastMessage from "./components/BroadcastMessage";
import BroadcastArchive from "./components/BroadcastArchive";

function App() {
  const [notificationShow, setNotificationShow] = useState(false);
  
  // const socket = socketIO.connect('http://localhost:4000');
  // const socket = socketIO('http://localhost:5000');
  return (
    <Provider store={store}>
      <ThemeProvider theme={appTheme}>
      <WebSocketProvider>
        <Router>
          <Routes>
            <Route path="/login"  element={<LoginScreen />} />
            <Route path="/"  exact={true} element={<LandingPage />} />
            <Route path="/landing"  exact={true} element={<LandingPage />} />
            <Route path="/searchpage"  element={<Protected Component={SearchPage} />} />
            <Route path="/Otp"  element={<Otp />} />
            <Route path="/mypoints"  element={<Protected Component={MyPointsScreen}/>} />
            {/* <Route path="/register"  element={<Protected Component={RegisterScreen}/>} /> */}
            <Route path="/register"  element={<RegisterScreen/>} />
            <Route path="/marketplace"  element={<Protected Component={Marketplace}/>} />
            {/* <Route path="/addrewards"  element={<Protected Component={AdminRewards}/>} /> */}
            <Route path="/addcamprewards"  element={<Protected Component={AdminCampRewards}/>} />
            <Route path="/addpfrewards"  element={<Protected Component={AdminPFRewards}/>} />
            <Route path="/addcamprecog"  element={<Protected Component={AdminCampRecognitions}/>} />
            <Route path="/addpfrecog"  element={<Protected Component={AdminPFRecognitions}/>} />
            <Route path="/reviewrewards"  element={<Protected Component={ManageRewards}/>} />
            <Route path="/rewardsreport"  element={<Protected Component={RewardsReport}/>} />
            <Route path="/notificationarchive"  element={<NotificationArchive/>} />
            <Route path="/campmapview"  element={<PlatOnMap/>} />
            <Route path="/volmapview"  element={<VolunteerOnMap/>} />
            
            <Route path="/myongoingcampaigns"  element={<Protected Component={MyOngoingCampaign}/>} />
            <Route path="/myongoingcampaignspvt"  element={<Protected Component={MyOngoingCampaignPrivate}/>} />
            <Route path="/video/:id/:campid/:userid"  element={<Protected Component={VideoDetails} />}/>
            <Route path="/file/:id/:campid/:userid"  element={<Protected Component={DeleteFileDetails} />}/>
            <Route path="/file/personal/:id/:campid/:userid"  element={<Protected Component={DeletePersonalFileDetails} />}/>
            <Route path="/chat"  element={<Chat />}/>
            <Route path="/join"  element={<Join />}/>
            <Route path="/vol/stat/report/:phoneno/:regid/:campid"  element={<Protected Component={VolParticipationStat} />}/>
            <Route path="/vol/reg/report/:phoneno/:regid/:campid"  element={<Protected Component={VolRegistrationStat} />}/>
            <Route path="/pdf"  element={<PDF />}/>
            <Route path="/participate/campaign/:campid/:phoneno"  element={<Protected Component={Participate} />}/>
            <Route path="/participate/camp/:title"  element={<Protected Component={Participate} />}/>
            <Route path="/aboutus"  element={<Aboutus />}/>
            <Route path="/moreaboutus"  element={<AboutusFromLanding />}/>
            <Route path="/redeemreport"  element={<Protected Component={RedeemReport} />}/>
            <Route path="/campreport"  element={<Protected Component={CampReport} />}/>
            <Route path="/reviewcm"  element={<Protected Component={ReviewUserOnboarding} />}/>
            <Route path="/sponsoradmindashboard"  element={<Protected Component={SponsorAdminDashboard}/>}/>
            <Route path="/sponsoradminreport"  element={<Protected Component={SponsorAdminReport}/>}/>
            <Route path="/dataalertreport"  element={<Protected Component={DataAlertReport} />}/>
            <Route path="/camp/leaderboard/:campid"  element={<Protected Component={CampLeaderBoardPage} />}/>
            <Route path="/redeemed"  element={<Protected Component={SOActionOnRewardsRedeemed} />}/>
            
            <Route path="/emailverify/:phoneno/:email"  element={<EmailVerification />}/>
            <Route path="/userperfreport"  element={<Protected Component={UserPerformanceReport} />}/>
            <Route path="/user/report/:userid/:phoneno"  element={<Protected Component={UserPerformanceReportDetails} />}/>
            <Route path="/campownerreport"  element={<Protected Component={CampOReport} />}/>
            <Route path="/newprofile/:phoneno"  element={<Protected Component={SocialProfile} />}/>
            {/* <Route path="/newestprofile/:phoneno"  element={<SocialProfileNew />}/> */}
            <Route path="/emailverifypr/:phoneno/:email"  element={<EmailVerificationPostRegistration />}/>
            <Route path="/mycommunity/:phoneno"  element={<Protected Component={MyCommunity} />}/>
            <Route path="/phonenochange/:phoneno"  element={<Protected Component={PhoneNumberChange} />}/>
            <Route path="/otpphonechange"  element={<OtpPhoneChange />} />
            <Route path="/cm/:profileurl" element={<SocialProfileExternal />}/>
            <Route path="/onboardrewards"  element={<Protected Component={OnboardNewRewards} />}/>
            <Route path="/pfrewardsreport"  element={<Protected Component={PFRewardsReport} />}/>
            <Route path="/onboardrecognitions"  element={<Protected Component={OnboardRecognitions} />}/>
            <Route path="/camp/detail/report/:phoneno/:campid"  element={<Protected Component={PFRewardsDetailReport} />}/>
            <Route path="/sponsorreport"  element={<Protected Component={SponsorBasedReport} />}/>
            <Route path="/campaignimpact"  element={<Protected Component={SOACampaignImpact} />}/>
            <Route path="/managecamporder"  element={<Protected Component={SOAManageCampOrder} />}/>
            <Route path="/entra"  element={<Protected Component={AD} />}/>
            <Route path="/getAToken"  element={<GetAToken />}/>
            {/* <Route path="/finaldestination"  element={<Protected Component={FinalDestination} />}/> */}
            <Route path="/finaldestination"  element={<FinalDestination/>}/>
            {/* <Route path="/campaign/xyz/:param1/:param2/:param3"  element={<CampaignDirectPage/>}/> */}
            <Route path="/campaign/xyz/*"  element={<CampaignDirectPage/>}/>
            {/* <Route path="/*"  element={<CampaignDirectPage/>}/> */}
            <Route path="/regform"  element={<Protected Component={RegForm} />}/>
            <Route path="/campaign/:title"  element={<CampaignPage/>}/>
            <Route path="/managelandingcamporder"  element={<Protected Component={PAManageLandingCampOrder} />}/>

            <Route path="/create/team"  element={<Protected Component={CreateTeam} />} />
            <Route path="/my/team"  element={<Protected Component={MyTeam} />}/>
            {/* <Route path="/team/home/:teamid/:phoneno"  element={<Protected Component={Team} />}/> */}
            <Route path="/team/home/:teamid"  element={<Protected Component={Team} />}/>
            <Route path="/edit/team/:teamid"  element={<Protected Component={EditTeam} />} />
            <Route path="/privacy-policy"  element={<PrivacyPolicy/>}/>
            <Route path="/terms-condition"  element={<TermsCondition/>}/>
            <Route path="/privacy-policy/landing"  element={<PrivacyPolicyLanding/>}/>
            <Route path="/terms-condition/landing"  element={<TermsConditionLanding/>}/>
            <Route path="/edit/team/campaign/:teamid"  element={<Protected Component={EditTeamCampaign} />} />
            <Route path="/join/team"  element={<Protected Component={JoinTeam} />} />
            <Route path="/about/team"  element={<Protected Component={TeamFAQ} />} />
            <Route path="/edit/user/profile/personal" element={ <Protected Component={EditProfilePersonal} />} />         
            <Route path="/edit/user/profile/skills" element={ <Protected Component={EditProfileSkills} />} />         
            <Route path="/edit/user/profile/interestareas" element={ <Protected Component={EditProfileInterestAreas} />} />         
            <Route path="/pg/register" element={<PGRegisterScreen />}  />
            <Route path="/emailverifypg/:phoneno/:email"  element={<EmailVerificationPG />}/>
            <Route path="/pg/verify"  element={<PGVerify />}/>
            <Route path="/getstarted"  element={<GetStarted />}/>
            <Route path="/cm/insights"  element={ <Protected Component={CMDashboardScreen} />}/>
            <Route path="/pg/registertemp" element={<PGRegisterScreenTemp />}  />
            {/* <Route path="/verifypg"  element={<PGVerify />}/> */}
            {/* <Route path="pg/payment/status/:tid" element={<PGPaymentStatus />}  /> */}
            <Route path="/pg/contactsales" element={<PGContactSales />}  />
            <Route path="/emailverifypgsales/:phoneno/:email"  element={<EmailVerificationPGContactSales />}/>
            <Route path="/pg/contactsales/confirm"  element={<PGContactSalesConfirm />}/>
            <Route path="/pg/payment/confirm/:status"  element={<PGPaymentStatus />}/>
            <Route path="/contactus"  element={<ContactUs />}/>
            <Route path="/contactlist"  element={ <Protected Component={ContactList}/>}/>
            <Route path="/broadcastmsg"  element={<Protected Component={BroadcastMessage} />}/>
            <Route path="/announcement"  element={<Protected Component={BroadcastArchive} />}/>

            <Route
              path="/createorganization"
              element={
                <Protected Component={CreateOrganization}
                  // notificationShow={notificationShow}
                  // setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/orgview/:clientid"
              
              element={
                <Protected Component={OrganizationViewScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/orgreview/:clientid"
              
              element={
                <Protected Component={OrganizationViewAndReviewScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/createsponsor"
              
              element={
                <Protected Component={CreateSponsor}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/sponsorview/:clientid"
              
              element={
                <Protected Component={SponsorViewScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/onboardvolunteer"
              
              element={
                <Protected Component={OnBoardVolunteer}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            {/* <Route
              path="/onboardvolunteer1"
              
              element={
                <OnBoardVolunteer1
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            /> */}
            <Route
              // path="/createcampaign1"
              path="/createcampaign1/:campname"
              
              element={
                <Protected Component={CreateCampaign}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/createcampaign"
              
              element={
                <Protected Component={CreateScreenMenu}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            {/* <Route
              path="/createcampaign2"
              
              element={
                <CreateCampaign2
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/createcampaign3"
              
              element={
                <CreateCampaign3
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            /> */}
            <Route
              path="/mycampaignview"
              
              element={
                <Protected Component={MyCampaignScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/campaignregisterstatus"
              
              element={
                <Protected Component={CampRegistrationStatus}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              // path="/hacampscreen"
              path="/home"
              element={
                <Protected Component={HAScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/createactivity"
              
              element={
                <CreateActivity
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/volunteerlist"
              element={
                <VolunteerListScreen
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/reviewvolunteer"
              element={
                <Protected Component={VolunteerManageScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/editvolunteer/:phoneno"
              
              element={
                <Protected Component={VolunteerEditScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/deletevolunteer/:phoneno"
              
              element={
                <Protected Component={VolunteerDeleteScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/activitylist"
              
              element={
                <ActivityListScreen
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
                        
            <Route
              path="/reviewcampaign"
              
              element={
                <Protected Component={ReviewListScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/editcampaign/:campid"
              
              element={
                <Protected Component={EditCampaignScreen1}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/pausecampaign/:campid"
              
              element={
                <Protected Component={PauseCampaignScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/resumecampaign/:campid"
              
              element={
                <Protected Component={ResumeCampaignScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/viewcampaign/:campid"
              
              element={
                <Protected Component={ViewCampaignScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/reviewcampaignregistration"
              
              element={
                <Protected Component={ReviewCampaignRegistrationScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/reviewcampaignparticipation"
              
              element={
                <Protected Component={ReviewCampaignParticipationScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/organizationlist"
              
              element={
                <Protected Component={OrganizationListScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/sponsorlist"
              
              element={
                <Protected Component={SponsorListScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/subscriptionlist"
              
              element={
                <Protected Component={SubscriptionListScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/editorg/:clientid"
              
              element={
                <Protected Component={EditOrganizationScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/editsponsor/:sponsorid"
              
              element={
                <Protected Component={EditSponsorScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/editreward/:rewardid"
              
              element={
                <RewardEditScreen
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/deletereward/:phoneno"
              
              element={
                <Protected Component={RewardDeleteScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/dashboard"
              
              element={
                <Protected Component={DashboardScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/SOAdashboard"
              
              element={
                <Protected Component={SOADashboardScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/edit/user/profile/expertise"
              
              element={
                <Protected Component={EditProfileExpertise}  />
              }
            />            
            <Route
              path="/adoption/:campid/:msg"
              
              element={
                <Protected Component={QRScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/revieworganization"
              
              element={
                <Protected Component={OrganizationReviewScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />
            <Route
              path="/reviewsponsor"
              
              element={
                <Protected Component={SponsorReviewScreen}
                  notificationShow={notificationShow}
                  setNotificationShow={setNotificationShow}
                />
              }
            />

            <Route element={<Error />}/>
          
          </Routes>
          
        </Router>
        </WebSocketProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
